import {
  faBan,
  faCheckCircle,
  faCreditCard,
  faExclamationCircle,
  faIdBadge,
  faPlusCircle,
  faRibbon,
  faShieldAlt,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconComponentCard from 'components/common/FalconComponentCard';
import Flex from 'components/common/Flex';
import PageHeader from 'components/common/PageHeader';
import AddPaymentCard from 'components/stripe/AddPaymnetCard';
import AppContext from 'context/Context';
import segmentHelper from 'helpers/segment-helper';
import { capitalize } from 'helpers/utils';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip
} from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { refreshAllAccountInfo } from 'stores/app';
import {
  paymentMethodDelete,
  paymentMethodGet,
  updateDefaultPaymentMethod
} from 'stores/subscription';
import api from 'util/api';
import logger from 'util/logger';

const PaymentMethodStripe = () => {
  const { paymentCards } = useSelector(state => state.subscription);
  const [loadingPaymentCards, setLoadingPaymentCards] = useState(false);
  const [removingPaymentMethod, setRemovingPaymentMethod] = useState(null);
  const [savingCard, setSavingCard] = useState(false);
  const [changingDefaultMethod, setChangingDefaultMethod] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [addCardMessage, setAddCardMessage] = useState(null);
  const [modalJourny, setModalJourny] = useState(null);
  const [paymentCardDisplay, setPaymentCardDisplay] = useState(null);

  const cc_allowed_year = 25;
  const start_year = moment().year();
  const exp_year = _.range(
    moment().year(),
    moment().year() + cc_allowed_year,
    1
  );

  useEffect(() => {
    setPaymentCardDisplay(paymentCards);
  }, [paymentCards]);

  const { register, getValues, setValue, handleSubmit } = useForm({
    defaultValues: {
      number: '',
      name: '',
      exp_month: '',
      exp_year: '',
      cvv: ''
    }
  });

  const {
    config: { isRTL }
  } = useContext(AppContext);

  const dispatch = useDispatch();

  const initPaymentCards = () => {
    setLoadingPaymentCards(true);
    dispatch(paymentMethodGet())
      .then(res => {
        setLoadingPaymentCards(false);
      })
      .catch(err => {
        setLoadingPaymentCards(false);
      });
  };

  useEffect(() => {
    initPaymentCards();
  }, []);

  const confirmRemovePaymentMethod = pm_id => {
    confirmAlert({
      //title: 'Confirmation',
      message: 'Are you sure you want to delete this payment method?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => proceedToDeletePaymentMethod(pm_id)
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  const proceedToDeletePaymentMethod = pm_id => {
    setRemovingPaymentMethod(pm_id);
    dispatch(paymentMethodDelete(pm_id))
      .then(res => {
        dispatch(paymentMethodGet());
        setRemovingPaymentMethod(null);
        if (res?.Success) {
          segmentHelper.track(segmentHelper.events.PAYMENT_METHOD_REMOVED);
          toast.success(res.Message || 'Payment method removed successfully.', {
            theme: 'colored',
            position: 'bottom-center',
            icon: faCheckCircle,
            toastId: 'payment-methhod-remove'
          });
        } else {
          toast.warning(
            res.Message || 'Unable to remove this payment method. try again',
            {
              theme: 'colored',
              position: 'bottom-center',
              icon: faExclamationCircle,
              toastId: 'payment-methhod-remove'
            }
          );
        }
      })
      .catch(err => {
        toast.warning('Unable to remove this payment method. try again', {
          theme: 'colored',
          position: 'bottom-center',
          icon: faExclamationCircle,
          toastId: 'payment-methhod-remove'
        });
        setRemovingPaymentMethod(null);
      });
  };

  const setResponseMessage = (message, variant) => {
    setAddCardMessage(
      message
        ? { message: capitalize(message), variant: variant || 'primary' }
        : null
    );
  };

  const addPaymentMethod = () => {
    setResponseMessage();
    setModalJourny(null);
    setModalShow(true);
  };

  const cancelAddPaymentMethod = () => {
    setModalShow(false);
    setResponseMessage();
    setModalJourny(null);
  };

  const handlePaymentIdMethod = async res => {
    setSavingCard(true);
    try {
      new Promise((resolve, reject) => {
        logger.log('handlePaymentIdMethod res', res);
        if (res) resolve(api.PaymentCardV2.create(res.id));
        else reject(false);
        //resolve(api.PaymentCardV2.create('pm_card_visa'))
      })
        .then(paymentCardRes => {
          dispatch(refreshAllAccountInfo());
          setSavingCard(false);
          cancelAddPaymentMethod();
          initPaymentCards();
          setResponseMessage('Payment card added successfully.', 'success');
        })
        .catch(err => {
          setSavingCard(false);
        });
    } catch (err) {
      setSavingCard(true);
      logger.log('payment update error', err);
      setResponseMessage(
        err.Message || 'Unable to save payment method. Please try again.',
        'danger'
      );
      setModalJourny(
        err.Message || 'Unable to save payment method.  Please try again.'
      );
    }
  };

  const markDefaultPaymentMethod = method => {
    if (method.Active) {
      setChangingDefaultMethod(method.GUID);
      dispatch(updateDefaultPaymentMethod(method.GUID))
        .then(res => {
          dispatch(paymentMethodGet()).then(res => {
            setChangingDefaultMethod(null);
            toast.success(
              res.Message || 'Card set as default payment method.',
              {
                theme: 'colored',
                position: 'bottom-center',
                icon: faCheckCircle,
                toastId: 'payment-methhod-change'
              }
            );
          });
        })
        .catch(err => {
          toast.warning(
            res.Message || 'Unable to set default payment method.',
            {
              theme: 'colored',
              position: 'bottom-center',
              icon: faExclamationCircle,
              toastId: 'payment-methhod-change'
            }
          );
        });
    }
  };

  return (
    <>
      <PageHeader
        title="Payment method"
        description="List of payment card added on stripe account for billing ."
        className="mb-3"
        titleTag="h2"
      ></PageHeader>

      <Row className="mb-3 g-3">
        <Col lg={12}>
          <FalconComponentCard noGuttersBottom>
            <FalconComponentCard.Header
              title="Added payment cards"
              endEl={
                <Button onClick={() => addPaymentMethod()} variant="primary">
                  <FontAwesomeIcon icon={faPlusCircle} size="sm" />{' '}
                  <span className="d-none d-md-inline">Add New Card</span>
                </Button>
              }
            />
            <hr className="mt-0 pt-0" />
            <FalconComponentCard.Body noLight className="py-0">
              {loadingPaymentCards ? (
                <Flex
                  justifyContent={'justify'}
                  direction="column"
                  alignContent="center"
                  alignItems="center"
                  className="text-center my-3 py-3"
                >
                  <Spinner />
                  <p className="mt-3 text-muted">Loading payment method</p>
                </Flex>
              ) : (
                <Col sm={12} md={12} className="pe-lg-2 mb-3">
                  <Row>
                    {_.map(
                      _.filter(paymentCardDisplay, p => p.Active),
                      method => {
                        return (
                          <Col
                            key={method.GUID}
                            sm={12}
                            md={6}
                            lg={4}
                            xl={3}
                            className="my-3"
                          >
                            <div className="position-relative">
                              {method.IsDefaultPaymentMethod && (
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip
                                      style={{ position: 'fixed' }}
                                      id="overlay-trigger-example"
                                    >
                                      Default Payment Method
                                    </Tooltip>
                                  }
                                >
                                  <div className="defualtpaymentmethod-badge text-success">
                                    <FontAwesomeIcon
                                      size="2x"
                                      icon={faShieldAlt}
                                    />
                                  </div>
                                </OverlayTrigger>
                              )}
                              <Card
                                bg={
                                  method.Active
                                    ? 'soft-success'
                                    : method.is_valid_pm
                                    ? 'primary'
                                    : 'secondary'
                                }
                                text="dark"
                              >
                                <Card.Body>
                                  <Card.Title
                                    as="div"
                                    className="d-flex align-items-center justify-content-between"
                                  >
                                    <div>
                                      {changingDefaultMethod === method.GUID ? (
                                        <Spinner size="sm" />
                                      ) : (
                                        <Form.Check
                                          size={'sm'}
                                          type="switch"
                                          disabled={!method.Active}
                                          onChange={() =>
                                            markDefaultPaymentMethod(method)
                                          }
                                          id={'method-switch' + method.GUID}
                                          checked={
                                            method.IsDefaultPaymentMethod
                                          }
                                          defaultChecked={
                                            method.IsDefaultPaymentMethod
                                              ? true
                                              : false
                                          }
                                        />
                                      )}
                                    </div>
                                    <div>
                                      <span className="fs-0">
                                        xxxx xxxx xxxx
                                      </span>{' '}
                                      <span className="fs-2">
                                        {method.CardLast4}
                                      </span>
                                    </div>
                                  </Card.Title>

                                  <Flex justifyContent="between fs--1">
                                    <Card.Text>
                                      {method.Active ? (
                                        <div>
                                          {/* <FontAwesomeIcon icon={faCheckCircle} />{' '} */}
                                          Active
                                        </div>
                                      ) : (
                                        <div className="text-warning">
                                          {/* <FontAwesomeIcon icon={faBan} />{' '} */}
                                          InActive
                                        </div>
                                      )}
                                    </Card.Text>
                                  </Flex>
                                  <Flex justifyContent="between fs--1">
                                    <Card.Text>
                                      {method.ExpMonth} / {method.ExpYear}
                                    </Card.Text>
                                    <Card.Text className="text-end">
                                      {method.CardType}
                                    </Card.Text>
                                  </Flex>
                                  <Flex justifyContent="between fs--1 mt-3">
                                    {method.Active ? (
                                      <Card.Text className="mb-0 mt-1">
                                        {removingPaymentMethod ===
                                        method.GUID ? (
                                          <span>
                                            <Spinner size="sm" />
                                          </span>
                                        ) : (
                                          <div>
                                            <FontAwesomeIcon
                                              icon={faTrashAlt}
                                              className="cursor-pointer"
                                              onClick={() => {
                                                confirmRemovePaymentMethod(
                                                  method.GUID
                                                );
                                              }}
                                            />
                                          </div>
                                        )}
                                      </Card.Text>
                                    ) : (
                                      <div> </div>
                                    )}
                                    <Card.Text className="text-end mb-0">
                                      Added on{' '}
                                      <span className="fs-1">
                                        {moment(method.Created)
                                          .local()
                                          .format('MM/DD/YYYY')}
                                      </span>
                                    </Card.Text>
                                  </Flex>
                                </Card.Body>
                              </Card>
                            </div>
                          </Col>
                        );
                      }
                    )}
                  </Row>
                </Col>
              )}

              {!loadingPaymentCards && paymentCardDisplay?.length == 0 && (
                <Col sm={12} className="text-center text-muted">
                  <p>
                    <FontAwesomeIcon icon={faCreditCard} size="2x" />
                  </p>
                  <p>You have not added payment method yet.</p>
                </Col>
              )}
            </FalconComponentCard.Body>
          </FalconComponentCard>
        </Col>
      </Row>

      <Modal
        show={modalShow}
        onHide={() => cancelAddPaymentMethod()}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Add Card</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <AddPaymentCard
                handlePaymentIdMethod={handlePaymentIdMethod}
                isSaving={savingCard}
                setSaving={setSavingCard}
              />
            </Col>
            {addCardMessage && (
              <div className="flex-1 mt-1">
                <Alert variant={addCardMessage.variant}>
                  <p className="py-0 my-0">{addCardMessage.message}</p>
                </Alert>
              </div>
            )}
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PaymentMethodStripe;
