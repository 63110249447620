import FalconCloseButton from 'components/common/FalconCloseButton';
import Flex from 'components/common/Flex';
import SignatureScratchPad from 'components/common/SignatureScratchPad';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const SignatureModalByMark = ({
  author,
  signatureForType,
  signatureForId,
  showSignatureByMarkForm,
  setShowSignatureByMarkForm,
  setSignatureByMarkDataFunc
}) => {
  const [enableSignatureSave, setEnableSignatureSave] = useState(false);
  const [strokeWidth, setStrokWidth] = useState(1.5);

  let signaturePad = {};

  const clearSignaturePad = () => {
    signaturePad.clear();
  };

  const saveSignerSignature = (signatureDataObject) => {
    // let signatureImage = signaturePad.getTrimmedCanvas().toDataURL('image/png');
    // if (signatureImage.length === 0) {
    //   dispatch(
    //     showAlert(
    //       'Unable to get Signature',
    //       'There was a problem generating this signature image.  Please try again.'
    //     )
    //   );
    //   return;
    // }

    // let signatureDataObject = {
    //   signatureForId: signatureForId,
    //   data: _.replace(signatureImage, 'data:image/png;base64,', ''),
    //   content_type: 'image/png',
    //   dataUri: signatureImage
    // };
    setSignatureByMarkDataFunc(signatureDataObject);
    //setShowSignatureByMarkForm(false);
  };

  return (
    <Modal
      show={showSignatureByMarkForm}
      onHide={() => setShowSignatureByMarkForm(false)}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>
          Add Signature
          <p className="fs--1 mb-0">
            for <span className="fw-bold fs-0"> {author} </span>
          </p>
        </Modal.Title>
        <FalconCloseButton onClick={() => setShowSignatureByMarkForm(false)} />
      </Modal.Header>
      <Modal.Body>
        {/* <Flex justifyContent="center">
          <ReactSignatureCanvas
            ref={ref => (signaturePad = ref)}
            penColor="black"
            minWidth={strokeWidth - 1}
            maxWidth={strokeWidth + 1}
            dotSize={1.5}
            canvasProps={{ width: 430, height: 300, className: 'sigCanvas' }}
            onEnd={() => setEnableSignatureSave(true)}
          />
        </Flex> */}
        <SignatureScratchPad setSignatureData={saveSignerSignature} signatureSignerId={signatureForId} closeEle={<Button
          className="mx-1"
          variant="danger"
          onClick={() => setShowSignatureByMarkForm(false)}
        >
          Close
        </Button>} />
      </Modal.Body>

    </Modal>
  );
};
SignatureModalByMark.propTypes = {
  author: PropTypes.string,
  signatureForType: PropTypes.string,
  signatureForId: PropTypes.string,
  showSignatureByMarkForm: PropTypes.bool.isRequired,
  setShowSignatureByMarkForm: PropTypes.func.isRequired,
  setSignatureByMarkDataFunc: PropTypes.func.isRequired
};

export default SignatureModalByMark;
