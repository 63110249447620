import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import ValidationErrors from 'components/utilities/ValidationErrors';
import AppContext, { AuthWizardContext } from 'context/Context';
import segmentHelper from 'helpers/segment-helper';
import { useAuth } from 'hooks/useAuth';
import moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useContext, useState } from 'react';
import { Button, Card, Form, Nav, ProgressBar, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { refreshAllAccountInfo } from 'stores/app';
import { setPreAuthEmail, setToken } from 'stores/authentication';
import api from 'util/api';
import c from 'util/const';
import AccountForm from './AccountForm';
import CommissionForm from './CommissionForm';
import PersonalForm from './PersonalForm';
import Success from './Success';
import WizardModal from './WizardModal';
import _ from 'lodash';
import logger from 'util/logger';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import checkPrimaryLight from 'assets/img/animated-icons/check-primary-light.json';
import Lottie from 'lottie-react';
import store from 'app/store';
import { showAlert } from 'stores/modal';
import { Link } from 'react-router-dom';

const WizardLayout = ({ variant, validation, progressBar }) => {
  const { isRTL } = useContext(AppContext);
  const [windowClose, setWindowClose] = useState(false);
  const { user, setUser, step, setStep } = useContext(AuthWizardContext);
  const [stateData, setStateData] = useState({
    hasEmailBeenValidated: false,
    boardingEmailValidating: false,
    boardingEmailIsValid: false,
    creatingAccount: false,
    finishingAccount: false,
    validationErr: []
  });

  const auth = useAuth();
  const dispatch = useDispatch();
  const getParsedQuerystring = () => {
    //This is pretty dumb.  Apparently the response from querystring.parse is not a serializable object
    //even though it is.  You get react errors if you pass this parsed result to initStartupQS.  booooooo.
    return _.extend({}, queryString.parse(location.search));
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
    clearErrors,
    getValues
  } = useForm();

  const [modal, setModal] = useState(false);

  const navItems = [
    {
      icon: 'lock',
      label: 'Account'
    },
    {
      icon: 'user',
      label: 'Personal'
    },
    {
      icon: 'dollar-sign',
      label: 'Commission'
    },
    {
      icon: 'thumbs-up',
      label: 'Done'
    }
  ];

  const onSubmitData = data => {
    setUser({ ...user, ...data });
  };
  const onError = () => {
    if (!validation) {
      clearErrors();
      //setStep(step + 1);
    }
  };

  const toggle = () => setModal(!modal);

  const handleNavs = targetStep => {
    if (step !== 4) {
      if (targetStep < step) {
        setStep(targetStep);
      } else if (doValidateSignupForm()) {
        if (step == 1) {
          doValidateEmail(getValues('email')).then(function (res) {
            logger.log('doValidateEmail res', res);
            if (res) {
              handleSubmit(onSubmitData, onError)();
              setStep(step + 1);
            }
          });
        } else if (step == 3) {
          setStateData(stateData => ({
            ...stateData,
            finishingAccount: true
          }));
          handleSubmit(submitSignupFlow, onError)();
        } else {
          handleSubmit(onSubmitData, onError)();
          setStep(targetStep);
        }
      }
    } else {
      toggle();
    }
  };

  const doValidateSignupForm = () => {
    if (step === 1) {
      if (!getValues('first_name')) {
        toast.error(`First name required`, {
          position: 'bottom-center',
          theme: 'colored'
        });
        return false;
      } else if (!getValues('last_name')) {
        toast.error(`Last name required`, {
          position: 'bottom-center',
          theme: 'colored'
        });
        return false;
      } else if (!getValues('email')) {
        toast.error(`Email required`, {
          position: 'bottom-center',
          theme: 'colored'
        });
        return false;
      } else if (!getValues('pwd')) {
        toast.error(`Password required`, {
          position: 'bottom-center',
          theme: 'colored'
        });
        return false;
      } else if (!getValues('agreedToTerms')) {
        toast.error(
          `You must agree to the terms and privacy policy to continue`,
          {
            position: 'bottom-center',
            theme: 'colored'
          }
        );
        return false;
      } else {
        return true;
      }
    } else if (step === 3) {
      if (!getValues('commission_name')) {
        toast.error(`Commission name required`, {
          position: 'bottom-center',
          theme: 'colored'
        });
        return false;
      } else if (!getValues('state')) {
        toast.error(`State required`, {
          position: 'bottom-center',
          theme: 'colored'
        });
        return false;
      } else if (!getValues('localtimezone')) {
        toast.error(`Timezone required`, {
          position: 'bottom-center',
          theme: 'colored'
        });
        return false;
      } else return true;
    } else return true;
  };

  const doValidateEmail = async email => {
    logger.log('email', email);
    setStateData(stateData => ({
      ...stateData,
      boardingEmailIsValid: false,
      boardingEmailValidating: true,
      validationErr: []
    }));

    return api.ValidateEmail.validate(email)
      .then(res => {
        if (res && res.Valid) {
          setStateData(stateData => ({
            ...stateData,
            hasEmailBeenValidated: true,
            boardingEmailIsValid: true,
            boardingEmailValidating: false
          }));
          return true;
        } else {
          setStateData(stateData => ({
            ...stateData,
            boardingEmailIsValid: false,
            boardingEmailValidating: false,
            validationErr: _.concat(
              [
                'Your address could not be validated.  Did you type it correctly?'
              ],
              stateData.validationErr
            )
          }));
          return false;
        }
      })
      .catch(err => {
        setStateData(stateData => ({
          ...stateData,
          boardingEmailIsValid: false,
          boardingEmailValidating: false,
          validationErr: _.concat(
            [
              'There was a problem validating your email address.  Please try again.'
            ],
            stateData.validationErr
          )
        }));
        return false;
      });
  };

  const submitSignupFlow = async signupData => {
    let {
      email,
      pwd,
      first_name,
      last_name,
      state,
      county,
      commission_number,
      commission_exp,
      commission_name,
      leadSource,
      campaignId,
      phone,
      SmsOptIn,
      localtimezone
    } = signupData;
    setStateData(stateData => ({
      ...stateData,
      creatingAccount: true,
      validationErr: []
    }));
    let expDate = commission_exp ? moment(commission_exp) : null;
    return api.Boarding.setup(
      first_name,
      last_name,
      email,
      pwd,
      commission_name,
      state,
      commission_number,
      county,
      expDate,
      leadSource,
      campaignId,
      phone,
      SmsOptIn,
      localtimezone,
      true
    )
      .then(res => {
        if (res?.Success == false) {
          setStateData(stateData => ({
            ...stateData,
            creatingAccount: false,
            validationErr: [res.Text]
          }));
          return false;
        } else {
          setStateData(stateData => ({
            ...stateData,
            creatingAccount: false
          }));

          let token = _.get(res, 'Token');
          if (token) {
            setStep(step + 1);

            let parsed = getParsedQuerystring();
            if (parsed?.flow === 'signup') {
              setWindowClose(true);
              window.close();
              return;
            } else {
              logger.log('Outside parsed', parsed);
              Promise.all([
                api.setToken(token),
                auth.setAuthToken(token),
                dispatch(setToken(token)),
                dispatch(setPreAuthEmail(user.email))
                //dispatch(refreshAndCreateAccount())
              ]).then(() => {
                dispatch(refreshAllAccountInfo()).then(res => {
                  segmentHelper.identity();
                  let user = store.getState().app.userMaster;
                  segmentHelper.track(segmentHelper.events.SIGNUP_SUCCESS, {
                    name: `${user.FirstName} ${user.LastName}`,
                    email: user.Email,
                    createdAt: user.Created,
                    id: user.GUID
                  });
                  setStateData(stateData => ({
                    ...stateData,
                    finishingAccount: false
                  }));
                  if (SmsOptIn && user.Telephone) {
                    api.Verify.startSMS()
                      .then(res => {
                        dispatch(
                          showAlert(
                            'Success',
                            'A verification code has been sent to ' +
                              user.Telephone +
                              '.  Please check your device.  If more than one verification text was sent, you must use the latest.'
                          )
                        );
                        return dispatch(refreshUserMaster());
                      })
                      .catch(err => {
                        // dispatch(
                        //   showAlert(
                        //     'Error Verifying SMS',
                        //     'There was a problem while verifying your sms number.  Please try again, or contact our support.'
                        //   )
                        // );
                      });
                  }
                });
              });
            }
          }
        }
      })
      .catch(err => {
        logger.log('boarding res error', err);
        setStep(step - 1);
        setStateData(stateData => ({
          ...stateData,
          creatingAccount: false,
          validationErr: [err.Text]
        }));
        return false;
      });
  };

  return windowClose ? (
    <>
      <Flex
        justifyContent={'center'}
        alignItems={'center'}
        direction={'column'}
      >
        <div className="text-success my-0">
          <Lottie
            animationData={checkPrimaryLight}
            loop={false}
            style={{ height: '120px', width: '120px' }}
          />
        </div>
        <div className="fs-2 my-2 fw-semi-bold">Signup successfull</div>
        <div className="my-2 text-center">
          Close this window and return to continue App login
        </div>
        <div className="my-3 text-center">
          <Button
            onClick={() => {
              window.close();
            }}
            variant="primary"
          >
            Continue to App
          </Button>
        </div>
      </Flex>
    </>
  ) : (
    <>
      <WizardModal modal={modal} setModal={setModal} />
      <Card
        as={Form}
        noValidate
        onSubmit={handleSubmit(onSubmitData, onError)}
        className="theme-wizard mb-5"
      >
        <Card.Header
          className={classNames('bg-light', {
            'px-4 py-3': variant === 'pills',
            'pb-2': !variant
          })}
        >
          <Nav className="justify-content-center" variant={variant}>
            {variant === 'pills'
              ? navItems.map((item, index) => (
                  <NavItemPill
                    key={item.label}
                    index={index + 1}
                    step={step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))
              : navItems.map((item, index) => (
                  <NavItem
                    key={item.label}
                    index={index + 1}
                    step={step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))}
          </Nav>
        </Card.Header>
        {progressBar && (
          <ProgressBar
            now={step * (100 / navItems.length)}
            style={{ height: 2 }}
          />
        )}
        <Card.Body className="fw-normal px-md-6 py-4">
          {step === 1 && (
            <AccountForm
              register={register}
              errors={errors}
              watch={watch}
              setValue={setValue}
              stateData={stateData}
            />
          )}
          {step === 2 && (
            <PersonalForm
              register={register}
              errors={errors}
              setValue={setValue}
              stateData={stateData}
            />
          )}
          {step === 3 && (
            <CommissionForm
              register={register}
              errors={errors}
              setValue={setValue}
              stateData={stateData}
            />
          )}
          {step === 4 && <Success reset={reset} stateData={stateData} />}

          {stateData.validationErr.length > 0 && (
            <div>
              <ValidationErrors
                className="fs-1"
                errors={stateData.validationErr}
              />
            </div>
          )}
        </Card.Body>
        <Card.Footer
          className={classNames('px-md-6 bg-light', {
            'd-none': step === 4,
            ' d-flex': step < 4
          })}
        >
          {stateData.creatingAccount || stateData.boardingEmailValidating ? (
            <Spinner size="sm" />
          ) : (
            <IconButton
              variant="link"
              icon={isRTL ? 'chevron-right' : 'chevron-left'}
              iconAlign="left"
              transform="down-1 shrink-4"
              className={classNames('px-0 fw-semi-bold', {
                'd-none': step === 1
              })}
              onClick={() => {
                setStep(step - 1);
              }}
            >
              Prev
            </IconButton>
          )}
          {step == 1 && (
            <IconButton
              as={Link}
              variant="link"
              icon={isRTL ? 'chevron-right' : 'chevron-left'}
              iconAlign="left"
              transform="down-1 shrink-4"
              className={classNames('px-0 fw-semi-bold')}
              to="/login"
            >
              Back to login
            </IconButton>
          )}
          <IconButton
            variant="primary"
            className="ms-auto px-5"
            icon={isRTL ? 'chevron-left' : 'chevron-right'}
            iconAlign="right"
            transform="down-1 shrink-4"
            onClick={() => {
              handleNavs(step + 1);
            }}
          >
            Next
          </IconButton>
        </Card.Footer>
      </Card>
    </>
  );
};

const NavItem = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: index < 4 ? step > index : step > 3,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon={icon} />
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs--1">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

const NavItemPill = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: step > index,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <Flex alignItems="center" justifyContent="center">
          <FontAwesomeIcon icon={icon} />
          <span className="d-none d-md-block mt-1 fs--1 ms-2">{label}</span>
        </Flex>
      </Nav.Link>
    </Nav.Item>
  );
};

WizardLayout.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool
};

NavItemPill.propTypes = {
  index: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  handleNavs: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

NavItem.propTypes = NavItemPill.propTypes;

export default WizardLayout;
