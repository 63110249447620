import IconAlert from 'components/common/IconAlert';
import PageHeader from 'components/common/PageHeader';
import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  ListGroup,
  ListGroupItem,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
  Tooltip
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import corner3 from 'assets/img/illustrations/corner-3.png';
import Flex from 'components/common/Flex';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteSavedExport,
  getAllSavedExports,
  getSavedExport,
  refreshDataExportMonths,
  savePdfDataToServer
} from 'stores/app';
import { showAlert } from 'stores/modal';
import api from 'util/api';
import logger from 'util/logger';
import DatePicker from 'react-datepicker';
import pdfReportGenerator from 'util/pdf-report-generator';
import {
  faCalendarAlt,
  faCheckCircle,
  faCloudDownloadAlt,
  faCloudUploadAlt,
  faDownload,
  faExclamationCircle,
  faInfoCircle,
  faRetweet,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import {
  FaCloudDownloadAlt,
  FaCloudUploadAlt,
  FaRegLifeRing
} from 'react-icons/fa';
import pdfReportGeneratorNew from 'util/pdf-report-generator-new';
import { confirmAlert } from 'react-confirm-alert';
import { enableValidation } from 'schema-utils';
import segmentHelper from 'helpers/segment-helper';
import config from 'util/config';
import apiReport from 'util/apiReport';

const JuratReportingList = () => {
  const [show, setShow] = useState(true);
  const reportPrefix = 'month-report';
  const posReportPrefix = 'pos-month-report';
  const MAX_PAGE_SIZE = 25;

  const [loadingDisplayMonthRange, setLoadingDisplayMonthRange] =
    useState(false);

  const [generatingReportsDisplayKeys, setGeneratingReportsDisplayKeys] =
    useState([]);
  const [finishedReportsByDisplayKeys, setFinishedReportsByDisplayKeys] =
    useState([]);
  const [loading, setLoading] = useState(false);
  const [savedExportData, setSavedExportData] = useState(null);
  const [uploadingPdfData, setUploadingPdfData] = useState(false);
  const [deletingSavedExport, setDeletingSavedExport] = useState(false);
  const [downloadingExport, setDownloadingExport] = useState(false);
  const [enbaleCustomReportExport, setEnbaleCustomReportExport] =
    useState(false);
  const [localExportReport, setLocalExportReport] = useState([]);
  const [reportRange, setReportRange] = useState({
    start: '',
    end: ''
  });
  const [downloadReportsContent, setDownloadReportsContent] = useState([]);
  const dispatch = useDispatch();

  const { userProfile, userMaster, dataExportMonths } = useSelector(
    state => state.app
  );

  const { subscriptionStatus } = useSelector(state => state.subscription);
  let activeSubscriptions = _.get(subscriptionStatus, 'Active', []);

  const getEarliestCreatedDate = entries => {
    //Chnage by Kaushal from created to SigningTimeStamp
    // let lastCreatedOnDate = null;
    // _.each(entries, e => {
    //   if (!lastCreatedOnDate) {
    //     lastCreatedOnDate = moment(e.Created);
    //   }
    //   let thisCreated = moment(e.Created);
    //   if (thisCreated.isBefore(lastCreatedOnDate)) {
    //     lastCreatedOnDate = thisCreated;
    //   }
    // });

    let lastCreatedOnDate = null;
    _.each(entries, e => {
      if (!lastCreatedOnDate) {
        lastCreatedOnDate = moment(e.SigningTimestamp);
      }
      let thisCreated = moment(e.SigningTimestamp);
      if (thisCreated.isBefore(lastCreatedOnDate)) {
        lastCreatedOnDate = thisCreated;
      }
    });
    return lastCreatedOnDate;
  };

  useEffect(() => {
    console.log('generatingReportsDisplayKeys', generatingReportsDisplayKeys);
  }, [generatingReportsDisplayKeys]);

  const fetchRangeOfData = (
    downloadedEntries,
    createdAfterDate,
    createdBeforeDate,
    includeData
  ) => {
    return new Promise((resolve, reject) => {
      let earliestCreatedDate = getEarliestCreatedDate(downloadedEntries);
      if (!earliestCreatedDate) {
        earliestCreatedDate = createdBeforeDate;
      }
      //earliestCreatedDate = earliestCreatedDate.add(23, 'hours').add(59, 'minutes').add(59, 'seconds');
      //Here's your problem now - this is still the main dashboard call
      //and it returns things in reverse order.  So flip your dates around to walk the other way.

      setLoading(true);
      api.Signing2.fetchReportDateRange(
        null,
        earliestCreatedDate.utc().format(),
        MAX_PAGE_SIZE,
        includeData
      )
        .then(res => {
          setLoading(false);
          logger.log('got entries', res);

          let mAccountCreated = moment.utc(userMaster.Created);

          //we want to grab chunks of data until we get results that are after createdBeforeData
          //Or get less than a full page.

          let endOfResultsFound = false;
          let filteredResults = [];
          let mCreatedAfter = moment(createdAfterDate);
          _.each(res, e => {
            //Commented by Kaushal to chnage date behaviur from Created to SigningTimestamp
            // let thisCreated = moment(e.Created);
            let thisCreated = moment(e.SigningTimestamp);
            if (thisCreated.isBefore(mCreatedAfter)) {
              logger.log(
                'found end of results with this entry',
                thisCreated.format(),
                mCreatedAfter.format(),
                e
              );
              endOfResultsFound = true;
            } else {
              logger.log(
                'this created is before the after',
                thisCreated.format(),
                mCreatedAfter.format(),
                e
              );
              filteredResults.push(e);
            }
          });

          downloadedEntries = _.uniqBy(
            [...downloadedEntries, ...filteredResults],
            'GUID'
          );
          let hasMoreResults =
            filteredResults.length > 0 &&
            (!endOfResultsFound || filteredResults.length === MAX_PAGE_SIZE);
          logger.log(
            'do we continue?',
            hasMoreResults,
            endOfResultsFound,
            filteredResults.length,
            mCreatedAfter.isBefore(mAccountCreated)
          );
          //Commented by Kaushal to remove onBording date check
          // if (mCreatedAfter.isBefore(mAccountCreated)) {
          //   //Then we're at the beginning of their account.  No more results will be found.
          //   resolve(downloadedEntries);
          // } else
          if (hasMoreResults) {
            let earliestCreatedOnDate =
              getEarliestCreatedDate(downloadedEntries);
            logger.log(
              'moving on',
              earliestCreatedOnDate.format(),
              createdAfterDate.format()
            );
            resolve(
              fetchRangeOfData(
                downloadedEntries,
                createdAfterDate,
                earliestCreatedOnDate,
                includeData
              )
            );
          } else {
            resolve(downloadedEntries);
          }
        })
        .catch(err => {
          setLoading(false);
          reject(err);
        });
    });
  };

  const generateFinishedReport = async (displayKey, returnData) => {
    let found = _.find(
      finishedReportsByDisplayKeys,
      item => item.displayKey === displayKey
    );
    if (!found) {
      return;
    }
    //logger.log('found', found, displayKey);
    let res = found.data;
    let mStartDate = moment(found.startDate).utc();
    let mEndDate = moment(found.endDate).utc();

    if (res.length === 0) {
      dispatch(
        showAlert(
          'No Data',
          'There is no data available for the selected dates.'
        )
      );
      return;
    }

    let notaryInfo = [];
    if (userProfile) {
      _.each(userProfile, p => {
        notaryInfo.push({
          commission_state: p.CommissionState,
          commission_county: p.CommissionProvince,
          commission_number: p.CommissionNumber,
          commission_name: p.CommissionedName,
          commission_exp: moment(p.ExpirationDate)
        });
      });
    }
    //logger.log('mStartDate, mEndDate', mStartDate, mEndDate);
    return pdfReportGeneratorNew
      .generateDateRangeReport(
        mStartDate,
        mEndDate,
        notaryInfo,
        res,
        returnData
      )
      .then(resData => {
        logger.log('resData', resData);
        if (returnData && resData) {
          logger.log('pdfData', resData);
          setUploadingPdfData(displayKey);
          dispatch(
            savePdfDataToServer(
              resData,
              displayKey,
              'Report has ' + res.length + ' Journal entry'
            )
          )
            .then(res => {
              segmentHelper.track(
                segmentHelper.events.EXPORT_REPORT_SAVED_ON_SERVER
              );
              setUploadingPdfData(false);
              getReportsDisplayDateRange();
            })
            .catch(err => {
              setUploadingPdfData(false);
            });
        }
      })
      .catch(err => {
        dispatch(
          showAlert(
            'Problem Generating Report',
            'It looks like there was a problem generating your report.  Please try disabling any popup blockers you may have, and try again.'
          )
        );
      });
  };

  const removeSavedExportReport = guid => {
    confirmAlert({
      //title: 'Confirmation',
      message: 'Are you sure you want to delete this report?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => proceedToDeleteExportReport(guid)
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  const proceedToDeleteExportReport = guid => {
    setDeletingSavedExport(guid);
    dispatch(deleteSavedExport(guid))
      .then(res => {
        setDeletingSavedExport(false);
        getReportsDisplayDateRange();
      })
      .catch(err => {
        setDeletingSavedExport(false);
      });
  };

  const downloadExportedSavedPdf = guid => {
    setDownloadingExport(guid);
    dispatch(getSavedExport(guid, true))
      .then(res => {
        setDownloadingExport(false);
        logger.log('getSavedExport', res[0]);
        let doc = res[0];
        //window.open(`data:application/pdf;base64,${doc.Body}`);
        setDownloadReportsContent(downloadReportsContent => [
          ...downloadReportsContent,
          { guid: guid, content: `data:application/pdf;base64,${doc.Body}` }
        ]);
        //showBase64DataonNewTab(doc.Body);
      })
      .catch(err => {
        setDownloadingExport(false);
      });
  };
  useEffect(() => {
    console.log('downloadReportsContent', downloadReportsContent);
  }, [downloadReportsContent]);

  const showBase64DataonNewTab = (data, mimeType = 'application/pdf') => {
    var byteCharacters = atob(data);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], { type: mimeType + ';base64' });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  const generateFinishedProofOfSequentialityReport = async displayKey => {
    let found = _.find(
      finishedReportsByDisplayKeys,
      item => item.displayKey === displayKey
    );
    if (!found) {
      return;
    }
    let res = found.data;
    let mStartDate = moment(found.startDate);
    let mEndDate = moment(found.endDate);
    mEndDate = mEndDate.add(23, 'hours').add(59, 'minutes').add(59, 'seconds');
    if (res.length === 0) {
      dispatch(
        showAlert(
          'No Data',
          'There is no data available for the selected dates.'
        )
      );
      return;
    }

    let notaryInfo = [];
    if (userProfile) {
      _.each(userProfile, p => {
        notaryInfo.push({
          commission_state: p.CommissionState,
          commission_county: p.CommissionProvince,
          commission_number: p.CommissionNumber,
          commission_name: p.CommissionedName,
          commission_exp: moment(p.ExpirationDate)
        });
      });
    }

    logger.log(
      'this will work some day',
      mStartDate,
      mEndDate,
      notaryInfo,
      res
    );

    return pdfReportGenerator
      .generateDateRangeProofOfSequentialityReport(
        mStartDate,
        mEndDate,
        notaryInfo,
        res
      )
      .catch(err => {
        dispatch(
          showAlert(
            'Problem Generating Report',
            'It looks like there was a problem generating your report.  Please try disabling any popup blockers you may have, and try again.'
          )
        );
      });
  };

  const generatePOSReportForDateRange = (displayKey, mStartDate, mEndDate) => {
    //need to startOf/endOf your dates
    //also utc
    if (loading) {
      toast.warning('A report is on progress. Please wait to complete it.', {
        theme: 'colored',
        position: 'bottom-center',
        icon: faInfoCircle,
        toastId: 'report'
      });
      return;
    }

    let createdAfterDate = moment(mStartDate).startOf('d').utc();
    let createdBeforeDate = moment(mEndDate).startOf('d').utc();
    createdBeforeDate = createdBeforeDate
      .add(23, 'hours')
      .add(59, 'minutes')
      .add(59, 'seconds');

    setGeneratingReportsDisplayKeys(
      _.concat([displayKey], generatingReportsDisplayKeys)
    );

    //console.time('gen-report');
    fetchRangeOfData(
      [],
      moment(createdAfterDate),
      moment(createdBeforeDate),
      false
    )
      .then(res => {
        logger.log('report date range res', res);

        console.timeEnd('gen-report');
        //logger.log('done generating report', res, this.state);
        let generatedReportUpdate = _.concat([], generatingReportsDisplayKeys);
        _.remove(generatedReportUpdate, dKey => dKey === displayKey);

        setGeneratingReportsDisplayKeys(generatedReportUpdate);
        setFinishedReportsByDisplayKeys(
          _.concat(
            [
              {
                displayKey,
                startDate: mStartDate.format(),
                endDate: mEndDate.format(),
                data: res
              }
            ],
            finishedReportsByDisplayKeys
          )
        );
      })
      .catch(err => {
        logger.log('error fetching report data', err);
        let generatedReportUpdate = _.concat([], generatingReportsDisplayKeys);
        _.remove(generatedReportUpdate, dKey => dKey === displayKey);
        setGeneratingReportsDisplayKeys(generatedReportUpdate);
      });
  };

  const generateReportForCustomDateRange = () => {
    let mStartDate = moment(reportRange.start).utc().local();

    let mEndDate = moment(reportRange.end).utc().local();
    let displayKey =
      mStartDate.format('YYYY-MM-DD') + '-to-' + mEndDate.format('YYYY-MM-DD');
    setLocalExportReport([
      ...localExportReport,
      {
        //displayKey: ++displayKeyCounter,
        displayKey: displayKey,
        begin: mStartDate.format('YYYY-MM-DD'),
        title: 'Report',
        end: mEndDate.format('YYYY-MM-DD'),
        signingCount: 0
      }
    ]);
    generateReportForDateRange(displayKey, mStartDate, mEndDate);
  };

  const generateReportForDateRange = (displayKey, mStartDate, mEndDate) => {
    if (loading) {
      toast.warning('A Report is on progress. Please wait.', {
        theme: 'colored',
        position: 'bottom-center',
        icon: faInfoCircle,
        toastId: 'report'
      });
      return;
    }
    //need to startOf/endOf your dates
    //also utc
    logger.log('displayKey', displayKey);
    logger.log('mStartDate', mStartDate);
    logger.log('mEndDate', mEndDate);
    let createdAfterDate = moment(mStartDate).startOf('d').utc();
    let createdBeforeDate = moment(mEndDate).startOf('d').utc();
    createdBeforeDate = createdBeforeDate
      .add(23, 'hours')
      .add(59, 'minutes')
      .add(59, 'seconds');
    setGeneratingReportsDisplayKeys(
      _.concat([displayKey], generatingReportsDisplayKeys)
    );

    if (config.generateReportOnServer) {
      let data = {
        Start: createdAfterDate.local().format(),
        End: createdBeforeDate.local().format(),
        DisplayKey: displayKey,
        Token: api.token
      };
      if (!config.debug) {
        data.Env = 'prod';
      }
      apiReport.JournalReports.export(data).then(res => {
        console.log(res);
        toast.success(res.message, {
          theme: 'colored',
          position: 'bottom-center',
          icon: faCheckCircle,
          toastId: 'report-success'
        });
        setGeneratingReportsDisplayKeys([]);
      });
    } else {
      fetchRangeOfData(
        [],
        moment(createdAfterDate),
        moment(createdBeforeDate),
        true
      )
        .then(res => {
          logger.log('report date range res', res);

          console.timeEnd('gen-report');
          // logger.log('done generating report', res, this.state);
          let generatedReportUpdate = _.concat(
            [],
            generatingReportsDisplayKeys
          );
          _.remove(generatedReportUpdate, dKey => dKey === displayKey);

          setGeneratingReportsDisplayKeys(generatedReportUpdate);
          setFinishedReportsByDisplayKeys(
            _.concat(
              [
                {
                  displayKey,
                  startDate: mStartDate.format(),
                  endDate: mEndDate.format(),
                  data: res
                }
              ],
              finishedReportsByDisplayKeys
            )
          );
        })
        .catch(err => {
          logger.log('error fetching report data', err);
          let generatedReportUpdate = _.concat(
            [],
            generatingReportsDisplayKeys
          );
          _.remove(generatedReportUpdate, dKey => dKey === displayKey);

          setGeneratingReportsDisplayKeys(generatedReportUpdate);
        });
    }
  };

  const refreshReportList = () => {
    getReportsDisplayDateRange();
  };

  const getReportsDisplayDateRange = () => {
    if (!loadingDisplayMonthRange) {
      setLoadingDisplayMonthRange(true);
      dispatch(getAllSavedExports())
        .then(res => {
          setSavedExportData(res);

          dispatch(refreshDataExportMonths())
            .then(res => {
              setLoadingDisplayMonthRange(false);
            })
            .catch(err => {
              setLoadingDisplayMonthRange(false);
            });
        })
        .catch(err => {
          setLoadingDisplayMonthRange(false);
        });
    }
  };

  const renderPageOfReportLinks = () => {
    //logger.log('render page of links', this.state);
    // let now = moment().utc();
    // let userCreatedMonth = moment.utc(userMaster.Created).startOf('month');

    // let displayDateRanges = [];
    // let curMonthEnd = moment(now);
    // let curMonthStart = moment(now).startOf('month');
    // //curMonthEnd = curMonthEnd.add(23, 'hours').add(59, 'minutes').add(59, 'seconds');
    // while (curMonthStart.isSameOrAfter(userCreatedMonth, 'month')) {
    //   displayDateRanges.push({
    //     displayKey: displayDateRanges.length + 1,
    //     begin: curMonthStart,
    //     end: curMonthEnd
    //   });

    //   curMonthEnd = moment(curMonthEnd).subtract(1, 'month').endOf('month');
    //   curMonthStart = moment(curMonthStart)
    //     .subtract(1, 'month')
    //     .startOf('month');
    // }

    return (
      <Col sm={{ size: 10 }}>
        <ListGroup>
          {_.map(dataExportMonths, function (range) {
            let startDate = moment(range.begin).local().startOf('month');
            let endDate = moment(
              moment(range.begin).local().endOf('month').format('YYYY-MM-DD')
            );

            let monthlyReportKey = reportPrefix + range.displayKey;
            let monthlyPOSReportKey = posReportPrefix + range.displayKey;

            let isGeneratingThisMonthlyReport = !!_.find(
              generatingReportsDisplayKeys,
              displayKey => monthlyReportKey === displayKey
            );
            let doneGeneratingThisMonthlyReport = !!_.find(
              finishedReportsByDisplayKeys,
              item => monthlyReportKey === item.displayKey
            );
            let isGeneratingThisPOSMonthlyReport = !!_.find(
              generatingReportsDisplayKeys,
              displayKey => monthlyPOSReportKey === displayKey
            );
            let doneGeneratingThisPOSMonthlyReport = !!_.find(
              finishedReportsByDisplayKeys,
              item => monthlyPOSReportKey === item.displayKey
            );

            let isExportSavedOnServer = _.find(
              savedExportData,
              e => e.Name === monthlyReportKey
            );
            return (
              <ListGroupItem
                key={'range-' + range.displayKey}
                style={{ lineHeight: '40px' }}
                className="d-md-flex block-text-blur"
              >
                <div style={{ flex: 1 }}>{range.title}</div>
                <div className="d-inline-block text-right" style={{ flex: 2 }}>
                  {!doneGeneratingThisMonthlyReport &&
                    !isExportSavedOnServer && (
                      <Button
                        variant={
                          activeSubscriptions.length == 0
                            ? 'warning'
                            : 'primary'
                        }
                        disabled={
                          isGeneratingThisMonthlyReport ||
                          activeSubscriptions.length == 0
                        }
                        onClick={() =>
                          generateReportForDateRange(
                            monthlyReportKey,
                            startDate,
                            endDate
                          )
                        }
                      >
                        {activeSubscriptions.length == 0 && (
                          <FontAwesomeIcon icon={faExclamationCircle} />
                        )}
                        {isGeneratingThisMonthlyReport && <Spinner size="sm" />}{' '}
                        {isGeneratingThisMonthlyReport
                          ? 'Generating ...'
                          : 'Generate Report'}
                      </Button>
                    )}

                  {isExportSavedOnServer && (
                    <>
                      <OverlayTrigger
                        placement="auto"
                        overlay={
                          <Tooltip>
                            Created on{' '}
                            {moment(isExportSavedOnServer.Created)
                              .local()
                              .format('MMMM Do YYYY, h:mm:ss a')}{' '}
                            <br />
                            {isExportSavedOnServer.AdditionalData}
                          </Tooltip>
                        }
                      >
                        <a
                          className={`btn ${
                            _.includes(
                              _.map(downloadReportsContent, 'guid'),
                              isExportSavedOnServer.GUID
                            )
                              ? 'btn-success'
                              : 'btn-primary'
                          }`}
                          variant="success"
                          disabled={
                            isExportSavedOnServer &&
                            isExportSavedOnServer.GUID === downloadingExport
                          }
                          download={`journal-export-${isExportSavedOnServer.GUID}.pdf`}
                          href={
                            _.includes(
                              _.map(downloadReportsContent, 'guid'),
                              isExportSavedOnServer.GUID
                            )
                              ? _.find(
                                  downloadReportsContent,
                                  d => d.guid == isExportSavedOnServer.GUID
                                )?.content
                              : '#'
                          }
                          onClick={() =>
                            _.includes(
                              _.map(downloadReportsContent, 'guid'),
                              isExportSavedOnServer.GUID
                            )
                              ? void 0
                              : downloadExportedSavedPdf(
                                  isExportSavedOnServer.GUID
                                )
                          }
                        >
                          {isExportSavedOnServer.GUID === downloadingExport ? (
                            <>
                              <Spinner size="sm" /> Downloading...
                            </>
                          ) : (
                            <>
                              <FontAwesomeIcon icon={faCloudDownloadAlt} />{' '}
                              Download Report
                            </>
                          )}
                        </a>
                      </OverlayTrigger>{' '}
                      &nbsp;
                      <OverlayTrigger
                        placement="auto"
                        overlay={
                          <Tooltip>
                            Auto delete by{' '}
                            {moment(isExportSavedOnServer.Expires)
                              .local()
                              .format('MMMM Do YYYY, h:mm:ss a')}
                          </Tooltip>
                        }
                      >
                        <span>
                          {deletingSavedExport ===
                          isExportSavedOnServer.GUID ? (
                            <Spinner size="sm" />
                          ) : (
                            <Button
                              variant="outline-danger"
                              onClick={() =>
                                removeSavedExportReport(
                                  isExportSavedOnServer.GUID
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                          )}
                        </span>
                      </OverlayTrigger>
                    </>
                  )}

                  {doneGeneratingThisMonthlyReport &&
                    !isExportSavedOnServer && (
                      <>
                        <Button
                          variant="success"
                          disabled={isGeneratingThisMonthlyReport}
                          onClick={() =>
                            generateFinishedReport(monthlyReportKey)
                          }
                        >
                          Download Report
                        </Button>
                        &nbsp;
                        {uploadingPdfData === monthlyReportKey ? (
                          <Spinner size="sm" />
                        ) : (
                          <Button
                            variant="primary"
                            disabled={isGeneratingThisMonthlyReport}
                            onClick={() =>
                              generateFinishedReport(monthlyReportKey, true)
                            }
                          >
                            <FontAwesomeIcon icon={faCloudUploadAlt} />
                          </Button>
                        )}
                      </>
                    )}
                </div>
                <div className="d-inline-block text-right" style={{ flex: 2 }}>
                  {/*{isGeneratingThisPOSMonthlyReport &&
                                        <div className="d-inline-block">
                                            <Spinner size="sm" />
                                        </div>
                                    }*/}

                  {!doneGeneratingThisPOSMonthlyReport && (
                    <Button
                      variant={
                        activeSubscriptions.length == 0 ? 'warning' : 'primary'
                      }
                      disabled={
                        isGeneratingThisPOSMonthlyReport ||
                        activeSubscriptions.length == 0
                      }
                      onClick={() =>
                        generatePOSReportForDateRange(
                          monthlyPOSReportKey,
                          startDate,
                          endDate
                        )
                      }
                    >
                      {activeSubscriptions.length == 0 && (
                        <FontAwesomeIcon icon={faExclamationCircle} />
                      )}
                      {isGeneratingThisPOSMonthlyReport && (
                        <Spinner size="sm" />
                      )}{' '}
                      {isGeneratingThisPOSMonthlyReport
                        ? 'Generating Report ...'
                        : 'Generate Sequence Report'}
                    </Button>
                  )}
                  {doneGeneratingThisPOSMonthlyReport && (
                    <Button
                      variant="success"
                      disabled={isGeneratingThisPOSMonthlyReport}
                      onClick={() =>
                        generateFinishedProofOfSequentialityReport(
                          monthlyPOSReportKey
                        )
                      }
                    >
                      Download Sequence Report
                    </Button>
                  )}
                </div>
              </ListGroupItem>
            );
          })}
        </ListGroup>
      </Col>
    );
  };

  const IconAlertCode = function IconAlertDemo({ variant, message }) {
    if (show) {
      return (
        <Alert variant="info">
          <Alert.Heading>
            <FontAwesomeIcon icon="info-circle" className="fs-1" /> Important
            information:
          </Alert.Heading>
          <p>
            Due to the sensitive nature of the data stored in these entries,
            this is normally recommended for very specific situations such as
            undergoing a journal audit or submitting your journal to a governing
            body upon request. Jurat inc is not liable for the loss or misuse of
            data once it has been exported.
          </p>
          <hr />
          <p className="mb-0">
            Note - Depending on the amount of entries you completed in the
            specified time frame, the report may take a few minutes to finalize.
            Please be patient!
          </p>
        </Alert>
      );
    }
  };

  useEffect(() => {
    getReportsDisplayDateRange();
  }, []);

  return (
    <>
      <PageHeader
        preTitle="Reports"
        title="Bulk Journal Exports"
        description="Generate and download your notary journal entries. All exports are generated as a PDF file for easy viewing."
        className="mb-3"
        titleTag="h2"
        image={corner3}
      />

      <IconAlertCode
        variant="info"
        message="Any saved drafts you may have are stored on your device only, until they are completed and uploaded to our servers."
      />
      <div className="pb-3">
        <Alert variant="warning">
          <Alert.Heading>
            <FontAwesomeIcon icon={faInfoCircle} /> Bulk Journal Export Feature
            Under Reconstruction
          </Alert.Heading>
          <p>
            The bulk journal export experience is currently being reimagined &
            rebuilt. You can still export single entries when viewing them from
            your journal. If you need any assistance with functionality that is
            currently unavailable, please contact us at
            <a href="mailto:support@juratinc.com" className="ms-1">
              support@juratinc.com
            </a>
          </p>
        </Alert>
      </div>
      {activeSubscriptions.length === 0 && (
        <div className="pb-3">
          <Alert variant="warning">
            <Alert.Heading>
              <FontAwesomeIcon icon={faInfoCircle} /> Subscription Required
            </Alert.Heading>
            <p>
              You have no active subscription and your signings are queued for
              deletion. Reactivate your subscription in the Account tab.
            </p>
          </Alert>
        </div>
      )}

      <Row className="mb-3 g-3">
        <Col lg={12}>
          <Card className="mb-3">
            <Card.Header>
              <Row className="flex-between-center">
                <Col
                  xs={12}
                  sm="auto"
                  className="d-flex align-items-center pe-0 justify-content-between w-100"
                >
                  <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                    Reports
                  </h5>
                  <div className="d-flex align-items-center justify-content-center block-text-blur">
                    <OverlayTrigger
                      placement="auto"
                      overlay={<Tooltip>Refresh report list</Tooltip>}
                    >
                      <Button
                        variant="success"
                        className="mx-1"
                        onClick={() => refreshReportList()}
                      >
                        <FontAwesomeIcon icon={faRetweet} />
                      </Button>
                    </OverlayTrigger>
                    <Button
                      className="mx-1"
                      variant="outline-primary me-3"
                      onClick={() =>
                        setEnbaleCustomReportExport(!enbaleCustomReportExport)
                      }
                    >
                      {!enbaleCustomReportExport ? (
                        <>
                          <FontAwesomeIcon icon={faCalendarAlt} /> &nbsp; Custom
                          Range
                        </>
                      ) : (
                        <>
                          <FontAwesomeIcon icon={faCalendarAlt} /> &nbsp;
                          Monthly Report
                        </>
                      )}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Header>
            {!enbaleCustomReportExport ? (
              <>
                <Card.Body className="p-0">
                  {!loadingDisplayMonthRange ? (
                    userMaster && dataExportMonths ? (
                      renderPageOfReportLinks()
                    ) : (
                      <IconAlert variant="warning">
                        <p className="mb-0">Export data is not available.</p>
                      </IconAlert>
                    )
                  ) : (
                    <Flex justifyContent="center">
                      <Spinner size="sm" /> &nbsp; Loading...
                    </Flex>
                  )}
                  {!loadingDisplayMonthRange && !dataExportMonths?.length && (
                    <div className="text-center">
                      <FontAwesomeIcon
                        className="text-muted"
                        icon={faCloudDownloadAlt}
                        size="3x"
                      />
                      <br />
                      <p className="my-3">
                        No Journal data available to export
                      </p>
                    </div>
                  )}
                </Card.Body>
              </>
            ) : (
              <>
                <Card.Body>
                  <Col sm={12}>
                    <Row>
                      <Col sm={12} md={12}>
                        <Flex justifyContent={'start'} direction="row">
                          <Form.Group
                            as={Col}
                            md={'auto'}
                            controlId="start"
                            className="mx-1"
                          >
                            <Form.Label> Start Date</Form.Label>{' '}
                            <DatePicker
                              selected={
                                reportRange.start
                                  ? new Date(reportRange.start)
                                  : null
                              }
                              onChange={date =>
                                setReportRange({ ...reportRange, start: date })
                              }
                              className="form-control"
                              placeholderText="MM/DD/YYYY"
                              dateFormat="MM/dd/yyyy"
                              startDate="01/01/1950"
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              yearDropdownItemNumber={40}
                            />
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md={'auto'}
                            controlId="end"
                            className="mx-1"
                          >
                            <Form.Label> End Date</Form.Label>{' '}
                            <DatePicker
                              selected={
                                reportRange.end
                                  ? new Date(reportRange.end)
                                  : null
                              }
                              onChange={date =>
                                setReportRange({ ...reportRange, end: date })
                              }
                              className="form-control"
                              placeholderText="MM/DD/YYYY"
                              dateFormat="MM/dd/yyyy"
                              startDate="01/01/1950"
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              yearDropdownItemNumber={40}
                            />
                          </Form.Group>
                          <Col xs={4} sm={'auto'} as={Flex} alignItems="end">
                            <Button
                              disabled={!reportRange.start || !reportRange.end}
                              variant="outline-primary me-3"
                              onClick={() => generateReportForCustomDateRange()}
                            >
                              Generate Report
                            </Button>
                          </Col>
                        </Flex>
                      </Col>
                      <Col sm={12}>
                        <Table responsive striped hover>
                          <thead>
                            <tr>
                              <th scope="col">Name</th>
                              <th scope="col">Start</th>
                              <th scope="col">End</th>
                              <th scope="col">Created</th>
                              <th scope="col">Expiring</th>
                              <th className="text-end" scope="col">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {_.map(savedExportData, report => {
                              return (
                                <tr
                                  className="align-middle block-text-blur"
                                  key={'saved-' + report.Name}
                                >
                                  <td className="text-nowrap">{report.Name}</td>
                                  <td className="text-nowrap">
                                    {moment(report.Start)
                                      .utc()
                                      .local()
                                      .format('MM/DD/YYYY')}
                                  </td>
                                  <td className="text-nowrap">
                                    {moment(report.End)
                                      .utc()
                                      .local()
                                      .format('MM/DD/YYYY')}
                                  </td>
                                  <td className="text-nowrap">
                                    {moment(report.Created)
                                      .utc()
                                      .local()
                                      .format('MM/DD/YYYY')}
                                  </td>
                                  <td className="text-nowrap">
                                    {moment(report.Expires)
                                      .utc()
                                      .local()
                                      .format('MM/DD/YYYY')}
                                  </td>
                                  <td className="text-end">
                                    <Button
                                      id={'btn-report-download' + report.GUID}
                                      className={`mx-1 btn ${
                                        _.includes(
                                          _.map(downloadReportsContent, 'guid'),
                                          report?.GUID
                                        )
                                          ? 'btn-success'
                                          : 'btn-primary'
                                      }`}
                                      // disabled={
                                      //   !doneGeneratingThisCustomRangeReport
                                      // }
                                      disabled={
                                        report?.GUID === downloadingExport
                                      }
                                      download={`journal-export-${report?.GUID}.pdf`}
                                      href={
                                        _.includes(
                                          _.map(downloadReportsContent, 'guid'),
                                          report?.GUID
                                        )
                                          ? _.find(
                                              downloadReportsContent,
                                              d => d.guid == report?.GUID
                                            )?.content
                                          : '#'
                                      }
                                      onClick={() =>
                                        _.includes(
                                          _.map(downloadReportsContent, 'guid'),
                                          report.GUID
                                        )
                                          ? void 0
                                          : downloadExportedSavedPdf(
                                              report.GUID
                                            )
                                      }
                                    >
                                      {report?.GUID === downloadingExport ? (
                                        <Spinner size="sm" animation="border" />
                                      ) : (
                                        <FontAwesomeIcon
                                          icon={faCloudDownloadAlt}
                                        />
                                      )}
                                    </Button>
                                    <Button
                                      variant="outline-danger"
                                      className="mx-1"
                                      onClick={() =>
                                        removeSavedExportReport(report.GUID)
                                      }
                                    >
                                      <FontAwesomeIcon icon={faTrashAlt} />
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })}

                            {_.map(localExportReport, report => {
                              let isExportSavedOnServer = _.find(
                                savedExportData,
                                e => e.Name === report.displayKey
                              );
                              let isGeneratingThisCustomRangeReport = !!_.find(
                                generatingReportsDisplayKeys,
                                displayKey => report.displayKey === displayKey
                              );
                              let doneGeneratingThisCustomRangeReport =
                                !!_.find(
                                  finishedReportsByDisplayKeys,
                                  item => report.displayKey === item.displayKey
                                );
                              return (
                                !isExportSavedOnServer && (
                                  <tr
                                    className="align-middle block-text-blur"
                                    key={'local-' + report.displayKey}
                                  >
                                    <td className="text-nowrap">
                                      {report.displayKey}
                                    </td>
                                    <td className="text-nowrap">
                                      {moment(report.begin)
                                        .utc()
                                        .local()
                                        .format('MM/DD/YYYY')}
                                    </td>
                                    <td className="text-nowrap">
                                      {moment(report.end)
                                        .utc()
                                        .local()
                                        .format('MM/DD/YYYY')}
                                    </td>
                                    <td className="text-nowrap">Now</td>
                                    <td className="text-nowrap">N/A</td>
                                    <td className="text-end">
                                      {isGeneratingThisCustomRangeReport ? (
                                        <Spinner size="sm" />
                                      ) : (
                                        <>
                                          <Button
                                            variant="primary"
                                            className="mx-1"
                                            disabled={
                                              !doneGeneratingThisCustomRangeReport
                                            }
                                            onClick={() =>
                                              generateFinishedReport(
                                                report.displayKey
                                              )
                                            }
                                          >
                                            <FontAwesomeIcon
                                              icon={faCloudDownloadAlt}
                                            />
                                          </Button>
                                          {uploadingPdfData ===
                                          report.displayKey ? (
                                            <Spinner size="sm" />
                                          ) : (
                                            <Button
                                              variant="outline-primary"
                                              disabled={
                                                !doneGeneratingThisCustomRangeReport
                                              }
                                              className="mx-1"
                                              onClick={() =>
                                                generateFinishedReport(
                                                  report.displayKey,
                                                  true
                                                )
                                              }
                                            >
                                              <FontAwesomeIcon
                                                icon={faCloudUploadAlt}
                                              />
                                            </Button>
                                          )}
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                )
                              );
                            })}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </Col>
                </Card.Body>
              </>
            )}
            <Card.Footer>
              {loading ? (
                <Flex justifyContent="center">
                  <Spinner size="sm" />{' '}
                  <span className="mx-2"> Generating report</span>
                </Flex>
              ) : (
                <></>
              )}
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default JuratReportingList;
