import React, { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import invite from 'assets/img/icons/spot-illustrations/41.png';
import piiBadge from 'assets/img/jurat/piiBadge.png';
import piiBadgeBorder from 'assets/img/jurat/piiBadgeBorder.png';
import { Link } from 'react-router-dom';
import IconButton from 'components/common/IconButton';
import PageHeader from 'components/common/PageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import segmentHelper from 'helpers/segment-helper';

const JuratBadges = () => {
  const [showModal, setShowModal] = useState(false);

  const handleCopy = () => {
    setShowModal(true);
  };

  const startDownloadBadge = () => {
    saveAs(piiBadge, 'jurat-pii-badge-' + moment().format('YYYY-MM-DD-HH-mm-ss') + '.png') // Put your image URL here
    saveAs(piiBadgeBorder, 'jurat-pii-badge-border-' + moment().format('YYYY-MM-DD-HH-mm-ss') + '.png') // Put your image URL here
    segmentHelper.track(segmentHelper.events.DOWNLOADED_BADGES);
  }

  return (
    <>
      <PageHeader
        preTitle="Notary Badges"
        title="Notary Badges"
        description="Download your display badges for use on business cards, social media posts, and marketing material. You must have an account with The Notary eJournal in order to use these badges."
        className="mb-3"
        titleTag="h2"
      >
        <div></div>

      </PageHeader>
      <Card>
        <Card.Body className="text-center py-5">
          <Row className="justify-content-center">
            <Col xs={12} md={10}>
              <img className="img-fluid" src={piiBadge} alt="image" width="30%" />
              <img className="img-fluid" src={piiBadgeBorder} alt="image" width="30%" />
            </Col>
          </Row>
          <h3 className="mt-3 fw-normal fs-2 mt-md-4 fs-md-3">
            What does PII stand for?
          </h3>
          <p className="lead mb-5">
            PII stands for personally identifiable information, and refers to the information collected in the notary journal that can personally identify the individual being notarized. This may include ID information, addresses, signatures, etc. Our goal is to help establish trust between notaries and the communities they serve, and we believe it is important for our community members to know that their information is being properly handled & safeguarded.
          </p>
        </Card.Body>
        <Card.Footer className="bg-light text-center pt-2">
          <Row className="justify-content-center">
            <Col xs={11} sm={10}>

              <h4 className="fw-normal mb-0 fs-1 fs-md-2">
                More ways to <br className="d-sm-none" /> download badges
              </h4>

              <Row className="gx-2 my-4">
                <Col lg={12} className="d-flex justify-content-center">
                  <Button onClick={() => startDownloadBadge()}
                    variant="falcon-default"
                    className="d-block mb-2 mb-xl-0"
                  >
                    <FontAwesomeIcon icon="download" />
                    <span className="fw-medium ms-2">Download Badges</span>
                  </Button>
                </Col>

              </Row>

              <p className="mb-2 fs--1">
                Once downloaded, we recommend adding these badges to your professional signature lines, marketing websites & business cards. <br />They showcase that you use the only electronic notary journal that has achieved <Link target='_blank' to="https://www.juratinc.com/insights/2023/2/24/jurat-inc-has-achieved-soc-ii-type-ii-compliance">SOC II compliance</Link>, providing the most secure data storage environment available on the market for notaries.
                Once you've invited friends, you can{' '}

              </p>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </>
  );
};

export default JuratBadges;
