import pkg from '../../package.json';
import api from './api';
import logger from './logger';
import utils from './utils';

const errorReportHelper = {
  trySubmitError(userMessage, err) {
    return new Promise((resolve, reject) => {
      try {
        let message = {
          type: 'js-error',
          msg: userMessage || '- no message -',
          error: utils.recursiveStringify(err),
          app_version: pkg.version
        };
        api.Reporting.reportBug(message)
          .then(res => {
            logger.log('bug submitted successfully');
            resolve(true);
          })
          .catch(err => {
            logger.warn('error submitting bug report', err);
            resolve(false);
          });
      } catch (err) {
        logger.error('caught error submitting report', err);
        resolve(false);
      }
    });
  }
};

export default errorReportHelper;
