import { createSlice } from '@reduxjs/toolkit';
import api from '../util/api';
import logger from '../util/logger';
import { authenticationSlice } from './authentication';
import _ from 'lodash';
import c from '../util/const';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import Colors from 'util/Colors';

export const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    modals: []
  },
  reducers: {
    show: (state, action) => {
      let update = _.concat([], state.modals);

      update.push({
        modalType: action.payload.modalType,
        modalProps: action.payload.modalProps,
        modalSize: action.payload.modalSize,
        id: _.uniqueId('jurat-modal-dialog-')
      });
      state.modals = update;
    },
    closeModal: (state, action) => {
      let copy = _.concat([], state.modals);
      _.remove(copy, modal => {
        return modal.id === action.payload;
      });
      state.modals = copy;
    },

    cleanupState: (state, action) => {
      state.modals = [];
    }
  }
});

let { closeModal, cleanupState } = modalSlice.actions;

let actions = {
  showAlert: (title, message, callback) => {
    return actions.show(c.modal.alert, {
      title,
      message,
      callback
    });
  },

  showConnectionError: () => {
    return actions.show(c.modal.alert, {
      title: 'Connection Error!!!',
      message:
        "Opps... Look like you don't have active internet connection. Try again!",
      callback: null
    });
  },

  showConfirm: (title, message, callback) => {
    return actions.show(c.modal.confirm, {
      title,
      message,
      callback
    });
  },

  showUpgrade: (subscriptionTier, callback) => {
    return actions.show(c.modal.upgradeDialog, {
      subscriptionTier,
      callback
    });
  },

  showCouponUpgrade: callback => {
    return actions.show(c.modal.couponUpgradeDialog, {
      callback
    });
  },

  showUpdateCard: (subscriptionGUID, callback) => {
    return actions.show(c.modal.updateCardDialog, {
      subscriptionGUID,
      callback
    });
  },

  showSubmitFeedback: callback => {
    return actions.show(c.modal.submitFeedbackDialog, {
      callback
    });
  },

  showDowngradeWarning: (subscriptionType, callback) => {
    return actions.show(
      c.modal.downgradeDialog,
      {
        subscriptionType,
        callback
      },
      'lg'
    );
  },

  showChangePassword: callback => {
    return actions.show(c.modal.changePassword, {
      callback
    });
  },

  showEditProfile: (editGUID, callback) => {
    return actions.show(c.modal.editProfile, {
      editGUID,
      callback
    });
  },

  showEditUserMaster: callback => {
    return actions.show(c.modal.editUserMaster, {
      callback
    });
  },

  showViewEntryDialog: (entry_guid, callback) => {
    return actions.show(
      c.modal.viewEntryDialog,
      {
        entry_guid,
        callback
      },
      'lg'
    );
  },

  show: (type, props, size) => {
    return (dispatch, getState) => {
      // logger.log('modal.show called', getState().modal);
      // let modalCount = getState().modal.modals.length;
      // if(modalCount === 0){
      //   document.body.classList.add('modal-open');
      // }

      dispatch(
        modalSlice.actions.show({
          modalType: type,
          modalProps: props,
          modalSize: size || 'md'
        })
      );
    };
  },

  close: id => {
    return (dispatch, getState) => {
      let modalCount = getState().modal.modals.length;
      if (modalCount === 1) {
        document.body.classList.remove('modal-open');
      }
      dispatch(closeModal(id));
    };
  },

  cleanup: () => {
    return (dispatch, getState) => {
      dispatch(cleanupState());
    };
  }
};

export const {
  showAlert,
  showConnectionError,
  showConfirm,
  showDowngradeWarning,
  showEditProfile,
  showEditUserMaster,
  showChangePassword,
  showViewEntryDialog,
  showSubmitFeedback,
  showUpdateCard,
  showUpgrade,
  showCouponUpgrade,
  close,
  cleanup
} = actions;

export default modalSlice.reducer;
