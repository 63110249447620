import {
  faArrowCircleLeft,
  faCheckCircle,
  faExclamation,
  faForward
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import corner6 from 'assets/img/illustrations/corner-6.png';
import Flex from 'components/common/Flex';
import PageHeader from 'components/common/PageHeader';
import FaqBasicCard from 'components/pages/knowledge-center/faq/FaqBasicCard';
import { faqs } from 'data/faqs-subscription';
import draftEntryHelper from 'helpers/draft-entry-helper';
import segmentHelper from 'helpers/segment-helper';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  actionSetDraftSaveError,
  actionSetDraftSaveStatus,
  createDraftOnServer,
  refreshSavedLocation,
  removeDraftFromServer,
  updateDraftOnServer
} from 'stores/app';
import { refreshDocumentCollections, refreshDocumentList } from 'stores/docs';
import { showAlert, showConfirm } from 'stores/modal';
import { actionAddNotification } from 'stores/notification';
import {
  actionLogSigningAction,
  actionReInstateSigning,
  actionReset,
  actionStart
} from 'stores/signing';
import c from 'util/const';
import logger from 'util/logger';
import utils from 'util/utils';
import SigningLayout from './SigningLayout';
import SigningProvider from './SigningProvider';

const Signing = ({ variant, validation, progressBar }) => {
  const [draftEntry, setDraftEntry] = useState([]);
  const dispatch = useDispatch();
  const { signing } = useSelector(state => state);
  const { userMaster } = useSelector(state => state.app);
  const [savingDraft, setSavingDraft] = useState(false);
  const navigate = useNavigate();
  const { urlDraftId } = useParams();
  const [isCompleted, setIsCompleted] = useState(false);
  const [initializingDraftEntry, setInitializingDraftEntry] = useState(false);
  const [initializingNewEntry, setInitializingNewEntry] = useState(false);
  const [readyToStartEntry, setReadyToStartEntry] = useState(false);

  const [step, setStep] = useState(1);
  const initialized = useRef(false);

  const { subscriptionStatus } = useSelector(state => state.subscription);
  let activeSubscriptions = _.get(subscriptionStatus, 'Active', []);

  // const deleteDraft = () => {
  //   return new Promise((resolve, reject) => {
  //     if (signing.draft_id && userMaster && userMaster.GUID) {
  //       draftEntryHelper
  //         .removeDraft(userMaster.GUID, signing.draft_id)
  //         .then(res => {
  //           dispatch(
  //             removeDraftFromServer(
  //               draftEntryHelper.getDarftKeyFromId(signing.draft_id)
  //             )
  //           ).then(res => {
  //             navigate('/notary/drafts');
  //             resolve(true);
  //           });
  //         });
  //     } else {
  //       logger.warn(
  //         'draft not removed, data does not exist',
  //         userMaster,
  //         currentDraftId
  //       );
  //       reject(false);
  //     }
  //   });
  // };
  const saveSigningToDraft = () => {
    if (userMaster && urlDraftId && !savingDraft) {
      setSavingDraft(true);
      let res = draftEntryHelper.getDraft(
        userMaster.GUID,
        draftEntryHelper.getDarftIdFromKey(urlDraftId)
      );

      if (res) {
        res.then(draft => {
          if (
            draft &&
            draftEntryHelper.getDarftIdFromKey(urlDraftId) === signing.draft_id
          ) {
            let currentStep = draftEntryHelper.getDraftStepName(step);
            let now = moment().format();

            let draftMetadata = {
              created: draft.draft_metadata?.created,
              completed_at: draft.draft_metadata?.completed_at || null,
              last_updated: now,
              entry_step: currentStep.code,
              source: 'Desktop'
            };
            if (signing && !draftMetadata.completed_at) {
              dispatch(actionSetDraftSaveStatus(c.draftStatus.Saving));

              Promise.all([
                draftEntryHelper.saveUpdateDraft(
                  userMaster.GUID,
                  draftEntryHelper.getDarftIdFromKey(urlDraftId),
                  draftMetadata,
                  signing
                ),

                dispatch(
                  updateDraftOnServer(
                    urlDraftId,
                    utils.encodeObjectForBlobData({
                      draft_id: draftEntryHelper.getDarftIdFromKey(urlDraftId),
                      draft_data: draftMetadata,
                      entry_data: signing
                    })
                  )
                )
                  .then(res => {
                    setSavingDraft(false);
                  })
                  .catch(err => {
                    setSavingDraft(false);
                    if (err && err.Code == 'NotFound') {
                      dispatch(
                        showConfirm(
                          'Signing draft deleted',
                          'Current signing draft is no longer available on your account. System will delete this draft from your device to prevent duplicate entry.<br/><strong>Click Cancel to retry</strong>',
                          () => {
                            //deleteDraft();
                          }
                        )
                      );
                    }
                  })
              ])
                .then(res => {
                  setSavingDraft(false);
                  setTimeout(() => {
                    dispatch(actionSetDraftSaveStatus(c.draftStatus.Saved));
                    setSavingDraft(false);
                  }, 500);
                })
                .catch(err => {
                  setSavingDraft(false);
                  setTimeout(() => {
                    dispatch(actionSetDraftSaveStatus(c.draftStatus.Saved));
                    setSavingDraft(false);
                  }, 500);
                  reject(err);
                });
            } else {
              setSavingDraft(false);
            }
            logger.log('Updating Meta data', draftMetadata);
          } else {
            setSavingDraft(false);
            logger.log(
              'DraftId Missmatched',
              draftEntryHelper.getDarftIdFromKey(urlDraftId),
              '-----',
              signing.draft_id
            );
          }
        });
      } else {
        setSavingDraft(false);
      }
    }
  };

  // useEffect(() => {
  //   logger.log(signing);
  // }, [signing]);

  useEffect(() => {
    if (userMaster && !urlDraftId) {
      initialized.current = true;
      initialiseNewEntry();
    } else if (urlDraftId) {
      setDraftToEdit(draftEntryHelper.getDarftIdFromKey(urlDraftId));
    }
  }, [urlDraftId]);

  const refreshDraftEntry = () => {
    //logger.log("Refreshing draft entry");
    let draftItemArray = [];
    draftEntryHelper.getAllDrafts(userMaster.GUID).then(draftList => {
      logger.log('Draft local Items', draftList);
      if (draftList?.length > 0) {
        _.map(draftList, d => {
          let progress = draftEntryHelper.getDraftStepName(
            d.draft_metadata?.entry_step
          );
          ////logger.log('Draft local Item', d);
          let draftItem = {
            id: d.draft_id,
            title: moment(d.draft_metadata.last_updated)
              .utc()
              .local()
              .format('MM/DD/YYYY HH:mm A'),
            last_updated: d.draft_metadata.last_updated,
            avatar: {
              name: 'C',
              size: 'xl'
            },
            projectName: progress.label,
            documents: d.entry_data?.notarization_documents?.length || 'N/A',
            members: getDraftSigners(d.entry_data),
            progress: progress.value,
            color: progress.color,
            date: moment(d.draft_metadata.created)
              .utc()
              .local()
              .format('MM/DD/YYYY HH:mm A'),
            action: d.draft_id,
            isCompleted: !!d.draft_metadata.completed_at
          };
          ////logger.log('Draft local Item list', draftItem);
          draftItemArray.push(draftItem);
        });
      }
      //logger.log("Updated draft item array", draftItemArray);
      let sortedEntry = draftItemArray.sort((a, b) => {
        //logger.log("Draft Updated time", moment(b.last_updated).unix(), moment(a.last_updated).unix())
        return moment(b.last_updated).unix() - moment(a.last_updated).unix();
      });
      setDraftEntry(sortedEntry);
    });
  };

  const getDraftSigners = entryData => {
    let signers = entryData.signers || [];
    let signersArray = [];
    if (signers) {
      _.map(signers, s => {
        signersArray.push({
          id: s.signer_id,
          name: s.first_name + ' ' + s.last_name,
          size: 'xl'
        });
      });
    }
    return signersArray;
  };

  const createNewDraftIdOrWarn = () => {
    return new Promise((resolve, reject) => {
      draftEntryHelper
        .getNewDraftId()
        .then(newDraftId => {
          dispatch(createDraftOnServer({})).then(res => {
            logger.log('createNewDraftIdOrWarn Signing', res);
            dispatch(actionSetDraftSaveStatus(c.draftStatus.None));
            resolve(c.storage.draftEntryIdPrefix + res.GUID);
          });
        })
        .catch(err => {
          //logger.log('createNewDraftIdOrWarn catch error', err);
          dispatch(
            showAlert(
              'Drafts not available',
              'We were unable to create a new draft on your device.  Your signing will not be saved until uploaded to our server.'
            )
          );
          resolve(null);
        });
    });
  };

  const setDraftToEdit = async draft_id => {
    draftEntryHelper
      .getDraft(userMaster?.GUID, draft_id)
      .then(draft => {
        if (draft === null) {
          navigate('/notary/signing');
          logger.log('setDraftToEdit Signing', draft_id);
          toast.error("Draft doesn't exist.", {
            theme: 'colored',
            position: 'bottom-center',
            icon: faExclamation
          });
        } else {
          return new Promise((resolve, reject) => {
            if (draft && !_.isEmpty(draft)) {
              let now = moment().format();
              let draftMetadata = draft.draft_metadata;
              let draftEntrydata = draft.entry_data;

              let currentStepVal = c.entrySteps.STEP_1;
              if (
                !!draft.draft_metadata?.completed_at ||
                !!draftEntrydata.completedAtDate
              ) {
                setIsCompleted(true);
                currentStepVal = c.entrySteps.STEP_6;
              } else {
                setIsCompleted(false);
                currentStepVal = draftMetadata.entry_step;
              }
              let currentStep =
                draftEntryHelper.getDraftStepName(currentStepVal);

              dispatch(actionSetDraftSaveError(null));
              dispatch(actionSetDraftSaveStatus(c.draftStatus.None));
              setStep(currentStep.id);

              draftEntryHelper.saveUpdateDraft(
                userMaster?.GUID,
                draft_id,
                draftMetadata,
                draftEntrydata
              );
              //juratLocalStorage.set(c.storage.draftCurrent, draft_id);
              if (draftEntrydata.draft_id) {
                dispatch(actionReInstateSigning(draftEntrydata));
                navigate(
                  '/notary/signing/' +
                    draftEntryHelper.getDarftKeyFromId(draft_id)
                );
                resolve(true);
              } else {
                //logger.log('No draft found in signig, saving skipped');
              }
            } else {
              logger.warn(
                'draft was null or empty',
                userMaster?.GUID,
                draft_id,
                draft
              );
              resolve(null);
            }
          });
        }
      })
      .then(res => {});
  };

  const setBacktoDraftList = () => {
    segmentHelper.track(segmentHelper.events.SIGNING_BACK_TO_DRAFT_CLICK);
    navigate('/notary/drafts');
  };

  const initialiseNewEntry = () => {
    setInitializingNewEntry(true);
    Promise.all([
      dispatch(refreshSavedLocation()),
      dispatch(refreshDocumentList()),
      dispatch(refreshDocumentCollections())
    ]).then(res => {
      setInitializingNewEntry(false);
      setReadyToStartEntry(true);
    });
  };

  useEffect(() => {
    if (readyToStartEntry)
      segmentHelper.track(segmentHelper.events.CREATE_NEW_ENTRY_CLICKED);
  }, [readyToStartEntry]);

  const createNewDraftEntry = () => {
    setReadyToStartEntry(false);
    if (!initializingDraftEntry) {
      setInitializingDraftEntry(true);
      segmentHelper.track(segmentHelper.events.NEW_ENTRY_CLICK_TO_PROCEED);
      refreshDraftEntry();
      let draft_id = null;
      dispatch(actionReset());
      setStep(1);
      setIsCompleted(false);
      dispatch(actionSetDraftSaveError(null));
      createNewDraftIdOrWarn().then(newDraftId => {
        draft_id = newDraftId;
        logger.log('createNewDraftIdOrWarn Signing', draft_id);
        let now = moment().format();
        let draftMetadata = {
          created: now,
          completed_at: null,
          last_updated: now,
          entry_step: c.entrySteps.STEP_1
        };

        let updatedSavedDraftPromise = new Promise((resolve, reject) => {
          draftEntryHelper.saveUpdateDraft(
            userMaster.GUID,
            draft_id,
            draftMetadata,
            {}
          );

          setTimeout(() => {
            resolve(true);
          }, 100);
        });

        Promise.all([updatedSavedDraftPromise]).then(() => {
          let signingResetPromise = new Promise((resolve, reject) => {
            dispatch(actionReset());
            resolve(true);
          });

          Promise.all([signingResetPromise]).then(() => {
            let signingActionStartPromise = new Promise((resolve, reject) => {
              dispatch(
                actionStart({
                  draft_id: draft_id,
                  promptForRatingOnComplete: false,
                  startedAtDate: now
                })
              );
              resolve(true);
            });

            Promise.all([signingActionStartPromise]).then(() => {
              dispatch(actionLogSigningAction(`Signing Created.`));
              setInitializingDraftEntry(false);
              navigate(
                '/notary/signing/' +
                  draftEntryHelper.getDarftKeyFromId(draft_id)
              );
            });

            dispatch(
              actionAddNotification({
                message: 'Started New Journal Entry',
                to:
                  '/notary/signing/' +
                  draftEntryHelper.getDarftKeyFromId(draft_id)
              })
            );
          });
        });
      });
    }
  };

  return (
    <>
      <PageHeader
        //preTitle={urlDraftId ? 'New Signing' : 'Saved Signing Drafts'}
        title="New Journal Entry"
        className="mb-3"
        titleTag="h2"
        image={corner6}
        col={{ lg: 8, md: 7, sm: 12 }}
        eCol={{ lg: 4, md: 5, sm: 12 }}
        eol={
          <>
            <Flex justifyContent="end" alignItems="center">
              <div className=" me-2">
                {savingDraft ? (
                  <span className="text-primary">
                    <Spinner
                      style={{ width: 16, height: 16 }}
                      variant="primary"
                      size="sm"
                    />{' '}
                    Saving Draft
                  </span>
                ) : urlDraftId ? (
                  <span className="text-success">
                    <FontAwesomeIcon icon={faCheckCircle} /> Draft saved
                  </span>
                ) : (
                  <></>
                )}
              </div>
              {urlDraftId ? (
                <Button
                  onClick={() => setBacktoDraftList()}
                  variant="falcon-primary"
                  className="me-2 mb-1"
                >
                  <FontAwesomeIcon icon={faArrowCircleLeft} /> Draft list
                </Button>
              ) : (
                <></>
              )}
            </Flex>
          </>
        }
      />
      {initializingDraftEntry ? (
        <Flex
          className={'vh-50'}
          alignItems={'center'}
          justifyContent={'center'}
          direction={'column'}
        >
          <Spinner variant="secondary" size="md" />{' '}
          <p className="mt-3">Starting.... entry</p>
        </Flex>
      ) : (
        <SigningProvider>
          {urlDraftId ? (
            <SigningLayout
              variant={variant}
              validation={validation}
              progressBar={progressBar}
              saveSigningToDraft={saveSigningToDraft}
              setSavingDraft={setSavingDraft}
              setIsCompleted={setIsCompleted}
              isCompleted={isCompleted}
              step={step}
              setStep={setStep}
            />
          ) : (
            <></>
          )}
        </SigningProvider>
      )}
      {!urlDraftId && (
        <Row className="mb-3 g-3">
          <Col lg={12}>
            <Card>
              <Card.Body>
                {initializingNewEntry && (
                  <Flex
                    className={'vh-50'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    direction={'column'}
                  >
                    <Spinner variant="secondary" size="md" />{' '}
                    <p className="mt-3">Initializing... Draft</p>
                  </Flex>
                )}
                {readyToStartEntry && (
                  <Flex
                    alignItems={'center'}
                    justifyContent={'center'}
                    direction={'column'}
                  >
                    <p>
                      <FontAwesomeIcon icon={faCheckCircle} /> New entry pre
                      check completed & initialized.
                    </p>
                    <p>
                      <Button
                        variant="primary"
                        onClick={() => createNewDraftEntry()}
                      >
                        Click to proceed <FontAwesomeIcon icon={faForward} />
                      </Button>
                    </p>
                    {activeSubscriptions.length == 0 && (
                      <div className="pb-3">
                        <Alert variant="warning">
                          <p className="text-center">
                            You do not have an active subscription and will not
                            be able to upload new entries to the secure servers.
                            Any new entries created will be saved as a draft.
                            Please activate or upgrade your subscription to
                            resume normal journal functionality.
                          </p>
                        </Alert>
                      </div>
                    )}
                  </Flex>
                )}
              </Card.Body>
            </Card>
            <div className="mt-3">
              <FaqBasicCard
                faqs={faqs}
                header
                headerText="Frequently asked questions"
                bodyClass="bg-light"
                faqIdentity="Signing page"
              />
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

Signing.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool
};

export default Signing;
