import _ from 'lodash';
import logger from './logger';
import moment from 'moment';
import jpeg from 'jpeg-js';
import { Buffer } from 'buffer';
import { encode, decode } from 'base64-arraybuffer';
import c from './const';
import config from './config';

const utils = {
  waitForCondition(evaluateFn, waitTime) {
    return new Promise((resolve, reject) => {
      if (evaluateFn()) {
        resolve(true);
      } else {
        setTimeout(() => {
          resolve(this.waitForCondition(evaluateFn, waitTime));
        }, waitTime || 50);
      }
    });
  },

  waitFor(waitTime) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(true);
      }, waitTime || 50);
    });
  },

  trimInvalidCharsFromDate(dateDisplay) {
    return _.trim(dateDisplay, '-+ ');
  },

  isValidDate(dateDisplay) {
    let mdateDisplay = moment(dateDisplay);
    //The second format() is to work around a weird bug in moment I found, where large dates, wtih 5 digit years
    //could return values from format() that would not be valid.
    return (
      mdateDisplay.isValid() &&
      mdateDisplay.year() <= c.api.MAX_YEAR &&
      moment(mdateDisplay.format()).isValid()
    );
  },

  urlEncodeString(str) {
    return encodeURIComponent(str);
  },

  encodeObjectForBlobData(obj) {
    let objJsonStr = JSON.stringify(obj);
    return Buffer.from(objJsonStr).toString('base64');
  },

  decodeBlobData: function (str) {
    let decoded = atob(str);
    return JSON.parse(decoded);
  },
  recursiveStringify(data) {
    if (data === undefined) return undefined;
    else if (data === null) return 'null';
    else if (data.constructor === String)
      return '"' + data.replace(/"/g, '\\"') + '"';
    else if (data.constructor === Number) return String(data);
    else if (data.constructor === Boolean) return data ? 'true' : 'false';
    else if (data.constructor === Array)
      return (
        '[ ' +
        data
          .reduce((acc, v) => {
            if (v === undefined) return [...acc, 'null'];
            else return [...acc, this.recursiveStringify(v)];
          }, [])
          .join(', ') +
        ' ]'
      );
    else if (data.constructor === Object)
      return (
        '{ ' +
        Object.keys(data)
          .reduce((acc, k) => {
            if (data[k] === undefined) return acc;
            else
              return [
                ...acc,
                this.recursiveStringify(k) +
                  ':' +
                  this.recursiveStringify(data[k])
              ];
          }, [])
          .join(', ') +
        ' }'
      );
    else return '{}';
  },

  arrayBufferToBase64: function (buffer) {
    // var binary = '';
    // var bytes = new Uint8Array(buffer);
    // var len = bytes.byteLength;
    // for (var i = 0; i < len; i++) {
    //   binary += String.fromCharCode(bytes[i]);
    // }
    // return window.btoa(binary);
    return encode(buffer);
  },
  convertRawBitmapToJpegB64(bytes, imgWidth, imgHeight) {
    //Need to format raw bitmap bytes, encode to jpeg
    //Then return b64 string

    let data = bytes.reduce(function (carry, current, index) {
      let baseIndex = index * 4;

      carry[baseIndex] = current;
      carry[baseIndex + 1] = current;
      carry[baseIndex + 2] = current;
      carry[baseIndex + 3] = 256;

      return carry;
    }, new Uint8ClampedArray(imgWidth * imgHeight * 4));

    let encoded = jpeg.encode({
      data,
      width: imgWidth,
      height: imgHeight
    });

    return new Buffer(encoded.data).toString('base64');
  },

  getSigningFeeObject(signingFeeArry) {
    let newSigningFeeObject = [];
    if (signingFeeArry) {
      _.map(signingFeeArry, s => {
        newSigningFeeObject.push({
          id: _.uniqueId(Date.now()),
          Name: s.Name,
          Amount: s.Amount,
          FeeCurrency: s.FeeCurrency || 'USD'
        });
      });
    }
    return newSigningFeeObject;
  },

  getProfilePic(user) {
    return user?.Blob?.GUID
      ? `${config.endpoint}/blob/${user?.Blob?.GUID}`
      : '';
  }
};

export default utils;
