import PageHeader from 'components/common/PageHeader';
import React, { useEffect, useState } from 'react';
import { Card, Row, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import api from 'util/api';

const FeedbackComp = ({ user }) => {
  const appID = '63d0c6b47178db4507027fb7';
  const boardToken = '0990bb65-0981-4165-bcf0-1da848c4ecb6';
  const [isCannyLoading, setCannyLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    setCannyLoading(true);
    (function (w, d, i, s) {
      function l() {
        if (!d.getElementById(i)) {
          var f = d.getElementsByTagName(s)[0],
            e = d.createElement(s);
          (e.type = 'text/javascript'),
            (e.async = !0),
            (e.src = 'https://canny.io/sdk.js'),
            f.parentNode.insertBefore(e, f);
        }
      }
      if ('function' != typeof w.Canny) {
        var c = function () {
          c.q.push(arguments);
        };
        (c.q = []),
          (w.Canny = c),
          'complete' === d.readyState
            ? l()
            : w.attachEvent
            ? w.attachEvent('onload', l)
            : w.addEventListener('load', l, !1);
      }
    })(window, document, 'canny-jssdk', 'script');

    try {
      new Promise((resolve, reject) => {
        resolve(api.UserMaster.getCannyToken());
      })
        .then(res => {
          console.log('CannyToken res', res);
          Canny('render', {
            boardToken: boardToken,
            basePath: '/feedback', // See step 2
            ssoToken: res.CannyToken, // See step 3
            onLoadCallback: feedbackLoaded
          });

          Canny('identify', {
            appID: appID,
            user: {
              // Replace these values with the current user's data
              email: user?.Email,
              name: user?.FirstName + ' ' + user?.LastName,
              id: user?.GUID,
              // These fields are optional, but recommended:
              //avatarURL: userinfo.image,
              created: user?.Created
              //created: new Date().toISOString(),
            }
          });
        })
        .catch(err => {});
    } catch (err) {}
    //const token = dispatch(getCannyToken());
    //logger.log("Canny token", token);
  }, []);

  const feedbackLoaded = () => {
    setCannyLoading(false);
  };

  return (
    <>
      {isCannyLoading ? (
        <>
          <div className="text-center py-3 pb-5">
            <Spinner className="position-absolute start-50" animation="grow" />
          </div>
        </>
      ) : (
        <></>
      )}
      <div data-canny />
    </>
  );
};

const FeatureRequest = ({ user }) => {
  const { userMaster } = useSelector(state => state.app);
  return (
    <>
      <PageHeader
        title="Feature request"
        description="We constantly build new features based directly on our user's feedback. Join the conversation below and ensure you are heard!"
        className="mb-3"
      ></PageHeader>
      <Card className="mb-3">
        <Card.Body>
          <Row className="g-0">
            {' '}
            {userMaster && <FeedbackComp user={userMaster} />}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default FeatureRequest;
