import { faFolder, faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import { faBook, faCreditCard, faFileContract, faListOl, faListUl, faThumbsUp } from '@fortawesome/free-solid-svg-icons';

export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      active: true,
      icon: 'chart-pie',
      to: '/',
      exact: true,
      active: true
    }
  ]
};

export const notaryRoutes = {
  label: 'The Notary eJournal',
  children: [
    {
      name: 'Journal',
      icon: 'book',
      to: '/notary/journal',
      active: true
    },
    {
      name: 'Journal Exports',
      icon: 'list',
      to: '/notary/reporting',
      active: true
    },
    {
      name: 'Saved Drafts',
      icon: faFolder,
      to: '/notary/drafts',
      active: true
    },
    {
      name: 'New Entry',
      icon: 'plus',
      to: '/notary/signing',
      badge: {
        type: 'success',
        text: 'New'
      },
      active: true
    }
  ]
};

export const libraryRoutes = {
  label: 'Signing Library',
  children: [
    {
      name: 'Document Types',
      icon: faFileContract,
      to: '/library/document-types',
      active: true
    },
    {
      name: 'Favorite Locations',
      icon: 'star',
      to: '/library/favorite-locations',
      active: true
    },
    {
      name: 'Signing Packages',
      icon: faFolderOpen,
      to: '/library/signing-packages',
      active: true
    }
  ]
};

export const accountRoutes = {
  label: 'Account',
  children: [
    {
      name: 'User Profile',
      icon: 'user',
      to: '/account/user-profile',
      active: true
    },
    {
      name: 'Notary Commission',
      icon: 'user-circle',
      to: '/account/notary-profile',
      active: true
    },
    {
      name: 'Verification',
      icon: 'check',
      to: '/account/verification',
      active: true
    },
    {
      name: 'Devices',
      icon: 'mobile',
      to: '/account/devices',
      active: true
    }
  ]
};

export const knowledgeRoutes = {
  label: 'Knowledge Center',
  children: [
    {
      name: 'FAQs',
      icon: 'info-circle',
      to: '/knowledge-center/faqs',
      active: true
    },
    {
      name: 'Tutorials',
      icon: 'play',
      target: '_blank',
      to: 'https://intercom.help/jurat-inc/en',
      badge: {
        type: 'warning',
        text: 'Popular'
      },
      active: true
    }
  ]
};

export const extraRoutes = {
  label: 'Extras',
  children: [
    {
      name: 'Affiliate Program',
      icon: 'link',
      to: '/extra/affiliate-program',
      active: true
    },
    {
      name: 'Badges',
      icon: 'award',
      to: '/extra/badges',
      active: true
    },
    {
      name: 'Feature Requests',
      icon: 'layer-group',
      to: '/extra/feature-request',
      badge: {
        type: 'success',
        text: 'New'
      },
      active: true
    }
  ]
};

export const billingRoutes = {
  label: 'Billing',
  children: [
    {
      name: 'Subscriptions',
      icon: 'crown',
      to: '/billing/subscriptions',
      active: true
    },
    {
      name: 'Payment History',
      icon: 'paper-plane',
      to: '/billing/payment-history',
      active: true
    },
    {
      name: 'Payment Method',
      icon: faCreditCard,
      to: '/billing/payment-method',
      active: true
    }
  ]
};

export default [
  dashboardRoutes,
  notaryRoutes,
  libraryRoutes,
  accountRoutes,
  knowledgeRoutes,
  extraRoutes,
  billingRoutes
];
