import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import { capitalize, currencyCodeToSign } from 'helpers/utils';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Spinner } from 'react-bootstrap';
import enums from 'util/enums';
import logger from 'util/logger';

const SubscriptionV1Card = ({
  pricing: {
    GUID,
    Type,
    Recur,
    Name,
    InitialAmount,
    Amount,
    Currency,
    CouponRequired,
    MaxSigningsTotal,
    MaxSigningsMonthly,
    MaxIdScansTotal,
    MaxIdScansMonthly,
    IsPopular,
    Active,
    Created
  }, icon, confirmUpgradePlan, subscribingPriceId, activeSubscriptions }) => {
  let variant = _.map(activeSubscriptions, 'SubscriptionTierGUID').includes(GUID) ? "success" : "primary"
  return (Amount > 0 && <Col sm={12} md={6} lg={4} xl={6} className='position-relative my-3'>
    {Active && IsPopular && <div className="ribbon ribbon-top-right"><span>Popular</span></div>}
    <div className="border rounded-3 overflow-hidden mb-3 mb-md-0">
      <Flex justifyContent="between" alignItems="center" className="p-4">
        <div>
          <h3 className={`fw-light fs-4 mb-0 text-${variant}`}>{'Active ' + (capitalize(Recur + 'ly'))}</h3>
          <h2 className={`fw-light mt-0 text-${variant}`}>
            <sup className="fs-1">{currencyCodeToSign(Currency.toString().toUpperCase())}</sup>
            <span className="fs-4">{Amount}</span>
            <span className="fs--1 mt-1">/ {capitalize(Recur + 'ly')}</span>
          </h2>

        </div>
        <div className="pe-3">
          <img src={icon} width="70" alt={Name} />
        </div>
      </Flex>
      <div className="p-4 pt-0 bg-light">
        {/* <ul className="list-unstyled">
          <li className={classNames('border-bottom py-2', `text-${variant}`)}> <FontAwesomeIcon icon="check" className={`text-${variant}`} transform="shrink-2" />{' '}   Max monthly signing {MaxSigningsMonthly == 2147483647 ? 'Unlimited' : MaxSigningsMonthly}</li>
          <li className={classNames('border-bottom py-2', `text-${variant}`)}> <FontAwesomeIcon icon="check" className={`text-${variant}`} transform="shrink-2" />{' '}   Max yearly signing {MaxSigningsMonthly == 2147483647 ? 'Unlimited' : MaxSigningsMonthly}</li>
          <li className={classNames('border-bottom py-2', `text-${variant}`)}> <FontAwesomeIcon icon="check" className={`text-${variant}`} transform="shrink-2" />{' '}   Max total signing {MaxSigningsTotal == 2147483647 ? 'Unlimited' : MaxSigningsTotal}</li>
          <li className={classNames('border-bottom py-2', `text-${variant}`)}> <FontAwesomeIcon icon="check" className={`text-${variant}`} transform="shrink-2" />{' '}   Max monthly ID scan {MaxIdScansMonthly == 2147483647 ? 'Unlimited' : MaxIdScansMonthly}</li>
          <li className={classNames('border-bottom py-2', `text-${variant}`)}> <FontAwesomeIcon icon="check" className={`text-${variant}`} transform="shrink-2" />{' '}   Max total ID scan {MaxIdScansTotal == 2147483647 ? 'Unlimited' : MaxIdScansTotal}</li>
        </ul> */}
        {Amount > 0 && <Button
          onClick={() => confirmUpgradePlan(GUID)}
          variant={`outline-${variant}`}
          className="d-block w-100"
          disabled={_.map(activeSubscriptions, 'SubscriptionTierGUID').includes(GUID) || Type == enums.SUBSCRIPTION_TYPE.Retention}
        >
          {_.map(activeSubscriptions, 'SubscriptionTierGUID').includes(GUID) ? "Subscribed" : "Subscribe"}
          {subscribingPriceId === GUID && <>
            <Spinner size='sm' /> Upgrading...
          </>}
        </Button>}
      </div>
    </div>
  </Col>
  );
};

SubscriptionV1Card.propTypes = {
  pricing: PropTypes.shape({
    Name: PropTypes.string

  })
};

export default SubscriptionV1Card;
