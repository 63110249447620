import React from 'react';
import PropTypes from 'prop-types';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import FalconCardHeader from 'components/common/FalconCardHeader';
import CardDropdown from 'components/common/CardDropdown';
import { Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { Link } from 'react-router-dom';
import Avatar from 'components/common/Avatar';
import classNames from 'classnames';

const ActiveDevices = ({ users, end = 5, ...rest }) => {
  return (
    <Card {...rest}>
      <FalconCardHeader
        light
        title="Active Devices"
        titleTag="h6"
        className="py-2"
      // endEl={<CardDropdown />}
      />
      <Card.Body className="py-2">
        {users && users.slice(0, end).map(({ id, ...rest }, index) => (
          <ActiveDevice {...rest} key={id + '-' + index} isLast={index === users.length - 1} />
        ))}
      </Card.Body>
      <FalconCardFooterLink
        title="View all devices"
        to="/account/devices"
        size="sm"
      />
    </Card>
  );
};

const ActiveDevice = ({ name, icon, Lastseen, blocked, isLast }) => (
  <Flex className={classNames('align-items-center', { 'mb-3': !isLast })}>
    <Avatar
      icon={icon}
      size="xl"
      className={`status-${blocked ? 'offline' : 'online'}`}
    />
    <div className="ms-3">
      <h6 className="mb-0 fw-semi-bold">
        <Link className="text-900" to="#">
          {name}
        </Link>
      </h6>
      <p className="text-500 fs--2 mb-0">Last seen {Lastseen}</p>
    </div>
  </Flex>
);

ActiveDevices.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.any,
  Lastseen: PropTypes.any,
  blocked: PropTypes.bool,
  isLast: PropTypes.bool
};

ActiveDevices.propTypes = {
  users: PropTypes.array,
  end: PropTypes.number
};

export default ActiveDevices;
