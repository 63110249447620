import {
  faExclamation,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import corner6 from 'assets/img/illustrations/corner-6.png';
import PageHeader from 'components/common/PageHeader';
import draftEntryHelper from 'helpers/draft-entry-helper';
import segmentHelper from 'helpers/segment-helper';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  actionSetDraftSaveError,
  actionSetDraftSaveStatus,
  listDraftFromServer,
  removeDraftFromServer
} from 'stores/app';
import { showAlert, showConfirm } from 'stores/modal';
import { actionAddNotification } from 'stores/notification';
import {
  actionLogSigningAction,
  actionReInstateSigning,
  actionReset,
  actionStart
} from 'stores/signing';
import { resolve } from 'superagent/lib/request-base';
import c from 'util/const';
import logger from 'util/logger';
import utils from 'util/utils';
import DarftLayout from './DarftLayout';

const Drafts = ({ variant, validation, progressBar }) => {
  const [draftEntry, setDraftEntry] = useState([]);
  const dispatch = useDispatch();
  const { signing } = useSelector(state => state);
  const { userMaster } = useSelector(state => state.app);
  const [savingDraft, setSavingDraft] = useState(false);
  const navigate = useNavigate();
  const { urlDraftId } = useParams();
  const [isCompleted, setIsCompleted] = useState(false);
  const [isDraftSynced, setDraftSynced] = useState(false);
  const [isRefreshingDrafts, setRefreshingDrafts] = useState(false);

  const [step, setStep] = useState(1);

  useEffect(() => {
    if (userMaster && !urlDraftId) {
      draftEntryHelper.refreshLocalDraft(userMaster.GUID).then(res => {
        if (!isRefreshingDrafts) refreshDraftFromServer();
      });
    } else if (urlDraftId) {
      logger.log('Found urlDraftId to edit', urlDraftId);
      setDraftToEdit(draftEntryHelper.getDarftIdFromKey(urlDraftId));
    }
  }, [userMaster, urlDraftId]);

  // useEffect(() => {
  //   draftEntryHelper.refreshLocalDraft(userMaster?.GUID).then(res => {
  //     refreshDraftFromServer();
  //   });
  // }, []);

  const refreshDraftFromServer = () => {
    if (userMaster && !isRefreshingDrafts) {
      appendDraftFromServer().then(res => {
        refreshDraftEntry();
      });
    }
  };

  // useEffect(() => {
  //   if (userMaster) {
  //     refreshDraftEntry();
  //   }
  // }, [isDraftSynced]);

  const refreshDraftEntry = () => {
    setDraftEntry([]);
    //logger.log("Refreshing draft entry");
    let draftItemArray = [];
    draftEntryHelper.getAllDrafts(userMaster.GUID).then(draftList => {
      logger.log('Draft local Items', draftList);
      if (draftList?.length > 0) {
        draftItemArray = listOurDraft(draftList);
      }
      //logger.log("Updated draft item array", draftItemArray);
      let sortedEntry = draftItemArray.sort((a, b) => {
        //logger.log("Draft Updated time", moment(b.last_updated).unix(), moment(a.last_updated).unix())
        return moment(b.last_updated).unix() - moment(a.last_updated).unix();
      });
      logger.log('sortedEntry', sortedEntry);
      setDraftEntry(sortedEntry);
    });
  };

  const listOurDraft = draftList => {
    let draftItemArray = [];
    _.map(draftList, d => {
      if (d !== null) {
        console.log('listOurDraft draft', d);
        let progress = draftEntryHelper.getDraftStepName(
          d.draft_metadata?.entry_step
        );
        ////logger.log('Draft local Item', d);
        let draftItem = {
          id: d.draft_id,
          title: moment(d.draft_metadata?.last_updated)
            .utc()
            .format('MM/DD/YYYY HH:mm A'),
          last_updated: d.draft_metadata?.last_updated,
          avatar: {
            name: 'C',
            size: 'xl'
          },
          projectName: progress.label,
          documents: d.entry_data?.notarization_documents?.length || 'N/A',
          members: getDraftSigners(d.entry_data),
          progress: progress.value,
          color: progress.color,
          date: moment(d.draft_metadata?.created)
            .utc()
            .format('MM/DD/YYYY HH:mm A'),
          action: d.draft_id,
          isCompleted: !!d.draft_metadata?.completed_at,
          location: d.entry_data?.location || null,
          source: d.draft_metadata?.source || 'Desktop'
        };
        ////logger.log('Draft local Item list', draftItem);
        draftItemArray.push(draftItem);
      }
    });
    return draftItemArray;
  };

  const appendDraftFromServer = async () => {
    setRefreshingDrafts(true);

    dispatch(listDraftFromServer(true))
      .then(async res => {
        logger.log('appendDraftFromServer', res);
        if (res) {
          let promisses = [];
          let DraftGUIDList = [];
          let UserMasterGUID = null;
          _.map(res, async entry => {
            let serverDraft = utils.decodeBlobData(entry.Body);
            //logger.log('serverDraft', serverDraft);
            UserMasterGUID = entry?.UserMasterGUID;
            promisses.push(
              draftEntryHelper.saveUpdateDraft(
                entry?.UserMasterGUID,
                draftEntryHelper.getDarftIdFromKey(entry.GUID),
                serverDraft.draft_data,
                serverDraft.entry_data
              )
            );
            DraftGUIDList.push(draftEntryHelper.getDarftIdFromKey(entry.GUID));
          });

          const res_3 = await Promise.allSettled(promisses).then(res => {
            try {
              draftEntryHelper
                .saveUsersDraftGUIDs(UserMasterGUID, DraftGUIDList)
                .then(res => {
                  setRefreshingDrafts(false);
                  setDraftSynced(true);
                  refreshDraftEntry();
                });
            } catch (e) {
              setRefreshingDrafts(false);
              setDraftSynced(true);
              refreshDraftEntry();
            }
          });
        } else {
          setRefreshingDrafts(false);
          resolve(true);
        }
      })
      .catch(err => {
        console.log(err);
        toast.warning("Draft couldn't refresh properly. Try again!", {
          theme: 'colored',
          position: 'bottom-center',
          icon: faExclamationTriangle,
          toastId: 'login-success'
        });
        setRefreshingDrafts(false);
      });
  };

  const getDraftSigners = entryData => {
    let signers = entryData?.signers || [];
    let signersArray = [];
    if (signers) {
      _.map(signers, s => {
        signersArray.push({
          id: s.signer_id,
          name: s.first_name + ' ' + s.last_name,
          size: 'xl'
        });
      });
    }
    return signersArray;
  };

  const createNewDraftIdOrWarn = () => {
    return new Promise((resolve, reject) => {
      draftEntryHelper
        .getNewDraftId()
        .then(newDraftId => {
          dispatch(createDraftOnServer({})).then(res => {
            logger.log('createNewDraftIdOrWarn Draft', res);
            dispatch(actionSetDraftSaveStatus(c.draftStatus.None));
            resolve(c.storage.draftEntryIdPrefix + res.GUID);
          });
        })
        .catch(err => {
          //logger.log('createNewDraftIdOrWarn catch error', err);
          dispatch(
            showAlert(
              'Drafts not available',
              'We were unable to create a new draft on your device.  Your signing will not be saved until uploaded to our server.'
            )
          );
          resolve(null);
        });
    });
  };

  const setDraftToEdit = async draft_id => {
    draftEntryHelper
      .getDraft(userMaster?.GUID, draft_id)
      .then(draft => {
        if (draft === null) {
          navigate('/notary/signing');
          logger.log('setDraftToEdit Draft', draft_id);
          toast.error("Draft doesn't exist.", {
            theme: 'colored',
            position: 'bottom-center',
            icon: faExclamation
          });
        } else {
          return new Promise((resolve, reject) => {
            if (draft && !_.isEmpty(draft)) {
              let now = moment().format();
              let draftMetadata = draft.draft_metadata;
              let draftEntrydata = draft.entry_data;

              let currentStepVal = c.entrySteps.STEP_1;
              if (
                !!draft.draft_metadata.completed_at ||
                !!draftEntrydata.completedAtDate
              ) {
                setIsCompleted(true);
                currentStepVal = c.entrySteps.STEP_6;
              } else {
                setIsCompleted(false);
                currentStepVal = draftMetadata.entry_step;
              }
              let currentStep =
                draftEntryHelper.getDraftStepName(currentStepVal);

              dispatch(actionSetDraftSaveError(null));
              dispatch(actionSetDraftSaveStatus(c.draftStatus.None));
              setStep(currentStep.id);

              draftEntryHelper.saveUpdateDraft(
                userMaster?.GUID,
                draft_id,
                draftMetadata,
                draftEntrydata
              );
              //juratLocalStorage.set(c.storage.draftCurrent, draft_id);
              if (draftEntrydata.draft_id) {
                dispatch(actionReInstateSigning(draftEntrydata));
                navigate(
                  '/notary/signing/' +
                    draftEntryHelper.getDarftKeyFromId(draft_id)
                );
                resolve(true);
              } else {
                //logger.log('No draft found in signig, saving skipped');
              }
            } else {
              logger.warn(
                'draft was null or empty',
                userMaster?.GUID,
                draft_id,
                draft
              );
              resolve(null);
            }
          });
        }
      })
      .then(res => {});
  };

  const removeDraft = draft_id => {
    dispatch(
      showConfirm(
        'Are you sure?',
        'All data associated with this draft, including signatures, documents & identifications will be deleted. This action can not be undone.',
        res => {
          if (res) {
            draftEntryHelper
              .removeDraft(userMaster?.GUID, draft_id)
              .then(res => {
                dispatch(
                  removeDraftFromServer(
                    draftEntryHelper.getDarftKeyFromId(draft_id)
                  )
                ).then(res => {
                  segmentHelper.track(
                    segmentHelper.events.DELETE_DRAFT_CLICKED
                  );
                  refreshDraftEntry();
                });
              });

            dispatch(
              actionAddNotification({
                message: 'Deleted Journal Entry from draft',
                name: userMaster.FirstName + ' ' + userMaster.LastName
              })
            );
          }
        }
      )
    );
  };

  const createNewDraftEntry = () => {
    segmentHelper.track(segmentHelper.events.CREATE_NEW_ENTRY_CLICKED);
    refreshDraftEntry();
    let draft_id = null;
    dispatch(actionReset());
    setStep(1);
    setIsCompleted(false);
    dispatch(actionSetDraftSaveError(null));
    createNewDraftIdOrWarn().then(newDraftId => {
      draft_id = newDraftId;

      let now = moment().format();
      let draftMetadata = {
        created: now,
        completed_at: null,
        last_updated: now,
        entry_step: c.entrySteps.STEP_1
      };

      let updatedSavedDraftPromise = new Promise((resolve, reject) => {
        draftEntryHelper.saveUpdateDraft(
          userMaster.GUID,
          draft_id,
          draftMetadata,
          {}
        );

        setTimeout(() => {
          resolve(true);
        }, 100);
      });

      Promise.all([updatedSavedDraftPromise]).then(() => {
        let signingResetPromise = new Promise((resolve, reject) => {
          dispatch(actionReset());
          resolve(true);
        });

        Promise.all([signingResetPromise]).then(() => {
          let signingActionStartPromise = new Promise((resolve, reject) => {
            dispatch(
              actionStart({
                draft_id: draft_id,
                promptForRatingOnComplete: false,
                startedAtDate: now
              })
            );
            resolve(true);
          });

          Promise.all([signingActionStartPromise]).then(() => {
            dispatch(actionLogSigningAction(`Signing Created.`));
            navigate(
              '/notary/signing/' + draftEntryHelper.getDarftKeyFromId(draft_id)
            );
          });

          dispatch(
            actionAddNotification({
              message: 'Started New Journal Entry',
              to:
                '/notary/signing/' +
                draftEntryHelper.getDarftKeyFromId(draft_id)
            })
          );
        });
      });
    });
  };

  return (
    <>
      <PageHeader
        //preTitle={urlDraftId ? 'New Signing' : 'Saved Signing Drafts'}
        title="Saved Drafts"
        className="mb-3"
        titleTag="h2"
        image={corner6}
        col={{ lg: 8, md: 7, sm: 12 }}
        eCol={{ lg: 4, md: 5, sm: 12 }}
        eol={<></>}
      />
      <DarftLayout
        draftEntry={draftEntry}
        createNewDraftEntry={createNewDraftEntry}
        removeDraft={removeDraft}
        isDraftSynced={isDraftSynced}
        refreshDraftFromServer={refreshDraftFromServer}
        isRefreshingDrafts={isRefreshingDrafts}
      />
    </>
  );
};

Drafts.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool
};

export default Drafts;
