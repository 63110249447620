import _ from 'lodash';

const enums = {
  //From Mobile
  FINGERS: {
    RIGHT_THUMB: 'Right Thumb',
    RIGHT_INDEX: 'Right Index Finger',
    RIGHT_MIDDLE: 'Right Middle Finger',
    RIGHT_RING: 'Right Ring Finger',
    RIGHT_PINKY: 'Right Pinky Finger',
    LEFT_THUMB: 'Left Thumb',
    LEFT_INDEX: 'Left Index Finger',
    LEFT_MIDDLE: 'Left Middle Finger',
    LEFT_RING: 'Left Ring Finger',
    LEFT_PINKY: 'Left Pinky Finger'
  },
  ID_TYPES: {
    DRIVERS_LICENSE: 'Driver License',
    STATE_ID: 'State ID Card',
    PASSPORT: 'Passport',
    MILITARY_ID: 'Military ID',
    OTHER: 'Other',
    NONE: 'No Identification',
    PERSONALLY_KNOWN: 'Personally Known'
  },
  NOTARIZATION_TYPE: {
    //ACKNOWLEDGE : "Acknowledge", - old, needs translation
    ACKNOWLEDGE: 'Acknowledgement',
    JURAT: 'Jurat',
    COPY_CERTIFICATION: 'Copy Certification',
    SIGNATURE_WITNESSING: 'Signature Witnessing',
    OATH_AFFIRMATION: 'Oath/Affirmation',
    OTHER: 'Other'
  },

  findResultCaseInsensitive(enumObj, val) {
    if (!enumObj || !val) {
      return null;
    }

    let foundResult = null;
    _.each(_.keys(enumObj), key => {
      if (val.toLowerCase() === enumObj[key].toLowerCase()) {
        foundResult = enumObj[key];
      }
    });
    if (!foundResult) {
      return val;
    }
    return foundResult;
  },

  normalizeNotarizationType(type) {
    if (!type) {
      return type;
    }
    let lowerCaseType = type.toLowerCase();
    //From an old typo in code, could be either Acknowledge or Acknowledgement
    if (lowerCaseType === 'acknowledge') {
      return this.NOTARIZATION_TYPE.ACKNOWLEDGE;
    } else {
      return this.findResultCaseInsensitive(
        this.NOTARIZATION_TYPE,
        lowerCaseType
      );
    }
  },

  DOCUMENT_TYPE: {
    POWER_OF_ATTORNEY: 'Power of Attorney',
    DEED_OF_TRUST: 'Deed of Trust',
    GRANT_DEED: 'Grant Deed',
    NAME_AFFIDAVIT: 'Name Affidavit',
    QUITCLAIM_DEED: 'Quitclaim Deed',
    LIVING_TRUST: 'Living Trust',
    SUBORDINATION_AGREEMENT: 'Subordination Agreement',
    AFFIDAVIT_OF_FINANCIAL_STATUS: 'Affidavit of Financial Status',
    OTHER: 'Other',
    TRUST_CERTIFICATION: 'Trust Certification',
    HO_TITLE_INSURANCE_AFF: 'Home Owner Title Insurance Affidavit',
    OWNERS_AFF: 'Owners Affidavit',
    COMPLIANCE_AGREEMENT: 'Compliance Agreement',
    OCCUPANCY_AFF: 'Occupancy Affidavit'
  },
  BLOB_GUIDS: {
    DOC_BLOB: 'documentblob',
    FINGERPRINT_BLOB: 'fingerprintblob',
    IDENTIFICATION_BLOB: 'identificationblob',
    PICTURE_BLOB: 'pictureblob',
    SIGNATURE_BLOB: 'signatureblob'
  },

  BLOB_TYPES: {
    DOCUMENT: 'document',
    FINGERPRINT: 'fingerprint',
    IDENTIFICATION: 'identification',
    SCAN_DATA: 'scan_data',
    PICTURE: 'picture',
    SIGNATURE: 'signature',
    MARK_WITNESS_SIGNATURE: 'mark_witness_signature'
  },
  CONTENT_TYPES: {
    APPLICATION_OCTET_STREAM: 'application/octet-stream',
    APPLICATION_JSON: 'application/json'
  },

  UI_FLOW_TYPE: {
    SIGNER: 'signer',
    WITNESS_ONE: 'witness_one',
    WITNESS_TWO: 'witness_two',
    PERSONALLY_KNOWN: 'personally_known'
  },

  SUBSCRIPTION_TYPE: {
    Trial: 'Trial',
    Retention: 'Retention',
    Standard: 'Standard',
    Professional: 'Professional',
    NoCharge: 'NoCharge'
  },

  RECUR_TYPE: {
    Month: 'Month',
    Year: 'Year'
  },

  VERIFICATION_TYPES: {
    SMS: 'sms',
    EMAIL: 'email'
  }
};

export default enums;
