import { Analytics } from 'analytics';
import segmentPlugin from '@analytics/segment';
import logger from 'util/logger';
import config from 'util/config';
import { useSelector } from 'react-redux';
import store from 'app/store';

const segmentHelper = {
  eventPrefix() {
    return 'WebApp-';
  },
  reset() {
    this.analytics().reset();
  },
  analytics() {
    if (config?.segmentWriteKey)
      return Analytics({
        app: 'Web App',
        plugins: [
          segmentPlugin({
            writeKey: config.segmentWriteKey
          })
        ]
      });
    else return null;
  },
  track(eventName, options) {
    logger.log('Segment Tracking Added', eventName);
    window.Intercom('trackEvent', this.eventPrefix() + eventName);
    return new Promise((resolve, reject) => {
      let analytics = this.analytics();
      analytics
        ? analytics.track(this.eventPrefix() + eventName, options || {}, {
            page: {
              referrer: document.referrer,
              url: window.location.href
            }
          })
        : reject(false);
    });
  },

  identity() {
    let user = store.getState().app.userMaster;
    let subscriptionStatus = store.getState().subscription.subscriptionStatus;
    let activeSubscriptions = _.get(subscriptionStatus, 'Active', []);

    logger.log('Segment Identity Called', user);
    if (user) {
      logger.log('Segment Identity added', user);
      return new Promise((resolve, reject) => {
        let analytics = this.analytics();
        analytics
          ? analytics.identify(user.GUID, {
              name: user.FirstName + ' ' + user.LastName,
              email: user.Email,
              createdAt: user.Created,
              phone: user.Telephone || '',
              plan: _.join(_.map(activeSubscriptions, 'Name'), ','),
              subscriptionStatus:
                activeSubscriptions.length > 0 ? 'Active' : 'Inactive',
              address: {
                country: user.Country || '',
                state: user.State || ''
              }
            })
          : reject(false);
      });
    }
  },

  page() {
    logger.log('Segment Page Added');
    return new Promise((resolve, reject) => {
      let analytics = this.analytics();
      analytics ? analytics.page() : reject(false);
    });
  },
  events: {
    SIGNIN_SUCCESS: 'Signin Success',
    SIGNUP_SUCCESS: 'Signup Success',
    CREATE_NEW_ENTRY_CLICKED: 'Create new entry clicked', //
    DELETE_DRAFT_CLICKED: 'Delete draft clicked',
    SIGNING_ADD_ANOTHER_IDENTIFICATION: 'Add another identification click',
    SIGNING_ADD_PERSON_OPTION_SELECTED: 'Add person option selected ',
    SIGNING_REVIEW_AND_RECIPIENT_ADDED: 'Signing review, add recipient email',
    //SIGNING_STEP_TWO_HISTORY_CLICK: 'Step Two history click',
    //SIGNING_STEP_THREE_HISTORY_CLICK: 'Step Three history click',
    //SIGNING_STEP_FOUR_HISTORY_CLICK: 'Step Four history click',
    //SIGNING_STEP_FIVE_HISTORY_CLICK: 'Step Five history click',
    SIGNING_REMOVE_FINGERPRINT: 'Remove fingerprint',
    //SIGNING_REMOVE_FINGERPRINT: 'Step One history click',
    //SIGNING_STEP_SIX_ADD_SIGNER_CLICK: 'Step 6 add signer click',
    //SIGNING_STEP_SIX_ADD_DOCUMENT_CLICK: 'Step 6 add document click',
    SIGNING_DOCUMENT_REMOVED_CLICK: 'Remove document click', //
    SIGNING_LOAD_SIGNING_PACKAGE: 'Load signing package',
    SIGNING_SAVE_SIGNING_PACKAGE: 'Save signing package', //
    SIGNING_SIGNER_ADDED: 'Signer added ', //
    SIGNING_REMOVE_SIGNER_CLICK: 'Remove Signer click',
    SIGNING_SIGNER_SIGNATURE_ADDED: 'Signer Signature added',
    SIGNING_REMOVE_SIGNATURE_CLICK: 'Remove Signature click',
    SIGNING_REMOVED_IDENTIFICATION: 'Remove Identification click', //
    SIGNING_BACK_TO_DRAFT_CLICK: 'Signing Back to Draft Click',
    SIGNING_DETECT_TO_CURRENT_LOCATION_CLICK: 'Detect current location clicked',
    SIGNING_PACKAGE_REMOVED: 'Signing package removed',
    SIGNING_FAVORITE_LOCATION_SAVED: 'Favorite Location Saved',
    SIGNING_SAVED_LOCATION_LOADED: 'Saved Location loaded',
    SIGNING_NEW_JOURNAL_ENTRY_CREATED: 'New journal entry created',
    SIGNING_NEW_JOURNAL_ENTRY_FAILED: 'New journal entry failed',

    SIGNING_DETAIL_STRIKE_ENTRY: 'Signing detail strike entry',
    SIGNING_DETAIL_UNSTRIKE_ENTRY: 'Signing detail unstrike entry',
    DOCUMENT_TYPE_DELETED: 'Library document type deleted', //
    DOCUMENT_TYPE_CREATED: 'Library document type created', //
    SIGNING_PACKAGE_DELETED: 'Library signing package deleted',

    SUBSCRIPTION_CANCELE_CLICK: 'Cancel subscription click', //
    SUBSCRIPTION_CANCELED: 'Canceled subscription', //
    SUBSCRIPTION_UPGRADED: 'Upgraded subscription', //
    SUBSCRIBED_FREE_TO_PAID: 'Upgraded Subscription Trial to Paid', //

    PAYMENT_METHOD_ADDED: 'Payment method added', //
    PAYMENT_METHOD_REMOVED: 'Payment method removed', //
    CLICKED_I_HAVE_A_COUPON: 'Clicked i have a coupon', //
    COUPON_CONFIRMED: 'Coupon confirmed', //
    SUBSCRIPTION_UPGRADED_WITH_COUPON: 'Upgraded subscription with coupon', //

    DOWNLOADED_BADGES: 'Downloaded badges',
    VIEW_RECEIPT: 'View Receipt',
    LOGIN_TO_AFFILIATE_DASHBOARD: 'Log in to affiliate dashboard',
    APPLY_TO_BECOME_AN_AFFILIATE: 'Apply to become an affiliate',
    AFFILIATE_PROGRAM_LEARN_MORE: 'Affiliate program Learn more',
    ADD_NEW_NOTARY_PROFILE: 'Add new notary profile',
    NEW_ENTRY_CLICK_TO_PROCEED: 'Click to proceed',
    GENERATE_SIGNING_REPORT: 'Generate signing report',
    AVAILABLE_ON_APP_STORE: 'Available on App Store',
    GET_IT_ON_GOOGLE_PLAY: 'Get it on Google play',

    EXPORT_REPORT_SAVED_ON_SERVER: 'Exported Journal report saved on server'
  }
};

export default segmentHelper;
