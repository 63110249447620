import FalconCardHeader from 'components/common/FalconCardHeader';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import api from 'util/api';
import c from 'util/const';
import logger from 'util/logger';
import DatePicker from 'react-datepicker';
import CustomDateInput from 'components/common/CustomDateInput';
import moment from 'moment';
import { refreshUserProfile } from 'stores/app';
import { showAlert } from 'stores/modal';
import Flex from 'components/common/Flex';
import segmentHelper from 'helpers/segment-helper';
import IconAlert from 'components/common/IconAlert';
import ValidationErrors from 'components/utilities/ValidationErrors';

const NotaryProfileForm = ({ profile, setCollapsed, formRef }) => {
  const { userMaster } = useSelector(state => state.app);
  const timezones = c.timezones;
  const resetData = {
    isEditing: false,
    editProfileGUID: profile?.GUID,
    CommissionedName: profile?.CommissionedName,
    CommissionNumber: profile?.CommissionNumber,
    ExpirationDate: profile?.ExpirationDate,
    CommissionAddress1: profile?.CommissionAddress1,
    CommissionAddress2: profile?.CommissionAddress2,
    CommissionAddress3: profile?.CommissionAddress3,
    CommissionCity: profile?.CommissionCity,
    CommissionState: profile?.CommissionState,
    CommissionProvince: profile?.CommissionProvince,
    CommissionCountry: profile?.CommissionCountry,
    CommissionPostalCode: profile?.CommissionPostalCode,
    Telephone: profile?.Telephone,
    LocalTimezone: profile?.LocalTimezone || userMaster?.LocalTimezone,
    validationErr: [],
    isNew: !profile,
    IsDefault: profile?.IsDefault
  };
  const [formData, setFormData] = useState(resetData);

  const dispatch = useDispatch();
  const states = c.states;
  const country = c.country;
  const handleChange = e => {
    if(e?.target?.name == "IsDefault"){
      setFormData({
        ...formData,
        [e.target.name]: e.target.checked
      });
    }else{
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }
  };

  const addProfileToEdit = profile => {
    if (profile) {
      setFormData(formData => ({
        isEditing: false,
        editProfileGUID: profile?.GUID,
        CommissionedName: profile?.CommissionedName,
        CommissionNumber: profile?.CommissionNumber,
        ExpirationDate:
          profile && profile.ExpirationDate
            ? new Date(moment.utc(profile.ExpirationDate).local().format('L'))
            : '',
        CommissionAddress1: profile?.CommissionAddress1,
        CommissionAddress2: profile?.CommissionAddress2,
        CommissionAddress3: profile?.CommissionAddress3,
        CommissionCity: profile?.CommissionCity,
        CommissionState: profile?.CommissionState,
        CommissionProvince: profile?.CommissionProvince,
        CommissionCountry: profile?.CommissionCountry || 'USA',
        CommissionPostalCode: profile?.CommissionPostalCode,
        Telephone: profile?.Telephone,
        LocalTimezone: profile?.LocalTimezone || userMaster?.LocalTimezone,
        validationErr: [],
        isNew: false,
        IsDefault: profile.IsDefault
      }));
    }
  };

  const resetProfileForm = () => {
    setFormData(formData => ({
      isEditing: false,
      editProfileGUID: null,
      CommissionedName: '',
      CommissionNumber: '',
      ExpirationDate: '',
      CommissionAddress1: '',
      CommissionAddress2: '',
      CommissionAddress3: '',
      CommissionCity: '',
      CommissionState: '',
      CommissionProvince: '',
      CommissionCountry: 'USA',
      CommissionPostalCode: '',
      Telephone: '',
      LocalTimezone: userMaster?.LocalTimezone,
      validationErr: [],
      isNew: true,
      IsDefault: false
    }));
    setCollapsed(false);
  };

  useEffect(() => {
    logger.log('Profile form changed', profile);
    addProfileToEdit(profile);
  }, [profile]);

  const handleSubmit = e => {
    e.preventDefault();
    setFormData({
      ...formData,
      validationErr: []
    });
    if (formData.CommissionedName.length === 0) {
      setFormData({
        ...formData,
        validationErr: ['Please enter commission name']
      });
      return false;
    }
    setFormData({
      ...formData,
      isEditing: true
    });
    let expDate = formData.ExpirationDate
      ? moment(formData.ExpirationDate)
      : null;
    let promise = null;
    let reqFieldsData = _.pickBy(formData, function (v, k) {
      return [
        'CommissionedName',
        'CommissionNumber',
        'ExpirationDate',
        'CommissionAddress1',
        'CommissionAddress2',
        'CommissionAddress3',
        'CommissionCity',
        'CommissionState',
        'CommissionProvince',
        'CommissionCountry',
        'CommissionPostalCode',
        'Telephone',
        'LocalTimezone',
        'IsDefault'
      ].includes(k);
    });
    //reqFieldsData.CommissionProvince = reqFieldsData.CommissionState;

    if (formData.editProfileGUID) {
      promise = api.Profile.update(formData.editProfileGUID, reqFieldsData);
    } else {
      promise = api.Profile.create(reqFieldsData);
    }

    promise
      .then(res => {
        if (!formData.editProfileGUID) {
          segmentHelper.track(segmentHelper.events.ADD_NEW_NOTARY_PROFILE);
        }
        logger.log('update profile success', res);
        return dispatch(refreshUserProfile());
      })
      .then(res => {
        dispatch(
          showAlert('Success', 'Commission profile saved successfully.')
        );
        setFormData({
          ...formData,
          isEditing: false
        });
      })
      .catch(err => {
        console.warn('error saving profile', err);
        setFormData({
          ...formData,
          isEditing: false
        });
        dispatch(
          showAlert(
            'Unable to save profile',
            'There was a problem saving your profile.  Please try again.'
          )
        );
      });
  };

  return (
    <Card>
      <FalconCardHeader
        title={
          formData.isNew
            ? 'Add New Notary Commission'
            : 'Update Notary Commission'
        }
      />
      <Card.Body className="bg-light">
        {/* <IconAlert variant={'info'}>
          <p className="mb-0">Use this section to explain any uncommon abbreviations and symbols used in this journal, or add any general notes that may help you in your notarial business</p>
        </IconAlert> */}
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} sm={12} md={4} controlId="CommissionedName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Commission name"
                value={formData.CommissionedName}
                name="CommissionedName"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} sm={12} md={4} controlId="CommissionNumber">
              <Form.Label>Commission number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Commission number"
                value={formData.CommissionNumber}
                name="CommissionNumber"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} sm={12} md={4} controlId="CommissionState">
              <Form.Label>Expiration date</Form.Label>

              <DatePicker
                dateFormat="MM/dd/yyyy"
                selected={formData.ExpirationDate}
                onChange={date => {
                  setFormData({
                    ...formData,
                    ['ExpirationDate']: date
                  });
                }}
                customInput={<CustomDateInput />}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                yearDropdownItemNumber={40}
              />
            </Form.Group>

            <Form.Group as={Col} sm={12} md={4} controlId="CommissionAddress1">
              <Form.Label>Address line 1</Form.Label>
              <Form.Control
                type="text"
                placeholder="Address line 1"
                value={formData.CommissionAddress1}
                name="CommissionAddress1"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} sm={12} md={4} controlId="CommissionAddress2">
              <Form.Label>Address line 2</Form.Label>
              <Form.Control
                type="text"
                placeholder="Address line 2"
                value={formData.CommissionAddress2}
                name="CommissionAddress2"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} sm={12} md={4} controlId="CommissionAddress3">
              <Form.Label>Address line 3</Form.Label>
              <Form.Control
                type="text"
                placeholder="Address line 3"
                value={formData.CommissionAddress3}
                name="CommissionAddress3"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} sm={12} md={4} controlId="CommissionCity">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                placeholder="City name"
                value={formData.CommissionCity}
                name="CommissionCity"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} sm={12} md={4} controlId="CommissionState">
              <Form.Label>State</Form.Label>
              <Form.Select
                type="text"
                name="CommissionState"
                onChange={handleChange}
              >
                <option value="">Select state</option>
                {states.map(option => (
                  <option
                    selected={
                      formData.CommissionState == option.text ||
                      formData.CommissionState == option.name
                    }
                    value={option.text}
                    key={option.text}
                  >
                    {option.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} sm={12} md={4} controlId="CommissionProvince">
              <Form.Label>County</Form.Label>
              <Form.Control
                type="text"
                placeholder="County name"
                value={formData.CommissionProvince}
                name="CommissionProvince"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} sm={12} md={4} controlId="CommissionCountry">
              <Form.Label>Country</Form.Label>
              <Form.Select
                type="text"
                name="CommissionCountry"
                onChange={handleChange}
              >
                <option value="">Select country</option>
                {country.map(option => (
                  <option
                    selected={
                      formData.CommissionCountry == option.text ||
                      formData.CommissionCountry == option.name
                    }
                    value={option.text}
                    key={option.text}
                  >
                    {option.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group
              as={Col}
              sm={12}
              md={4}
              controlId="CommissionPostalCode"
            >
              <Form.Label>Postal Code</Form.Label>
              <Form.Control
                type="text"
                maxLength={6}
                placeholder="Postal name"
                value={formData.CommissionPostalCode}
                name="CommissionPostalCode"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} sm={12} md={4} controlId="Telephone">
              <Form.Label>Telephone</Form.Label>
              <Form.Control
                type="text"
                placeholder="Postal name"
                maxLength={13}
                value={formData.Telephone}
                name="Telephone"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} sm={12} md={4} controlId="LocalTimezone">
              <Form.Label>Timezone</Form.Label>
              <Form.Select
                type="text"
                name="LocalTimezone"
                onChange={handleChange}
                defaultValue={formData.LocalTimezone}
                value={formData.LocalTimezone}
              >
                <option value="">Select timezone</option>
                {timezones.map(option => (
                  <option value={option.offset} key={option.abbreviation}>
                    {option.name} ({option.offset})
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} sm={12} md={4} controlId="LocalTimezone">
              <Form.Label>Set as default notary commission profile</Form.Label>
              <Form.Check
                type="switch"
                id="IsDefault"
                name="IsDefault"
                className="pull-right"
                label=""
                size={'md'}
                checked={formData.IsDefault}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
          {formData.validationErr.length > 0 && (
            <Row className="mb-3 g-3">
              <ValidationErrors errors={formData.validationErr} />
            </Row>
          )}
          <div className="text-end">
            <Button
              variant="danger"
              className="mx-1"
              onClick={() => resetProfileForm()}
            >
              Close
            </Button>
            {formData.isEditing && (
              <Button variant="outline-default">
                <Spinner size="sm" /> Saving...
              </Button>
            )}
            <Button
              variant="primary"
              type="submit"
              className="mx-1"
              disabled={formData.isEditing}
            >
              {formData.isNew ? 'Add' : 'Update'}
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default NotaryProfileForm;
