import {
  faCalendarAlt,
  faExclamationCircle,
  faExclamationTriangle,
  faFileAlt,
  faFolderOpen,
  faPlus,
  faPlusCircle,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import QuantityController from 'components/utilities/QuantityController';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  InputGroup,
  ListGroup,
  Offcanvas,
  Row,
  Spinner
} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { refreshDocumentList } from 'stores/docs';
import c from 'util/const';
import idGenerator from 'util/idGenerator';
import logger from 'util/logger';
import utils from 'util/utils';
import DocumentTypesForm from '../document-types/DocumentTypesForm';

const SigningPackagesForm = ({
  preSigningPackage,
  saveSigningPackage,
  setShowSigningPackagesForm,
  savingSigningPackage
}) => {
  const initsigningPackage = {
    editingPackage: preSigningPackage || null,
    package_name: '',
    notary_fee: '',
    notes: '',
    package_documents: [],
    Signing_Fees: []
  };
  const [localSigningPackage, setLocalSigningPackage] =
    useState(initsigningPackage);
  const [totalNotaryFee, setTotalNotaryFee] = useState(0);
  const [showDocsCanvas, setShowDocsCanvas] = useState(false);
  const [documentListFiltered, setDocumentListFiltered] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [openingDocs, setOpeningDocs] = useState(false);
  const [showDocumentTypeForm, setShowDocumentTypesForm] = useState(false);
  const [docSearchKeyword, setDocSearchKeyword] = useState('');
  const hideDocCanvas = () => setShowDocsCanvas(false);

  const dispatch = useDispatch();
  const handleQuantityChange = e => {
    //logger.log(e);
    //setProductCount(parseInt(e.target.value < 0 ? 0 : e.target.value));
  };

  const openDocCanvasForm = () => {
    setOpeningDocs(true);
    new Promise((resolve, reject) => {
      dispatch(refreshDocumentList()).then(res => {
        resolve(res);
      });
    }).then(res => {
      //logger.log('openDocCanvasForm 2', res[0]);
      setDocumentList(res[0]);
      setDocumentListFiltered(res[0]);
      setOpeningDocs(false);
      setShowDocsCanvas(true);
    });
  };
  const handleQuantityIncrease = item => {
    appendDocuments(true, item);
  };

  const handleQuantityDecrease = item => {
    let newItem = _.find(localSigningPackage.package_documents, d => {
      return d.GUID == item.GUID;
    });
    appendDocuments(false, newItem);
  };

  const addNewDocumentType = documentType => {
    setShowDocumentTypesForm(true);
  };

  const onDocumentTypeAddedSuccess = () => {
    new Promise((resolve, reject) => {
      dispatch(refreshDocumentList()).then(res => {
        resolve(res);
      });
    }).then(res => {
      setDocumentList(res[0]);
      setDocumentListFiltered(res[0]);
    });
    setShowDocumentTypesForm(false);
  };

  const onDocumentTypeAddedFailure = () => {
    //loadDocumentTypes();
  };

  function getNewDocFromRaw(item) {
    ////logger.log("getNewDocFromRaw", item);
    let newItem = {};
    newItem.document_id = idGenerator.getDocumentId();
    newItem.GUID = item.GUID;
    newItem.UserMasterGUID = item.UserMasterGUID;
    newItem.Value = item.Value;
    newItem.NotarizationType = item.NotarizationType;
    newItem.Created = item.Created;
    return newItem;
  }

  const appendDocuments = (e, item) => {
    if (e) {
      let parsedItems = _.concat(
        localSigningPackage.package_documents,
        getNewDocFromRaw(item)
      );
      setLocalSigningPackage(localSigningPackage => ({
        ...localSigningPackage,
        package_documents: parsedItems
      }));
    } else {
      let selectedDocumentListUpdated = _.filter(
        localSigningPackage.package_documents,
        d => {
          return d.document_id
            ? d.document_id !== item.document_id
            : d.GUID !== item.GUID;
        }
      );
      setLocalSigningPackage(localSigningPackage => ({
        ...localSigningPackage,
        package_documents: selectedDocumentListUpdated
      }));
    }
  };

  useEffect(() => {
    if (preSigningPackage) {
      logger.log(preSigningPackage);
      let updatePackageDocuments = [] 
      preSigningPackage?.DocumentTypeGUIDs?.map((docID,index)=>{
        let document = preSigningPackage?.DocumentTypes?.find(item => {
          if (item.GUID === docID) {
            return item;
          }
        });
        if (document) {
          updatePackageDocuments.push({...document, document_id:idGenerator.getDocumentId()});
        }
      })
      setLocalSigningPackage({
        editingPackage: preSigningPackage || null,
        package_name: preSigningPackage.Name || '',
        notary_fee: preSigningPackage.NotaryFee || '',
        notes: preSigningPackage.AdditionalData || '',
        package_documents: updatePackageDocuments || [],
        Signing_Fees:
          utils.getSigningFeeObject(preSigningPackage?.SigningFees) || [],
        isDuplicate: !!preSigningPackage?.isDuplicate
      });
    } else {
      setLocalSigningPackage(initsigningPackage);
    }

    setDocumentListFiltered(documentList);
  }, []);

  useEffect(() => {
    if (documentList.length) {
      setDocumentListFiltered(
        _.filter(documentList, d => {
          return (
            docSearchKeyword == null ||
            d.Value.toString()
              .toLowerCase()
              .indexOf(docSearchKeyword.toLowerCase()) !== -1 ||
            d.NotarizationType.toString()
              .toLowerCase()
              .indexOf(docSearchKeyword.toLowerCase()) !== -1
          );
        })
      );
    }
  }, [docSearchKeyword]);

  const setSigningPackageData = (field, value) => {
    setLocalSigningPackage(localSigningPackage => ({
      ...localSigningPackage,
      [field]: value
    }));
  };

  const doValidation = () => {
    //logger.log('localSigningPackage', localSigningPackage);
    let err = [];
    if (_.trim(localSigningPackage.package_name).length === 0) {
      err.push('Please enter signing package name');
    } else if (localSigningPackage.notary_fee.length === 0) {
      err.push('Please enter notary fee');
    } else if (localSigningPackage.package_documents.length === 0) {
      err.push('Please select minimum one document');
    }
    let SigningFees = localSigningPackage?.Signing_Fees;
    _.map(SigningFees, s => {
      if (!s.Name || !s.Amount) {
        err.push('Addional Fee must have Fee Type and Amount');
      }
    });
    if (err.length) {
      toast.info(err[0], {
        theme: 'colored',
        position: 'bottom-center',
        icon: faExclamationTriangle,
        toastId: 'signing-package'
      });
    }
    return err.length === 0;
  };

  const addMoreExtraFee = () => {
    let updatedExtraFee = localSigningPackage.Signing_Fees || [];
    //logger.log('Signing_Fees', updatedExtraFee);
    updatedExtraFee.push({
      id: Date.now(),
      Name: null,
      Amount: null,
      FeeCurrency: 'USD'
    });
    setLocalSigningPackage(localSigningPackage => ({
      ...localSigningPackage,
      Signing_Fees: updatedExtraFee
    }));
    //logger.log('Signing_Fees', localSigningPackage.Signing_Fees);
  };

  const saveExtraFeeValue = (id, field, value) => {
    let isFeeExist = _.find(
      localSigningPackage.Signing_Fees,
      f => f.Name === value
    );
    let updatedExtraFee = [];
    _.map(localSigningPackage.Signing_Fees, f => {
      if (f.id === id) {
        let eFee = Object.assign({}, f);
        eFee[field] = isFeeExist ? '' : value;
        if (!isFeeExist) updatedExtraFee.push(eFee);
      } else {
        updatedExtraFee.push(f);
      }
    });
    setLocalSigningPackage(localSigningPackage => ({
      ...localSigningPackage,
      Signing_Fees: updatedExtraFee
    }));

    if (isFeeExist) {
      toast.warning('Fee Type "' + value + '" already exist', {
        theme: 'colored',
        position: 'bottom-center',
        icon: faExclamationCircle
      });
    }
  };

  const removeExtraFeeItem = id => {
    let updatedExtraFee = _.filter(localSigningPackage.Signing_Fees, f => {
      return f.id !== id;
    });
    setLocalSigningPackage(localSigningPackage => ({
      ...localSigningPackage,
      Signing_Fees: updatedExtraFee
    }));
  };

  const validateAndSetSigningPackageItem = () => {
    if (doValidation()) {
      //logger.log('Sending Signing Package to prop', localSigningPackage);
      saveSigningPackage(localSigningPackage);
    } else {
      //logger.log('Not an Signing Package');
    }
  };

  const removeSelectedDoc = d => {
    appendDocuments(false, d);
  };

  useEffect(() => {
    logger.log('localSigningPackage', localSigningPackage);
    let total = 0;
    total += parseFloat(localSigningPackage.notary_fee || 0);
    _.map(localSigningPackage.Signing_Fees, e => {
      total += parseFloat(e.Amount || 0);
    });
    setTotalNotaryFee(total.toFixed(2));
  }, [localSigningPackage]);
  const displayDocumentList = ()=>{
    return _.map(localSigningPackage.package_documents, d => {
      //logger.log('Selected doc item', d);
      return (
        <ListGroup.Item>
          <Flex justifyContent="between" alignItems="center">
            <div className="fs--1">
              <p className="mb-0">{d.Value}</p>
              <p className="text-primary mb-0">
                <FontAwesomeIcon icon={faFolderOpen} />{' '}
                {d.NotarizationType}
              </p>
            </div>
            <SoftBadge pill bg="danger" className="me-1">
              <FontAwesomeIcon
                icon={faTrash}
                onClick={() => removeSelectedDoc(d)}
              />
            </SoftBadge>
          </Flex>
        </ListGroup.Item>
      );
    })
}
  return (
    <>
      <Row className="g-2 mb-3">
        <Col sm={12}>
          <Form.Group className="mb-2" controlId="package_name">
            <Form.Label>Package name*</Form.Label>
            <Form.Control
              placeholder="Enter package name"
              value={localSigningPackage.package_name}
              name="package_name"
              onChange={e => {
                setSigningPackageData('package_name', e.target.value);
              }}
              type="text"
            />
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group className="mb-2" controlId="notary_fee">
            <Form.Label>Notary fee*</Form.Label>
            <Form.Control
              placeholder="Enter notary fee"
              value={localSigningPackage.notary_fee || ''}
              name="notary_fee"
              onChange={e => {
                setSigningPackageData('notary_fee', e.target.value);
              }}
              type="number"
              min={0}
              step={0.01}
            />
          </Form.Group>
        </Col>
        <Col sm={6} className="d-flex justify-content-end">
          <Form.Group className="mb-2" controlId="notary_fee">
            <Form.Label className="w-100 text-end">
              {' '}
              Total fee{' '}
              <SoftBadge>
                {_.isNaN(totalNotaryFee)
                  ? 0
                  : parseFloat(totalNotaryFee || 0).toString()}
              </SoftBadge>
            </Form.Label>
            <Flex alignContent="end" wrap="wrap" className="mb-2">
              <Button
                variant="outline-primary"
                onClick={() => addMoreExtraFee()}
              >
                <FontAwesomeIcon icon={faPlus} /> Add more
              </Button>
            </Flex>
          </Form.Group>
        </Col>

        <Col sm={12}>
          {_.map(localSigningPackage.Signing_Fees, ef => {
            return (
              <Row key={ef.id}>
                <Col sm={6}>
                  <Form.Select
                    type="text"
                    value={ef.Name || null}
                    onChange={e =>
                      saveExtraFeeValue(ef.id, 'Name', e.target.value)
                    }
                  >
                    <option value="">Fee type</option>
                    {c.additionFeeTypes.map(feeType => (
                      <option value={feeType} key={feeType}>
                        {feeType}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col sm={4}>
                  <Form.Group className="mb-2" controlId="Amount">
                    <Form.Control
                      placeholder="Amount"
                      value={ef.Amount}
                      name="Amount"
                      onChange={e => {
                        saveExtraFeeValue(ef.id, 'Amount', e.target.value);
                      }}
                      type="number"
                      step={0.01}
                      min={0}
                    />
                  </Form.Group>
                </Col>
                <Col sm={2}>
                  <Button
                    className="py-1 mt-1"
                    size="sm"
                    variant="outline-danger"
                    onClick={() => removeExtraFeeItem(ef.id)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </Col>
              </Row>
            );
          })}
        </Col>

        <Col sm={12}>
          <Form.Group className="mb-2" controlId="notes">
            <Form.Label>Additional notes</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Enter additional notes - (Optional)"
              value={localSigningPackage.notes}
              name="notes"
              onChange={e => {
                setSigningPackageData('notes', e.target.value);
              }}
              rows={3}
              type="textarea"
            />
          </Form.Group>
        </Col>

        <Col sm={12}>
          <hr />
          <Flex justifyContent="between">
            <h5>Documents</h5>
            {openingDocs ? (
              <Spinner size="sm" />
            ) : (
              <Button
                variant="outline-primary"
                size="sm"
                onClick={() => openDocCanvasForm()}
              >
                <FontAwesomeIcon icon={faPlus} /> Add
              </Button>
            )}
          </Flex>
          <hr />
          <ListGroup>
          {displayDocumentList()}
          </ListGroup>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <hr />
          <Flex justifyContent="center">
            <Button
              disabled={savingSigningPackage}
              className="mx-1"
              variant="secondary"
              onClick={() => setShowSigningPackagesForm(false)}
            >
              Close
            </Button>
            {savingSigningPackage ? (
              <Spinner variant="primary" size="sm" />
            ) : (
              <Button
                variant="primary"
                onClick={() => validateAndSetSigningPackageItem()}
              >
                Save
              </Button>
            )}
          </Flex>
        </Col>
      </Row>
      <Offcanvas
        id="library-document-list"
        keyboard={false}
        show={showDocsCanvas}
        backdrop="static"
        placement="end"
        onHide={hideDocCanvas}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Saved Documents</Offcanvas.Title>
          {localSigningPackage.package_documents.length ? (
            <span className="text-primary fw-bold">
              {localSigningPackage.package_documents.length} Selected
            </span>
          ) : (
            <></>
          )}
        </Offcanvas.Header>
        <hr className="my-0" />
        <Offcanvas.Body>
          <Flex justifyContent="center">
            <InputGroup className="mb-3">
              <Form.Control
                size="md"
                className="w-50"
                type="text"
                placeholder="Search by name and type"
                value={docSearchKeyword}
                name="search"
                onChange={e => setDocSearchKeyword(e.target.value)}
              />
              <Button variant="primary" onClick={() => addNewDocumentType()}>
                <FontAwesomeIcon icon={faPlusCircle} /> New Type
              </Button>
            </InputGroup>
          </Flex>
          {documentListFiltered ? (
            <>
              <ListGroup>
                {documentListFiltered.map((item, index) => (
                  <ListGroup.Item key={item.GUID}>
                    <Flex justifyContent="between" alignItems="center">
                      <div className="flex-fill w-50">
                        <QuantityController
                          quantity={_.sumBy(
                            localSigningPackage.package_documents,
                            function (o) {
                              return o.GUID == item.GUID ? 1 : 0;
                            }
                          )}
                          handleChange={handleQuantityChange}
                          handleIncrease={() => handleQuantityIncrease(item)}
                          handleDecrease={() => handleQuantityDecrease(item)}
                        />
                      </div>
                      <Flex
                        className="flex-fill w-50"
                        justifyContent="end"
                      ></Flex>
                    </Flex>
                    <p className="my-1">{item.Value}</p>
                    <Flex justifyContent="between" alignItems="center">
                      <span className="mb-1 fs--1">
                        <FontAwesomeIcon icon={faFolderOpen} size="sm" />{' '}
                        {item.NotarizationType}
                      </span>
                      <span className="my-0 fs--2 text-muted">
                        <FontAwesomeIcon icon={faCalendarAlt} />{' '}
                        {moment(item.Created)
                          .utc()
                          .format('MM/DD/YYYY HH:mm A')}
                      </span>
                    </Flex>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </>
          ) : (
            <div className="text-center">
              <FontAwesomeIcon icon={faFileAlt} size="3x" />
              <br />
              <span>No Package found</span>
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
      <Offcanvas
        id="offcanvas-address-form"
        show={showDocumentTypeForm}
        backdrop="static"
        onHide={() => setShowDocumentTypesForm(false)}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Document Type</Offcanvas.Title>
        </Offcanvas.Header>
        <hr className="my-0" />
        <Offcanvas.Body>
          <DocumentTypesForm
            setShowDocumentTypesForm={setShowDocumentTypesForm}
            onSuccess={onDocumentTypeAddedSuccess}
            onFailure={onDocumentTypeAddedFailure}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
SigningPackagesForm.propTypes = {
  preSigningPackage: PropTypes.object,
  saveSigningPackage: PropTypes.func.isRequired,
  setShowSigningPackagesForm: PropTypes.func,
  savingSigningPackage: PropTypes.bool
};
export default SigningPackagesForm;
