import {
  faCheckCircle,
  faExclamationCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { GaugeChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { currencyCodeToSign, getColor } from 'helpers/utils';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Colors from 'util/Colors';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  GaugeChart,
  CanvasRenderer,
  LegendComponent
]);

const SubscriptionDaysCountdown = ({ bodyClassName }) => {
  const { subscriptionStatus } = useSelector(state => state.subscription);
  //let activeSubscriptions = _.get(subscriptionStatus, 'Active', []);
  let currentSubscriptions = _.get(subscriptionStatus, 'Current', null);
  let activeSubscriptions = currentSubscriptions ? [currentSubscriptions] : [];
  const getOptions = subscription => {
    let totalValue = Math.round(subscription.DaysRemaining);
    let dataValue = Math.round(
      subscription.DaysRemaining - subscription.DaysSinceLastCharge
    );
    let valuePrecent = totalValue
      ? Math.round((dataValue * 100) / totalValue)
      : 0;
    return {
      series: [
        {
          type: 'gauge',
          startAngle: 90,
          endAngle: -270,
          radius: '90%',
          pointer: {
            show: false
          },
          progress: {
            show: true,
            overlap: false,
            roundCap: true,
            clip: false,
            itemStyle: {
              color: subscription.Active
                ? Colors.success.color
                : Colors.primary.color
            }
          },
          axisLine: {
            lineStyle: {
              width: 8,
              color: [[1, getColor('gray-200')]]
            }
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          },
          data: [
            {
              value: valuePrecent,
              detail: {
                offsetCenter: ['7%', '4%']
              }
            }
          ],
          detail: {
            width: 50,
            height: 14,
            fontSize: 26,
            fontWeight: 500,
            fontFamily: 'poppins',
            color: subscription.Active
              ? Colors.success.color
              : Colors.primary.color,
            formatter: dataValue + '\nDay' + (dataValue > 1 ? 's' : '')
          }
        }
      ]
    };
  };

  return (
    <Card className="h-100">
      <FalconCardHeader
        title="Subscription Health Status"
        titleTag="h6"
        className="py-2"
        light
        // endEl={<CardDropdown />}
      />
      <Card.Body
        className={classNames(bodyClassName, 'h-100 pb-0')}
        as={Flex}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        {_.map(activeSubscriptions, sub => {
          return (
            <div
              key={_.uniqueId('subscription-days-counter')}
              className={`w-100 text-${sub.Active ? 'primary' : 'secondary'}`}
            >
              <ReactEChartsCore
                echarts={echarts}
                option={getOptions(sub)}
                style={{ height: '9.3rem', widht: '5.3rem' }}
              />

              <div className="text-center mt-0">
                <p className="px-2 fs-1 fw-semi-bold mb-0">
                  <FontAwesomeIcon
                    icon={sub.Active ? faCheckCircle : faExclamationCircle}
                    transform="shrink-2"
                    className={`me-1 text-${
                      sub.Active ? 'success' : 'primary'
                    }`}
                  />
                  {sub.Type}
                </p>
                <p className="px-2 mb-0">
                  {currencyCodeToSign(sub.Currency)}
                  {sub.Amount} / {sub.Recur}
                </p>
              </div>
            </div>
          );
        })}

        {subscriptionStatus?.Effective === 'None' && (
          <div className="text-center">
            <p className="text-danger mb-1">
              You do not have an active subscription. You can fix this problem
              in the '
              <Link
                className="text-danger fw-bolder"
                to={'/billing/subscriptions'}
              >
                Subscriptions
              </Link>
              ' tab
            </p>
          </div>
        )}
      </Card.Body>
      <Card.Footer className="bg-light py-2">
        <Row className="flex-between-center">
          <Col xs="auto">
            {/* <Form.Select size="sm" className="me-2">
              <option>Last 6 Months</option>
              <option>Last Year</option>
              <option>Last 2 Year</option>
            </Form.Select> */}
            <Link
              className="fs--1 font-sans-serif"
              to="billing/payment-history"
            >
              Payment History
            </Link>
          </Col>
          <Col xs="auto">
            <Link className="fs--1 font-sans-serif" to="billing/subscriptions">
              Subscription
            </Link>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};

SubscriptionDaysCountdown.propTypes = {
  bodyClassName: PropTypes.string
};

export default SubscriptionDaysCountdown;
