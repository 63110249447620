import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import Main from './Main';
import 'helpers/initFA';
import { Provider } from 'react-redux';
import store from 'app/store';
import ModalRoot from 'components/modals/ModalRoot';

const container = document.getElementById('main');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Main>
        <ModalRoot />
        <App />
      </Main>
    </Provider>
  </React.StrictMode>
);
