import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authenticationReducer from '../stores/authentication';
import appReducer from '../stores/app';
import dashboardReducer from '../stores/dashboard';
import modalReducer from '../stores/modal';
import subscriptionReducer from '../stores/subscription';
import signingReducer from '../stores/signing';
import docsReducer from '../stores/docs';
import notificationReducer from '../stores/notification';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
const preloadedState = window.__PRELOADED_STATE__;

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['subscription', 'modal']
};

export default configureStore({
  reducer: persistReducer(
    rootPersistConfig,
    combineReducers({
      authentication: authenticationReducer,
      app: appReducer,
      dashboard: dashboardReducer,
      modal: modalReducer,
      subscription: subscriptionReducer,
      signing: signingReducer,
      docs: docsReducer,
      notification: notificationReducer,
    })
  ),
  preloadedState
});
