import IconAlert from 'components/common/IconAlert';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { usePlacesWidget } from 'react-google-autocomplete';
import _ from 'lodash';
import PropTypes from 'prop-types';
import WizardInput from '../WizardInput';
import c from 'util/const';
import logger from 'util/logger';
import { toast } from 'react-toastify';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Flex from 'components/common/Flex';
import { useEffect } from 'react';
import { Bounds } from 'leaflet';
import GooglePlaceSearch from './GoolePlaceSearch';

const GenericAddressForm = ({
  setAddressValue,
  setShowAddressForm,
  register,
  errors,
  col
}) => {
  const states = c.states;
  const initLocalAddress = {
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    zip: '',
    latitude: '',
    longitude: '',
    isDetectingLocation: false
  };
  const [localAddress, setLocalAddress] = useState(initLocalAddress);

  useEffect(() => {
    setLocalAddress(localAddress => ({
      ...localAddress,
      initLocalAddress
    }));
  }, []);

  const setLocationInfoData = (field, value) => {
    setLocalAddress(localAddress => ({
      ...localAddress,
      [field]: value
    }));
  };

  const doValidation = () => {
    //logger.log('localAddress', localAddress);
    let err = [];
    if (localAddress.address_1.length === 0) {
      err.push('Please enter your address');
    } else if (localAddress.city.length === 0) {
      err.push('Please enter your city');
    } else if (localAddress.state.length === 0) {
      err.push('Please enter your state');
    } else if (localAddress.zip.length === 0) {
      err.push('Please enter your zip code');
    }
    if (err.length) {
      toast.info(err[0], {
        theme: 'colored',
        position: 'bottom-center',
        icon: faExclamationTriangle,
        toastId: 'signing'
      });
    }
    return err.length === 0;
  };

  const validateAndSetAddressItem = () => {
    if (doValidation()) {
      //logger.log('Sending address to prop', localAddress);
      setAddressValue(localAddress);
    } else {
      //logger.log('Not an address');
    }
  };

  const setSearchedAddress = address => {
    logger.log('Updating serach address', address);
    //let updatedAddress = _.merge(localAddress, address);
    setLocalAddress(address);
  };

  useEffect(() => {
    logger.log('Effected search address', localAddress);
  }, [localAddress]);

  return (
    <>
      <Row className="g-2 mb-3">
        <GooglePlaceSearch col={12} setSearchedAddress={setSearchedAddress} />
      </Row>

      <Row className="g-2 mb-3">
        <Col sm={col}>
          <WizardInput
            label="Address line 1"
            name="address_1"
            errors={errors}
            formGroupProps={{ className: 'mb-3' }}
            placeholder="Address line 1 here"
            formControlProps={{
              ...register('address_1', {
                required: 'Enter address line 1',
                pattern: {
                  //value: /^[a-z\d\-_\s]+$/i,
                  message: 'Address line 1 required'
                }
              })
            }}
            value={localAddress.address_1}
            setValue={setLocationInfoData}
          />
        </Col>
        <Col sm={col}>
          <WizardInput
            label="Address line 2 (Optional)"
            name="address_2"
            errors={errors}
            formGroupProps={{ className: 'mb-3' }}
            placeholder="Address line 2 here"
            formControlProps={{
              ...register('address_2', {
                pattern: {
                  //value: /^[a-z\d\-_\s]+$/i,
                  message: 'Address line 2 required'
                }
              })
            }}
            value={localAddress.address_2}
            setValue={setLocationInfoData}
          />
        </Col>

        <Col sm={col}>
          <WizardInput
            label="City"
            name="city"
            errors={errors}
            formGroupProps={{ className: 'mb-3' }}
            placeholder="City name here"
            formControlProps={{
              ...register('city', {
                pattern: {
                  value: /^[a-z\d\-_\s]+$/i,
                  message: 'City name required'
                }
              })
            }}
            value={localAddress.city}
            setValue={setLocationInfoData}
          />
        </Col>

        <Col sm={col}>
          <Form.Group as={Col} lg={6} controlId="state" className="w-100">
            <Form.Label>State</Form.Label>
            <Form.Select
              type="text"
              name="state"
              value={localAddress.state}
              onChange={e => setLocationInfoData(e.target.name, e.target.value)}
            >
              <option value="">Select state</option>
              {states.map(option => (
                <option value={option.text} key={option.text}>
                  {option.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>

        <Col sm={col}>
          <WizardInput
            label="Postal code"
            name="zip"
            errors={errors}
            formGroupProps={{ className: 'mb-3' }}
            placeholder="Postal code here"
            formControlProps={{
              ...register('zip', {
                pattern: {
                  value: /^[A-Za-z0-9? ,_-]+$/i,
                  message: 'Postal code required'
                }
              })
            }}
            value={localAddress.zip}
            setValue={setLocationInfoData}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <hr />
          <Flex justifyContent="center">
            <Button
              className="mx-1"
              variant="secondary"
              onClick={() => setShowAddressForm(false)}
            >
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => validateAndSetAddressItem()}
            >
              Save
            </Button>
          </Flex>
        </Col>
      </Row>
    </>
  );
};
GenericAddressForm.propTypes = {
  setAddressValue: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  col: PropTypes.number.isRequired,
  errors: PropTypes.object
};
export default GenericAddressForm;
