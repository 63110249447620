import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import image from 'assets/img/icons/spot-illustrations/navbar-vertical.png';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { FaApple, FaGooglePlay } from 'react-icons/fa';
import c from 'util/const';
import Flex from 'components/common/Flex';
import segmentHelper from 'helpers/segment-helper';

const PurchaseCard = () => {
  const [show, setShow] = useState(true);
  const openAppDownload = (plateform) => {
    if (plateform === 'IOS') {
      window.open(c.mobileApp.store.ios);
      segmentHelper.track(segmentHelper.events.AVAILABLE_ON_APP_STORE)
    } else {
      window.open(c.mobileApp.store.android);
      segmentHelper.track(segmentHelper.events.GET_IT_ON_GOOGLE_PLAY)
    }
  }
  return (
    show && (
      <div className="settings my-3">
        <Card className="p-0 rounded-2 position-relative">
          {/* <div
            className="position-absolute"
            style={{ right: '3px', top: '3px' }}
          >
            <FalconCloseButton
              size="sm"
              noOutline
              onClick={() => setShow(false)}
            />
          </div> */}
          <Card.Body className="text-center">
            <div className="d-grid gap-2">
              <Flex direction="column" justifyContent='center' className='mt-3'>
                <div className="text-center mb-2">
                  <Button className='w-100 px-2' onClick={() => openAppDownload('IOS')}>
                    <Flex justifyContent='between' alignContent='center'>
                      <FaApple className="btn-icon-prepend" style={{ verticalAlign: 'baseline', fontSize: '40px' }} />
                      <div>
                        <span
                          className="d-inline-block text-left"> <small
                            className="font-weight-light d-block">Available on</small> App Store </span>
                      </div>
                    </Flex></Button>
                </div>

                <div className="text-center mb-2">
                  <Button className='w-100 px-2' onClick={() => openAppDownload('ANDROID')}>
                    <Flex justifyContent='between' alignContent='center'>
                      <FaGooglePlay className="btn-icon-prepend" style={{ verticalAlign: 'baseline', fontSize: '40px' }} />
                      <div><span
                        className="d-inline-block text-left"> <small
                          className="font-weight-light d-block">Get it on</small> Google Play </span>
                      </div>
                    </Flex></Button>
                </div>
              </Flex>
            </div>
          </Card.Body>
        </Card>
      </div>
    )
  );
};

export default PurchaseCard;
