import request from 'superagent';
import pkg from '../../package.json';
import apiHelper from './apiHelper';
import config from './config';
import c from './const';
import enums from './enums';
import filters from './filters';
import logger from './logger';
import utils from './utils';
import moment from 'moment';
import draftEntryHelper from 'helpers/draft-entry-helper';

const api = {};

api.endpoint = config.debug ? config.endpoint : config.prodEndpoint;
api.token = null;

api.hasAuthentication = () => {
  return !!api.token;
};

api.Subscription = {
  uri: '/v2.0/subscription',

  url(action) {
    return api.apiRoot(this.uri, action);
  },

  create(payment_card_guid, subscription_tier_guid, coupon_code) {
    let req = {
      PaymentCardGUID: payment_card_guid,
      SubscriptionTierGUID: subscription_tier_guid
    };

    if (coupon_code) {
      req.CouponCode = coupon_code;
    }

    return request
      .post(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send(req)
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },

  updatePaymentMethod(subscription_guid, payment_method_id) {
    let req = {
      StripePaymentMethodId: payment_method_id
    };

    return request
      .put(this.url(subscription_guid))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send(req)
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },

  list() {
    return request
      .get(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },

  status() {
    return request
      .get(this.url('status'))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },

  get(guid) {
    return request
      .get(this.url(guid))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },

  delete(guid, PaymentCardGUID, SubscriptionTierGUID) {
    return request
      .delete(this.url(guid))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send({
        PaymentCardGUID: PaymentCardGUID,
        SubscriptionTierGUID: SubscriptionTierGUID
      })
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },

  subscriptionPayments(guid) {
    return request
      .get(this.url(guid + '/payments'))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  }
};

api.Reporting = {
  uri: '/bugreport',

  url(action) {
    return api.apiRoot(this.uri, action);
  },

  reportBug(data) {
    return request
      .post(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send({ data })
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  }
};

api.Devices = {
  uri: '/device',

  url(action) {
    return api.apiRoot(this.uri, action);
  },

  addDevice(manufacturer, model, deviceId) {
    let req = {
      Manufacturer: manufacturer,
      Model: model,
      Identifier: deviceId,
      AppVersion: pkg.version
    };
    //logger.log('addDevice', req);
    return request
      .post(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send(req)
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  },

  block(guid) {
    return request
      .put(this.url(guid + '/block'))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },

  unblock(guid) {
    return request
      .put(this.url(guid + '/unblock'))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },

  list() {
    return request
      .get(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },

  delete(guid) {
    return request
      .delete(this.url(guid))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  }
};

api.Feedback = {
  uri: '/feedback',

  url(action) {
    return api.apiRoot(this.uri, action);
  },

  sendFeedback(data) {
    return request
      .post(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send({ data })
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  }
};

api.Profile = {
  uri: '/profile',

  url(action) {
    return api.apiRoot(this.uri, action);
  },

  delete(guid) {
    return request
      .delete(this.url(guid))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },

  get() {
    return request
      .get(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },

  update(guid, data) {
    data.ExpirationDate = data.ExpirationDate
      ? moment(data.ExpirationDate).format()
      : null;

    return request
      .put(this.url(guid))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send(data)
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },

  create(data) {
    data.ExpirationDate = data.ExpirationDate
      ? moment(data.ExpirationDate).format()
      : null;
    return request
      .post(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send(data)
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  }
};
api.User = {
  uri: '/v2.0/useraccount',

  url(action) {
    return api.apiRoot(this.uri, action);
  },
  delete(guid, params) {
    let qs = {};
    if (params) {
      qs.deletetype = params;
    }
    if (params) {
      return request
        .delete(this.url(guid))
        .query(qs)
        .timeout({ response: c.api.timeout_ms })
        .set(api.globalHeaders())
        .send({})
        .then(apiHelper.responseHdl)
        .catch(apiHelper.errorHdl);
    }
  }
};

api.UserMaster = {
  uri: '/usermaster',

  url(action) {
    return api.apiRoot(this.uri, action);
  },

  //Just to get full userInformation
  get(GUID) {
    return request
      .get(this.url(GUID))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  },

  //Just a more open version of the changeName and changePassword calls
  update(user_guid, params) {
    return request
      .put(this.url(user_guid))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send(params)
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },

  changeContactInformation(user_guid, contact_information) {
    return request
      .put(this.url(user_guid))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send({
        ContactInformation: contact_information
      })
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  },

  changeName(user_guid, first_name, last_name) {
    return request
      .put(this.url(user_guid))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send({
        FirstName: first_name,
        LastName: last_name
      })
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },

  changePassword(user_guid, email, cur_pwd, new_pwd) {
    return request
      .put(this.url(user_guid))
      .timeout({ response: c.api.timeout_ms })
      .set({
        [c.api.email]: email,
        [c.api.password]: cur_pwd
      })
      .send({
        Password: new_pwd
      })
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },
  updatePrivacyMode(user_guid, PrivacyEnabled) {
    return request
      .put(this.url(user_guid))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send({ PrivacyEnabled: PrivacyEnabled })
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },
  updatePersistIdNumbers(user_guid, PersistIdNumbers) {
    return request
      .put(this.url(user_guid))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send({ PersistIdNumbers: PersistIdNumbers })
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },
  getCannyToken() {
    return request
      .get(this.url('canny'))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  },
  uploadProficPic(user_guid, imageData) {
    return request
      .put(this.url(user_guid))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({
        Blob: {
          ContentType: 'application/octet-stream',
          Data: imageData
        }
      })
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  }
};

api.Token = {
  uri: '/token',

  url(action) {
    return api.apiRoot(this.uri, action);
  },

  login(email, password) {
    return request
      .get(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set({
        [c.api.email]: email,
        [c.api.password]: password
      })
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },

  test(token) {
    return request
      .get(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .set({
        [c.api.token]: token
      })
      .retry(c.api.retry_count)
      .then(apiHelper.responseHdl)
      .then(apiHelper.tokenCheck)
      .catch(apiHelper.errorHdl);
  },

  fetchUser() {
    logger.log('Fetch User');
    return request
      .get(this.url('detail'))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },
  detail(url) {
    return request
      .get(this.url(url))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  }
};

api.Reset = {
  uri: '/reset',

  url(action) {
    return api.apiRoot(this.uri, action);
  },

  reset(email) {
    return request
      .post(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send({
        Email: email
      })
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  }
};

api.Location = {
  uri: '/address',

  url(action) {
    return api.apiRoot(this.uri, action);
  },

  lookupAddress(address) {
    return request
      .get(this.url())
      .timeout({ response: c.api.timeout_ms })
      .query({ address })
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  },

  lookup(lat, lng) {
    return request
      .get(this.url())
      .timeout({ response: c.api.timeout_ms })
      .query({ latitude: lat, longitude: lng })
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  },

  list() {
    return request
      .get(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .then(apiHelper.apiNotaryEntryTranslationHelper)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  },

  get(guid) {
    return request
      .get(this.url(guid))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .then(apiHelper.apiNotaryEntryTranslationHelper)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  },

  add() {
    let req = {};

    return request
      .post(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send(req)
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  },

  update(guid, data) {
    let req = {};

    return request
      .put(this.url(guid))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send(req)
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  },

  remove(guid) {
    return request
      .delete(this.url(guid))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  }
};

api.SavedLocations = {
  uri: '/v2.0/location/favorite',

  url(action) {
    return api.apiRoot(this.uri, action);
  },

  list() {
    return request
      .get(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .then(apiHelper.apiNotaryEntryTranslationHelper)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  },

  get(guid) {
    return request
      .get(this.url(guid))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .then(apiHelper.apiNotaryEntryTranslationHelper)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  },

  add(displayName, address_1, address_2, city, state, postal) {
    let req = { DisplayName: displayName };

    if (address_1) {
      req.Address1 = address_1;
    }
    if (address_2) {
      req.Address2 = address_2;
    }
    if (city) {
      req.City = city;
    }
    if (state) {
      req.State = state;
    }
    if (postal) {
      req.PostalCode = postal;
    }

    return request
      .post(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send(req)
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  },

  update(
    guid,
    displayName,
    address_1,
    address_2,
    city,
    state,
    postal,
    favorite
  ) {
    let req = { DisplayName: displayName };

    if (address_1) {
      req.Address1 = address_1;
    }
    if (address_2) {
      req.Address2 = address_2;
    }
    if (city) {
      req.City = city;
    }
    if (state) {
      req.State = state;
    }
    if (postal) {
      req.PostalCode = postal;
    }
    if (favorite) {
      req.IsFavorite = !!favorite;
    }

    return request
      .put(this.url(guid))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send(req)
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  },

  remove(guid) {
    return request
      .delete(this.url(guid))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  }
};

api.Ping = {
  uri: '/loopback',

  url(action) {
    return api.apiRoot(this.uri, action);
  },

  ping() {
    return request
      .get(this.url())
      .timeout({ response: c.api.timeout_ms })
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl);
  }
};

api.Version = {
  uri: '/v2.0/appversion',

  url(action) {
    return api.apiRoot(this.uri, action);
  },

  get() {
    return request
      .get(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  }
};

api.Search = {
  uri: '/search',

  url(action) {
    return api.apiRoot(this.uri, action);
  },

  searchPaging(createdBefore, searchTerms) {
    return request
      .put(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({
        CreatedBefore: createdBefore,
        Terms: searchTerms
      })
      .then(apiHelper.responseHdl)
      .then(apiHelper.apiNotaryEntryTranslationHelper)
      .catch(apiHelper.errorHdl);
  }
};

api.DocumentCollection = {
  uri: '/v2.0/doccoll',

  url(action) {
    return api.apiRoot(this.uri, action);
  },

  add(name, description, fee, notes, doc_type_guids, Signing_Fees) {
    let req = {
      Name: name,
      Description: description,
      NotaryFee: fee,
      NotaryFeeCurrency: 'USD',
      AdditionalData: notes,
      DocumentTypeGUIDs: doc_type_guids,
      SigningFees: Signing_Fees
    };

    return request
      .post(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send(req)
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  },

  update(guid, name, description, fee, notes, doc_type_guids, Signing_Fees, SortOrder=null) {
    let req = {
      NotaryFeeCurrency: 'USD'
    };

    if (name) {
      req.Name = name;
    }

    if (description) {
      req.Description = description;
    }

    if (fee) {
      req.NotaryFee = fee;
    }

    if (notes) {
      req.AdditionalData = notes;
    }

    if (doc_type_guids) {
      req.DocumentTypeGUIDs = doc_type_guids;
    }

    if (Signing_Fees) {
      req.SigningFees = Signing_Fees;
    }
    if (SortOrder !== null) {
      req.SortOrder = SortOrder;
    }
    //logger.log('Updating Signing Package', req);
    return request
      .put(this.url(guid))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send(req)
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  },

  remove(guid) {
    return request
      .delete(this.url(guid))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  },

  list() {
    return request
      .get(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .then(apiHelper.apiNotaryEntryTranslationHelper)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  },

  get(guid) {
    return request
      .get(this.url(guid))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .then(apiHelper.apiNotaryEntryTranslationHelper)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  }
};

api.DocumentType = {
  uri: '/v2.0/doctype',

  url(action) {
    return api.apiRoot(this.uri, action);
  },

  add(doc_type, notarization_type, favorite) {
    let req = {
      NotarizationType: notarization_type,
      Value: doc_type,
      IsFavorite: favorite
    };

    return request
      .post(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send(req)
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  },

  update(guid, data) {
    return request
      .put(this.url(guid))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send(data)
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  },

  remove(guid) {
    return request
      .delete(this.url(guid))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  },

  search(terms_arr) {
    return request
      .put(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send({
        Terms: terms_arr
      })
      .then(apiHelper.responseHdl)
      .then(apiHelper.apiNotaryEntryTranslationHelper)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  },

  list() {
    return request
      .get(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .then(apiHelper.apiNotaryEntryTranslationHelper)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  },

  get(guid) {
    return request
      .get(this.url(guid))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .then(apiHelper.apiNotaryEntryTranslationHelper)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  }
};

api.Signing2 = {
  uri: '/v2.0/signing',

  url(action) {
    return api.apiRoot(this.uri, action);
  },

  get(guid, includeData) {
    let qs = {};
    if (includeData) {
      qs.inclsub = true;
      qs.incldata = true;
    }

    return request
      .get(this.url(guid))
      .query(qs)
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .then(apiHelper.apiNotaryEntryTranslationHelper)
      .catch(apiHelper.errorHdl);
  },

  fetchReportDateRange(createdAfter, createdBefore, maxresults, include_data) {
    let params = { maxresults };

    params.inclsub = include_data;
    params.incldata = include_data;

    if (createdAfter) {
      params.createdafter = createdAfter;
    }

    if (createdBefore) {
      params.createdbefore = createdBefore;
    }

    return request
      .get(this.url())
      .timeout({ response: c.api.reporting_timeout_ms })
      .query(params)
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .then(apiHelper.apiNotaryEntryTranslationHelper)
      .catch(apiHelper.errorHdl);
  },

  listPaging(maxresults, createdbefore, createdafter, include_data) {
    let params = { maxresults };

    params.inclsub = include_data;
    params.incldata = include_data;

    if (createdbefore) {
      params.createdbefore = createdbefore;
    }
    if (createdafter) {
      params.createdafter = createdafter;
    }
    logger.log('params', params);
    return request
      .get(this.url())
      .timeout({ response: c.api.timeout_ms })
      .query(params)
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .then(apiHelper.apiNotaryEntryTranslationHelper)
      .catch(apiHelper.errorHdl);
  },

  monthly() {
    return request
      .get(this.url('monthly'))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .then(apiHelper.apiNotaryEntryTranslationHelper)
      .catch(apiHelper.errorHdl);
  },

  create(
    requestGUID,
    userMasterGuid,
    deviceGUID,
    mStartDate,
    mCompletedDate,
    signers,
    locationInfo,
    notarizationDetails,
    notarizationDocuments,
    documents,
    fingerprints,
    identifications,
    pictures,
    signatures,
    witnesses,
    emailRecipients,
    auditLogArray,
    signingTimestamp,
    localTimezone,
    userProfileGUID
  ) {
    let req = draftEntryHelper.getSigningFromDraft(
      requestGUID,
      userMasterGuid,
      deviceGUID,
      mStartDate,
      mCompletedDate,
      signers,
      locationInfo,
      notarizationDetails,
      notarizationDocuments,
      documents,
      fingerprints,
      identifications,
      pictures,
      signatures,
      witnesses,
      emailRecipients,
      auditLogArray,
      signingTimestamp,
      localTimezone,
      userProfileGUID
    );
    logger.log('API: CREATE ENTRY', req);
    //return;
    return request
      .post(this.url())
      .timeout({ response: c.api.signing_upload_timeout_ms })
      .set(api.globalHeaders())
      .send(req)
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  },

  sendEmail(guid, email) {
    logger.log('email', email);
    return request
      .put(this.url(`${guid}/email`))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send({ Email: email })
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  }
};

api.Signing = {
  uri: '/signing',

  url(action) {
    return api.apiRoot(this.uri, action);
  },

  strike(guid) {
    return request
      .put(this.url(`${guid}/strike`))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },

  unstrike(guid) {
    return request
      .put(this.url(`${guid}/unstrike`))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },

  saveNotes(guid, notes) {
    return request
      .put(this.url(`${guid}/notes`))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send(notes)
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },

  list() {
    return request
      .get(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .then(apiHelper.apiNotaryEntryTranslationHelper)
      .catch(apiHelper.errorHdl);
  },

  get(guid, metaData) {
    return request
      .get(this.url(guid))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .then(apiHelper.apiNotaryEntryTranslationHelper)
      .catch(apiHelper.errorHdl);
  }
};

api.NotaryEntry = {
  uri: '/notaryentry',

  url(action) {
    return api.apiRoot(this.uri, action);
  },

  saveEntryNotes(guid, notes) {
    return request
      .put(this.url(`${guid}/notes`))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send(notes)
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },

  list() {
    return request
      .get(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },

  get(guid) {
    return request
      .get(this.url(guid))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  }
};

api.UserOath = {
  uri: '/useroath',

  url(action) {
    return api.apiRoot(this.uri, action);
  },

  get(guid) {
    return request
      .get(this.url(guid))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .then(apiHelper.apiNotaryEntryTranslationHelper)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  },
  getAll() {
    return request
      .get(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .then(apiHelper.apiNotaryEntryTranslationHelper)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  },
  save(Statement) {
    return request
      .post(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send({ Statement: Statement })
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },
  update(GUID, Statement) {
    return request
      .put(this.url(GUID))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send({ Statement: Statement })
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },
  remove(guid) {
    return request
      .delete(this.url(guid))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  }
};

api.Statement = {
  uri: '/statement',

  url(action) {
    return api.apiRoot(this.uri, action);
  },

  get(guid) {
    return request
      .get(this.url(guid))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .then(apiHelper.apiNotaryEntryTranslationHelper)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  },
  getAll() {
    return request
      .get(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .then(apiHelper.apiNotaryEntryTranslationHelper)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  },
  save(Statement) {
    return request
      .post(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send({ Statement: Statement })
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },
  remove(guid) {
    return request
      .delete(this.url(guid))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  }
};

api.UserSignature = {
  uri: '/usersignature',

  url(action) {
    return api.apiRoot(this.uri, action);
  },

  get(guid, includeData) {
    let qs = {};
    if (includeData) {
      qs.inclsub = true;
      qs.incldata = true;
    }
    return request
      .get(this.url(guid))
      .timeout({ response: c.api.timeout_ms })
      .query(qs)
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .then(apiHelper.apiNotaryEntryTranslationHelper)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  },
  getAll(includeData) {
    let qs = {};
    if (includeData) {
      qs.inclsub = true;
      qs.incldata = true;
    }
    return request
      .get(this.url())
      .timeout({ response: c.api.timeout_ms })
      .query(qs)
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .then(apiHelper.apiNotaryEntryTranslationHelper)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  },
  save(data) {
    return request
      .post(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send(data)
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },
  remove(guid) {
    return request
      .delete(this.url(guid))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdlNoConnectionHdl);
  }
};

//==================Api WebApp Specific

api.Reports = {
  uri: '/v2.0/report',

  url(action) {
    return api.apiRoot(this.uri, action);
  },

  signing(Start, End, Fidelity) {
    return request
      .put(this.url('signing'))
      .query({})
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({
        CreatedAfter: moment(Start).utc().format(),
        CreatedBefore: moment(End).utc().format(),
        Fidelity: Fidelity
      })
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  }
};

api.Stripe = {
  uri: '/stripe',

  url(action) {
    return api.apiRoot(this.uri, action);
  },

  publicKey() {
    return request
      .get(this.url('publickey'))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  }
};

api.Boarding = {
  uri: '/setup',

  url(action) {
    return api.apiRoot(this.uri, action);
  },

  setup(
    first_name,
    last_name,
    email,
    pwd,
    commissioned_name,
    commission_state,
    commission_number,
    commission_province,
    m_license_exp_date,
    leadSource,
    campaignId,
    telephone,
    SmsOptIn,
    localtimezone,
    IsDefault
  ) {
    let userMaster = {
      FirstName: first_name,
      LastName: last_name,
      Email: email,
      Password: pwd,
      Telephone: telephone,
      SmsOptIn: SmsOptIn ? moment().utc().format() : null,
      LocalTimezone: localtimezone,
      Profiles: [
        {
          CommissionedName: commissioned_name,
          CommissionState: commission_state,
          CommissionProvince: commission_province,
          CommissionNumber: commission_number,
          ExpirationDate: m_license_exp_date
            ? m_license_exp_date.format()
            : null,
          LocalTimezone: localtimezone,
          IsDefault: IsDefault
        }
      ]
    };

    if (leadSource) {
      userMaster.LeadSource = leadSource;
    }
    if (campaignId) {
      userMaster.CampaignId = campaignId;
    }

    return request
      .post(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send(userMaster)
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  }
};

api.PaymentHistory = {
  uri: '/v2.0/paymenttxn',

  url(action) {
    return api.apiRoot(this.uri, action);
  },

  list() {
    return request
      .get(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .then(apiHelper.apiNotaryEntryTranslationHelper)
      .catch(apiHelper.errorHdl);
  }
};

api.ValidateEmail = {
  uri: '/email/validate',

  url(action) {
    return api.apiRoot(this.uri, action);
  },

  validate(email) {
    return request
      .post(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send({ Email: email })
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  }
};

api.Verify = {
  uri: '/verify',

  url(action) {
    return api.apiRoot(this.uri, action);
  },

  startEmail() {
    return request
      .post(this.url('email/start'))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },

  startSMS() {
    return request
      .post(this.url('sms/start'))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },

  finishEmail(code) {
    return request
      .post(this.url('email/finish'))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send({
        Code: code
      })
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },

  finishSMS(code, SmsOptIn) {
    return request
      .post(this.url('sms/finish'))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send({
        Code: code,
        SmsOptIn: SmsOptIn
      })
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  }
};

api.SubscriptionTier = {
  uri: '/v2.0/subscriptiontier',

  url(action) {
    return api.apiRoot(this.uri, action);
  },

  testCoupon(couponCode) {
    return request
      .get(this.url())
      .query({ coupon: couponCode })
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },

  get() {
    return request
      .get(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  }
};

api.JournalExport = {
  uri: '/v2.0/journalexport',

  url(action) {
    return api.apiRoot(this.uri, action);
  },

  create(bodyData, Name, AdditionalData) {
    return request
      .post(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({ Name: Name, AdditionalData: AdditionalData, Body: bodyData })
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },

  get(guid, includeData) {
    let qs = {};
    if (includeData) {
      qs.includeData = true;
    }
    return request
      .get(this.url(guid))
      .query(qs)
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },

  getAll(includeData) {
    let qs = {};
    if (includeData) {
      qs.includeData = true;
    }
    return request
      .get(this.url())
      .query(qs)
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },
  delete(guid) {
    return request
      .delete(this.url(guid))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  }
};

api.PaymentCardV2 = {
  uri: '/v2.0/paymentcard',

  url(action) {
    return api.apiRoot(this.uri, action);
  },

  create(payment_method_id) {
    let req = {
      StripePaymentMethodId: payment_method_id
    };

    return request
      .post(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send(req)
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },
  markDefaultMethod(payment_method_id) {
    let req = {
      IsDefaultPaymentMethod: true
    };

    return request
      .put(this.url(payment_method_id))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send(req)
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },

  get(guid = null) {
    let req = {};

    return request
      .get(this.url(guid))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send(req)
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },

  remove(guid) {
    let req = {};
    return request
      .delete(this.url(guid))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send(req)
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  }
};

api.SubscriptionTier = {
  uri: '/v2.0/subscriptiontier',

  url(action) {
    return api.apiRoot(this.uri, action);
  },

  testCoupon(couponCode) {
    return request
      .get(this.url())
      .query({ coupon: couponCode })
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },

  get() {
    return request
      .get(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  }
};

api.SigningDraft = {
  uri: '/v2.0/signingdraft',

  url(action) {
    return api.apiRoot(this.uri, action);
  },

  get(guid) {
    return request
      .get(this.url(guid))
      .query({ coupon: couponCode })
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },

  list(incldata) {
    let qs = {};
    if (incldata) qs.incldata = true;
    return request
      .get(this.url())
      .timeout({ response: c.api.timeout_ms })
      .query(qs)
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .send({})
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },
  create(data) {
    let draftData = {
      AdditionalData: data.draft_id,
      Body: utils.encodeObjectForBlobData(data)
    };
    return request
      .post(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send(draftData)
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },
  update(guid, data) {
    return request
      .put(this.url(guid))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .send(data)
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },
  finalise(
    guid,
    requestGUID,
    userMasterGuid,
    deviceGUID,
    mStartDate,
    mCompletedDate,
    signers,
    locationInfo,
    notarizationDetails,
    notarizationDocuments,
    documents,
    fingerprints,
    identifications,
    pictures,
    signatures,
    witnesses,
    emailRecipients,
    auditLogArray,
    signingTimestamp,
    localTimezone,
    userProfileGUID
  ) {
    let qs = {};
    qs.finalize = '';
    qs.inclsub = '';
    qs.incldata = '';
    let signingData = utils.encodeObjectForBlobData(
      draftEntryHelper.getSigningFromDraft(
        requestGUID,
        userMasterGuid,
        deviceGUID,
        mStartDate,
        mCompletedDate,
        signers,
        locationInfo,
        notarizationDetails,
        notarizationDocuments,
        documents,
        fingerprints,
        identifications,
        pictures,
        signatures,
        witnesses,
        emailRecipients,
        auditLogArray,
        signingTimestamp,
        localTimezone,
        userProfileGUID
      )
    );
    let data = {
      AdditionalData: 'Finalize draft',
      Body: signingData
    };
    return request
      .put(this.url(guid))
      .timeout({ response: c.api.signing_upload_timeout_ms })
      .query(qs)
      .set(api.globalHeaders())
      .send(data)
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  },
  remove(guid) {
    return request
      .delete(this.url(guid))
      .timeout({ response: c.api.timeout_ms })
      .set(api.globalHeaders())
      .retry(c.api.retry_count)
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  }
};

api.setEndpoint = endpoint => {
  api.endpoint = endpoint;
};

api.apiRoot = (uri, action) => {
  return api.endpoint + uri + (action ? `/${action}` : '');
};

api.globalHeaders = () => {
  let headers = {};

  let token = api.token;
  if (token && token.length > 0) {
    headers[c.api.token] = token;
  }

  return headers;
};

api.setToken = token => {
  api.token = token;
};

api.getToken = () => {
  return api.token;
};

export default api;
