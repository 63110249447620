import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import WizardInput from './WizardInput';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const AccountForm = ({ register, errors, watch, setValue, stateData }) => {
  return (
    <>
      <h6 className="text-center">
        Thanks for your interest in the Notary eJournal! We're excited to have
        you on board. Please fill out the form below to create an account.
      </h6>
      <Row className="g-2 mb-3">
        <Col sm={6}>
          <WizardInput
            label="First Name"
            name="first_name"
            errors={errors}
            formGroupProps={{ className: 'mb-3' }}
            formControlProps={{
              ...register('first_name', {
                required: 'You must enter first name',
                pattern: {
                  value: /^[a-z\d\-_\s]+$/i,
                  message: 'First name must be valid'
                }
              })
            }}
            setValue={setValue}
          />
        </Col>
        <Col sm={6}>
          <WizardInput
            label="Last Name"
            name="last_name"
            errors={errors}
            formGroupProps={{ className: 'mb-3' }}
            formControlProps={{
              ...register('last_name', {
                required: 'You must enter last name',
                pattern: {
                  value: /^[a-z\d\-_\s]+$/i,
                  message: 'Last name must be valid'
                }
              })
            }}
            setValue={setValue}
          />
        </Col>
      </Row>

      <WizardInput
        type="email"
        errors={errors}
        label="Email*"
        name="email"
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('email', {
            required: 'Email field is required',
            pattern: {
              value:
                /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/i,
              message: 'Email must be valid'
            }
          })
        }}
        setValue={setValue}
      />

      <Row className="g-2 mb-3">
        <WizardInput
          type="password"
          errors={errors}
          label="Password*"
          name="pwd"
          formGroupProps={{ as: Col, sm: 12 }}
          formControlProps={{
            ...register('pwd', {
              required: 'You must specify a password',
              minLength: {
                value: 8,
                message: 'Password must have at least 8 characters'
              }
            })
          }}
          setValue={setValue}
        />

        {/*<WizardInput
          type="password"
          errors={errors}
          label="Confirm Password*"
          name="confirmPassword"
          formGroupProps={{ as: Col, sm: 6 }}
          formControlProps={{
            ...register('confirmPassword', {
              required: 'Confirm Password field is required',
              validate: value =>
                value === watch('password') || 'The password do not match'
            })
          }}
        />*/}
      </Row>

      <WizardInput
        type="checkbox"
        errors={errors}
        label={
          <span className="text-danger">
            I accept the{' '}
            <Link
              target="_blank"
              to="https://www.juratinc.com/terms-of-service"
            >
              {' '}
              terms
            </Link>{' '}
            and{' '}
            <Link target="_blank" to="https://www.juratinc.com/privacy-policy">
              {' '}
              privacy policy
            </Link>
          </span>
        }
        name="agreedToTerms"
        formControlProps={{
          ...register('agreedToTerms', {
            required:
              'You must agree to the terms and privacy policy to continue.'
          })
        }}
      />
    </>
  );
};

AccountForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  watch: PropTypes.func
};

export default AccountForm;
