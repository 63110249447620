import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { close } from 'stores/modal';
import c from 'util/const';
import logger from 'util/logger';
import AlertDialog from './AlertDialog';
import ConfirmDialog from './ConfirmDialog';
import ModalContainer from './ModalContainer';
import ViewEntryDialog from './ViewEntryDialog';

let MODAL_COMPONENTS = {};
MODAL_COMPONENTS[c.modal.alert] = AlertDialog;
MODAL_COMPONENTS[c.modal.confirm] = ConfirmDialog;
MODAL_COMPONENTS[c.modal.viewEntryDialog] = ViewEntryDialog;
class ModalRoot extends Component {
  Z_INDEX_START = 1056;
  Z_INDEX_START_FULL = 1050;

  constructor(props) {
    super(props);

    this.state = {
      containerRefs: []
    };
  }

  onContainerRef(id, ref) {
    let update = _.concat([], this.state.containerRefs);

    update.push({
      id,
      ref
    });

    this.setState({
      containerRefs: update
    });
  }

  handleCallback(id, res) {
    let { modals } = this.props;

    let found = _.find(modals, modal => {
      return modal.id === id;
    });

    let { callback } = found.modalProps;

    if (callback) {
      setTimeout(() => {
        callback(res);
      });
    }
  }

  setLargeSize(id, isLarge) {
    logger.log('setLargeSize not implemented');
    //this.props.updateSize(id, isLarge);
  }

  //I'm not sure this is as clear as it should be.
  //ModalContainer needs to know when things close so it can dismiss the active dialog
  //and close things properly with animation.  I pass this close method around to the dialogs to be called.
  //So ModalRoot handles the close, and passes it to the modal container
  //I'm hoping that when we support showing multiple modals at once it will make it easier to keep track of.
  doClose(id, res) {
    let update = _.concat([], this.state.containerRefs);

    let foundItem = _.remove(update, item => {
      return item.id === id;
    })[0];

    foundItem.ref.doClose(res);
    this.setState({ containerRefs: update });
  }

  render() {
    let { modals } = this.props;

    return _.map(modals, (modal, modalIndex) => {
      let { modalType, modalProps, modalSize, isLarge } = modal;
      let SpecificType = MODAL_COMPONENTS[modalType];

      // let modalCls = '';
      // if(SpecificType.MODAL_LARGE || isLarge){
      //   modalCls = 'modal-lg'
      // }
      // else if(SpecificType.MODAL_XL){
      //   modalCls = 'modal-xl'
      // }
      // else if(SpecificType.MODAL_SM){
      //   modalCls = 'modal-sm'
      // }

      let isModalFullscreen = false;
      if (modalType === c.modal.viewEntryDialog) {
        isModalFullscreen = true;
      }

      return (
        <Fragment key={modal.id}>
          <ModalContainer
            onRef={this.onContainerRef.bind(this, modal.id)}
            size={modalSize}
            callback={this.handleCallback.bind(this, modal.id)}
            zIndex={
              isModalFullscreen
                ? this.Z_INDEX_START_FULL + modalIndex + 2
                : this.Z_INDEX_START + modalIndex + 2
            }
            closeModal={this.props.close.bind(this, modal.id)}
            className={isModalFullscreen ? 'modal-fullscreen' : ''}
          >
            <SpecificType
              close={this.doClose.bind(this, modal.id)}
              setLargeSize={this.setLargeSize.bind(this, modal.id)}
              modalProps={modalProps}
              {...modalProps}
            />
          </ModalContainer>
          <div style={{ zIndex: this.Z_INDEX_START + modalIndex + 1 }} />
        </Fragment>
      );
    });
  }
}

const mapStateToProps = state => {
  return {
    modals: state.modal.modals
  };
};

const mapDispatchToProps = dispatch => {
  return {
    close: id => dispatch(close(id))
    // updateSize: (id, isLarge) => dispatch(modalActions.updateSize(id, isLarge))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalRoot);
