import {
  faCheckCircle,
  faCloud,
  faExclamationCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import corner5 from 'assets/img/illustrations/corner-4.png';
import Background from 'components/common/Background';
import segmentHelper from 'helpers/segment-helper';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { Button, Card, FormGroup, Tab, Tabs } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Input, Label } from 'reactstrap';
import { refreshAllAccountInfo } from 'stores/app';
import { deleteSubscribedPlan } from 'stores/subscription';
import enums from 'util/enums';

const CurrentSubscriptionView = ({ className }) => {
  const dispatch = useDispatch();
  const { subscriptionStatus } = useSelector(state => state.subscription);
  let currentSubscriptions = _.get(subscriptionStatus, 'Current', null);
  let activeSubscriptions = currentSubscriptions ? [currentSubscriptions] : [];
  const initialized = useRef(false);
  const toggleConfirmDeleteSubscriptionCheck = () => {
    //setConfirmDeleteSubscriptionCheck(!confirmDeleteSubscriptionCheck);
  };
  const proceddToCancelSubscription = subscription => {
    if (initialized.current) {
      dispatch(
        deleteSubscribedPlan(
          subscription.GUID,
          subscription.PaymentCardGUID,
          subscription.SubscriptionTierGUID
        )
      ).then(res => {
        segmentHelper.track(segmentHelper.events.SUBSCRIPTION_CANCELED);
        toast.success('Subscription cacelled successfully', {
          theme: 'colored',
          position: 'bottom-center',
          icon: faCheckCircle,
          toastId: 'subscription'
        });
        dispatch(refreshAllAccountInfo());
      });
    } else {
      toast.warning('Kindly accept Subscription cacel terms & condition', {
        theme: 'colored',
        position: 'bottom-center',
        icon: faExclamationCircle,
        toastId: 'subscription'
      });
      return false;
    }
  };

  const confirmCancelSubscription = subscription => {
    segmentHelper.track(segmentHelper.events.SUBSCRIPTION_CANCELE_CLICK);
    initialized.current = false;
    confirmAlert({
      title: 'Subscription',
      message: (
        <Card.Body>
          <h4>Cancel my Subscription</h4>
          <div>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  //checked={confirmDeleteSubscriptionCheck}
                  onChange={e => {
                    initialized.current = !initialized.current;
                  }}
                />{' '}
                I am aware of my state or commissions requirements on notary
                journal retention and am willingly deleting my data. I am solely
                responsible for this action and agree that I cannot hold Jurat
                Inc responsible for the implications therein.
              </Label>
            </FormGroup>
          </div>
        </Card.Body>
      ),
      buttons: [
        {
          label: 'Cancel my subscription',
          onClick: () => {
            return proceddToCancelSubscription(subscription);
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  const renderSubscriptionType = activeSubscription => {
    let content = null;
    let cancelTime = _.get(activeSubscription, 'CancelAt');

    if (activeSubscription.Type === enums.SUBSCRIPTION_TYPE.Retention) {
      content = (
        <>
          <div className="text-center">
            <h3 className="text-info">
              <FontAwesomeIcon icon={faCloud} color="info" /> Retention
              Subscription Active
            </h3>
            <p className="text-info mb-1">
              We will continue to securely store and manage your data, but new
              entries cannot be created. You have the ability to view and export
              journal entries.
            </p>
            <p className="text-info mb-1">
              ${activeSubscription.Amount} / {activeSubscription.Recur}
            </p>
            {activeSubscription.LastCharge && (
              <p className="text-muted mb-1">
                Last Charge:{' '}
                {moment
                  .utc(activeSubscription.LastCharge)
                  .local()
                  .format('MMMM Do YYYY, h:mm a')}
              </p>
            )}
            {activeSubscription.NextCharge && !activeSubscription.CancelAt && (
              <p className="text-muted mb-1">
                Next Charge:{' '}
                {moment
                  .utc(activeSubscription.NextCharge)
                  .local()
                  .format('MMMM Do YYYY, h:mm a')}
              </p>
            )}
            {cancelTime && (
              <div>
                <p className="text-danger mb-1">
                  Your subscription will be canceled on:{' '}
                  {moment.utc(cancelTime).local().format('MMMM Do YYYY')}
                </p>
              </div>
            )}
            {!cancelTime && (
              <div className=" mt-1">
                <Button
                  variant="outline-danger"
                  onClick={() => confirmCancelSubscription(activeSubscription)}
                >
                  Cancel Subscription
                </Button>
              </div>
            )}
          </div>
        </>
      );
    } else if (activeSubscription.Type === enums.SUBSCRIPTION_TYPE.Standard) {
      content = (
        <>
          <div className="text-center">
            <h3 className="text-success">
              <FontAwesomeIcon icon={faCheckCircle} /> Subscription Active
            </h3>
            <p className="text-success mb-1">
              You have a full active subscription, which includes secure data
              storage. You can create new entries, as well as view and export
              them.
            </p>
            <p className="text-success mb-1">
              ${activeSubscription.Amount} / {activeSubscription.Recur}
            </p>
            {activeSubscription.LastCharge && (
              <p className="text-secondary mb-1">
                Last Charge:{' '}
                {moment
                  .utc(activeSubscription.LastCharge)
                  .local()
                  .format('MMMM Do YYYY, h:mm a')}
              </p>
            )}
            {activeSubscription.NextCharge && !activeSubscription.CancelAt && (
              <p className="text-secondary mb-1">
                Next Charge:{' '}
                {moment
                  .utc(activeSubscription.NextCharge)
                  .local()
                  .format('MMMM Do YYYY, h:mm a')}
              </p>
            )}
            {cancelTime && (
              <div>
                <p className="text-danger mb-1">
                  Your subscription will be canceled on:{' '}
                  {moment.utc(cancelTime).local().format('MMMM Do YYYY')}
                </p>
              </div>
            )}
            {!cancelTime && (
              <div className="mt-1">
                <Button
                  variant="outline-danger"
                  onClick={() => confirmCancelSubscription(activeSubscription)}
                >
                  Cancel Subscription
                </Button>
              </div>
            )}
          </div>
        </>
      );
    } else if (activeSubscription.Type === enums.SUBSCRIPTION_TYPE.Trial) {
      let allowedSigningCount = _.get(activeSubscription, 'MaxSigningsTotal');
      let trialExpirationDate = _.get(activeSubscription, 'EndTime');
      content = (
        <>
          <div className="text-center">
            <h3 className="text-success">
              <FontAwesomeIcon icon={faCheckCircle} /> Trial Subscription Active
            </h3>
            <p className="text-success mb-1">
              You have a trial subscription, which includes secure data storage.
              You can create up to {allowedSigningCount} new entries, as well as
              view and export them.
            </p>
            <p className="text-center mb-1">
              Your Trial subscription is Valid until{' '}
              {moment
                .utc(trialExpirationDate)
                .local()
                .format('MMMM Do YYYY, h:mm a')}
              , and allows for {allowedSigningCount} Signing
              {allowedSigningCount === 1 ? '' : 's'}
            </p>
          </div>
        </>
      );
    } else if (
      activeSubscription.Type === enums.SUBSCRIPTION_TYPE.Professional
    ) {
      content = (
        <>
          <div className="text-center">
            <h3 className="text-success">
              <FontAwesomeIcon icon={faCheckCircle} /> Professional Subscription
              Active
            </h3>
            <p className="text-success mb-1">
              You have a full active Professional subscription, which includes
              secure data storage. You can create new entries, as well as view
              and export them.
            </p>
            <p className="text-success mb-1">
              ${activeSubscription.Amount} / {activeSubscription.Recur}
            </p>
            {activeSubscription.LastCharge && (
              <p className="text-secondary mb-1">
                Last Charge:{' '}
                {moment
                  .utc(activeSubscription.LastCharge)
                  .local()
                  .format('MMMM Do YYYY, h:mm a')}
              </p>
            )}
            {activeSubscription.NextCharge && (
              <p className="text-secondary mb-1">
                Next Charge:{' '}
                {moment
                  .utc(activeSubscription.NextCharge)
                  .local()
                  .format('MMMM Do YYYY, h:mm a')}
              </p>
            )}
            {cancelTime && (
              <div>
                <p className="text-danger mb-1">
                  Your subscription will be canceled on:{' '}
                  {moment.utc(cancelTime).local().format('MMMM Do YYYY')}
                </p>
              </div>
            )}
            {!cancelTime && (
              <div className="mt-1">
                <Button
                  variant="outline-danger"
                  onClick={() => confirmCancelSubscription(activeSubscription)}
                >
                  Cancel Subscription
                </Button>
              </div>
            )}
          </div>
        </>
      );
    } else if (activeSubscription.Type === enums.SUBSCRIPTION_TYPE.NoCharge) {
      content = (
        <>
          <div className="text-center">
            <h3 className="text-success">
              <FontAwesomeIcon icon={faCheckCircle} /> Free Subscription Active
            </h3>
            <p className="text-success mb-1">
              You have a full active subscription for free, which includes
              secure data storage. You can create new entries, as well as view
              and export them. You must be pretty cool.
            </p>
            <p className="text-success mb-1">
              ${activeSubscription.Amount} / {activeSubscription.Recur}
            </p>
            {cancelTime && (
              <p className="text-danger mb-1">
                Your subscription will be canceled on:{' '}
                {moment.utc(cancelTime).local().format('MMMM Do YYYY')}
              </p>
            )}
            {!cancelTime && (
              <div className="mt-1">
                <Button
                  variant="outline-danger"
                  onClick={() => confirmCancelSubscription(activeSubscription)}
                >
                  Cancel Subscription
                </Button>
              </div>
            )}
          </div>
        </>
      );
    }

    return <>{content}</>;
  };

  return (
    <Card className={`overflow-hidden ${className}`}>
      <Background image={corner5} className="p-x1 bg-card" />
      {/* <FalconCardHeader
        title="Subscription"
        titleTag="h6"
        className="py-2"
        light
      // endEl={<CardDropdown />}
      /> */}
      <Card.Body className="position-relative">
        {activeSubscriptions.length > 0 && (
          <Tabs
            defaultActiveKey={activeSubscriptions[0]?.GUID}
            id="subscription-plans"
            transition={false}
          >
            {_.map(activeSubscriptions, e => {
              let cancelTime = _.get(e, 'CancelAt');
              return (
                <Tab
                  key={e.GUID}
                  eventKey={e.GUID}
                  title={e.Type + '/' + e.Recur + 'ly'}
                  className="border-bottom border-0 p-3"
                >
                  <div>{renderSubscriptionType(e)}</div>
                </Tab>
              );
            })}
          </Tabs>
        )}
        {subscriptionStatus?.Effective === 'None' && (
          <div className="text-center">
            <h3 className="text-danger">
              <FontAwesomeIcon icon={faCloud} color="info" /> No Subscription
            </h3>
            <p className="text-danger mb-1">
              Your trial subscription has ended, and you do not have an active
              subscription. Head over to the '
              <Link
                className="text-danger fw-bolder"
                to={'/billing/subscriptions'}
              >
                Subscriptions
              </Link>
              ' tab to fix your account.
            </p>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

CurrentSubscriptionView.propTypes = {
  className: PropTypes.string
};

export default CurrentSubscriptionView;
