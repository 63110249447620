import {
  faCheckCircle,
  faExclamation,
  faIdBadge,
  faPlus,
  faPlusCircle,
  faUser,
  faUserTie
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/common/Avatar';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import draftEntryUiHelper from 'helpers/draft-entry-ui-helper';
import segmentHelper from 'helpers/segment-helper';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Card, Col, ListGroup, Offcanvas, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { showAlert } from 'stores/modal';
import {
  actionAddSignature,
  actionAddSignatureByMark,
  actionLogSigningAction,
  actionRemoveSignature
} from 'stores/signing';
import Colors from 'util/Colors';
import c from 'util/const';
import filters from 'util/filters';
import idGenerator from 'util/idGenerator';
import logger from 'util/logger';
import GenericAddressForm from './comp/GenericAddressForm';
import SignatureModal from './comp/SignatureModal';
import SignatureModalByMark from './comp/SignatureModalByMark';
import IdentificationForm from './s2/IdentificationForm';
import _ from 'lodash';
import IdentificationView from './comp/IdentificationView';

const Step4 = ({ register, errors, setValue, setStep, saveSigningToDraft }) => {
  const [showSignatureForm, setShowSignatureForm] = useState(false);
  const [showSignatureByMarkForm, setShowSignatureByMarkForm] = useState(false);
  const [signer, setSigner] = useState({});

  const [signatureForId, setSignatureForId] = useState(null);
  const [author, setAuthor] = useState(null);

  const [showSignByMarkVal, setShowSignByMarkVal] = useState(false);

  const [showIdentityForm, setShowIdentityForm] = useState(false);
  const [showAddressForm, setShowAddressForm] = useState(false);

  const [signatureForType, setSignatureForType] = useState(null);

  const [witnessId, setWitnessId] = useState(null);

  const dispatch = useDispatch();

  const { signers, identifications, signatures } = useSelector(
    state => state.signing
  );

  const [signerByMark, setSignerByMark] = useState({
    signer_id: null,
    signer: null,

    signerSignature: null,

    witness_one_id: null,
    witnessOneIdentification: null,
    witnessOneAddress: null,
    witnessOneSignature: null,

    witness_two_id: null,
    witnessTwoIdentification: null,
    witnessTwoAddress: null,
    witnessTwoSignature: null
  });

  const promptToAddSigners = () => {
    setStep(2);
  };

  const openAddressViewModel = () => { };

  const openWitnessAddressForm = witness_id => {
    setWitnessId(witness_id);
    setShowAddressForm(true);
  };

  const openWitnessIdentityForm = (signer_id, witness_id) => {
    setWitnessId(witness_id);
    setShowIdentityForm(true);
  };

  const openWitnessSignatureForm = (witness_id, title) => {
    setWitnessId(witness_id);
    setSignatureForType('WITNESS');
    setSignatureForId(witness_id);
    setAuthor(title + ' signature');
    //This will open witness signature pad again, make sure you have primary signature and witness signature check
    setShowSignatureByMarkForm(true);
  };

  const setAddressValueFunc = addressObj => {
    if (witnessId === signerByMark.witness_one_id) {
      setSignerByMark(signerByMark => ({
        ...signerByMark,
        witnessOneAddress: addressObj
      }));
    } else if (witnessId === signerByMark.witness_two_id) {
      setSignerByMark(signerByMark => ({
        ...signerByMark,
        witnessTwoAddress: addressObj
      }));
    }
    setShowAddressForm(false);
  };

  const setIdentityItemFunc = identificationObj => {
    if (witnessId === signerByMark.witness_one_id) {
      setSignerByMark(signerByMark => ({
        ...signerByMark,
        witnessOneIdentification: identificationObj
      }));
    } else if (witnessId === signerByMark.witness_two_id) {
      setSignerByMark(signerByMark => ({
        ...signerByMark,
        witnessTwoIdentification: identificationObj
      }));
    }
    setShowIdentityForm(false);
  };

  const setSignatureDataFunc = signatureDataObj => {
    if (signatureDataObj && signatureDataObj.signatureForId) {
      dispatch(
        actionAddSignature({
          signer_id: signer.signer_id,
          signature: signatureDataObj
        })
      );
      dispatch(
        actionLogSigningAction(
          `Signature added for ${filters.formatNameObj(signer)}.`
        )
      );
      segmentHelper.track(segmentHelper.events.SIGNING_SIGNER_SIGNATURE_ADDED);
    }
  };

  const setSignatureByMarkDataFunc = signatureDataObj => {
    logger.log('setSignatureByMarkDataFunc witnessId', witnessId);
    logger.log('setSignatureByMarkDataFunc signatureForType', signatureForType);

    if (signatureDataObj && signatureDataObj.signatureForId) {
      if (witnessId === null && signatureForType === 'SIGNER') {
        logger.log('setSignatureByMarkDataFunc 1');
        setSignerByMark(signerByMark => ({
          ...signerByMark,
          signerSignature: signatureDataObj
        }));
        setShowSignatureByMarkForm(false);
        setShowSignByMarkVal(true);
      } else {
        logger.log('setSignatureByMarkDataFunc 2');
        if (witnessId === signerByMark.witness_one_id) {
          logger.log('setSignatureByMarkDataFunc 2-1');
          setSignerByMark(signerByMark => ({
            ...signerByMark,
            witnessOneSignature: signatureDataObj
          }));
        } else if (witnessId === signerByMark.witness_two_id) {
          logger.log('setSignatureByMarkDataFunc 2-2');
          setSignerByMark(signerByMark => ({
            ...signerByMark,
            witnessTwoSignature: signatureDataObj
          }));
        } else {
          logger.log('setSignatureByMarkDataFunc 2-3');
        }
        setShowSignatureByMarkForm(false);
      }
    }
  };

  const openSignerSignatureByMarkForm = signer_id => {
    setSignatureForId(c.signaturePad.signer);
    let signer = _.find(signers, s => {
      return s.signer_id === signer_id;
    });

    if (signer) {
      setWitnessId(null);
      setSignerByMark(signerByMark => ({
        ...signerByMark,
        signer_id: signer.signer_id,
        signer: signer,
        witness_one_id: idGenerator.getMarkWitnessId(),
        witness_two_id: idGenerator.getMarkWitnessId(),

        witnessOneIdentification: null,
        witnessOneAddress: null,
        witnessOneSignature: null,

        witnessTwoIdentification: null,
        witnessTwoAddress: null,
        witnessTwoSignature: null
      }));
      setSignatureForType('SIGNER');
      setSigner(signer);
      setAuthor(signer.first_name + ' ' + signer.last_name);
      setShowSignatureByMarkForm(true);
    } else {
      logger.log('Signer not found', signer_id);
    }
  };

  const openIdentityViewModelFromState = (title, identification) => {
    dispatch(
      showAlert(
        title,
        <div>
          <IdentificationView
            title={title}
            identification={identification}
            allowRemove={false}
          />
        </div>,
        res => {
          if (res) {
            //setStartSavingFavourite(startSavingFavourite + 1);
          }
        }
      )
    );
  };

  const hasWitnessOne = () => {
    let { witnessOneIdentification, witnessOneAddress, witnessOneSignature } =
      signerByMark;
    return !!(
      witnessOneIdentification ||
      witnessOneAddress ||
      witnessOneSignature
    );
  };

  const hasWitnessTwo = () => {
    let { witnessTwoIdentification, witnessTwoAddress, witnessTwoSignature } =
      signerByMark;
    return !!(
      witnessTwoIdentification ||
      witnessTwoAddress ||
      witnessTwoSignature
    );
  };

  const validateAndSetSignatureByMark = () => {
    let {
      witnessOneIdentification,
      witnessOneSignature,
      witnessTwoIdentification,
      witnessTwoSignature
    } = signerByMark;

    let err = null;

    if (hasWitnessOne()) {
      if (witnessOneIdentification == null) {
        err = "You haven't added identification for witness one";
      } else if (witnessOneSignature == null) {
        err = "You haven't added witness one signature for witness";
      }
    }

    if (hasWitnessTwo()) {
      if (witnessTwoIdentification == null) {
        err = "You haven't added identification for witness two";
      } else if (witnessTwoSignature == null) {
        err = "You haven't added witness two signature for witness";
      }
    }

    if (!hasWitnessOne() && !hasWitnessTwo()) {
      err = 'At least one witness is required';
    }

    if (err !== null) {
      toast.warning(err, {
        theme: 'colored',
        position: 'bottom-center',
        icon: faExclamation,
        toastId: 'witness-signature'
      });
      return;
    }
    //Finally Add Signer in Redux
    saveSignatureByMak();
    //setShowSignatureByMarkForm(false);
    //setShowSignByMarkVal(false);
  };
  const saveSignatureByMak = () => {
    let witnesses = [];

    let witnessOne = {
      title: 'Witness One',
      witness_id: signerByMark.witness_one_id,
      signer_id: signerByMark.signer_id,
      address: signerByMark.witnessOneAddress,
      identification: signerByMark.witnessOneIdentification,
      signature: signerByMark.witnessOneSignature
    };

    let witnessTwo = {
      title: 'Witness Two',
      witness_id: signerByMark.witness_two_id,
      signer_id: signerByMark.signer_id,
      address: signerByMark.witnessTwoAddress,
      identification: signerByMark.witnessTwoIdentification,
      signature: signerByMark.witnessTwoSignature
    };

    if (hasWitnessOne()) {
      witnesses.push(witnessOne);
    }
    if (hasWitnessTwo()) {
      witnesses.push(witnessTwo);
    }

    dispatch(
      actionAddSignatureByMark(
        signer.signer_id,
        signerByMark.signerSignature,
        witnesses
      )
    );
    saveSigningToDraft();
    dispatch(
      actionLogSigningAction(
        `Signature by Mark added for ${filters.formatNameObj(signer)}.`
      )
    );

    // Reset whole signature by mark process for opened signer
    setSignatureForId(null);
    setSignerByMark();
    setSignatureForType(null);
    setSigner(null);
    setAuthor(null);
    setShowSignatureByMarkForm(false);
  };

  const openSignerSignatureForm = signer_id => {
    setSignatureForId(c.signaturePad.signer);
    let signer = _.find(signers, s => {
      return s.signer_id === signer_id;
    });
    if (signer) {
      setSigner(signer);
      setAuthor(signer.first_name + ' ' + signer.last_name);
      setShowSignatureForm(true);
    }
  };

  const removeSignature = signature_id => {
    let foundSignatures = _.find(
      signatures,
      sig => sig.signature_id === signature_id
    );
    let foundSigner = _.find(
      signers,
      s => s.signer_id === foundSignatures.signer_id
    );
    dispatch(
      actionLogSigningAction(
        `Signature removed for ${filters.formatNameObj(foundSigner)}.`
      )
    );
    segmentHelper.track(segmentHelper.events.REMOVE_SIGNATURE_CLICK);
    dispatch(actionRemoveSignature({ signature_id }));
    saveSigningToDraft();
  };

  // useEffect(() => {
  //   //Unset witness on witness Form Close
  //   if (!showSignatureByMarkForm) {
  //     logger.log('Witness value reset');
  //     setWitnessId(null);
  //   }
  // }, [showSignatureByMarkForm]);

  const renderWitness = (
    signer_id,
    witness_id,
    title,
    address,
    identification,
    signature
  ) => {
    return (
      <div className="mb-3">
        <h5 className="mb-2">{title}</h5>

        <ListGroup>
          <ListGroup.Item>
            <Flex justifyContent="between" alignItems="center">
              <div>
                <FontAwesomeIcon
                  color={
                    address ? Colors.success.color : Colors.gray.lightColor
                  }
                  icon={faCheckCircle}
                />{' '}
                {address ? 'Added' : 'Enter'} Witness Address
              </div>
              <div>
                {address ? (
                  <Flex justifyContent="center" direction="column">
                    {/* <Button
                      className="ms-1 mb-1"
                      variant="primary"
                      size="sm"
                      onClick={() =>
                        openAddressViewModel('Witness Address', address)
                      }
                    >
                      <FontAwesomeIcon icon={faIdBadge} /> View
                    </Button> */}
                    <Button
                      className="ms-1"
                      variant="outline-primary"
                      size="sm"
                      onClick={() => openWitnessAddressForm(witness_id)}
                    >
                      Change
                    </Button>
                  </Flex>
                ) : (
                  <Button
                    className="ms-1"
                    variant="outline-primary"
                    size="sm"
                    onClick={() => openWitnessAddressForm(witness_id)}
                  >
                    <FontAwesomeIcon icon={faPlusCircle} /> Add
                  </Button>
                )}
              </div>
            </Flex>
          </ListGroup.Item>
          <ListGroup.Item>
            <Flex justifyContent="between" alignItems="center">
              <div>
                <FontAwesomeIcon
                  color={
                    identification
                      ? Colors.success.color
                      : Colors.gray.lightColor
                  }
                  icon={faCheckCircle}
                />{' '}
                {identification ? 'Added' : 'Enter'} Identification
              </div>
              <div>
                {identification ? (
                  <Flex justifyContent="center" direction="column">
                    <Button
                      className="ms-1"
                      variant="outline-primary"
                      size="sm"
                      onClick={() =>
                        openWitnessIdentityForm(signer_id, witness_id)
                      }
                    >
                      Change
                    </Button>
                  </Flex>
                ) : (
                  <Button
                    className="ms-1"
                    variant="outline-primary"
                    size="sm"
                    onClick={() =>
                      openWitnessIdentityForm(signer_id, witness_id)
                    }
                  >
                    <FontAwesomeIcon icon={faPlusCircle} /> Add
                  </Button>
                )}
              </div>
            </Flex>
          </ListGroup.Item>
          <ListGroup.Item>
            <Flex justifyContent="between" alignItems="center">
              <div>
                <FontAwesomeIcon
                  color={
                    signature ? Colors.success.color : Colors.gray.lightColor
                  }
                  icon={faCheckCircle}
                />{' '}
                {signature ? 'Added' : 'Enter'} Signature
              </div>
              <div>
                <Button
                  className="ms-1"
                  variant="outline-primary"
                  size="sm"
                  onClick={() => openWitnessSignatureForm(witness_id, title)}
                >
                  {signature ? (
                    'Change'
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faPlusCircle} /> Add
                    </>
                  )}
                </Button>
              </div>
            </Flex>
          </ListGroup.Item>
        </ListGroup>
      </div>
    );
  };

  const AddNewBlock = () => {
    return (
      <div className="text-center">
        <h4>
          <FontAwesomeIcon icon={faUser} />
        </h4>
        <p className="my-3">You haven't added any Signers yet.</p>
        <Button
          onClick={() => promptToAddSigners()}
          className="ml-2"
          color="primary"
        >
          <FontAwesomeIcon icon={faPlusCircle} /> Add Signer
        </Button>
      </div>
    );
  };

  const openIdentityViewModel = (title, identification) => {
    dispatch(
      showAlert(
        title,
        <div>
          <IdentificationView title={title} identification={identification} />
        </div>,
        res => {
          if (res) {
            //setStartSavingFavourite(startSavingFavourite + 1);
          }
        }
      )
    );
  };
  const SignerRow = ({ data, isLast }) => {
    let identification =
      identifications.length > 0
        ? _.filter(identifications, w => w.signer_id === data.signer_id)
        : [];

    let signerSignature =
      signatures.length > 0
        ? _.filter(signatures, s => s.signer_id === data.signer_id)
        : signatures;
    //logger.log('SignerRow signature', signerSignature);
    return (
      <div key={data.signer_id}>
        <Row className="align-middle">
          <Col sm={12} md={3} className="text-nowrap">
            <div>
              <Card>
                <Card.Body className="py-2">
                  <Flex
                    justifyContent="center"
                    alignContent="center"
                    direction="column"
                    alignItems="center"
                  >
                    <Avatar
                      src={data.avatar}
                      size="2xl"
                      name={data.first_name + ' ' + data.last_name}
                      mediaClass={`text-primary bg-soft-primary fs--2`}
                    />
                    <span className="my-2">
                      {data.first_name + ' ' + data.last_name}
                    </span>
                    {identification?.length ? (
                      <Button
                        variant="outline-primary"
                        size="sm"
                        onClick={() =>
                          openIdentityViewModel(
                            `${data.first_name}  ${data.last_name}'s Identification`,
                            identification
                          )
                        }
                      >
                        {identification?.length} Identification
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Flex>
                </Card.Body>
              </Card>
            </div>
          </Col>

          <Col sm={12} md={6} className="text-center">
            <Row>
              {_.map(signerSignature, s => {
                return s?.imgData?.data?.length ? (
                  <Col
                    sm={12}
                    md={6}
                    lg={4}
                    className="my-1"
                    key={_.uniqueId('signer-row' + s?.signature_id)}
                  >
                    <Card>
                      <p className="py-2 text-center">
                        {draftEntryUiHelper.getSignatureViewImage(
                          s?.imgData?.data,
                          90,
                          '100%',
                          'card-img-top'
                        )}
                      </p>
                      <Card.Body className="py-1">
                        <Button
                          variant="outline-danger"
                          onClick={() => removeSignature(s?.signature_id)}
                          size="sm"
                        >
                          Remove
                        </Button>
                      </Card.Body>
                    </Card>
                  </Col>
                ) : (
                  'N/A'
                );
              })}
            </Row>
          </Col>
          <Col sm={12} md={3} className="text-nowrap text-end">
            <Flex
              justifyContent="center"
              wrap="wrap"
              alignContent="end"
              direction="column"
            >
              <Button
                className="mx-1 my-1"
                variant="outline-primary"
                onClick={() => openSignerSignatureForm(data.signer_id)}
              >
                <FontAwesomeIcon icon={faPlus} /> Add Signature
              </Button>
              <Button
                className="mx-1 my-1"
                variant="outline-primary"
                onClick={() => openSignerSignatureByMarkForm(data.signer_id)}
              >
                Signature by Mark
              </Button>
            </Flex>
          </Col>
        </Row>
        {!isLast && <hr />}
      </div>
    );
  };

  return (
    <>
      <div>
        <h5 className="text-center"> Collect Signatures (Optional)</h5>
        <p className="text-center fs-0">
          Click "Add signature" to open a signature pad to capture signature.
          Collecting signatures is completely optional.
        </p>
        <hr />
        {signers && signers.length > 0 ? (
          <div className="pt-1">
            <FalconCardHeader
              title={
                'Signer' + (signers.length > 1 ? 's' : '') + ' in this entry'
              }
              icon={faUserTie}
            />

            {signers.map((s, index) => (
              <SignerRow
                key={_.uniqueId('signer-rows')}
                data={s}
                isLast={index === signers.length - 1}
              />
            ))}
          </div>
        ) : (
          <></>
        )}

        {!signers?.length ? <AddNewBlock /> : <></>}
      </div>

      {signer && (
        <SignatureModal
          author={author}
          signatureForId={signatureForId || null}
          showSignatureForm={showSignatureForm}
          setShowSignatureForm={setShowSignatureForm}
          setSignatureData={setSignatureDataFunc}
        />
      )}

      {signer && (
        <SignatureModalByMark
          author={author}
          signatureForType={signatureForType}
          signatureForId={signatureForId || null}
          showSignatureByMarkForm={showSignatureByMarkForm}
          setShowSignatureByMarkForm={setShowSignatureByMarkForm}
          setSignatureByMarkDataFunc={setSignatureByMarkDataFunc}
        />
      )}

      <Offcanvas
        id="offcanvas-identity-form"
        show={showIdentityForm}
        backdrop="static"
        onHide={() => setShowIdentityForm(false)}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Identification Information</Offcanvas.Title>
        </Offcanvas.Header>
        <hr className="my-0" />
        <Offcanvas.Body>
          <IdentificationForm
            signerData={signer}
            witnessId={witnessId}
            setIdentityItem={setIdentityItemFunc}
            setShowIdentityForm={setShowIdentityForm}
          />
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        id="offcanvas-address-form"
        show={showAddressForm}
        backdrop="static"
        onHide={() => setShowAddressForm(false)}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Address form</Offcanvas.Title>
        </Offcanvas.Header>
        <hr className="my-0" />
        <Offcanvas.Body>
          <GenericAddressForm
            setAddressValue={setAddressValueFunc}
            setShowAddressForm={setShowAddressForm}
            register={register}
            errors={errors}
            col={12}
          />
        </Offcanvas.Body>
      </Offcanvas>

      {showSignByMarkVal && !_.isUndefined(signerByMark) && (
        <Offcanvas
          id="offcanvas-signature-by-mark-form"
          show={showSignByMarkVal}
          backdrop="static"
          onHide={() => setShowSignByMarkVal(false)}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Signature by mark detail form</Offcanvas.Title>
          </Offcanvas.Header>
          <hr className="my-0" />
          <Offcanvas.Body>
            {signerByMark.witness_one_id !== null &&
              renderWitness(
                signerByMark.signer_id,
                signerByMark.witness_one_id,
                'Witness One',
                signerByMark.witnessOneAddress,
                signerByMark.witnessOneIdentification,
                signerByMark.witnessOneSignature
              )}

            {signerByMark.witness_two_id != null &&
              renderWitness(
                signerByMark.signer_id,
                signerByMark.witness_two_id,
                'Witness Two',
                signerByMark.witnessTwoAddress,
                signerByMark.witnessTwoIdentification,
                signerByMark.witnessTwoSignature
              )}

            <Flex justifyContent="center">
              <Button
                className="mx-1"
                variant="secondary"
                onClick={() => setShowSignByMarkVal(false)}
              >
                Close{' '}
              </Button>{' '}
              <Button
                variant="primary"
                onClick={() => validateAndSetSignatureByMark()}
              >
                {' '}
                Save{' '}
              </Button>{' '}
            </Flex>
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </>
  );
};

Step4.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setValue: PropTypes.func.isRequired
};

export default Step4;
