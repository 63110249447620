import {
  faExternalLinkAlt,
  faFileInvoice,
  faReply
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import corner1 from 'assets/img/illustrations/corner-1.png';
import FalconComponentCard from 'components/common/FalconComponentCard';
import Flex from 'components/common/Flex';
import PageHeader from 'components/common/PageHeader';
import SoftBadge from 'components/common/SoftBadge';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import segmentHelper from 'helpers/segment-helper';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import api from 'util/api';
import filters from 'util/filters';

const PaymentHistoryList = () => {
  const [paymentHistoryMessage, setPaymentHistoryMessage] = useState(null);
  const [paymentHistoryList, setPaymentHistoryList] = useState(null);
  const [fetchingHistory, setFetchingHistory] = useState(false);
  const { subscriptionStatus } = useSelector(state => state.subscription);
  let activeSubscriptions = _.get(subscriptionStatus, 'Active', []);
  const fetchPaymentHistory = () => {
    if (!fetchingHistory) {
      setFetchingHistory(true);
      return new Promise((resolve, reject) => {
        api.Subscription.subscriptionPayments(activeSubscriptions[0]?.GUID)
          .then(res => {
            setFetchingHistory(false);
            if (res) {
              setPaymentHistoryList(res);
              resolve(res);
            } else {
              reject(false);
            }
          })
          .catch(err => {
            setPaymentHistoryMessage(
              'Unable to load payment history. Try again'
            );
            setFetchingHistory(false);
            reject(err);
          });
      });
    }
  };

  const trackEventClick = () => {
    segmentHelper.track(segmentHelper.events.VIEW_RECEIPT);
  }

  useEffect(() => {
    fetchPaymentHistory();
  }, []);

  const columns = [
    {
      accessor: 'StripeChargeId',
      Header: 'Charge ID'
    },
    {
      accessor: 'ChargeAmount',
      Header: 'Amount',
      Cell: rowData => {
        const { ChargeAmount, RefundAmount } = rowData.row.original;
        return (
          <>
            {filters.formatCurrencyFromServer(
              (ChargeAmount).toFixed(2),
              'USD'
            )}{' '}
            {RefundAmount ? (
              <SoftBadge pill bg="secondary" className="me-2">
                {filters.formatCurrencyFromServer(
                  (RefundAmount).toFixed(2),
                  'USD'
                )}{' '}
                Refunded
              </SoftBadge>
            ) : (
              <></>
            )}
          </>
        );
      }
    },
    {
      accessor: 'Approved',
      Header: 'Approved',
      Cell: rowData => {
        const { Approved } = rowData.row.original;
        return Approved ? (
          <SoftBadge pill bg="success" className="me-2">
            {'Approved'}
          </SoftBadge>
        ) : (
          <SoftBadge pill bg="warning" className="me-2">
            {'Unapproved'}
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'Created',
      Header: 'Created',
      Cell: rowData => {
        const { Created } = rowData.row.original;
        return moment(Created)
          .local()
          .format('MMMM Do YYYY, h:mm a');
      }
    },
    {
      accessor: 'ReceiptUrl',
      Header: 'Receipt',
      Cell: rowData => {
        const { ReceiptUrl } = rowData.row.original;
        return (
          <a target="_blank" href={ReceiptUrl} onClick={() => trackEventClick()}>
            <FontAwesomeIcon icon={faExternalLinkAlt} size="sm" /> &nbsp; View
          </a>
        );
      }
    }
  ];

  return (
    <>
      <PageHeader
        preTitle={null}
        title="Payment history"
        description="View your transaction history."
        className="mb-3"
        titleTag="h2"
        image={corner1}
      ></PageHeader>
      <Row>
        <Col sm={12}>
          <Flex
            className="w-100"
            justifyContent="center"
            alignContent="center"
            alignItems="center"
          >
            {fetchingHistory && (
              <div className="w-100 text-center">
                <Spinner /> <br />
                <br />
                <p>Loading Payment History</p>
              </div>
            )}
          </Flex>

          {!fetchingHistory && paymentHistoryMessage && (
            <div className="w-100 mt-3 text-center">
              <p className="text-center">{paymentHistoryMessage}</p>
              <Button
                onClick={() => fetchPaymentHistory()}
                variant="outline-primary"
              >
                <FontAwesomeIcon icon={faReply} size="sm" /> Reload Payment
                History &nbsp;{' '}
              </Button>
            </div>
          )}

          {paymentHistoryList?.length > 0 && (
            <FalconComponentCard>
              <FalconComponentCard.Body>
                <AdvanceTableWrapper
                  columns={columns}
                  data={paymentHistoryList}
                  sortable
                  pagination
                  perPage={5}
                >
                  <Row className="flex-end-center mb-3">
                    <Col xs="auto" sm={6} lg={4}>
                      <AdvanceTableSearchBox table />
                    </Col>
                  </Row>
                  <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tableProps={{
                      bordered: true,
                      striped: true,
                      className: 'fs--1 mb-0 overflow-hidden'
                    }}
                  />
                  <div className="mt-3">
                    <AdvanceTableFooter
                      rowCount={paymentHistoryList?.length || 0}
                      table
                      rowInfo
                      navButtons
                      rowsPerPageSelection
                    />
                  </div>
                </AdvanceTableWrapper>
              </FalconComponentCard.Body>
            </FalconComponentCard>
          )}

          {!fetchingHistory && paymentHistoryList?.length == 0 && (
            <div className="text-center mt-3">
              <FontAwesomeIcon icon={faFileInvoice} size="3x" />
              <br />
              <p className="my-3">You don't have transaction history</p>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default PaymentHistoryList;
