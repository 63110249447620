import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import BasicECharts from 'components/common/BasicEChart';
import Flex from 'components/common/Flex';
import { progressPercentStatus } from 'data/common/common';
import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor, rgbaColor } from 'helpers/utils';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import logger from 'util/logger';

import corner6 from 'assets/img/illustrations/corner-6.png';
import Background from 'components/common/Background';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer
]);

const SigningWidget = ({ width, amountClassName }) => {
  const [dataValues, setDataValues] = useState([]);
  const [dataKeys, setDataKeys] = useState([]);
  const [signing, setSigning] = useState(null);
  const { rawData } = useSelector((state) => state.dashboard);


  useEffect(() => {
    logger.log("SigningWidget extracted", signing);
    let dates = _.map(signing, 'date', []);
    let dateKeys = [];
    _.map(dates, d => {
      dateKeys.push(d);
    })
    setDataKeys(dateKeys);
    setDataValues(_.map(signing, 'signing', []));

  }, [signing])

  useEffect(() => {
    if (rawData) {
      logger.log("rawData", rawData);
      let Slices = _.get(rawData, 'Slices');
      logger.log("Slices data for graph", Slices);
      let signingData = [];
      _.map(Slices, slice => {
        signingData.push({
          date: moment(slice.End).format('MM/DD/YY'),
          signing: slice.Signings.length
        });
      })
      setSigning(signingData);
    }
  }, [rawData])


  const getOptions = (data, keys) => ({
    tooltip: {
      trigger: 'axis',
      formatter: '<strong>{b0}</strong> : {c0}'
    },
    xAxis: {
      data: keys
    },
    series: [
      {
        type: 'bar',
        data,
        smooth: true,
        lineStyle: {
          width: 3
        },

        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: rgbaColor(getColor('primary'), 0.25)
              },
              {
                offset: 1,
                color: rgbaColor(getColor('primary'), 0)
              }
            ]
          }
        }
      }
    ],
    grid: {
      bottom: '2%',
      top: '2%',
      right: '10px',
      left: '10px'
    }
  });

  // const getOptions = (data, keys) => ({
  //   tooltip: {
  //     trigger: 'item',
  //     axisPointer: {
  //       type: 'none'
  //     },
  //     padding: [7, 10],
  //     //backgroundColor: getColor('gray-100'),
  //     //borderColor: getColor('gray-100'),
  //     textStyle: { color: getColor('dark') },
  //     borderWidth: 1,
  //     transitionDuration: 0
  //   },
  //   xAxis: {
  //     type: 'category',
  //     show: false,
  //     boundaryGap: false
  //   },
  //   yAxis: {
  //     show: false,
  //     type: 'value',
  //     boundaryGap: false
  //   },
  //   series: [
  //     {
  //       type: 'bar',
  //       symbol: 'none',
  //       data,
  //       z: 10
  //     }
  //   ],
  //   grid: { right: 5, left: 10, top: 0, bottom: 0 }
  // });

  return (
    <Card className="h-md-100">
      <Background image={corner6} className="p-x1 bg-card" />
      <Card.Header className="pb-0">
        <h6 className="mb-0 mt-2">
          Last Month's Journal
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                Notary eJournal completed in last months
              </Tooltip>
            }
          >
            <span>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                transform="shrink-1"
                className="ms-1 text-400"
                id="weeklySalesTooltip"
              />
            </span>
          </OverlayTrigger>
        </h6>
      </Card.Header>

      <Card.Body as={Flex} alignItems="end" justifyContent="between">
        <div>
          <h2
            className={classNames(
              amountClassName,
              'mb-1 text-700 fw-normal lh-1'
            )}
          >
            {_.sum(dataValues)}
          </h2>
          {progressPercentStatus(dataValues)}
        </div>
        <BasicECharts
          echarts={echarts}
          options={getOptions(dataValues, dataKeys)}
          style={{ width: width || '8.5rem', height: width / 2 || '4.25rem', }}
        />
      </Card.Body>
    </Card>
  );
};

SigningWidget.propTypes = {
  width: PropTypes.string,
  amountClassName: PropTypes.string
};

export default SigningWidget;
