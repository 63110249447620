import { createSlice } from '@reduxjs/toolkit';
import api from '../util/api';
import logger from '../util/logger';
import _ from 'lodash';
import { setStageValue } from './app';
import c from 'util/const';

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: {
    subscriptionTier: null,
    subscriptionStatus: null,
    paymentCards: null,
    stripeKey: null
  },
  reducers: {
    setSubscriptionsStatus: (state, action) => {
      state.subscriptionStatus = action.payload;
    },

    setSubscriptionTier: (state, action) => {
      state.subscriptionTier = action.payload;
    },

    setPaymentCards: (state, action) => {
      state.paymentCards = action.payload;
    },

    setStripeKey: (state, action) => {
      state.stripeKey = action.payload;
    },

    cleanupState: (state, action) => {
      state.subscriptionStatus = null;
      state.subscriptionTier = null;
      state.paymentCards = null;
      state.stripePromise = null;
    }
  }
});

let {
  setSubscriptionsStatus,
  setSubscriptionTier,
  setStripeKey,
  setPaymentCards,
  cleanupState
} = subscriptionSlice.actions;

let actions = {
  refreshAllSubscriptionData: () => {
    return (dispatch, getState) => {
      dispatch(setStageValue(c.stages.SUBSCRIPTION));
      return new Promise(function (resolve, reject) {
        dispatch(actions.refreshSubscriptionStatus())
          .then(res => {
            dispatch(actions.refreshSubscriptionTier()).then(res => {
              resolve(true);
            });
          })
          .catch(err => {
            return reject(err);
          });
      });
    };
  },

  refreshStripeKey: () => {
    return (dispatch, getState) => {
      dispatch(setStageValue(c.stages.PAYMENT));
      return new Promise((resolve, reject) => {
        api.Stripe.publicKey()
          .then(res => {
            dispatch(setStripeKey(_.get(res, 'StripePublicKey')));
            return resolve(res);
          })
          .catch(err => {
            return reject(err);
          });
      });
    };
  },

  // refreshPaymentCards : () => {
  //   return (dispatch, getState) => {
  //     return new Promise((resolve, reject) => {
  //       api.PaymentCard.status()
  //         .then((res) => {
  //           dispatch(setSubscriptionsStatus(res));
  //           resolve(res);
  //         })
  //         .catch((err) => {
  //           logger.warn('error refreshSubscriptionStatus', err);
  //           dispatch(setSubscriptionsStatus(null));
  //           reject(err);
  //         })
  //     })
  //   }
  // },

  refreshSubscriptionStatus: () => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        api.Subscription.status()
          .then(res => {
            dispatch(setSubscriptionsStatus(res));
            resolve(res);
          })
          .catch(err => {
            logger.warn('error refreshSubscriptionStatus', err);
            dispatch(setSubscriptionsStatus(null));
            reject(err);
          });
      });
    };
  },

  refreshSubscriptionTier: () => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        api.SubscriptionTier.get()
          .then(res => {
            dispatch(setSubscriptionTier(res));
            resolve(res);
          })
          .catch(err => {
            logger.warn('error refreshSubscriptionTier', err);
            dispatch(setSubscriptionTier(null));
            reject(err);
          });
      });
    };
  },

  paymentMethodGet: () => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        api.PaymentCardV2.get()
          .then(res => {
            logger.log('paymentMethodAdd', res);
            if (res) {
              dispatch(setPaymentCards(_.orderBy(res, ['Created'], ['desc'])));
              resolve(res);
            } else {
              dispatch(setPaymentCards([]));
              reject(false);
            }
          })
          .catch(err => {
            dispatch(setPaymentCards([]));
            logger.warn('error paymentMethodAdd', err);
            reject(err);
          });
      });
    };
  },

  paymentMethodDelete: pm_id => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        api.PaymentCardV2.remove(pm_id)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            logger.warn('error paymentMethodDelete', err);
            reject(err);
          });
      });
    };
  },

  updateDefaultPaymentMethod: pm_id => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        api.PaymentCardV2.markDefaultMethod(pm_id)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            logger.warn('error markDefaultPaymentMethod', err);
            reject(err);
          });
      });
    };
  },

  proceedToSubscribePlan: (cardGUID, plnGUID, couponCode) => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        api.Subscription.create(cardGUID, plnGUID, couponCode)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            logger.warn('error paymentMethodDelete', err);
            reject(err);
          });
      });
    };
  },

  deleteSubscribedPlan: (GUID, PaymentCardGUID, SubscriptionTierGUID) => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        api.Subscription.delete(GUID, PaymentCardGUID, SubscriptionTierGUID)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            logger.warn('error paymentMethodDelete', err);
            reject(err);
          });
      });
    };
  },

  cleanupSubscription: () => {
    return (dispatch, getState) => {
      dispatch(cleanupState());
    };
  }
};

export const {
  refreshAllSubscriptionData,
  refreshSubscriptionStatus,
  refreshSubscriptionTier,
  refreshStripeKey,
  paymentMethodGet,
  paymentMethodDelete,
  updateDefaultPaymentMethod,
  proceedToSubscribePlan,
  deleteSubscribedPlan,
  cleanupSubscription
} = actions;

export default subscriptionSlice.reducer;
