import {
  faEnvelope,
  faFilePdf,
  faPencilAlt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import store from 'app/store';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import IconAlert from 'components/common/IconAlert';
import SoftBadge from 'components/common/SoftBadge';
import draftEntryUiHelper from 'helpers/draft-entry-ui-helper';
import segmentHelper from 'helpers/segment-helper';
import userHelper from 'helpers/userHelper';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Button,
  Card,
  Col,
  ListGroup,
  Placeholder,
  Row,
  Spinner
} from 'react-bootstrap';
import { BsFileText, BsGeoAlt, BsPersonFill } from 'react-icons/bs';
import {
  FaDollarSign,
  FaFileSignature,
  FaFingerprint,
  FaListAlt,
  FaRegAddressCard,
  FaStickyNote
} from 'react-icons/fa';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import {
  Alert,
  CardImg,
  FormGroup,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import { showAlert, showConfirm } from 'stores/modal';

import api from 'util/api';
import c from 'util/const';
import filters from 'util/filters';
import logger from 'util/logger';
import pdfReportGenerator from 'util/pdf-report-generator';
import pdfReportGeneratorNew from 'util/pdf-report-generator-new';
import utils from 'util/utils';

class ViewEntryDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      entry: null,
      signatureBlobs: [],
      identificationBlobs: [],
      documentBlobs: [],
      fingerprintBlobs: [],
      pictureBlobs: [],
      entryNotes: '',
      originalNotesLookup: {},
      currentNotesLookup: {},
      parsedAuditLog: null,
      changesMade: false,
      notarizationEmail: '',
      SigningFees: [],
      totalFee: 0,
      localTimezone: null
    };
  }

  componentDidMount() {
    this.loadEntry();
  }

  loadEntry() {
    let isFirstTimeLoad = !this.state.entry;
    if (isFirstTimeLoad) {
      this.setState({
        loading: true,
        entry: null,
        signatureBlobs: [],
        identificationBlobs: [],
        documentBlobs: [],
        fingerprintBlobs: [],
        pictureBlobs: [],
        parsedAuditLog: null,
        entryNotes: '',
        savingSigningNote: false,
        savingDocumentNote: false,
        SigningFees: [],
        totalFee: 0,
        localTimezone: userHelper.getTimezoneVal(
          store.getState()?.app?.userMaster?.LocalTimezone
        ),
        //don't clear the current look note lookup!  They might have unsaved changes.
        originalNotesLookup: {}
      });
    }

    api.Signing2.get(this.props.entry_guid, true)
      .then(res => {
        logger.log('get signing res', res);
        let entry = res[0];

        let blobsArray = _.get(entry, 'Blobs', []);
        let auditLogBlob = _.find(blobsArray, b => b.Name === 'AuditLog');
        let parsedAuditLog = auditLogBlob
          ? utils.decodeBlobData(auditLogBlob.Data)
          : null;

        let signatureBlobs = _.uniqBy(
          _.flatten(
            _.map(entry.NotaryEntries, e => {
              let signatureArr = _.get(e, 'Signatures', []);
              _.each(signatureArr, s => {
                let sigBlob = _.get(s, 'Blobs', []);
                if (sigBlob.length > 0) {
                  s.uniqueDataKey = sigBlob[0].Md5;
                } else {
                  s.uniqueDataKey = '';
                }
              });
              return signatureArr;
            })
          ),
          'uniqueDataKey'
        );

        let fingerprintBlobs = _.uniqBy(
          _.flatten(
            _.map(entry.NotaryEntries, e => {
              let fingerArr = _.get(e, 'Fingerprints', []);
              _.each(fingerArr, s => {
                let fBlob = _.get(s, 'Blobs', []);
                if (fBlob.length > 0) {
                  s.uniqueDataKey = fBlob[0].Md5;
                } else {
                  s.uniqueDataKey = '';
                }
              });
              return fingerArr;
            })
          ),
          'uniqueDataKey'
        );

        this.setState(
          {
            entry,
            entryNotes: _.get(entry, 'AdditionalData', ''),
            signatureBlobs,
            fingerprintBlobs,
            parsedAuditLog,
            SigningFees: entry.SigningFees
              ? _.get(entry, 'SigningFees', '')
              : [],
            totalFee: this.calculateTotalFee(entry),
            localTimezone:
              userHelper.getTimezoneVal(entry.LocalTimezone) ||
              userHelper.getTimezoneVal(
                store.getState()?.app?.userMaster?.LocalTimezone
              ) ||
              null
          },
          () => {
            this.updateNoteLookups();
          }
        );
      })
      .catch(err => {
        logger.log('error loading entry', err);
        this.props.showAlert(
          'Unable to Load Entry',
          'There was a problem loading your entry.  Please try again.',
          () => {
            this.closeModal(this.state.changesMade);
          }
        );
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  calculateTotalFee(entry) {
    let notaryFee = entry.NotaryFee || 0;
    let SigningFees = entry.SigningFees ? entry.SigningFees : [];
    logger.log('SigningFees', SigningFees);
    let totalFee = parseFloat(notaryFee);
    _.map(SigningFees, ef => {
      totalFee += parseFloat(ef.Amount);
    });
    return totalFee;
  }

  closeModal(res) {
    let { close } = this.props;
    close(res);
  }

  updateNoteLookups() {
    let { entry } = this.state;
    let originalUpdate = {};
    _.each(entry.NotaryEntries, e => {
      originalUpdate[e.GUID] = e.AdditionalData;
    });
    this.setState({ originalNotesLookup: originalUpdate });

    // logger.log('updateNoteLookups', _.keys(this.state.currentNotesLookup), this.state.currentNotesLookup, originalUpdate);
    if (_.keys(this.state.currentNotesLookup).length === 0) {
      this.setState({ currentNotesLookup: originalUpdate });
    }
  }

  saveSigningNotes() {
    this.setState({ savingSigningNote: true });
    api.Signing.saveNotes(this.state.entry.GUID, this.state.entryNotes)
      .then(() => {
        this.loadEntry();
        this.props.showAlert('Success', 'Signing note saved successfull.');
        this.setState({ savingSigningNote: false });
      })
      .catch(err => {
        logger.log('error while saving signing notes', err);
        this.props.showAlert(
          'Unable to save notes',
          'There was a problem saving your notes.  Please try again.'
        );
        this.setState({ savingSigningNote: false });
      })
      .finally(() => {
        this.setState({ changesMade: true });
        this.setState({ savingSigningNote: false });
      });
  }

  saveEntryNotes(entryNoteGUID) {
    this.setState({ savingDocumentNote: true });
    let currentNotes = _.get(
      this.state.currentNotesLookup,
      `[${entryNoteGUID}]`,
      ''
    );
    api.NotaryEntry.saveEntryNotes(entryNoteGUID, currentNotes)
      .then(() => {
        this.loadEntry();
        this.props.showAlert('Success', 'Document note saved successfull.');
        this.setState({ savingDocumentNote: false });
      })
      .catch(err => {
        logger.log('error while saving entry notes', err);
        this.props.showAlert(
          'Unable to save notes',
          'There was a problem saving your notes.  Please try again.'
        );
        this.setState({ savingDocumentNote: false });
      })
      .finally(() => {
        this.setState({ changesMade: true });
        this.setState({ savingDocumentNote: false });
      });
  }

  handleStrikeEntry() {
    let { entry } = this.state;

    if (entry.Stricken) {
      api.Signing.unstrike(entry.GUID)
        .then(res => {
          segmentHelper.track(
            segmentHelper.events.SIGNING_DETAIL_UNSTRIKE_ENTRY
          );
          logger.log('unstrike res', res);
          this.loadEntry();
        })
        .catch(err => {
          logger.log('error while saving unstriking entry', err);
          this.props.showAlert(
            'Unable to unstrike entry',
            'There was a problem unstriking your entry.  Please try again.'
          );
        })
        .finally(() => {
          this.setState({ changesMade: true });
        });
    } else {
      this.props.showConfirm(
        'Are you sure?',
        'Striking a signing will cross it out in your journal history, but the entry will remain in your journal history.  You will be able to undo this action.',
        res => {
          if (res) {
            api.Signing.strike(entry.GUID)
              .then(res => {
                segmentHelper.track(
                  segmentHelper.events.SIGNING_DETAIL_STRIKE_ENTRY
                );
                logger.log('strike res', res);
                this.loadEntry();
              })
              .catch(err => {
                logger.log('error while saving striking entry', err);
                store.dispatch(
                  showAlert(
                    'Unable to strike entry',
                    'There was a problem striking your entry.  Please try again.'
                  )
                );
              })
              .finally(() => {
                this.setState({ changesMade: true });
              });
          }
        }
      );
    }
  }

  openSendEmailPopup() {
    let { entry, notarizationEmail } = this.state;
    //logger.log('notarizationEmail', notarizationEmail);
    this.props.showConfirm(
      'Send a review email to signer',
      <div>
        <p>An email will be send to the email below containing general information about thisnotarization event</p>
        <p>Note - it will not include any information pertaining to fees.</p>
        <FormGroup>
          <Input
            type="text"
            name="notarizationEmail"
            onChange={evt => {
              this.setState({ notarizationEmail: evt.target.value });
            }}
            //value={notarizationEmail}
            placeholder="Enter signer's email"
            id="notarizationEmail"
          />
        </FormGroup>
      </div>,
      res => {
        let { notarizationEmail } = this.state;
        if (res) {
          api.Signing2.sendEmail(entry.GUID, notarizationEmail)
            .then(res => {
              logger.log('email send', res);
              this.props.showAlert(
                'Email message',
                'An email send to ' + notarizationEmail
              );
              this.loadEntry();
            })
            .catch(err => {
              logger.log('error while sending email. try again', err);
              this.props.showAlert(
                'Unable to send email',
                'There was a problem sending email.  Please try again.'
              );
            });
        }
      }
    );
  }

  getCommissionProfileList() {
    let { userProfile } = this.props.app;

    let notaryInfo = [];
    if (userProfile) {
      _.each(userProfile, p => {
        notaryInfo.push({
          GUID: p.GUID,
          LocalTimezone: p.LocalTimezone,
          commission_country: p.CommissionCountry,
          commission_state: p.CommissionState,
          commission_county: p.CommissionProvince,
          commission_number: p.CommissionNumber,
          commission_name: p.CommissionedName,
          commission_exp: moment(p.ExpirationDate)
        });
      });
    }
    return notaryInfo;
  }

  downloadReportClick() {
    segmentHelper.track(segmentHelper.events.GENERATE_SIGNING_REPORT);
    pdfReportGeneratorNew
      .generateSigningEntryReport(
        this.getCommissionProfileList(),
        this.state.entry,
        this.state.entryNotes,
        this.state.signatureBlobs,
        this.state.identificationBlobs,
        this.state.documentBlobs,
        this.state.fingerprintBlobs,
        this.state.pictureBlobs,
        this.state.parsedAuditLog
      )
      .catch(err => {
        this.props.showAlert(
          'Problem Generating Report',
          'It looks like there was a problem generating your report.  Please try disabling any popup blockers you may have, and try again.'
        );
      });
  }

  downloadDocumentReportClick(documentEntry) {
    pdfReportGeneratorNew
      .generateSignedDocumentEntryReport(
        this.getCommissionProfileList(),
        this.state.entry,
        this.state.entryNotes,
        this.state.signatureBlobs,
        this.state.identificationBlobs,
        this.state.documentBlobs,
        this.state.fingerprintBlobs,
        this.state.pictureBlobs,
        documentEntry
      )
      .catch(err => {
        this.props.showAlert(
          'Problem Generating Report',
          'It looks like there was a problem generating your report.  Please try disabling any popup blockers you may have, and try again.'
        );
      });
  }

  updateNotes(entryGUID, val) {
    let update = _.extend({}, this.state.currentNotesLookup);
    update[entryGUID] = val;
    this.setState({ currentNotesLookup: update });
  }

  renderMetadata() {
    let { entry, entryNotes } = this.state;

    return (
      <Card>
        <Card.Header>
          <h5>
            <FaListAlt /> Information
          </h5>
        </Card.Header>
        <hr className="my-0" />
        <Card.Body>
          <Row>
            <Col sm={12} md={6}>
              <ListGroup>
                {entry.Started && (
                  <ListGroup.Item>
                    <Flex justifyContent="between" alignItems="center">
                      Started:{' '}
                      <span>
                        {draftEntryUiHelper
                          .getDateTimeWithTimeZone(
                            entry.Started,
                            this.state.localTimezone
                          )

                          .format(c.dateFormat)}
                      </span>
                    </Flex>
                  </ListGroup.Item>
                )}
                {entry.Completed && (
                  <ListGroup.Item>
                    <Flex justifyContent="between" alignItems="center">
                      Completed:{' '}
                      <span>
                        {draftEntryUiHelper
                          .getDateTimeWithTimeZone(
                            entry.Completed,
                            this.state.localTimezone
                          )

                          .format(c.dateFormat)}
                      </span>
                    </Flex>
                  </ListGroup.Item>
                )}
                {entry.Created && (
                  <ListGroup.Item>
                    <Flex justifyContent="between" alignItems="center">
                      Uploaded:{' '}
                      <span>
                        {draftEntryUiHelper
                          .getDateTimeWithTimeZone(
                            entry.Created,
                            this.state.localTimezone
                          )

                          .format(c.dateFormat)}
                      </span>
                    </Flex>
                  </ListGroup.Item>
                )}
                {entry.SigningTimestamp && (
                  <ListGroup.Item>
                    <Flex justifyContent="between" alignItems="center">
                      Notarization date & time :{' '}
                      <span>
                        {draftEntryUiHelper
                          .getDateTimeWithTimeZone(
                            entry.SigningTimestamp,
                            this.state.localTimezone
                          )
                          .format(c.dateFormat)}
                      </span>
                    </Flex>
                  </ListGroup.Item>
                )}
                <ListGroup.Item>
                  <Flex justifyContent="between" alignItems="center">
                    Identifier: <span>{entry.GUID}</span>
                  </Flex>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={12} md={6}>
              <h5>Signing Notes (optional)</h5>
              <FormGroup className="mx-auto">
                <Input
                  type="textarea"
                  name="text"
                  className="no-resize w-100"
                  rows={4}
                  onChange={evt =>
                    this.setState({ entryNotes: evt.target.value })
                  }
                  value={entryNotes}
                  placeholder="Notes"
                />
              </FormGroup>
              <Button
                variant="outline-primary"
                disabled={
                  entryNotes === entry.AdditionalData ||
                  this.state.savingSigningNote
                }
                onClick={this.saveSigningNotes.bind(this)}
              >
                {this.state.savingSigningNote ? 'Saving' : 'Save Notes'}
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }

  renderFee() {
    let { entry, SigningFees, totalFee } = this.state;

    return (
      <Card className="mt-2">
        <Card.Header>
          <h5>
            <FaDollarSign /> Fee Assessed
          </h5>
        </Card.Header>
        <hr className="my-0" />
        <Card.Body>
          <Row>
            <Col sm={12} md={12}>
              <ListGroup>
                <ListGroup.Item className="font-italic">
                  <Flex justifyContent="between" alignItems="center">
                    Notary fee:{' '}
                    <span>
                      { entry.NotaryFee >= 0
                        ? filters.formatCurrencyFromServer(entry.NotaryFee)
                        : '- None entered -'}
                    </span>
                  </Flex>
                </ListGroup.Item>

                {_.map(SigningFees, ef => {
                  return (
                    <ListGroup.Item
                      key={_.uniqueId('extra-fee')}
                      className="font-italic"
                    >
                      <Flex justifyContent="between" alignItems="center">
                        {ef.Name}:{' '}
                        <span>
                          {ef.Amount
                            ? filters.formatCurrencyFromServer(ef.Amount)
                            : '- None entered -'}
                        </span>
                      </Flex>
                    </ListGroup.Item>
                  );
                })}

                <ListGroup.Item className="font-italic">
                  <Flex
                    justifyContent="between"
                    alignItems="center"
                    className={'fw-bold'}
                  >
                    Total Amount:{' '}
                    <span>{filters.formatCurrencyFromServer(totalFee)}</span>
                  </Flex>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }

  renderName() {
    let { entry } = this.state;

    let signers = _.get(entry, 'Signers', []);
    let signerString = _.join(signers, ', ');
    return (
      <Card className="my-2">
        <Card.Header>
          <h5>
            <BsPersonFill /> {signers.length} Signer
            {signers.length === 1 ? '' : 's'}
          </h5>
        </Card.Header>
        <hr className="my-0" />
        <Card.Body>
          {/* <div>{signerString} </div> */}
          {_.map(signers, signer => {
            return (
              <Flex
                key={_.uniqueId('signer-name')}
                className="me-2 border rounded-2 px-2 py-1 mt-1"
                justifyContent="center"
                alignItems="center"
              >
                <Avatar name={signer} size="l" />
                <div className="flex-1 chat-contact-body ms-1">
                  <h6 className="mb-0 chat-contact-title">{signer}</h6>
                </div>
              </Flex>
            );
          })}
        </Card.Body>
      </Card>
    );
  }

  renderLocation() {
    let { entry } = this.state;

    let hasAddressField = !!(
      (entry.Address1 && entry.Address1.length > 0) ||
      (entry.Address2 && entry.Address2.length > 0)
    );
    let hasAreaField = !!(
      (entry.City && entry.City.length > 0) ||
      (entry.State && entry.State.length > 0) ||
      (entry.PostalCode && entry.PostalCode.length > 0)
    );
    return (
      <Card className="my-2">
        <Card.Header>
          {' '}
          <h5>
            <BsGeoAlt /> Location
          </h5>
        </Card.Header>
        <hr className="my-0" />
        <Card.Body>
          {!hasAddressField && !hasAreaField && (
            <div>
              <div>- Location Not Specified -</div>
            </div>
          )}
          {hasAddressField && (
            <div>
              <div>
                {entry.Address1 && entry.Address1.length > 0
                  ? entry.Address1
                  : ''}{' '}
                {entry.Address2 && entry.Address2.length > 0
                  ? entry.Address2
                  : ''}
              </div>
            </div>
          )}
          {hasAreaField && (
            <div>
              <div>
                {entry.City && entry.City.length > 0 ? entry.City + ', ' : ''}
                {entry.State && entry.State.length > 0
                  ? entry.State + ', '
                  : ''}
                {entry.PostalCode && entry.PostalCode.length > 0
                  ? entry.PostalCode
                  : ''}
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
    );
  }

  renderEntryDocNote(entry) {
    let { originalNotesLookup, currentNotesLookup } = this.state;

    let originalNotes = _.get(originalNotesLookup, `[${entry.GUID}]`, '');
    let currentNotes = _.get(currentNotesLookup, `[${entry.GUID}]`, '');

    // logger.log('render entry doc notes', entry.GUID, originalNotes, currentNotes);

    return (
      <div key={entry.GUID}>
        <FormGroup>
          <Input
            type="textarea"
            name="text"
            className="no-resize"
            onChange={evt => this.updateNotes(entry.GUID, evt.target.value)}
            value={currentNotes}
            placeholder="Notes"
            id="editNotes"
          />
        </FormGroup>
        <div>
          <Button
            variant="outline-primary"
            className="mx-2"
            disabled={
              originalNotes === currentNotes || this.state.savingDocumentNote
            }
            onClick={this.saveEntryNotes.bind(this, entry.GUID)}
          >
            {this.state.savingDocumentNote ? 'Saving' : 'Save'}
          </Button>
          <Button
            variant="secondary"
            outline
            className="me-2"
            disabled={originalNotes === currentNotes}
            onClick={() => this.updateNotes(entry.GUID, originalNotes)}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }

  renderDetails() {
    let { entry } = this.state;

    return (
      <Card className="my-2">
        <Card.Header>
          <h5>
            <BsFileText />
            {entry.NotaryEntries.length} Document
            {entry.NotaryEntries.length === 1 ? '' : 's'}
          </h5>
        </Card.Header>
        <hr className="my-0" />
        <Card.Body>
          {_.map(entry.NotaryEntries, entry => {
            let signerString = _.join(entry.Signers, ', ');
            return (
              <Row key={entry.GUID} className="my-2">
                <Col sm={12} md={6} lg={5}>
                  <Card className="h-md-100">
                    <Card.Body>
                      <div>
                        <div>Document: {entry.DocumentType}</div>
                        <div>Type: {entry.NotarizationType}</div>
                        <div>Signers: {signerString}</div>
                        <div className="mt-2">
                          <Button
                            variant="outline-primary"
                            onClick={() =>
                              this.downloadDocumentReportClick(entry)
                            }
                          >
                            Export Journal Entry
                          </Button>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={12} md={6} lg={7}>
                  <Card className="h-md-100">
                    <Card.Body>{this.renderEntryDocNote(entry)}</Card.Body>
                  </Card>
                </Col>
              </Row>
            );
          })}
        </Card.Body>
      </Card>
    );
  }

  renderIdentifications() {
    let { entry, signatureBlobs } = this.state;

    let uniqueIds = [];
    _.each(entry.NotaryEntries, ne => {
      uniqueIds = _.concat(uniqueIds, ne.Identifications);
    });
    uniqueIds = _.uniqBy(uniqueIds, id => {
      return `${id.FirstName}${id.LastName}${id.IdType}${id.IdNumber}${id.IdExpiration}${id.IdIssued}`;
    });

    return (
      <Card className="my-2">
        <Card.Header>
          <h5>
            <FaRegAddressCard /> {uniqueIds.length} Identification
            {uniqueIds.length === 1 ? '' : 's'}
          </h5>
        </Card.Header>
        <hr className="my-0" />
        <Card.Body>
          <Row>
            {_.map(uniqueIds, identification => {
              let hasAddressField = !!(
                (identification.Address1 &&
                  identification.Address1.length > 0) ||
                (identification.Address2 && identification.Address2.length > 0)
              );
              let hasAreaField = !!(
                (identification.City && identification.City.length > 0) ||
                (identification.State && identification.State.length > 0) ||
                (identification.PostalCode &&
                  identification.PostalCode.length > 0)
              );
              let idType =
                identification.IdTypeOther &&
                identification.IdTypeOther.length > 0
                  ? identification.IdTypeOther
                  : identification.IdType;

              //If you have a SignByMarkWitnessGUID, then look up the person in signature blobs
              let sigByMarkPerson = null;
              if (identification.SignByMarkWitnessGUID) {
                _.each(signatureBlobs, s => {
                  _.each(_.get(s, 'SignByMarkWitnesses', []), sw => {
                    let foundID = _.find(
                      sw.Identifications,
                      i => i.GUID === identification.GUID
                    );
                    if (foundID) {
                      sigByMarkPerson = s;
                    }
                  });
                });
              }

              return (
                <Col
                  sm={12}
                  md={4}
                  lg={3}
                  className="my-2"
                  key={_.uniqueId('identification')}
                >
                  <Card key={identification.GUID} className="h-md-100">
                    <Card.Body>
                      <div>
                        <div>{filters.formatNameObj(identification)}</div>
                        {!!sigByMarkPerson && (
                          <div>
                            Signature by Mark witness for{' '}
                            {filters.formatNameObj(sigByMarkPerson)}
                          </div>
                        )}
                        <div>
                          {idType} :{' '}
                          {identification?.State &&
                          identification.State?.length > 0
                            ? identification.State
                            : ''}{' '}
                          {identification.IdNumber &&
                          identification.IdNumber.length > 0
                            ? `${identification.IdNumber}`
                            : ''}
                        </div>
                        {hasAddressField && (
                          <div>
                            {identification.Address1 &&
                            identification.Address1.length > 0
                              ? identification.Address1
                              : ''}{' '}
                            {identification.Address2 &&
                            identification.Address2.length > 0
                              ? identification.Address2
                              : ''}
                          </div>
                        )}
                        {hasAreaField && (
                          <div>
                            {identification.City &&
                            identification.City.length > 0
                              ? identification.City + ', '
                              : ''}
                            {identification.State &&
                            identification.State.length > 0
                              ? identification.State + ', '
                              : ''}
                            {identification.PostalCode &&
                            identification.PostalCode.length > 0
                              ? identification.PostalCode
                              : ''}
                          </div>
                        )}

                        {identification.IdIssued && (
                          <div>
                            Issued:{' '}
                            {draftEntryUiHelper
                              .getDateTimeWithTimeZone(
                                identification.IdIssued,
                                this.state.localTimezone
                              )
                              .format('MM/DD/YYYY')}
                          </div>
                        )}
                        {identification.IdExpiration && (
                          <div>
                            Expires:{' '}
                            {draftEntryUiHelper
                              .getDateTimeWithTimeZone(
                                identification.IdExpiration,
                                this.state.localTimezone
                              )
                              .format('MM/DD/YYYY')}
                          </div>
                        )}
                        {identification.DateOfBirth && (
                          <div>
                            DOB:{' '}
                            {moment(identification.DateOfBirth).format(
                              'MM/DD/YYYY'
                            )}
                          </div>
                        )}
                        {identification.AdditionalData &&
                          identification.AdditionalData != 'undefined' &&
                          identification.AdditionalData != 'null' &&
                          identification.AdditionalData.length > 0 && (
                            <div>{identification.AdditionalData || ''}</div>
                          )}
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Card.Body>
      </Card>
    );
  }

  renderSignatures() {
    let { entry, signatureBlobs } = this.state;

    if (signatureBlobs.length === 0) {
      return null;
    }

    return (
      <Card className="my-2">
        <Card.Header>
          <h5>
            <FaFileSignature /> {signatureBlobs.length} Signature
            {signatureBlobs.length === 1 ? '' : 's'}
          </h5>
        </Card.Header>
        <hr className="my-0" />
        <Card.Body>
          <Row className="my-2">
            {_.map(signatureBlobs, s => {
              let blobData = _.get(s, 'Blobs[0]', null);
              let signatureWitnesses = _.get(s, 'SignByMarkWitnesses', []);
              return (
                <>
                  <Col
                    className="my-1"
                    sm={12}
                    md={4}
                    lg={3}
                    key={s.uniqueDataKey}
                  >
                    <Card className="h-100">
                      <Card.Header className="text-center">
                        {filters.formatNameObj(s)}{' '}
                        {s.IsWitness ? '(Witness)' : ''}
                      </Card.Header>
                      <Card.Body className="d-flex justify-content-center align-items-center">
                        {blobData && (
                          <CardImg
                            top
                            width="100%"
                            style={{ maxWidth: '120px' }}
                            src={`data:${blobData.ContentType};base64,${blobData.Data}`}
                          />
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                  {signatureWitnesses.length > 0 && (
                    <Col
                      className="my-1"
                      sm={12}
                      md={8}
                      lg={9}
                      key={s.uniqueDataKey + 'sigwitness'}
                    >
                      <Row>
                        {_.map(signatureWitnesses, sw => {
                          let hasAddressField = !!(
                            (sw.Address1 && sw.Address1.length > 0) ||
                            (sw.Address2 && sw.Address2.length > 0)
                          );
                          let hasAreaField = !!(
                            (sw.City && sw.City.length > 0) ||
                            (sw.State && sw.State.length > 0) ||
                            (sw.PostalCode && sw.PostalCode.length > 0)
                          );

                          let swBlobData = _.get(sw, 'Blobs[0]', null);
                          return (
                            <Col
                              key={_.uniqueId('signer-witness')}
                              className="my-1 h-100"
                              sm={12}
                              md={6}
                              lg={6}
                            >
                              <Card className="text-center h-100">
                                <Card.Header>
                                  Signature by mark witness{' '}
                                  {filters.formatNameObj(sw)}
                                </Card.Header>
                                <Card.Body className="d-flex justify-content-center align-items-center">
                                  <Row>
                                    <Col sm={12} md={6} lg={6}>
                                      <Card className="h-100 text-center">
                                        <Card.Body className="d-flex justify-content-center align-items-center">
                                          <CardImg
                                            top
                                            width="100%"
                                            style={{ maxWidth: '120px' }}
                                            src={`data:${swBlobData.ContentType};base64,${swBlobData.Data}`}
                                          />
                                        </Card.Body>
                                      </Card>
                                    </Col>
                                    {!!(hasAddressField || hasAreaField) && (
                                      <Col
                                        className="my-1"
                                        sm={12}
                                        md={6}
                                        lg={6}
                                      >
                                        <Card className="h-100 text-center">
                                          <Card.Body className="d-flex justify-content-center align-items-center">
                                            <b>Address: </b>
                                            <br />
                                            {hasAddressField && (
                                              <div>
                                                {sw.Address1 &&
                                                sw.Address1.length > 0
                                                  ? sw.Address1
                                                  : ''}{' '}
                                                {sw.Address2 &&
                                                sw.Address2.length > 0
                                                  ? sw.Address2
                                                  : ''}
                                              </div>
                                            )}
                                            {hasAreaField && (
                                              <div>
                                                {sw.City && sw.City.length > 0
                                                  ? sw.City + ', '
                                                  : ''}
                                                {sw.State && sw.State.length > 0
                                                  ? sw.State + ', '
                                                  : ''}
                                                {sw.PostalCode &&
                                                sw.PostalCode.length > 0
                                                  ? sw.PostalCode
                                                  : ''}
                                              </div>
                                            )}
                                          </Card.Body>
                                        </Card>
                                      </Col>
                                    )}
                                  </Row>
                                </Card.Body>
                              </Card>
                            </Col>
                          );
                        })}
                      </Row>
                    </Col>
                  )}
                </>
              );
            })}
          </Row>
        </Card.Body>
      </Card>
    );
  }

  renderFingerprints() {
    let { entry, fingerprintBlobs } = this.state;

    if (fingerprintBlobs.length === 0) {
      return null;
    }

    return (
      <Card>
        <Card.Header>
          <h5 className="mt-4">
            <FaFingerprint /> {fingerprintBlobs.length} Fingerprint
            {fingerprintBlobs.length === 1 ? '' : 's'}
          </h5>
        </Card.Header>
        <Card.Body>
          <Row>
            {_.map(fingerprintBlobs, s => {
              let blobData = _.get(s, 'Blobs[0]', null);
              return (
                <Col
                  key={s.uniqueDataKey}
                  className="my-1"
                  sm={6}
                  md={4}
                  lg={3}
                >
                  <Card className="mb-2 text-center">
                    <Card.Header>
                      <strong>{s.Finger}</strong>
                    </Card.Header>
                    <Card.Body className="p-1 pl-3 d-flex justify-content-center align-items-center">
                      {blobData && (
                        <CardImg
                          top
                          width="100%"
                          style={{ maxWidth: '150px' }}
                          src={`data:${blobData.ContentType};base64,${blobData.Data}`}
                        />
                      )}
                    </Card.Body>
                    <Card.Footer>
                      <p>{filters.formatNameObj(s)}</p>
                    </Card.Footer>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Card.Body>
      </Card>
    );
  }

  renderAuditLog() {
    let { parsedAuditLog } = this.state;

    return (
      <>
        <Card className="my-2">
          <Card.Header>
            <h5>
              <FaStickyNote /> Audit Log
            </h5>
          </Card.Header>
          {!parsedAuditLog && (
            <Card>
              <Card.Body className="p-1 pl-3">
                <p>- Audit Log Not Present -</p>
              </Card.Body>
            </Card>
          )}
          <hr className="my-0" />
          <Card.Body>
            <ListGroup>
              {_.map(parsedAuditLog, logItem => {
                return (
                  <ListGroup.Item key={logItem.audit_log_item_id}>
                    <Flex
                      justifyContent="between"
                      alignItems="center"
                      className="flex-wrap"
                    >
                      {logItem.description}
                      <SoftBadge pill bg="primary" className="me-2">
                        {' '}
                        {draftEntryUiHelper
                          .getDateTimeWithTimeZone(
                            logItem.timestamp,
                            this.state.localTimezone
                          )
                          .format(c.dateFormat)}
                      </SoftBadge>
                    </Flex>
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </Card.Body>
        </Card>
      </>
    );
  }

  renderSubscriptionRequired() {
    return (
      <Flex
        className={'py-5'}
        justifyContent={'center'}
        alignItems={'center'}
        direction={'column'}
      >
        <IconAlert variant={'info'}>
          <p className="mb-0">Subscription required to see full details</p>
        </IconAlert>
        {/* <p><Button
        variant="primary"
        onClick={() => this.openSubscriptionPage()}
      >Subscribe</Button></p> */}
      </Flex>
    );
  }

  openSubscriptionPage() {
    this.closeModal(this.state.changesMade);
    <Navigate to="/billing/subscription" />;
  }

  render() {
    let { entry, loading, entryNotes } = this.state;
    let { subscriptionStatus } = store.getState().subscription;
    let activeSubscription = _.get(subscriptionStatus, 'Active', []);
    let showFullContent = activeSubscription.length;
    return (
      <>
        <ModalHeader
          toggle={this.closeModal.bind(this, this.state.changesMade)}
        >
          Signing Details
        </ModalHeader>
        <ModalBody>
          {loading && (
            <Flex
              className="vh-100"
              direction="column"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
            >
              <div className="text-center w-75">
                <Alert color="light">
                  <Spinner color="success" />
                </Alert>
              </div>
              <div className="w-75 text-center">
                <Placeholder xs={6} />
                <Placeholder className="w-75" />
                <Placeholder className="w-50" />
                <Placeholder className="w-50" />
                <Placeholder className="w-75" />
              </div>
            </Flex>
          )}
          {!loading && entry && (
            <div className="m-3">
              {this.renderMetadata()}
              {this.renderFee()}
              {showFullContent ? (
                <></>
              ) : (
                <>{this.renderSubscriptionRequired()}</>
              )}
              <div className={showFullContent ? '' : 'block-text-blur'}>
                {this.renderName()}
                {this.renderLocation()}
                {this.renderDetails()}
                {this.renderIdentifications()}
                {this.renderSignatures()}
                {this.renderFingerprints()}
                {this.renderAuditLog()}
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-center align-items-center">
          {!loading && (
            <>
              <Button
                variant="outline-primary"
                className="mr-auto"
                onClick={() => this.openSendEmailPopup()}
              >
                <FontAwesomeIcon icon={faEnvelope} size="sm" /> Send Email
              </Button>
              <Button
                variant="primary"
                className="mr-auto"
                onClick={() => this.handleStrikeEntry()}
              >
                <FontAwesomeIcon icon={faPencilAlt} size="sm" />{' '}
                {entry && entry.Stricken ? 'Unstrike Entry' : 'Strike Entry'}
              </Button>
              <Button
                variant="primary"
                onClick={() => this.downloadReportClick()}
              >
                <FontAwesomeIcon icon={faFilePdf} size="sm" /> Export Entire
                Journal Entry
              </Button>
              <Button
                variant="danger"
                onClick={this.closeModal.bind(this, this.state.changesMade)}
              >
                Close
              </Button>
            </>
          )}
        </ModalFooter>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    app: state.app
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showAlert: (title, message, callback) =>
      dispatch(showAlert(title, message, callback)),
    showConfirm: (title, message, callback) =>
      dispatch(showConfirm(title, message, callback)),
    refreshUserProfile: () => dispatch(refreshUserProfile())
  };
};

ViewEntryDialog.propTypes = {
  close: PropTypes.func.isRequired,
  modalProps: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewEntryDialog);
