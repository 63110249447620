import BasicECharts from 'components/common/BasicEChart';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { PieChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from 'helpers/utils';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import logger from 'util/logger';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer,
  LegendComponent
]);




const SessionByUsesChart = ({ data, mapType, labelPrefix }) => {
  // const data = [
  //   { value: 50.3, name: 'Chrome' },
  //   { value: 20.6, name: 'Safari' },
  //   { value: 30.1, name: 'Mozilla' }
  // ];

  const getOptions = () => ({
    color: _.map(data, 'color'),
    tooltip: {
      trigger: 'item',
      padding: [2, 5],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      transitionDuration: 0,
      formatter: params =>
        `<strong>${params.data.label}:</strong>${labelPrefix}${params.data.value}`
    },

    legend: { show: false },
    series: [
      {
        type: mapType || 'pie',
        radius: ['100%', '65%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderWidth: 2,
          borderColor: getColor('gray-100')
        },
        label: {
          show: false
        },
        emphasis: {
          scale: false
        },
        data
      }
    ]
  });
  logger.log("getOptions", getOptions());
  return (<>
    {data ? <BasicECharts
      echarts={echarts}
      options={getOptions()}
      style={{ height: 200 }}
    /> : <Spinner />}
  </>);
};

export default SessionByUsesChart;
