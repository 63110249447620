import api from '../util/api';
import _ from 'lodash';
import apiHelper from 'util/apiHelper';
import c from 'util/const';
import logger from 'util/logger';

const deviceHelper = {
  isCurrentDeviceBlocked() {
    return new Promise((resolve, reject) => {
      let uniqueId = window.navigator.userAgent;
      deviceHelper
        .getDeviceForIdentifier(uniqueId)
        .then(res => {
          if (res && res.Blocked) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getDeviceForIdentifier(identifier) {
    logger.log('Adding a device getDeviceForIdentifier', identifier);
    return new Promise((resolve, reject) => {
      api.Devices.list()
        .then(res => {
          if (res) {
            let found = _.find(res, d => d.Identifier === identifier);
            resolve(found);
          } else {
            reject(null);
          }
        })
        .catch(err => {
          if (apiHelper.isConnectionError(err)) {
            resolve(null);
          } else {
            reject(err);
          }
        });
    });
  },

  addCurrentDevice() {
    return new Promise((resolve, reject) => {
      //let uniqueId = DeviceInfo.getUniqueId();
      logger.log('Adding a device addCurrentDevice');
      let uniqueId = window.navigator.userAgent;
      deviceHelper.getDeviceForIdentifier(uniqueId).then(blockRes => {
        logger.log('getDeviceForIdentifier', blockRes);
        if (blockRes && blockRes.Blocked) {
          logger.log('Adding a device blocked found', blockRes);
          resolve(c.device.status_blocked);
        } else {
          let model = 'Desktop';
          let manufacturer =
            window.navigator.oscpu ||
            window.navigator.appCodeName ||
            window.navigator.appName;
          logger.log('Adding a device', blockRes);
          resolve(api.Devices.addDevice(manufacturer, model, uniqueId));
        }
      });
    });
  }
};

export default deviceHelper;
