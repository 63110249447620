import { faIdBadge } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { showAlert } from 'stores/modal';
import { actionRemoveAddress } from 'stores/signing';

const AddressView = ({ title, addresses, removeAddress }) => {
  const dispatch = useDispatch();

  const [popupAddress, setPopupAddress] = useState(addresses);

  //setPopupAddress(identification);

  const getAddress = entry => {
    ////logger.log("entry", entry);
    return entry ? (
      <>
        {' '}
        {entry.address_1 && entry.address_1.length > 0
          ? entry.address_1 + ' '
          : ''}{' '}
        {entry.address_2 && entry.address_2.length > 0
          ? entry.address_2 + ' '
          : ''}
        {entry.city && entry.city.length > 0 ? entry.city + ', ' : ''}
        {entry.state && entry.state.length > 0 ? entry.state + ', ' : ''}
        {entry.zip && entry.zip.length > 0 ? entry.zip : ''}
      </>
    ) : (
      <></>
    );
  };

  const removeWitnessAddress = witness_id => {
    removeAddress(witness_id);
    let updatedAddress = _.filter(
      popupAddress,
      p => p.witness_id !== witness_id
    );
    setPopupAddress(updatedAddress);
    dispatch(showAlert('Success !!!', 'Witness address removed successfully'));
  };

  const addressCard = data => {
    let address = data.location;
    return (
      <Col lg={12} className="mb-3" key={data.witness_id}>
        <Card className="h-md-100">
          <Card.Header className="pb-0">
            <Flex justifyContent="between" alignItems="center">
              <Flex alignItems="center">
                <FontAwesomeIcon icon={faIdBadge} size="lg" className="me-3" />
                <h5 className="mb-0 mt-2">{title}</h5>
              </Flex>
              <Button
                variant="outline-danger"
                size="sm"
                onClick={() => removeWitnessAddress(data.witness_id)}
              >
                Remove
              </Button>
            </Flex>
            <hr className="my-0" />
          </Card.Header>

          <Card.Body
            as={Flex}
            alignItems="end"
            justifyContent="between"
            className="pt-1"
          >
            <div>
              <p className="mb-1">
                <span className="fw-semi-bold">Address:</span>{' '}
                {getAddress(address)}
              </p>
              {/* {address.notes && <p className='mb-1'><span className='fw-semi-bold'>Notes:</span> <i className='fs--1'>{address.notes}</i></p>} */}
            </div>
            <div></div>
          </Card.Body>
        </Card>
      </Col>
    );
  };

  return (
    <Row>
      {popupAddress?.length ? (
        <>
          {_.map(popupAddress, i => {
            return addressCard(i);
          })}
        </>
      ) : (
        <Col>
          <p className="text-center">No Address available</p>
        </Col>
      )}
    </Row>
  );
};
AddressView.propTypes = {
  address: PropTypes.any,
  removeAddress: PropTypes.func
};

export default AddressView;
