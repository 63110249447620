import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCardHeader from 'components/common/FalconCardHeader';
import SoftBadge from 'components/common/SoftBadge';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { refreshUserMaster } from 'stores/app';
import api from 'util/api';
import c from 'util/const';
import logger from 'util/logger';

const ProfileSettings = () => {
  const { userMaster } = useSelector(state => state.app);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    FirstName: userMaster.FirstName,
    LastName: userMaster.LastName,
    Telephone: userMaster.Telephone,
    SmsOptIn: !!userMaster.SmsOptIn,
    Address1: userMaster.Address1,
    Address2: userMaster.Address2,
    City: userMaster.City,
    State: userMaster.State,
    Province: userMaster.Province,
    PostalCode: userMaster.PostalCode,
    isUpdating: false
  });

  useEffect(() => {
    setFormData({
      FirstName: userMaster.FirstName,
      LastName: userMaster.LastName,
      Telephone: userMaster.Telephone,
      SmsOptIn: !!userMaster.SmsOptIn,
      Address1: userMaster.Address1,
      Address2: userMaster.Address2,
      City: userMaster.City,
      State: userMaster.State,
      Province: userMaster.Province,
      PostalCode: userMaster.PostalCode,
      isUpdating: false
    });
  }, [userMaster]);

  const states = c.states;
  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.value
    });
  };

  const doValidation = () => {
    let err = [];

    if (formData.FirstName.length === 0) {
      toast.error(`Please enter your First Name`, {
        theme: 'colored'
      });
    } else if (formData.LastName.length === 0) {
      toast.error(`Please enter your Last Name`, {
        theme: 'colored'
      });
    } else if (formData.Telephone.length === 0) {
      toast.error(`Please enter your mobile number`, {
        theme: 'colored'
      });
    } else return true;
  };

  const buildUpdateObject = () => {
    let updateParams = Object.assign({}, userMaster);
    if (userMaster.FirstName !== formData.FirstName) {
      updateParams.FirstName = formData.FirstName;
    }
    if (userMaster.LastName !== formData.LastName) {
      updateParams.LastName = formData.LastName;
    }
    if (userMaster.Telephone !== formData.Telephone) {
      updateParams.Telephone = formData.Telephone;
    }
    if (userMaster.SmsOptIn !== formData.SmsOptIn) {
      updateParams.SmsOptIn = formData.SmsOptIn
        ? moment().utc().format()
        : null;
    }
    if (userMaster.Address1 !== formData.Address1) {
      updateParams.Address1 = formData.Address1;
    }
    if (userMaster.Address2 !== formData.Address2) {
      updateParams.Address2 = formData.Address2;
    }
    if (userMaster.City !== formData.City) {
      updateParams.City = formData.City;
    }
    if (userMaster.State !== formData.State) {
      updateParams.State = formData.State;
    }
    if (userMaster.Province !== formData.Province) {
      updateParams.Province = formData.Province;
    }
    if (userMaster.PostalCode !== formData.PostalCode) {
      updateParams.PostalCode = formData.PostalCode;
    }
    return updateParams;
  };

  const save = () => {
    if (!doValidation()) {
      return;
    }

    let updateParams = buildUpdateObject();
    //If there are no changes, just pretend it worked.
    if (_.isEmpty(updateParams)) {
      toast.info(`You have made no changes`, {
        theme: 'colored'
      });
      return;
    }
    setFormData({
      ...formData,
      isUpdating: true
    });

    api.UserMaster.update(userMaster.GUID, updateParams)
      .then(res => {
        setFormData({
          ...formData,
          isUpdating: false
        });
        return dispatch(refreshUserMaster());
      })
      .then(res => {
        setFormData({
          ...formData,
          isUpdating: false
        });
        toast.success(`Profile updated successfully`, {
          theme: 'colored'
        });
      })
      .catch(err => {
        logger.log(err);
        if (err) {
          setFormData({
            ...formData,
            isUpdating: false
          });
          toast.error(
            `Unable to save', "There was a problem saving your information.  Please try again.`,
            {
              theme: 'colored'
            }
          );
        }
      });
  };

  const handleSubmit = e => {
    e.preventDefault();
  };

  return (
    <Card className="mt-4">
      <FalconCardHeader title="Profile Settings" />
      <Card.Body className="bg-light">
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={6} controlId="FirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="First Name"
                value={formData.FirstName}
                name="FirstName"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="LastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Last Name"
                value={formData.LastName}
                name="LastName"
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={12} controlId="Telephone">
              <Form.Label>SMS Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Telephone"
                value={formData.Telephone}
                name="Telephone"
                onChange={handleChange}
              />
            </Form.Group>
            {!formData.SmsOptIn ? (
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  name="SmsOptIn"
                  onChange={handleChange}
                  id="checkedCheckbox"
                  label="Tick this box to opt-in to receive SMS text messages from Jurat inc. We only send SMS text messages for account verification purposes Standard rates and data may apply. Reply STOP to opt out."
                  defaultChecked={!!formData.SmsOptIn}
                />
              </Form.Group>
            ) : (
              <Col className="mt-1">
                <SoftBadge bg="success">
                  <FontAwesomeIcon icon={faCheckCircle} /> You have opted in to
                  receive SMS text messages from Jurat inc.
                </SoftBadge>
              </Col>
            )}
          </Row>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={6} controlId="Address1">
              <Form.Label>Address 1</Form.Label>
              <Form.Control
                type="text"
                placeholder="Address line 1"
                value={formData.Address1}
                name="Address1"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="Address2">
              <Form.Label>Address 2</Form.Label>
              <Form.Control
                type="text"
                placeholder="Address line 2"
                value={formData.Address2}
                name="Address2"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="City">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                placeholder="City"
                value={formData.City}
                name="City"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="state">
              <Form.Label>State</Form.Label>
              <Form.Select
                type="text"
                name="State"
                onChange={handleChange}
                defaultValue={formData.State}
              >
                <option value="">Select state</option>
                {states.map(option => (
                  <option value={option.text} key={option.text}>
                    {option.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="Province">
              <Form.Label>County</Form.Label>
              <Form.Control
                type="text"
                placeholder="County Name"
                value={formData.Province}
                name="Province"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="PostalCode">
              <Form.Label>Postal Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Postal Code"
                value={formData.PostalCode}
                name="PostalCode"
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
          <div className="text-end">
            <Button
              variant="primary"
              type="submit"
              disabled={formData.isUpdating}
              onClick={save}
            >
              {formData.isUpdating ? (
                <Spinner size="sm" variant="light" />
              ) : (
                'Update'
              )}
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ProfileSettings;
