import { version } from 'config';
import packageInfo from '../../package.json';

const { homepage } = packageInfo;

export const faqs = [
  {
    id: 0,
    title: 'How long does my free trial last?',
    description:
      `<p class = "mb-1">
      All new accounts start off with a free 14-day trial. You have 14 days to explore our services and can create up to 5 free notary journal entries. After 14 days have passed or you have created 5 journal entries (which ever happens first), you will need to upgrade to an active subscription in order to continue using our services.
      </p>`
  },
  {
    id: 1,
    title: 'Does my trial subscription give me access to all of your features?',
    description:
      `<p class = "mb-1">
      Yes, you have access to all of our features during the trial subscription period, including our popular ID scanning technology, a customizable document library and more! Plus, you have full access to our both our mobile app (perfect for notaries who create their entries on the go) and our web app (preferred by notaries who want to log their entries from a computer).
      </p>`
  },
  {
    id: 2,
    title: 'How do I upgrade?',
    description:
      `<p class = "mb-1">
      Upgrade from a trial subscription by selecting one of the subscription options above. We offer monthly and yearly subscription plans
      </p>`
  },
  {
    id: 3,
    title: 'Are there any differences between the monthly and yearly subscriptions (other than price)?',
    description:
      `<p class = "mb-1">
      No, both monthly and yearly subscriptions are considered "active" and have full access to all of our features on both the mobile app as well as the web app.
      </p>`
  }
];
