import logger from "./logger";

const geolocationService = {

    detectCoords() {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(function (position) {
                logger.log("Geo Position", position);
                if (position) {
                    resolve(position.coords);
                }
                else {
                    reject(position);
                }

            });
        })
    }



}

export default geolocationService;