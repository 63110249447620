import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const VerifiedBadge = ({ classValue, label, placement = 'top' }) => {
  return (
    <OverlayTrigger placement={placement} overlay={<Tooltip>{label}</Tooltip>}>
      <><span>
        <FontAwesomeIcon
          icon="check-circle"
          transform="shrink-4 down-2"
          className={`me-1 ${classValue}`}
        />
      </span> <span className={classValue}>{label}</span></>
    </OverlayTrigger>
  );
};

VerifiedBadge.propTypes = {
  placement: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
  classValue: PropTypes.string.isRequired,
  label: PropTypes.string
};

export default VerifiedBadge;
