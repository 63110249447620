import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Card, Dropdown, ListGroup } from 'react-bootstrap';
import { isIterableArray } from 'helpers/utils';
import useFakeFetch from 'hooks/useFakeFetch';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Notification from 'components/notification/Notification';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { actionMarkNotificationRead } from 'stores/notification';

const NotificationDropdown = () => {
  // State
  const [newNotifications, setNewNotifications] = useState([]);
  const [earlierNotifications, setEarlierNotifications] = useState([]);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isAllRead, setIsAllRead] = useState(false);

  const dispatch = useDispatch();

  const { notifications } = useSelector(state => state.notification);

  // Handler
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.innerWidth < 1200 && setIsOpen(false);
    });
    setNewNotifications(notifications);
  }, []);

  useEffect(() => {
    let sortedNotification = _.sortBy(notifications, ['time']).reverse();
    setNewNotifications(sortedNotification);
    setUnreadNotificationCount(
      _.filter(notifications, n => {
        return n.unread;
      }).length
    );
  }, [notifications]);

  const markAsRead = e => {
    e.preventDefault();
    dispatch(actionMarkNotificationRead(null));
  };

  return (
    <Dropdown navbar={true} as="li" show={isOpen} onToggle={handleToggle}>
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className={classNames('px-0 nav-link', {
          'notification-indicator notification-indicator-primary': !isAllRead
        })}
      >
        <FontAwesomeIcon icon="bell" transform="shrink-6" className="fs-4" />
        {unreadNotificationCount > 0 && (
          <span className="notification-indicator-number">
            {unreadNotificationCount}
          </span>
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret dropdown-caret-bg">
        <Card
          className="dropdown-menu-notification dropdown-menu-end shadow-none"
          style={{ maxWidth: '20rem' }}
        >
          <FalconCardHeader
            className="card-header"
            title="Notifications"
            titleTag="h6"
            light={false}
            endEl={
              <Link
                className="card-link fw-normal"
                to="#!"
                onClick={markAsRead}
              >
                Mark all as read
              </Link>
            }
          />
          <ListGroup
            variant="flush"
            className="fw-normal fs--1 scrollbar"
            style={{ maxHeight: '19rem' }}
          >
            <div className="list-group-title">NEW</div>{' '}
            {isIterableArray(newNotifications) &&
              newNotifications.map(notification => (
                <ListGroup.Item key={notification.id} onClick={handleToggle}>
                  <Notification {...notification} flush />
                </ListGroup.Item>
              ))}
            {!isIterableArray(newNotifications) && (
              <ListGroup.Item key="no-new-notification">
                <p className="text-center text-muted">No new notifications</p>
              </ListGroup.Item>
            )}
            <div className="list-group-title">EARLIER</div>
            {isIterableArray(earlierNotifications) &&
              earlierNotifications.map(notification => (
                <ListGroup.Item key={notification.id} onClick={handleToggle}>
                  <Notification {...notification} flush />
                </ListGroup.Item>
              ))}
            {!isIterableArray(earlierNotifications) && (
              <ListGroup.Item key="no-earlier-notification">
                <p className="text-center text-muted">
                  No earlier notifications
                </p>
              </ListGroup.Item>
            )}
          </ListGroup>
          <div
            className="card-footer text-center border-top"
            onClick={handleToggle}
          >
            <Link className="card-link d-block" to="#!">
              View all
            </Link>
          </div>
        </Card>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NotificationDropdown;
