import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Modal } from "reactstrap";
import { Modal as ModalWizard } from 'react-bootstrap';


//This wrapper helps us manage the loading of modals.
//For now it's mostly just the animation of modals, but this could perform other helper
//functions later.
class ModalContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      doFade: false,
      timer: null,
      doShow: false
    }
  }

  componentDidMount() {
    var me = this;

    this.setState({ doShow: true })
    if (this.props.onRef) {
      this.props.onRef(this)
    }

    setTimeout(() => {
      this.setState({
        doFade: true
      })
    })

  }

  componentWillUnmount() {
    this.setState({ doShow: false })

    if (this.props.onRef) {
      this.props.onRef(undefined);
    }

    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  doClose(result) {
    let { callback, closeModal } = this.props;

    this.setState({ doShow: false });

    let timer = setTimeout(() => {
      this.setState({ timer: null });

      if (callback) {
        callback(result);
      }

      closeModal();
    }, 200)

    this.setState({ timer });
  }

  render() {
    let { children, zIndex, size, className } = this.props;
    let { doFade } = this.state;

    return (size == 'lg' ?
      <Modal isOpen={this.state.doShow} size={size} className={className} zIndex={zIndex}>
        {children}
      </Modal> :
      <ModalWizard show={this.state.doShow} centered dialogClassName="wizard-modal">
        {children}
      </ModalWizard>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

ModalContainer.propTypes = {
  onRef: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  zIndex: PropTypes.number.isRequired,
  size: PropTypes.string.isRequired,
  className: PropTypes.string
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
