import { faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import envelope from 'assets/img/icons/spot-illustrations/16.png';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

const VerificationEmail = ({ stateData, setStateData, userMaster, TitleTag, startEmailVerification }) => (
    <>

        {userMaster.EmailVerified && <Flex direction='column'>
            <p><img
                className="d-block mx-auto mb-4"
                src={envelope}
                alt="sent"
                width={100}
            /></p>
            <TitleTag className="text-success"><FontAwesomeIcon icon={faCheckCircle} className="text-success" /> Email Verified</TitleTag>
            <p>
                Your email address <span className="fw-bold">{userMaster.Email}</span> has been verified. Thank you!
            </p>
        </Flex>}

        {!userMaster.EmailVerified && <Flex direction='column'>
            <p><img
                className="d-block mx-auto mb-4"
                src={envelope}
                alt="sent"
                width={100}
            />
            </p>
            {userMaster.EmailVerificationRequested && <Flex direction='column'>

                <TitleTag className="text-warning fw-bold"><FontAwesomeIcon icon={faExclamationTriangle} className="text-warning" /> Email Unverified</TitleTag>
                <p>
                    A verification email has been sent to the following address: <span className='fw-bold'>{userMaster.Email}</span>.
                </p>
                <div className="text-center mt-3">
                    {stateData.isFinishingEmailVerification ? <Spinner size='sm' variant='primary' /> : <Button onClick={() => startEmailVerification()}
                        disabled={stateData.didSendEmailVerification}
                        className=""
                        color="primary">Send another Verification Email</Button>}
                </div>
            </Flex>
            }

            {!userMaster.EmailVerificationRequested && <Flex direction='column'>
                <p> Secure your account by verifying your email address: <span className="font-weight-bold">
                    {userMaster.Email}
                </span></p>

                <div className="text-center mt-3">
                    {stateData.isFinishingEmailVerification ? <Spinner size='sm' variant='primary' /> : <Button onClick={() => startEmailVerification()}
                        disabled={stateData.didSendEmailVerification}
                        className=""
                        color="primary">Verify Email Address</Button>}
                </div>
            </Flex>
            }

        </Flex>}
    </>
);

VerificationEmail.propTypes = {
    userMaster: PropTypes.object.isRequired,
    startEmailVerification: PropTypes.func.isRequired
};

VerificationEmail.defaultProps = { TitleTag: 'h4' };

export default VerificationEmail;
