import { createSlice } from '@reduxjs/toolkit';
import api from 'util/api';
import apiHelper from 'util/apiHelper';
import c from 'util/const';
import juratLocalStorage from 'util/local-storage';
import logger from 'util/logger';

export const docsSlice = createSlice({
  name: 'docs',
  initialState: {
    documentList: null,
    documentCollections: null,
  },

  reducers: {
    setDocumentList: (state, action) => {
      state.documentList = action.payload
    },

    setDocumentCollections: (state, action) => {
      state.documentCollections = action.payload
    },

    reset: (state, action) => {
      state.documentList = null;
      state.documentCollections = null;
    }
  },
});

let { setDocumentList, setDocumentCollections } = docsSlice.actions;

let actions = {
  actionSetDocumentList: (payload) => {
    return (dispatch, getState) => {
      return Promise.all([
        dispatch(setDocumentList(payload))
      ])
    }
  },

  actionSetDocumentCollections: (payload) => {
    return (dispatch, getState) => {
      return Promise.all([
        dispatch(setDocumentCollections(payload))
      ])
    }
  },

  refreshDocumentList() {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        let userGuid = _.get(getState(), 'app.userMaster.GUID');
        Promise.all([
          api.DocumentType.list(),
        ])
          .then((res) => {
            let serverResult = res[0];
            juratLocalStorage.set(c.storage.docListBackup, serverResult);
            dispatch(setDocumentList(serverResult));
            resolve(res);
          })
          .catch((err) => {
            if (apiHelper.isConnectionError(err)) {
              Promise.all([
                juratLocalStorage.get(c.storage.docListBackup),
              ])
                .then((res) => {
                  let cacheRes = res[0];
                  if (cacheRes) {
                    dispatch(setDocumentList(cacheRes));
                    resolve(cacheRes);
                  }
                  else {
                    logger.log('no doc list backup', cacheRes);
                    reject(err);
                  }
                })
                .catch((fetchErr) => {
                  logger.log('error fetching doc list backup', fetchErr);
                  //return original error.
                  reject(err);
                })
            }
            else {
              reject(err);
            }
          })
      })
    }
  },


  refreshDocumentCollections() {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        api.DocumentCollection.list()
          .then((res) => {
            dispatch(setDocumentCollections(res));
            juratLocalStorage.set(c.storage.docCollectionsBackup, res);
            resolve(res);
          })
          .catch((err) => {
            logger.warn('error refreshing document collections', err);
            if (apiHelper.isConnectionError(err)) {
              juratLocalStorage.get(c.storage.docCollectionsBackup)
                .then((cacheRes) => {
                  if (cacheRes) {
                    dispatch(setDocumentCollections(cacheRes));
                    resolve(cacheRes);
                  }
                  else {
                    logger.log('no doc collection backup', cacheRes);
                    reject(err);
                  }
                })
                .catch((fetchErr) => {
                  logger.log('error fetching doc collection backup', fetchErr);
                  //return original error.
                  reject(err);
                })
            }
            else {
              reject(err);
            }
          })
      })
    }
  },


  actionReset: () => {
    return (dispatch, getState) => {
      dispatch(reset());
    }
  },

}

export const { actionSetDocumentList, actionSetDocumentCollections, refreshDocumentList, refreshDocumentCollections, actionReset } = actions;

export default docsSlice.reducer;
