import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCloseButton from 'components/common/FalconCloseButton';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { showAlert } from 'stores/modal';

const FingerprintModal = ({
  author,
  showFingerprintForm,
  setShowFingerprintForm,
  setFingerprintData
}) => {
  const [enableFingerprintSave, setEnableFingerprintSave] = useState(false);

  let fingerprintPad = {};

  const clearFingerprintPad = () => {
    //fingerprintPad.clear();
  };

  const saveSignerFingerprint = () => {
    let fingerprintImage = fingerprintPad
      .getTrimmedCanvas()
      .toDataURL('image/png');
    if (fingerprintImage.length === 0) {
      dispatch(
        showAlert(
          'Unable to get Fingerprint',
          'There was a problem generating this fingerprint image.  Please try again.'
        )
      );
      return;
    }

    let fingerprintDataObject = {
      fingerprintForId: fingerprintForId,
      data: _.replace(fingerprintImage, 'data:image/png;base64,', ''),
      content_type: 'image/png',
      dataUri: fingerprintImage
    };

    setFingerprintData(fingerprintDataObject);

    //dispatch(actionAddFingerprint({ signer_id: signer.signer_id, fingerprint: image }));
    //this.props.newEntry.saveCurrentStateToDrafts(c.entrySteps.STEP_4);
    //this.props.newEntry.logSigningActionTaken(`Fingerprint added for ${filters.formatNameObj(foundSigner)}.`);

    setShowFingerprintForm(false);
  };

  return (
    <Modal
      show={showFingerprintForm}
      onHide={() => setShowFingerprintForm(false)}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title> {author}'s fingerprint</Modal.Title>
        <FalconCloseButton onClick={() => setShowFingerprintForm(false)} />
      </Modal.Header>
      <Modal.Body>
        <Flex justifyContent="center">
          <p className="text-warning primary-bg-warning text-center">
            <FontAwesomeIcon icon={faExclamationTriangle} size="2x" />
            <br />
            <span>
              Fingerprint capturing is not available on the web app.
              <br />
              Please use our mobile app for fingerprint capture functionality.
            </span>
          </p>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100">
          <Flex justifyContent="center">
            <Button
              className="mx-1"
              variant="danger"
              onClick={() => setShowFingerprintForm(false)}
            >
              Close{' '}
            </Button>{' '}
            {/* <Button
              className="mx-1"
              variant="secondary"
              onClick={() => clearFingerprintPad()}
            >
              Clear{' '}
            </Button>{' '} */}
            {enableFingerprintSave && (
              <Button
                className="mx-1"
                variant="primary"
                onClick={() => saveSignerFingerprint()}
              >
                {' '}
                Save{' '}
              </Button>
            )}{' '}
          </Flex>{' '}
        </div>{' '}
      </Modal.Footer>{' '}
    </Modal>
  );
};
FingerprintModal.propTypes = {
  author: PropTypes.string,
  fingerprintForId: PropTypes.string,
  showFingerprintForm: PropTypes.bool,
  setShowFingerprintForm: PropTypes.func,
  setFingerprintData: PropTypes.func
};

export default FingerprintModal;
