import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

class ConfirmDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  closeModal(res) {
    let { close } = this.props;
    close(res);
  }

  render() {
    let { modalProps } = this.props;

    return (
      <>
        <ModalHeader toggle={this.closeModal.bind(this, null)}>
          {modalProps.title}
        </ModalHeader>
        <ModalBody>{modalProps.message}</ModalBody>
        <ModalFooter>
          <Button
            variant="outline-danger"
            onClick={this.closeModal.bind(this, null)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={this.closeModal.bind(this, true)}>
            OK
          </Button>
        </ModalFooter>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

ConfirmDialog.propTypes = {
  close: PropTypes.func.isRequired,
  modalProps: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDialog);
