import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import AppContext from 'context/Context';
import React, { useContext } from 'react';
import { Card, Dropdown, Spinner, Table } from 'react-bootstrap';
import SessionByUsesChart from './SessionByUsesChart';
import TableRow from './TableRow';
import { useState } from 'react';

const SessionByUses = ({ title, data, labelPrefix }) => {
  const [mapType, setMapType] = useState('pie');
  const CardDropdown = ({
    btnRevealClass,
    drop,
    children,
    icon = 'ellipsis-h'
  }) => {
    const {
      config: { isRTL }
    } = useContext(AppContext);

    return (
      <Dropdown
        className="font-sans-serif btn-reveal-trigger"
        align={isRTL ? 'start' : 'end'}
        drop={drop}
      >
        <Dropdown.Toggle
          variant="link"
          size="sm"
          data-boundary="viewport"
          className={classNames('text-600', {
            [btnRevealClass]: btnRevealClass,
            'btn-reveal': !btnRevealClass
          })}
        >
          <FontAwesomeIcon icon={icon} className="fs--2" />
        </Dropdown.Toggle>
        <Dropdown.Menu className="border py-0">
          {children}
          {!children && (
            <div className="py-2">
              <Dropdown.Item onClick={() => setMapType('pie')} className={mapType == 'pie' && 'text-primary'}>Pie</Dropdown.Item>
              <Dropdown.Item onClick={() => setMapType('line')} className={mapType == 'line' && 'text-primary'}>line</Dropdown.Item>
              <Dropdown.Item onClick={() => setMapType('bar')} className={mapType == 'bar' && 'text-primary'}>Bar</Dropdown.Item>
              {/* <Dropdown.Divider />
              <Dropdown.Item className="text-primary">Download</Dropdown.Item> */}
            </div>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };
  return (
    <Card className="h-100">
      <FalconCardHeader
        title={title}
        titleTag="h6"
        className="py-2"
        light
        endEl={<CardDropdown />}
      />
      <Card.Body
        as={Flex}
        direction="column"
        justifyContent="between"
        className="py-0"
      >
        {data ? <div>
          <div className="my-auto py-3 pb-5 pb-md-5 py-md-3">
            <SessionByUsesChart data={data} mapType={mapType} labelPrefix={labelPrefix} />
          </div>
          <div className="border-top overflow-auto" style={{ height: 180 }}>
            <Table size="sm" className="mb-0">
              <tbody>
                {data.map(item => (
                  <TableRow key={item.label} data={item} labelPrefix={labelPrefix} />
                ))}
              </tbody>
            </Table>
          </div>
        </div> : <div className='d-flex h-100 align-items-center justify-content-center'><Spinner variant='primary' /></div>}
      </Card.Body>
    </Card>
  );
};

SessionByUses.propTypes = {
  //data: PropTypes.arrayOf(PropTypes.shape(TableRow.propTypes.data)).isRequired
};

export default SessionByUses;
