import { faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import {
  faCalendarAlt,
  faCheckCircle,
  faEdit,
  faExclamationCircle,
  faExclamationTriangle,
  faFileAlt,
  faFolder,
  faInfoCircle,
  faPlus,
  faPlusCircle,
  faSave,
  faStar,
  faTrashAlt,
  faUserCheck
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/common/Avatar';
import FalconCloseButton from 'components/common/FalconCloseButton';
import Flex from 'components/common/Flex';
import QuantityController from 'components/utilities/QuantityController';
import segmentHelper from 'helpers/segment-helper';
import { currencyCodeToSign } from 'helpers/utils';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  ListGroup,
  ListGroupItem,
  Modal,
  Offcanvas,
  Row,
  Spinner
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { refreshDocumentCollections } from 'stores/docs';
import { showAlert, showConfirm } from 'stores/modal';
import {
  actionAddNotarizationDocumentsMass,
  actionLogSigningAction,
  actionSetNotarizationDetails
} from 'stores/signing';
import Colors from 'util/Colors';
import api from 'util/api';
import apiHelper from 'util/apiHelper';
import c from 'util/const';
import enums from 'util/enums';
import filters from 'util/filters';
import idGenerator from 'util/idGenerator';
import logger from 'util/logger';
import utils from 'util/utils';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from 'components/pages/library/signing-package/StrictModeDroppable';

const Step3 = ({ saveSigningToDraft }) => {
  const dispatch = useDispatch();
  const additionFeeTypes = c.additionFeeTypes;

  const { signers, notarization_details, notarization_documents } = useSelector(
    state => state.signing
  );

  const [documentList, setDocumentList] = useState([]);
  const [documentCollections, setDocumentCollections] = useState([]);

  const [sortByDoc, setSortByDoc] = useState(null);
  const [sortByCollection, setSortByCollection] = useState(null);

  const [orderByDoc, setOrderByDoc] = useState(true);
  const [orderByCollection, setOrderByCollection] = useState(true);

  const [documentListFiltered, setDocumentListFiltered] = useState([]);
  const [documentCollectionsFiltered, setDocumentCollectionsFiltered] =
    useState([]);

  const [selectedDocumentList, setSelectedDocumentList] = useState(
    notarization_documents || []
  );
  const [NotaryFee, setNotaryFee] = useState(
    notarization_details?.notary_fee ? notarization_details.notary_fee : ''
  );
  const [SigningFees, setSigningFees] = useState(
    notarization_details?.Signing_Fees ? notarization_details.Signing_Fees : []
  );
  const [AdditionalData, setAdditionalData] = useState(
    notarization_details?.notes ? notarization_details.notes : ''
  );

  const [collectionName, setCollectionName] = useState(null);

  const [docSearchKeyword, setDocSearchKeyword] = useState('');
  const [collectionSearchKeyword, setCollectionSearchKeyword] = useState('');

  const [newDocTypeName, setNewDocTypeName] = useState(null);
  const [newDocNotarizationType, setNewDocNotarizationType] = useState(null);
  const [newNotarizationTypeOther, setNewNotarizationTypeOther] =
    useState(null);

  const [loadingDocumentCollectionList, setLoadingDocumentCollectionList] =
    useState(false);
  const [loadingDocumentList, setLoadingDocumentList] = useState(false);
  const [savingDocs, setSavingDocs] = useState(false);
  const [deletingDocs, setDeletingDocs] = useState(false);

  const [savingSigningPackage, setSavingSigningPackage] = useState(false);
  const [startSavingPackageCollection, setStartSavingPackageCollection] =
    useState(0);

  const [showDocsCanvas, setShowDocsCanvas] = useState(false);
  const [showDocPackagesCanvas, setShowDocPackagesCanvas] = useState(false);

  const [openNewDocForm, setOpenNewDocForm] = useState(false);
  const [showDocNotarizationTypeUpdate, setShowDocNotarizationTypeUpdate] =
    useState(false);
  const [updateUiDocs, setupdateUiDocs] = useState(null);

  const hideDocCanvas = () => setShowDocsCanvas(false);
  const hideDocPackageCanvas = () => setShowDocPackagesCanvas(false);

  const [documentTypesLoadError, setDocumentTypesLoadError] = useState(false);
  const [signingPackagesLoadError, setSigningPackagesLoadError] =
    useState(false);
  const [toggleFavorite, setToggleFavorite] = useState(false);

  const notarization_type_picker_opts = [
    {
      name: enums.NOTARIZATION_TYPE.ACKNOWLEDGE,
      text: enums.NOTARIZATION_TYPE.ACKNOWLEDGE
    },
    {
      name: enums.NOTARIZATION_TYPE.JURAT,
      text: enums.NOTARIZATION_TYPE.JURAT
    },
    {
      name: enums.NOTARIZATION_TYPE.COPY_CERTIFICATION,
      text: enums.NOTARIZATION_TYPE.COPY_CERTIFICATION
    },
    {
      name: enums.NOTARIZATION_TYPE.SIGNATURE_WITNESSING,
      text: enums.NOTARIZATION_TYPE.SIGNATURE_WITNESSING
    },
    {
      name: enums.NOTARIZATION_TYPE.OATH_AFFIRMATION,
      text: enums.NOTARIZATION_TYPE.OATH_AFFIRMATION
    },
    {
      name: enums.NOTARIZATION_TYPE.OTHER,
      text: enums.NOTARIZATION_TYPE.OTHER
    }
  ];

  useEffect(() => {
    dispatch(
      actionSetNotarizationDetails({
        notary_fee: NotaryFee,
        currency: 'USD',
        notes: AdditionalData,
        pictures: [],
        Signing_Fees: SigningFees
      })
    );
  }, [NotaryFee, AdditionalData, SigningFees]);

  useEffect(() => {
    ////logger.log('Dispatching Selected Documents', selectedDocumentList);
    dispatch(actionAddNotarizationDocumentsMass(selectedDocumentList));
    saveSigningToDraft();
  }, [selectedDocumentList]);

  const handleQuantityChange = e => {
    ////logger.log(e);
    //setProductCount(parseInt(e.target.value < 0 ? 0 : e.target.value));
  };

  const handleQuantityIncrease = item => {
    appendDocuments(true, item);
  };

  const handleQuantityDecrease = item => {
    let newItem = _.find(selectedDocumentList, d => {
      return d.document_type_guid == item.GUID;
    });
    appendDocuments(false, newItem);
  };

  useEffect(() => {
    setDocumentListFiltered(documentList);
    setDocumentCollectionsFiltered(documentCollections);
  }, []);

  useEffect(() => {
    setDocumentListFiltered(
      _.filter(documentList, d => {
        return (
          docSearchKeyword == null ||
          d.Value.toString()
            .toLowerCase()
            .indexOf(docSearchKeyword.toLowerCase()) !== -1 ||
          d.NotarizationType.toString()
            .toLowerCase()
            .indexOf(docSearchKeyword.toLowerCase()) !== -1
        );
      })
    );
  }, [docSearchKeyword]);

  useEffect(() => {
    setDocumentCollectionsFiltered(
      _.filter(documentCollections, d => {
        return (
          collectionSearchKeyword == null ||
          d.Name.toString()
            .toLowerCase()
            .indexOf(collectionSearchKeyword.toLowerCase()) !== -1
        );
      })
    );
  }, [collectionSearchKeyword]);

  useEffect(() => {
    //this.props.state.app.segmentClient.track('Save signing package');
    if (collectionName && collectionName.length) {
      let collectionPackageName = collectionName;
      setCollectionName(null);
      let doc_type_guids = _.map(
        selectedDocumentList,
        d => d.document_type_guid
      );
      let notaryFee = parseFloat(NotaryFee);
      setSavingSigningPackage(true);
      api.DocumentCollection.add(
        collectionPackageName,
        '',
        notaryFee,
        AdditionalData,
        doc_type_guids,
        SigningFees
      )
        .then(res => {
          setSavingSigningPackage(false);
          return dispatch(refreshDocumentCollections());
        })
        .then(() => {
          toast.success('Signing Package Saved', {
            theme: 'colored',
            position: 'bottom-center',
            icon: faCheckCircle
          });
          return dispatch(refreshDocumentCollections());
        })
        .catch(err => {
          setSavingSigningPackage(false);
          if (apiHelper.isConnectionError(err)) {
            //dialog.showConnectionErr();
          } else {
            toast.error('Unable to Save Package', {
              theme: 'colored',
              position: 'bottom-center',
              icon: faExclamationTriangle
            });
            // dialog.alert(
            //   'Unable to Save Package',
            //   'There was a problem saving your Signing Package.  Please try again.',
            // );
          }
        });
    }
  }, [startSavingPackageCollection]);

  function getNewDocFromRaw(item) {
    //////logger.log("getNewDocFromRaw", item);
    let newItem = {};
    newItem.document_id = idGenerator.getDocumentId();
    newItem.document_type_guid = item.GUID;
    newItem.user_master_guid = item.UserMasterGUID;
    newItem.document_type = item.Value;
    newItem.notarization_type = item.NotarizationType;
    newItem.created = item.Created;
    newItem.notes = null;
    newItem.signer_ids = signers ? _.map(signers, s => s.signer_id) : [];
    return newItem;
  }

  const appendDocuments = (e, item) => {
    logger.log('append document', item);
    if (e) {
      let parsedItem = getNewDocFromRaw(item);
      setSelectedDocumentList([...selectedDocumentList, parsedItem]);
      dispatch(
        actionLogSigningAction(
          `Document added ${item.document_type || item.Value} .`
        )
      );
    } else {
      let selectedDocumentListUpdated = _.filter(selectedDocumentList, d => {
        return d.document_id !== item.document_id;
      });
      setSelectedDocumentList(selectedDocumentListUpdated);
      dispatch(
        actionLogSigningAction(
          `Document removed ${item.document_type || item.Value}.`
        )
      );
    }
    saveSigningToDraft();
  };

  const manageSignerOnDoc = (e, document_id, signer_id) => {
    //////logger.log('manageSignerOnDoc', e);
    let updatedSelctedDocumentList = [];

    let foundSigner = _.find(signers, s => s.signer_id === signer_id);
    selectedDocumentList.map(doc => {
      if (doc.document_id === document_id) {
        let newDoc = Object.assign({}, doc);
        if (doc.signer_ids.indexOf(foundSigner.signer_id) >= 0) {
          let updatedSigners = _.filter(doc.signer_ids, sid => {
            return sid !== signer_id;
          });
          newDoc.signer_ids = updatedSigners;
          dispatch(
            actionLogSigningAction(
              `Signer ${filters.formatNameObj(
                foundSigner
              )} removed from document ${doc.document_type}.`
            )
          );
        } else {
          newDoc.signer_ids = _.concat([foundSigner.signer_id], doc.signer_ids);
          dispatch(
            actionLogSigningAction(
              `Signer ${filters.formatNameObj(foundSigner)} added to document ${
                doc.document_type
              }.`
            )
          );
        }
        updatedSelctedDocumentList = [...updatedSelctedDocumentList, newDoc];
      } else {
        updatedSelctedDocumentList = [...updatedSelctedDocumentList, doc];
      }
    });

    setSelectedDocumentList(updatedSelctedDocumentList);
    saveSigningToDraft();
  };

  const updateSelectedDocumentNotes = (document_id, notes) => {
    let updatedSelctedDocumentList = [];
    selectedDocumentList.map(doc => {
      if (doc.document_id === document_id) {
        let newDoc = Object.assign({}, doc);
        newDoc.notes = notes;
        updatedSelctedDocumentList = [...updatedSelctedDocumentList, newDoc];
      } else {
        updatedSelctedDocumentList = [...updatedSelctedDocumentList, doc];
      }
    });
    setSelectedDocumentList(updatedSelctedDocumentList);
    //setShowDocNotarizationTypeUpdate(false);
  };

  const updateDocNotarizationType = () => {
    let updatedSelctedDocumentList = [];
    selectedDocumentList.map(doc => {
      if (doc.document_id === updateUiDocs.document_id) {
        let newDoc = Object.assign({}, doc);
        let type = updateUiDocs.NotarizationType;
        if (!newDocNotarizationType || newDocNotarizationType.length == 0) {
          toast.warning('Document Notarization type required', {
            theme: 'colored',
            position: 'bottom-center',
            icon: faExclamationCircle
          });
          return;
        }
        if (newDocNotarizationType.length) {
          type = newDocNotarizationType;
          if (newDocNotarizationType === enums.NOTARIZATION_TYPE.OTHER) {
            if (
              !newNotarizationTypeOther ||
              newNotarizationTypeOther.length == 0
            ) {
              toast.warning('Other name required', {
                theme: 'colored',
                position: 'bottom-center',
                icon: faExclamationCircle
              });
              return;
            }
            type = newNotarizationTypeOther;
          }
        }
        newDoc.notarization_type = type;
        updatedSelctedDocumentList = [...updatedSelctedDocumentList, newDoc];
        dispatch(
          actionLogSigningAction(
            `Notarization type for ${doc.document_type} document changed from ${doc.notarization_type} to ${type}.`
          )
        ).then(res => {
          setShowDocNotarizationTypeUpdate(false);
        });
      } else {
        updatedSelctedDocumentList = [...updatedSelctedDocumentList, doc];
      }
    });

    setSelectedDocumentList(updatedSelctedDocumentList);
  };

  const editNotarizationType = item => {
    logger.log('editing doc', item);
    setupdateUiDocs(item);
    setNewDocTypeName(item.document_type);
    let isFromList = _.find(notarization_type_picker_opts, d => {
      return d.name === item.notarization_type;
    });
    if (!isFromList) {
      setNewDocNotarizationType(enums.NOTARIZATION_TYPE.OTHER);
      setNewNotarizationTypeOther(item.notarization_type);
    } else {
      setNewDocNotarizationType(item.notarization_type);
      setNewNotarizationTypeOther(null);
    }
    setShowDocNotarizationTypeUpdate(true);
  };

  const setDocCollectionSortBy = flag => {
    let order = orderByCollection ? 'asc' : 'desc';
    setOrderByCollection(!orderByCollection);
    setSortByCollection(flag);
    if (flag === 'name') {
      setDocumentCollectionsFiltered(
        _.orderBy(documentCollections, ['Name'], [order])
      );
    } else if (flag === 'Amount') {
      setDocumentCollectionsFiltered(
        _.orderBy(documentCollections, ['NotaryFee'], [order])
      );
    } else if (flag === 'favorite') {
      setDocumentCollectionsFiltered(
        _.orderBy(documentCollections, ['DocumentTypes'], [order])
      );
    } else if (flag === 'SortOrder') {
      setDocumentCollectionsFiltered(
        _.orderBy(documentCollections, ['SortOrder'], [order])
      );
    }
  };

  const setDocSortBy = flag => {
    logger.log(documentList);
    let order = orderByDoc ? 'asc' : 'desc';
    setOrderByDoc(!orderByDoc);
    setSortByDoc(flag);
    if (flag === 'name') {
      setDocumentListFiltered(_.orderBy(documentList, ['Value'], [order]));
    } else if (flag === 'type') {
      setDocumentListFiltered(
        _.orderBy(documentList, ['NotarizationType'], [order])
      );
    } else if (flag === 'created') {
      setDocumentListFiltered(_.orderBy(documentList, ['Created'], [order]));
    } else if (flag === 'favorite') {
      setDocumentListFiltered(_.orderBy(documentList, ['IsFavorite'], [order]));
    }
  };

  const getDocSignerView = (document_id, signer_id, checked) => {
    let found = _.find(signers, s => s.signer_id === signer_id);
    if (found) {
      return (
        <Flex
          key={_.uniqueId('signer-row')}
          className="me-2 border rounded-2 px-2 py-1 mt-1"
          justifyContent="center"
          alignItems="center"
        >
          <div className="me-2 fs--1">
            {/* <MdClose
              color={Colors.danger.color}
              size={14}
              onClick={() => manageSignerOnDoc(document_id, signer_id)}
            /> */}
            <Form.Check
              type="checkbox"
              id={'signer-check-box-' + found.signer_id}
              defaultChecked={checked}
              onChange={e => manageSignerOnDoc(e, document_id, signer_id)}
            />
          </div>
          <Avatar name={found.first_name + ' ' + found.last_name} size="s" />
          <div className="flex-1 chat-contact-body ms-1 d-md-none d-lg-block">
            <Flex justifyContent="between">
              <h6 className="mb-0 chat-contact-title">
                {found.first_name + ' ' + found.last_name}
              </h6>
            </Flex>
          </div>
        </Flex>
      );
    }
  };

  const updateDocumentType = e => {
    setToggleFavorite(e.GUID);
    let data = _.cloneDeep(e);
    data.IsFavorite = !e.IsFavorite;
    api.DocumentType.update(e.GUID, data)
      .then(res => {
        setToggleFavorite(false);
        refreshDocumentList();
      })
      .catch(err => {
        setToggleFavorite(false);
      });
  };

  const selectPackageDocs = docGUID => {
    let selectedPackage = _.filter(documentCollections, d => {
      return d.GUID === docGUID;
    });
    let sp = selectedPackage[0];
    ////logger.log('Package Used', sp);
    setNotaryFee(sp.NotaryFee || '');

    let prevAddedList = [];
    if (sp.DocumentTypes && sp.DocumentTypes.length) {
      sp.DocumentTypes.map(doc => {
        prevAddedList = [...prevAddedList, getNewDocFromRaw(doc)];
      });
    }

    dispatch(actionLogSigningAction(`Signing package ${sp.Name} loaded.`));

    setSelectedDocumentList(prevAddedList || []);
    setAdditionalData(sp.AdditionalData || null);
    setShowDocPackagesCanvas(false);
    setSigningFees(utils.getSigningFeeObject(sp?.SigningFees) || []);
    segmentHelper.track(segmentHelper.events.SIGNING_LOAD_SIGNING_PACKAGE);
  };

  const removeDocumentItemClick = item => {
    dispatch(
      showConfirm(
        'Are you sure?',
        <div>
          <p>Are you sure you want to delete this document?</p>
          <p>
            Removing docs will removed docs from current Signing Packages if it
            used, it will be removed from there as well. No signing drafts or
            saved signings will be affected.
          </p>
        </div>,
        res => {
          if (res) {
            setDeletingDocs(item.GUID);
            api.DocumentType.remove(item.GUID)
              .then(res => {
                setDeletingDocs(null);
                segmentHelper.track(
                  segmentHelper.events.SIGNING_DOCUMENT_REMOVED_CLICK
                );
                toast.success('Document removed successfully', {
                  theme: 'colored',
                  position: 'bottom-center',
                  icon: faCheckCircle
                });
                removeDocFromSelection(item);
                return refreshDocumentList();
              })
              .catch(err => {
                setDeletingDocs(null);
                ////logger.log('error removing saved location', err);
                if (apiHelper.isConnectionError(err)) {
                } else {
                  setDeletingDocs(null);
                  dispatch(
                    showAlert(
                      'Unable to Remove Document Type',
                      'There was a problem removing your document type.  Please try again.'
                    )
                  );
                }
              });
          }
        }
      )
    );
  };

  const removeDocFromSelection = item => {
    logger.log(
      'removeDocFromSelection selectedDocumentList',
      selectedDocumentList
    );
    logger.log('removeDocFromSelection', item);
    let selectedDocumentListUpdated = _.filter(selectedDocumentList, d => {
      return d.document_type_guid !== item.GUID;
    });
    setSelectedDocumentList(selectedDocumentListUpdated);
    dispatch(actionLogSigningAction(`Document removed ${item.document_type}.`));
    saveSigningToDraft();
  };

  const removeCollectionItemClick = collectionGUID => {
    dispatch(
      showConfirm(
        'Are you sure?',
        <div>
          <p>Are you sure you want to delete this collection package?</p>
        </div>,
        res => {
          if (res) {
            api.DocumentCollection.remove(collectionGUID)
              .then(res => {
                toast.success(
                  'Document Collection Package removed successfully',
                  {
                    theme: 'colored',
                    position: 'bottom-center',
                    icon: faCheckCircle
                  }
                );
                segmentHelper.track(
                  segmentHelper.events.SIGNING_PACKAGE_REMOVED
                );
                return dispatch(refreshDocumentCollections());
              })
              .catch(err => {
                ////logger.log('error removing saved location', err);
                if (apiHelper.isConnectionError(err)) {
                } else {
                  dispatch(
                    showAlert(
                      'Unable to Remove Document collection',
                      'There was a problem removing your document collection.  Please try again.'
                    )
                  );
                }
              });
          }
        }
      )
    );
  };

  const saveCollectionClick = () => {
    if (selectedDocumentList.length > c.limits.MAX_COLLECTION_DOCS) {
      dispatch(
        showAlert(
          'Too Many Documents',
          'Sorry for the inconvenience, but there is a maximum of ' +
            c.limits.MAX_COLLECTION_DOCS +
            ' documents in a Signing Package.'
        )
      );
      return;
    }

    dispatch(
      showConfirm(
        'Please enter a name for this Signing Package',
        <div>
          <Form.Group className="mb-3">
            <Form.Control
              placeholder="Enter signing package name"
              value={collectionName}
              name="locationName"
              // onChange={(evt) => { dispatch(actionSetLocationName(evt.target.value)); }}
              onChange={evt => {
                setCollectionName(evt.target.value);
              }}
              type="text"
            />
          </Form.Group>
        </div>,
        res => {
          if (res) {
            setStartSavingPackageCollection(startSavingPackageCollection + 1);
            segmentHelper.track(
              segmentHelper.events.SIGNING_SAVE_SIGNING_PACKAGE
            );
          }
        }
      )
    );
  };

  const saveDocType = () => {
    if (!newDocTypeName || newDocTypeName.length == 0) {
      toast.warning('Document name required', {
        theme: 'colored',
        position: 'bottom-center',
        icon: faExclamationCircle
      });
      return;
    } else if (!newDocNotarizationType || newDocNotarizationType.length == 0) {
      toast.warning('Document Notarization type required', {
        theme: 'colored',
        position: 'bottom-center',
        icon: faExclamationCircle
      });
      return;
    }
    if (newDocTypeName.length && newDocNotarizationType.length) {
      let type = newDocNotarizationType;
      if (newDocNotarizationType === enums.NOTARIZATION_TYPE.OTHER) {
        if (!newNotarizationTypeOther || newNotarizationTypeOther.length == 0) {
          toast.warning('Other name required', {
            theme: 'colored',
            position: 'bottom-center',
            icon: faExclamationCircle
          });
          return;
        }
        type = newNotarizationTypeOther;
      }
      setSavingDocs(true);
      return new Promise((resolve, reject) => {
        api.DocumentType.add(newDocTypeName, type)
          .then(res => {
            segmentHelper.track(segmentHelper.events.DOCUMENT_TYPE_CREATED);
            setSavingDocs(false);
            setOpenNewDocForm(false);
            resolve(res);
            refreshDocumentList();
          })
          .catch(err => {
            setSavingDocs(false);
            if (apiHelper.isConnectionError(err)) {
              ////logger.log('got offline error while saving doc type', err);
              let { user } = this.props.state.app;
              resolve(
                offlineDocHelper.addOfflineDocType(
                  user.GUID,
                  documentType,
                  notarizationType
                )
              );
            } else {
              setSavingDocs(false);
              reject(err);
            }
          });
      });
    }
  };

  const appendSigningFees = () => {
    let newItem = {
      id: Date.now(),
      Name: null,
      Amount: null,
      FeeCurrency: 'USD'
    };
    ////logger.log('Item Added', SigningFees);
    setSigningFees(current => [...current, newItem]);
  };

  const removeFeeItem = id => {
    let updatedSigningFees = _.filter(SigningFees, d => {
      return d.id !== id;
    });
    setSigningFees(updatedSigningFees);
  };

  const updateSigningFees = (id, type, value) => {
    let isFeeExist = _.find(SigningFees, f => f.Name === value);

    let newSigningFees = [];
    _.map(SigningFees, function (o) {
      let eFee = Object.assign({}, o);
      if (o.id == id) {
        if (type === 'Amount') {
          eFee[type] = isFeeExist ? '' : parseFloat(value);
        } else {
          eFee[type] = isFeeExist ? '' : value;
        }
        if (!isFeeExist) {
          newSigningFees.push(eFee);
        }
      } else {
        newSigningFees.push(o);
      }
    });
    setSigningFees(newSigningFees);
    if (isFeeExist) {
      toast.warning('Fee Type "' + value + '" already exist', {
        theme: 'colored',
        position: 'bottom-center',
        icon: faExclamationCircle
      });
    }
  };

  const refreshDocumentCollection = () => {
    setLoadingDocumentCollectionList(true);
    api.DocumentCollection.list()
      .then(res => {
        setLoadingDocumentCollectionList(false);
        let sortedCollection = _.sortBy(res, d => {
          return d.SortOrder;
        });
        setDocumentCollections(sortedCollection);
        setDocumentCollectionsFiltered(sortedCollection);
        setSigningPackagesLoadError(false);
      })
      .catch(err => {
        setLoadingDocumentCollectionList(false);
        setSigningPackagesLoadError(true);
      });
  };

  const openCollectionPicker = () => {
    refreshDocumentCollection();
    setShowDocPackagesCanvas(true);
  };
  const queryAttr = 'data-rbd-drag-handle-draggable-id';
  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    ...draggableStyle
  });

  const onDragUpdate = update => {
    if (!update.destination) {
      return;
    }
    const draggableId = update.draggableId;
    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    if (!draggedDOM) {
      return;
    }
  };
  const handleDragEnd = result => {
    if (result?.source?.index !== result?.destination?.index) {
      const data = documentCollectionsFiltered.find(
        data => data.GUID == result.draggableId
      );
      const reorderedPackages = Array.from(documentCollectionsFiltered);
      const [removed] = reorderedPackages.splice(result?.source?.index, 1);
      reorderedPackages.splice(result?.destination?.index, 0, removed);
      setDocumentCollectionsFiltered(reorderedPackages);
      api.DocumentCollection.update(
        data.GUID,
        data.Name,
        data.description,
        data.NotaryFee,
        data.AdditionalData,
        data.DocumentTypeGUIDs,
        data.SigningFees,
        result?.destination?.index
      )
        .then(() => {
          toast.success('Signing packages reordered successfully', {
            theme: 'colored',
            position: 'bottom-center',
            icon: faUserCheck,
            toastId: 'signing-package'
          });
          refreshDocumentList();
        })
        .catch(() => {
          setSavingSigningPackage(false);
          dispatch(
            showAlert(
              'Error Ordering Signing Package',
              'There was a problem ordering this Signing Package. Please try again.'
            )
          );
        });
    }
  };

  const refreshDocumentList = () => {
    //setLoadingDocumentList(true);
    api.DocumentType.list()
      .then(res => {
        setLoadingDocumentList(false);
        let sortedDocs = _.sortBy(res, d => {
          return -moment(d.Created).unix();
        });
        setDocumentList(sortedDocs);
        setDocumentListFiltered(sortedDocs);
        setDocumentTypesLoadError(false);
      })
      .catch(err => {
        setLoadingDocumentList(false);
        logger.log('error loading documentTypes', err);
        setDocumentTypesLoadError(true);
      });
  };

  const openDocsPicker = () => {
    refreshDocumentList();
    setShowDocsCanvas(true);
  };
  return (
    <>
      <h5 className="text-center">Add Documents & Signing Packages</h5>
      <p className="text-center fs-0">
        Load a Signing Package, or just start adding Documents below. You can
        save your current selection of Documents as a Signing Package to use it
        again later.
      </p>
      <hr />
      <Row className="g-2 my-3">
        <Col sm={4}>
          <Flex
            justifyContent="center"
            className="bg-soft-primary py-3 border rounded"
          >
            <Button
              variant="primary"
              type="button"
              onClick={() => openCollectionPicker()}
            >
              <FontAwesomeIcon icon={faFolder} /> Load Package
            </Button>
          </Flex>
        </Col>
        <Col sm={4}>
          <Flex
            justifyContent="center"
            className="bg-soft-primary py-3 border rounded"
          >
            <Button
              variant="primary"
              type="button"
              onClick={() => openDocsPicker()}
            >
              <FontAwesomeIcon icon={faFileAlt} /> Add Documents
            </Button>
          </Flex>
        </Col>
        <Col sm={4}>
          <Flex
            justifyContent="center"
            className="bg-soft-primary py-3 border rounded"
          >
            <Button
              disabled={savingSigningPackage}
              variant="primary"
              type="button"
              onClick={() => saveCollectionClick()}
            >
              {savingSigningPackage ? (
                <Spinner size="sm" />
              ) : (
                <FontAwesomeIcon icon={faSave} />
              )}{' '}
              {savingSigningPackage ? 'Saving...' : 'Save Package'}
            </Button>
          </Flex>
        </Col>
      </Row>

      <Row className="g-2 mb-1">
        <Col sm={4}>
          <Form.Group as={Col} sm={12} controlId="NotaryFee">
            <Form.Label>Notary fee</Form.Label>
            <Form.Control
              type="number"
              className="w-100"
              placeholder="Notary fee"
              value={NotaryFee}
              name="NotaryFee"
              onChange={e => setNotaryFee(parseInt(e.target.value))}
            />
          </Form.Group>
        </Col>
        <Col className="d-flex" sm={8}>
          <Flex alignItems="end">
            <Button type="button" onClick={() => appendSigningFees()}>
              <FontAwesomeIcon icon={faPlusCircle} size="sm" /> Add Additional
              Fee
            </Button>
          </Flex>
        </Col>
      </Row>
      {SigningFees.length ? (
        <Row className="mb-2">
          <Col sm={12} className="mt-3">
            <label className="form-label">
              Additional Fee ({SigningFees.length})
            </label>
            {SigningFees.map((efItem, efIndex) => (
              <Row className="mb-2" key={efItem.id + '-' + efIndex}>
                <Col xs={5} sm={4} className="mb-1">
                  <Form.Select
                    type="text"
                    name="Name"
                    value={efItem.Name}
                    onChange={e =>
                      updateSigningFees(
                        efItem.id,
                        e.target.name,
                        e.target.value
                      )
                    }
                  >
                    <option value="">Select fee type</option>
                    {additionFeeTypes.map((option, oIndex) => (
                      <option
                        value={option}
                        key={_.uniqueId(option + '-' + oIndex)}
                      >
                        {option}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col xs={5} sm={4} className="mb-1">
                  <Form.Control
                    type="number"
                    step={0.1}
                    placeholder="Fee amount"
                    value={efItem.Amount || ''}
                    name="Amount"
                    onChange={e =>
                      updateSigningFees(
                        efItem.id,
                        e.target.name,
                        e.target.value
                      )
                    }
                  />
                </Col>
                <Col xs={2} sm={4} className="mb-1">
                  <Button
                    variant="danger"
                    type="button"
                    onClick={() => removeFeeItem(efItem.id)}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} size="sm" />
                  </Button>
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
      ) : (
        <Row>
          <Col sm={12}>
            <p className="text-muted fs--1">No Additional fee added.</p>
          </Col>
        </Row>
      )}
      <Row className="g-2 mb-3">
        <Form.Label className="mb-0">Additional notes</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="General notes pertaining to this journal entry"
          value={AdditionalData}
          name="AdditionalData"
          onChange={e => setAdditionalData(e.target.value)}
        />
      </Row>

      <Row className="g-2 mb-3">
        {selectedDocumentList.length ? (
          <>
            <Col sm={12}>
              <label className="form-label mb-0">
                Selected documents ({selectedDocumentList.length})
              </label>
            </Col>
            <Col sm={12}>
              <ListGroup>
                {selectedDocumentList.map((item, index) => (
                  <ListGroup.Item
                    className="cursor-pointer"
                    key={item.document_id + '-' + index}
                  >
                    <Row>
                      <Col sm={6}>
                        <Row>
                          <Col sm={12}>
                            <h6 className="mb-1">{item.document_type}</h6>
                            <div className="mb-1">
                              <span className="mb-1 fs--1 text-primary">
                                <FontAwesomeIcon
                                  icon={faFolderOpen}
                                  size="sm"
                                />{' '}
                                {item.notarization_type}
                              </span>{' '}
                              &nbsp;
                              <span
                                className="my-0 fs--2 text-primary"
                                onClick={() => editNotarizationType(item)}
                              >
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  color={Colors.primary.color}
                                  size="sm"
                                />{' '}
                                Edit notarization type
                              </span>{' '}
                              &nbsp;
                              {/* <span className="my-0 fs--2">
                                <FontAwesomeIcon icon={faCalendarAlt} />{' '}
                                {moment(item.created)
                                  .utc()
                                  .format('MM/DD/YYYY HH:mm A')}
                              </span>{' '} */}
                              &nbsp;
                              <span
                                className="my-0 fs--2 text-danger"
                                onClick={() => appendDocuments(false, item)}
                              >
                                <FontAwesomeIcon
                                  icon={faTrashAlt}
                                  color={Colors.danger.color}
                                  size="sm"
                                />{' '}
                                Remove
                              </span>
                            </div>
                          </Col>
                          <Col sm={12}>
                            <Flex
                              justifyContent="start"
                              direction="row"
                              wrap="wrap"
                            >
                              {signers.map(signer => (
                                <div key={_.uniqueId('signer-row-div')}>
                                  {getDocSignerView(
                                    item.document_id,
                                    signer.signer_id,
                                    item.signer_ids.indexOf(signer.signer_id) >=
                                      0
                                  )}
                                </div>
                              ))}
                            </Flex>
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={6}>
                        <Form.Control
                          className="fs--1"
                          as="textarea"
                          rows={3}
                          placeholder={
                            'Notes for ' +
                            item.document_type +
                            ' document (Optional)'
                          }
                          value={item.notes || ''}
                          name="notes"
                          onChange={e =>
                            updateSelectedDocumentNotes(
                              item.document_id,
                              e.target.value
                            )
                          }
                        />
                      </Col>
                    </Row>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Col>
          </>
        ) : (
          <Col sm={12}>
            <div className="text-center">
              <p>
                No Document selected, minimum one document required.{' '}
                <Button
                  size="sm"
                  variant="outline-success"
                  type="button"
                  onClick={() => openDocsPicker()}
                >
                  <FontAwesomeIcon icon={faPlus} /> Add Documents
                </Button>
              </p>
            </div>
          </Col>
        )}
      </Row>

      <Offcanvas
        id="offcanvas-document-list"
        keyboard={false}
        show={showDocsCanvas}
        backdrop="static"
        placement="end"
        onHide={hideDocCanvas}
      >
        {!openNewDocForm ? (
          <>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                <span>
                  Document Library{' '}
                  {loadingDocumentList && (
                    <span>
                      <Spinner size="sm" />
                    </span>
                  )}
                </span>
              </Offcanvas.Title>
              {selectedDocumentList.length ? (
                <span className="text-primary fw-bold">
                  {selectedDocumentList.length} Selected
                </span>
              ) : (
                <></>
              )}
            </Offcanvas.Header>
            <hr className="my-0" />
            <Offcanvas.Body>
              <Flex justifyContent="center">
                <InputGroup className="mb-3">
                  <Form.Control
                    size="md"
                    className="w-50"
                    type="text"
                    placeholder="Search by name or type"
                    value={docSearchKeyword}
                    name="search"
                    onChange={e => setDocSearchKeyword(e.target.value)}
                  />
                  <Button
                    variant="primary"
                    onClick={() => setOpenNewDocForm(true)}
                  >
                    <FontAwesomeIcon icon={faPlusCircle} /> New Type
                  </Button>
                </InputGroup>
              </Flex>
              {!loadingDocumentList && documentListFiltered ? (
                <>
                  <Flex justifyContent="end" className="fs--1 mb-1">
                    Sort by{' - '}
                    <span
                      className={`cursor-pointer mx-1 ${
                        sortByDoc === 'name'
                          ? 'text-primary fw-bold'
                          : 'text-secondary'
                      }`}
                      onClick={() => setDocSortBy('name')}
                    >
                      Name
                    </span>
                    <span
                      className={`cursor-pointer mx-1 ${
                        sortByDoc === 'type'
                          ? 'text-primary fw-bold'
                          : 'text-secondary'
                      }`}
                      onClick={() => setDocSortBy('type')}
                    >
                      Type
                    </span>
                    <span
                      className={`cursor-pointer mx-1 ${
                        sortByDoc === 'created'
                          ? 'text-primary fw-bold'
                          : 'text-secondary'
                      }`}
                      onClick={() => setDocSortBy('created')}
                    >
                      Created
                    </span>
                    <span
                      className={`cursor-pointer mx-1 ${
                        sortByDoc === 'favorite'
                          ? 'text-primary fw-bold'
                          : 'text-secondary'
                      }`}
                      onClick={() => setDocSortBy('favorite')}
                    >
                      Favorite
                    </span>
                  </Flex>
                  <ListGroup>
                    {documentListFiltered.map((item, index) => (
                      <ListGroup.Item key={_.uniqueId(item.GUID)}>
                        <Flex justifyContent="between" alignItems="center">
                          <div className="flex-fill w-50">
                            <QuantityController
                              quantity={_.sumBy(
                                selectedDocumentList,
                                function (o) {
                                  return o.document_type_guid == item.GUID
                                    ? 1
                                    : 0;
                                }
                              )}
                              handleChange={handleQuantityChange}
                              handleIncrease={() =>
                                handleQuantityIncrease(item)
                              }
                              handleDecrease={() =>
                                handleQuantityDecrease(item)
                              }
                            />
                          </div>
                          <Flex className="flex-fill w-50" justifyContent="end">
                            {deletingDocs !== item.GUID ? (
                              <div>
                                {/* <FontAwesomeIcon
                                  color={Colors.primary.lightColor}
                                  className="ms-1"
                                  size="sm"
                                  icon={faEdit}
                                /> */}
                                {toggleFavorite === item.GUID ? (
                                  <Spinner className="mx-2" size="sm" />
                                ) : (
                                  <FontAwesomeIcon
                                    color={
                                      item.IsFavorite
                                        ? Colors.primary.color
                                        : Colors.primary.lightColor
                                    }
                                    className="mx-2 cursor-pointer"
                                    size="sm"
                                    icon={faStar}
                                    onClick={() => updateDocumentType(item)}
                                  />
                                )}
                                <FontAwesomeIcon
                                  color={Colors.danger.color}
                                  className="ms-1 cursor-pointer"
                                  size="sm"
                                  icon={faTrashAlt}
                                  onClick={() => removeDocumentItemClick(item)}
                                />
                              </div>
                            ) : (
                              <div className="text-muted">
                                <Spinner size="sm" />
                              </div>
                            )}
                          </Flex>
                        </Flex>
                        <p className="my-1">{item.Value}</p>
                        <Flex justifyContent="between" alignItems="center">
                          <span className="mb-1 fs--1">
                            <FontAwesomeIcon icon={faFolderOpen} size="sm" />{' '}
                            {item.NotarizationType}
                          </span>
                          <span className="my-0 fs--2 text-muted">
                            <FontAwesomeIcon icon={faCalendarAlt} />{' '}
                            {moment(item.Created)
                              .utc()
                              .local()
                              .format('MM/DD/YYYY HH:mm A')}
                          </span>
                        </Flex>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </>
              ) : loadingDocumentList ? (
                <div className="text-center py-3">
                  <Spinner size="sm" />
                  <br /> Loading...
                </div>
              ) : (
                <div className="text-center">
                  <FontAwesomeIcon icon={faFileAlt} size="3x" />
                  <br />
                  <span>No Package found</span>
                </div>
              )}
              {documentTypesLoadError && (
                <div className="text-danger text-center py-3">
                  <FontAwesomeIcon icon={faExclamationTriangle} size="2x" />{' '}
                  <br />
                  Unbale to load saved documents, Close this window and Try
                  again.
                </div>
              )}
            </Offcanvas.Body>
          </>
        ) : (
          <div>
            <Offcanvas.Header
              closeButton
              onHide={() => setOpenNewDocForm(false)}
            >
              <Offcanvas.Title>New document type</Offcanvas.Title>
            </Offcanvas.Header>

            <Offcanvas.Body>
              <Card>
                <Card.Body>
                  <Form.Group className="mb-3" controlId="newDocTypeName">
                    <Form.Label>Title of Document</Form.Label>
                    <Form.Control
                      placeholder="Title of the new document type"
                      value={newDocTypeName}
                      name="newDocTypeName"
                      onChange={e => {
                        setNewDocTypeName(e.target.value);
                      }}
                      type="text"
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="newDocNotarizationType"
                  >
                    <Form.Label>Notarization Type</Form.Label>
                    <Form.Select
                      type="text"
                      value={newDocNotarizationType}
                      onChange={e => setNewDocNotarizationType(e.target.value)}
                    >
                      <option value="">Select notarization type</option>
                      {notarization_type_picker_opts.map(option => (
                        <option
                          value={option.name}
                          key={_.uniqueId(option.name)}
                        >
                          {option.text}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  {newDocNotarizationType === enums.NOTARIZATION_TYPE.OTHER && (
                    <Form.Group
                      className="mb-3"
                      controlId="newNotarizationTypeOther"
                    >
                      <Form.Label>
                        Notarization Type (Please Specify)
                      </Form.Label>
                      <Form.Control
                        placeholder="Specify notarization type"
                        value={newNotarizationTypeOther}
                        name="newNotarizationTypeOther"
                        onChange={e => {
                          setNewNotarizationTypeOther(e.target.value);
                        }}
                        type="text"
                      />
                    </Form.Group>
                  )}

                  <Flex justifyContent="center">
                    <Button
                      variant="danger"
                      type="button"
                      onClick={() => setOpenNewDocForm(false)}
                    >
                      Close
                    </Button>{' '}
                    &nbsp;
                    <Button
                      variant="primary"
                      type="button"
                      disabled={savingDocs}
                      onClick={() => saveDocType()}
                    >
                      {savingDocs ? (
                        <>
                          <Spinner size="sm" /> Saving...
                        </>
                      ) : (
                        'Save'
                      )}
                    </Button>
                  </Flex>
                </Card.Body>
              </Card>
              <div className="p-4 text-center text-muted ">
                <FontAwesomeIcon icon={faInfoCircle} size="3x" />
                <br />
                <p>Did you know?</p>
                <p className="text-justify">
                  You can customize your document library by adding or removing
                  documents. If you delete a document from your library, it will
                  remove them from any signing packages that contain that
                  document. but will not affect your saved signings.
                </p>
              </div>
            </Offcanvas.Body>
          </div>
        )}
      </Offcanvas>
      <Offcanvas
        id="offcanvas-collection-list"
        show={showDocPackagesCanvas}
        backdrop="static"
        onHide={hideDocPackageCanvas}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Saved Signing Packages</Offcanvas.Title>
        </Offcanvas.Header>
        <hr className="my-0" />
        <Offcanvas.Body>
          <Flex justifyContent="center">
            <InputGroup className="mb-3">
              <Form.Control
                size="md"
                className="w-50"
                type="text"
                placeholder="Search by name or type"
                value={collectionSearchKeyword}
                name="search"
                onChange={e => setCollectionSearchKeyword(e.target.value)}
              />
            </InputGroup>
          </Flex>
          {!loadingDocumentCollectionList &&
          documentCollectionsFiltered?.length ? (
            <>
              <Flex justifyContent="end" className="fs--1 mb-1">
                Sort by{' - '}
                <span
                  className={`cursor-pointer mx-1 ${
                    sortByCollection === 'SortOrder'
                      ? 'text-primary fw-bold'
                      : 'text-secondary'
                  }`}
                  onClick={() => setDocCollectionSortBy('SortOrder')}
                >
                  Sort Order
                </span>
                <span
                  className={`cursor-pointer mx-1 ${
                    sortByCollection === 'name'
                      ? 'text-primary fw-bold'
                      : 'text-secondary'
                  }`}
                  onClick={() => setDocCollectionSortBy('name')}
                >
                  Name
                </span>
                <span
                  className={`cursor-pointer mx-1 ${
                    sortByCollection === 'Amount'
                      ? 'text-primary fw-bold'
                      : 'text-secondary'
                  }`}
                  onClick={() => setDocCollectionSortBy('Amount')}
                >
                  Fee
                </span>
                <span
                  className={`cursor-pointer mx-1 ${
                    sortByCollection === 'favorite'
                      ? 'text-primary fw-bold'
                      : 'text-secondary'
                  }`}
                  onClick={() => setDocCollectionSortBy('favorite')}
                >
                  Docs
                </span>
              </Flex>
              <DragDropContext
                onDragEnd={handleDragEnd}
                onDragUpdate={onDragUpdate}
              >
                <StrictModeDroppable droppableId="droppable1">
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {documentCollectionsFiltered.map((item, index) => (
                        <Draggable
                          key={item.GUID}
                          draggableId={item.GUID}
                          index={index}
                        >
                          {provided => (
                            <Card
                              key={item.GUID}
                              ref={provided.innerRef}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                              {...provided.draggableProps}
                              className="cursor-pointer mb-2"
                            >
                              <Card.Header className="pb-0 ps-3">
                                <Flex
                                  justifyContent="between"
                                  alignItems="center"
                                >
                                  <div
                                    className="d-flex align-items-center"
                                    {...provided.dragHandleProps}
                                  >
                                    <span className="m-2">
                                    <FontAwesomeIcon icon="grip-lines" />{' '}
                                    </span>
                                    <h6 className="m-2">{item.Name}</h6>
                                  </div>

                                  <div>
                                    <FontAwesomeIcon
                                      color={Colors.danger.color}
                                      className="ms-1"
                                      size="sm"
                                      icon={faTrashAlt}
                                      onClick={() =>
                                        removeCollectionItemClick(item.GUID)
                                      }
                                    />
                                  </div>
                                </Flex>
                              </Card.Header>
                              <ListGroup className="list-group-flush fs--1">
                                <ListGroupItem>
                                  <Flex
                                    justifyContent="between"
                                    alignItems="center"
                                  >
                                    <div>
                                      <p className="mb-1">
                                        Notary Fee:{' '}
                                        <span className="fw-semi-bold">
                                          {currencyCodeToSign(
                                            item.NotaryFeeCurrency
                                          )}
                                          {item.NotaryFee}
                                        </span>
                                      </p>
                                      {item?.SigningFees &&
                                        _.map(item?.SigningFees, ef => {
                                          return (
                                            <p className="mb-1">
                                              {ef.Name}:{' '}
                                              <span className="fw-semi-bold">
                                                {currencyCodeToSign(
                                                  item.NotaryFeeCurrency
                                                )}
                                                {ef.Amount}
                                              </span>
                                            </p>
                                          );
                                        })}
                                    </div>
                                    <div>
                                      <span className="fw-semi-bold">
                                        {item.DocumentTypes.length}
                                      </span>{' '}
                                      Document{item.DocumentTypes.length > 1 ? 's' : ''}
                                    </div>
                                  </Flex>
                                </ListGroupItem>
                                <ListGroupItem>
                                  {' '}
                                  {item.DocumentTypes.map((docItem, dIndex) => (
                                    <p
                                      key={_.uniqueId(docItem.GUID)}
                                      className="my-1"
                                    >
                                      <FontAwesomeIcon size="sm" icon={faFolder} />{' '}
                                      {docItem.Value} /{' '}
                                      <span className="text-primary">
                                        {docItem.NotarizationType}
                                      </span>
                                    </p>
                                  ))}
                                </ListGroupItem>
                                <ListGroupItem className="text-center">
                                  {item.AdditionalData}
                                </ListGroupItem>
                                <Card.Body>
                                  <Flex
                                    justifyContent="between"
                                    alignItems="center"
                                  >
                                    <Card.Link
                                      onClick={() =>
                                        selectPackageDocs(item.GUID)
                                      }
                                    >
                                      {' '}
                                      <FontAwesomeIcon icon={faFolder} /> Choose
                                      Package
                                    </Card.Link>
                                    <span className="my-0 fs--2 text-muted">
                                      <FontAwesomeIcon icon={faCalendarAlt} />{' '}
                                      {moment(item.Created)
                                        .utc()
                                        .local()
                                        .format('MM/DD/YYYY HH:mm A')}
                                    </span>
                                  </Flex>
                                </Card.Body>
                              </ListGroup>
                            </Card>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </StrictModeDroppable>
              </DragDropContext>
            </>
          ) : loadingDocumentCollectionList ? (
            <div className="text-center">
              <Spinner size="sm" /> Loading...
            </div>
          ) : (
            <div className="text-center py-2">
              <FontAwesomeIcon icon={faFolderOpen} size="3x" />
              <br />
              <p className="py-3">
                No Package available
                <br />
                Complete step-3 by adding Notarization Fee , Notes and Documents
                then Click "Save Package"{' '}
              </p>
            </div>
          )}

          {signingPackagesLoadError && (
            <div className="text-danger text-center py-3">
              <FontAwesomeIcon icon={faExclamationTriangle} size="2x" /> <br />
              Unbale to load signing package, Close this window and Try again.
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
      <Modal
        show={showDocNotarizationTypeUpdate}
        onHide={() => setShowDocNotarizationTypeUpdate(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Update Notarization Type</Modal.Title>
          <FalconCloseButton
            onClick={() => setShowDocNotarizationTypeUpdate(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="newDocNotarizationType">
            <Form.Label>Notarization Type</Form.Label>
            <Form.Select
              type="text"
              value={newDocNotarizationType}
              onChange={e => setNewDocNotarizationType(e.target.value)}
            >
              <option value="">Select notarization type</option>
              {notarization_type_picker_opts.map(option => (
                <option value={option.name} key={_.uniqueId(option.name)}>
                  {option.text}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          {newDocNotarizationType === enums.NOTARIZATION_TYPE.OTHER && (
            <Form.Group className="mb-3" controlId="newNotarizationTypeOther">
              <Form.Label>Notarization Type (Please Specify)</Form.Label>
              <Form.Control
                placeholder="Specify notarization type"
                value={newNotarizationTypeOther}
                name="newNotarizationTypeOther"
                onChange={e => {
                  setNewNotarizationTypeOther(e.target.value);
                }}
                type="text"
              />
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDocNotarizationTypeUpdate(false)}
          >
            Close
          </Button>
          <Button variant="primary" onClick={() => updateDocNotarizationType()}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

Step3.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setValue: PropTypes.func.isRequired
};

export default Step3;
