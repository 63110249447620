import moment from 'moment';
import logger from './logger';
import _ from 'lodash';
import enums from './enums';

let parseDateFromServer = val => {
  return val;
};

let getFriendlyDate = date => {
  let thisDate = moment(parseDateFromServer(date));

  let today = moment().startOf('d');
  let yesterday = moment(today).subtract(1, 'd').startOf('d');
  let withinOneWeek = moment(today).subtract(7, 'd').startOf('d');
  if (moment(thisDate).isSame(today, 'day')) {
    return thisDate.format('h:mm a');
  } else if (moment(thisDate).isSame(yesterday, 'day')) {
    return 'Yesterday ' + thisDate.format('h:mm a');
  } else if (moment(thisDate).isAfter(withinOneWeek, 'day')) {
    return thisDate.format('dddd h:mm a');
  } else {
    return thisDate.format('M/D/YY h:mm a');
  }
};

let formatCurrencyFromServer = (val, currency) => {
  return getCurrency(val * 100);
};

let getCurrency = centsVal => {
  let val = +centsVal;
  if (!val || _.isNaN(val)) {
    return '$0';
  }

  return '$' + (val / 100).toFixed(2);
};

let buildSignerString = signers => {
  if (signers.length <= 3) {
    return _.join(signers, ', ');
  } else {
    let signerList = [signers[0], signers[1]];
    let res = _.join(signerList, ', ');
    res += ` and ${signers.length - 2} others`;
    return res;
  }
};

let getPrefixFromObj = nameObj => {
  return _.get(
    nameObj,
    'prefix',
    _.get(nameObj, 'Prefix', _.get(nameObj, 'signer_prefix'))
  );
};

let getSuffixFromObj = nameObj => {
  return _.get(
    nameObj,
    'suffix',
    _.get(nameObj, 'Suffix', _.get(nameObj, 'signer_suffix'))
  );
};

let getFirstNameFromObj = nameObj => {
  return _.get(
    nameObj,
    'first_name',
    _.get(
      nameObj,
      'id_first_name',
      _.get(nameObj, 'FirstName', _.get(nameObj, 'signer_first_name'))
    )
  );
};

let getMiddleNameFromObj = nameObj => {
  return _.get(
    nameObj,
    'middle_name',
    _.get(
      nameObj,
      'id_middle_name',
      _.get(nameObj, 'MiddleName', _.get(nameObj, 'signer_middle_name'))
    )
  );
};

let getLastNameFromObj = nameObj => {
  return _.get(
    nameObj,
    'last_name',
    _.get(
      nameObj,
      'id_last_name',
      _.get(nameObj, 'LastName', _.get(nameObj, 'signer_last_name'))
    )
  );
};

let formatNameObj = nameObj => {
  //worth noting here, this looks a little messy,
  //but I'm trying to make one function that pulls the proper name properties out of
  //whatever kind of object we have.  So there's lots of failovers.
  return formatName(
    _.get(nameObj, 'prefix', _.get(nameObj, 'Prefix')),
    _.get(
      nameObj,
      'first_name',
      _.get(nameObj, 'id_first_name', _.get(nameObj, 'FirstName'))
    ),
    _.get(
      nameObj,
      'middle_name',
      _.get(nameObj, 'id_middle_name', _.get(nameObj, 'MiddleName'))
    ),
    _.get(
      nameObj,
      'last_name',
      _.get(nameObj, 'id_last_name', _.get(nameObj, 'LastName'))
    ),
    _.get(nameObj, 'suffix', _.get(nameObj, 'Suffix'))
  );
};

let formatName = (prefix, first_name, middle_name, last_name, suffix) => {
  let result = '';
  if (prefix) {
    result += prefix + ' ';
  }
  if (first_name) {
    result += first_name + ' ';
  }
  if (middle_name) {
    result += middle_name + ' ';
  }
  if (last_name) {
    result += last_name;
  }
  if (suffix) {
    result += ', ' + suffix;
  }

  return result;
};

let getSignerTypeName = type => {
  let name = 'Signer';
  if (type === enums.UI_FLOW_TYPE.WITNESS_ONE) {
    name = 'One Credible Witness';
  } else if (type === enums.UI_FLOW_TYPE.WITNESS_TWO) {
    name = 'Two Credible Witness';
  } else if (type === enums.UI_FLOW_TYPE.PERSONALLY_KNOWN) {
    name = 'Personally Known';
  }
  return name;
};

export default {
  formatNameObj,
  formatName,
  buildSignerString,
  getFriendlyDate,
  formatCurrencyFromServer,
  getPrefixFromObj,
  getFirstNameFromObj,
  getMiddleNameFromObj,
  getLastNameFromObj,
  getSuffixFromObj,
  getSignerTypeName
};
