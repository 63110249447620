import React, { createContext, useState } from 'react';
import c from 'util/const';
import juratLocalStorage from 'util/local-storage';
import logger from 'util/logger';
const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(juratLocalStorage.get(c.storage.user));
  const [token, setToken] = useState(juratLocalStorage.get(c.storage.token));

  // call this function when you want to authenticate the user
  const setAuth = async (token, user) => {
    if (user && token) {
      new Promise(function (resolve, reject) {
        try {
          setToken(token);
          setUser(user);
          juratLocalStorage.set(c.storage.user, user);
          juratLocalStorage.set(c.storage.token, token);
          return resolve(user);
        } catch {
          return reject();
        }
      });
    }
  };

  // call this function when you want to authenticate the user
  const setAuthToken = async token => {
    logger.log('Setting Token');
    if (token) {
      new Promise(function (resolve, reject) {
        try {
          setToken(token);
          juratLocalStorage.set(c.storage.token, token);
          return resolve(true);
        } catch {
          return reject(false);
        }
      });
    }
  };

  // call this function when you want to authenticate the user
  const setAuthUser = async user => {
    if (user) {
      new Promise(function (resolve, reject) {
        try {
          setUser(user);
          juratLocalStorage.set(c.storage.user, user);
          return resolve(true);
        } catch {
          return reject(false);
        }
      });
    }
  };

  // call this function to check auth
  const isAuth = () => {
    return !!(
      juratLocalStorage.get(c.storage.token) &&
      juratLocalStorage.get(c.storage.token) != null
    );
  };

  // call this function to sign out logged in user
  const unsetAuth = () => {
    new Promise(function (resolve, reject) {
      try {
        setUser(null);
        setToken(null);
        juratLocalStorage.remove(c.storage.user);
        juratLocalStorage.remove(c.storage.token);
        return resolve();
      } catch {
        return reject();
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        setAuth,
        unsetAuth,
        isAuth,
        setAuthToken,
        setAuthUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return React.useContext(AuthContext);
};
