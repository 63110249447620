import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/common/Avatar';
import segmentHelper from 'helpers/segment-helper';
import { useAuth } from 'hooks/useAuth';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { cleanup } from 'stores/app';
import utils from 'util/utils';

const ProfileDropdown = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [logingOut, setLogingOut] = useState(false);
  const dispatch = useDispatch();
  const appData = useSelector(state => state.app);
  const user = appData.userMaster;

  const handleLogout = () => {
    if (!logingOut) {
      setLogingOut(true);
      segmentHelper.reset();
      Intercom('shutdown');
      dispatch(cleanup);
      auth.unsetAuth();
      setLogingOut(false);
      //navigate('/login');
      window.location.href = '/login';
    }
  };

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar
          src={utils.getProfilePic(user)}
          name={user ? user.FirstName + ' ' + user.LastName : ''}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          {/* <Dropdown.Item
            className="fw-bold text-warning"
            href="/billing/subscriptions"
          >
            <FontAwesomeIcon icon="crown" className="me-1" />
            <span>Go Pro</span>
          </Dropdown.Item>
          <Dropdown.Divider /> */}
          <Dropdown.Item as={Link} to="/account/user-profile">
            Profile &amp; account
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/extra/feature-request">Feedback</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to="/account/user-profile">
            Settings
          </Dropdown.Item>
          <Dropdown.Item onClick={handleLogout}>{logingOut ? "Logging Off" : "Logout"}</Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown >
  );
};

export default ProfileDropdown;
