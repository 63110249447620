import { AuthWizardContext } from 'context/Context';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import celebration from './lottie/celebration.json';
import Flex from 'components/common/Flex';
import AppStageView from 'components/common/AppStageView';

const Success = ({ reset, stateData }) => {
  const { setStep, setUser } = useContext(AuthWizardContext);

  const emptyData = () => {
    setStep(1);
    setUser({});
    reset();
  };

  const showDashboard = () => { };

  return (
    <>
      <Row>
        {stateData.finishingAccount ? (
          <Col className="text-center">
            <Flex
              justifyContent="center"
              alignContent="center"
              alignItems="center"
            >
              <AppStageView />
            </Flex>
          </Col>
        ) : (
          <Col className="text-center">
            <div className="wizard-lottie-wrapper">
              <div className="wizard-lottie mx-auto">
                <Lottie animationData={celebration} loop={true} />
              </div>
            </div>
            <h4 className="mb-1">Your account is created!</h4>
            <p className="fs-0">Click below to access your account</p>
            <Button as={Link} to="/" color="primary" className="px-5 my-3">
              Go to Dashboard
            </Button>
          </Col>
        )}
      </Row>
    </>
  );
};

Success.propTypes = {
  reset: PropTypes.func.isRequired
};

export default Success;
