import { createSlice } from '@reduxjs/toolkit';
import juratLocalStorage from '../util/local-storage';
import c from '../util/const';
import logger from '../util/logger';

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: {
    isAuthenticated: false,
    token: null,
    preAuthEmail: '',
    deviceGUID: null
  },
  reducers: {
    setToken: (state, action) => {
      state.isAuthenticated = true;
      state.token = action.payload;
    },

    setPreAuthEmail: (state, action) => {
      state.preAuthEmail = action.payload;
    },

    logout: state => {
      state.isAuthenticated = true;
      state.token = null;
    },
    setDeviceGUID: (state, action) => {
      state.deviceGUID = action.payload;
    },
    getToken: (state, action) => {
      return state.token;
    }
  }
});

export const { setToken, setPreAuthEmail, logout, setDeviceGUID, getToken } =
  authenticationSlice.actions;

export default authenticationSlice.reducer;
