import React from 'react';
import PropTypes from 'prop-types';
import WizardInput from './WizardInput';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import c from 'util/const';
import _ from 'lodash';

const CommissionForm = ({ register, errors, setValue, stateData }) => {
  const states = c.states;
  return (
    <>
      <h6 className="text-center">
        Please provide us your commission details so we can set up your Notary
        eJournal account.
      </h6>
      <p className="text-center text-info fs--1">
        If you are commissioned in more than one state, you can add additional
        notary commissions later from the account page.
      </p>
      <Row className="g-2 mb-3">
        <WizardInput
          type="text"
          errors={errors}
          label="Commissioned Name *"
          name="commission_name"
          placeholder=""
          formGroupProps={{ as: Col, sm: 6 }}
          formControlProps={{
            className: 'input-spin-none',
            ...register('commission_name', {
              required: 'Please Enter commissioned Name'
            })
          }}
          setValue={setValue}
        />
        <WizardInput
          errors={errors}
          label="Commission Number"
          name="commission_number"
          placeholder="Number - Optional"
          formGroupProps={{ as: Col, sm: 6 }}
          formControlProps={{
            ...register('commission_number', {
              required: false
            })
          }}
          setValue={setValue}
        />

        <WizardInput
          errors={errors}
          label="Commissioned County"
          name="county"
          placeholder="County - Optional"
          formGroupProps={{ as: Col, sm: 6 }}
          formControlProps={{
            ...register('county', {
              required: false
            })
          }}
          setValue={setValue}
        />

        <WizardInput
          type="select-value-label"
          name="state"
          label="Commissioned State *"
          errors={errors}
          placeholder=""
          options={states}
          formGroupProps={{ as: Col, sm: 6 }}
          formControlProps={{
            ...register('state', {
              required: 'Please select commission profile state'
            })
          }}
          setValue={setValue}
        />

        <WizardInput
          label="Commission Expiration"
          type="date"
          name="commission_exp"
          setValue={setValue}
          errors={errors}
          placeholder="Expiration date - Optional"
          formGroupProps={{ as: Col, sm: 6 }}
          formControlProps={{
            placeholder: 'MM/DD/YYYY',
            ...register('commission_exp', {
              required: false
            })
          }}
          datepickerProps={{
            dateFormat: 'MM/dd/yyyy',
            showMonthYearPicker: false,
            showMonthDropdown: true,
            showYearDropdown: true,
            dropdownMode: 'select',
            yearDropdownItemNumber: 40
          }}
        />

        <WizardInput
          type="select-value-label"
          name="localtimezone"
          label="Timezone *"
          errors={errors}
          placeholder=""
          options={_.map(c.timezones, t => {
            return {
              name: `${t.name} ${t.offset}`,
              text: t.offset
            };
          })}
          formGroupProps={{ as: Col, sm: 6 }}
          formControlProps={{
            ...register('localtimezone', {
              required: 'Please select commission profile timezone'
            })
          }}
          setValue={setValue}
        />
      </Row>
    </>
  );
};

CommissionForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setValue: PropTypes.func.isRequired
};

export default CommissionForm;
