import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import api from 'util/api';
import logger from 'util/logger';
import {
  faArrowAltCircleLeft,
  faArrowLeft,
  faArrowsAlt,
  faCheckCircle,
  faUserCheck
} from '@fortawesome/free-solid-svg-icons';
import ValidationErrors from 'components/utilities/ValidationErrors';
import IconAlert from 'components/common/IconAlert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaArrowCircleLeft } from 'react-icons/fa';

const ForgetPasswordForm = () => {
  // State
  const [email, setEmail] = useState('');
  const [validationErr, setValidationErr] = useState([]);
  const [sendingLink, setSendingLink] = useState(false);
  const [sentSuccess, setSentSuccess] = useState(false);
  const navigate = useNavigate();

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    if (email) {
      setSendingLink(true);
      api.Reset.reset(email)
        .then(res => {
          logger.log('password reset result', res);
          setSendingLink(false);
          setSentSuccess(true);
          toast.success(
            `An email is sent to ${email} with password reset link`,
            {
              theme: 'colored',
              position: 'bottom-center',
              icon: faUserCheck,
              toastId: 'login-success'
            }
          );
        })
        .catch(err => {
          setSendingLink(false);
          setSentSuccess(false);
          logger.log('error during password reset', err);

          let validationMsg = '';
          if (err && err.Code && err.Code === 'NotFound') {
            validationMsg =
              "No account exist with given email address.";
          } else if (err && err.Text) {
            validationMsg = err.Text;
          } else {
            validationMsg =
              'There was an error processing your request.  Please try again.';
          }
          setValidationErr([validationMsg]);
        });
    }
  };

  return (
    <>
      {!sentSuccess ? (
        <>
          <div>
            <small>Please Enter your email to reset your password</small>
          </div>
          <Form className="mt-4" onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Control
                placeholder={'Email address'}
                value={email}
                name="email"
                onChange={({ target }) => setEmail(target.value)}
                type="email"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Button
                className="w-100"
                type="submit"
                disabled={!email || sendingLink}
              >
                {sendingLink ? <Spinner size="sm" /> : 'Send reset link'}
              </Button>
            </Form.Group>

            <Link className="fs--1 text-600" to="/login">
              <FontAwesomeIcon icon={faArrowAltCircleLeft} /> Back to login
            </Link>

            <div className="mt-3">
              <ValidationErrors errors={validationErr} />
            </div>
          </Form>
        </>
      ) : (
        <>
          <Row className="mt-3">
            <Col sm={12}>
              <IconAlert icon={faCheckCircle} variant="success">
                <p className="mb-0">
                  Success! We sent something to your email to reset your
                  password. You should receive it shortly.
                </p>
              </IconAlert>
            </Col>
          </Row>
          <Row>
            <Col sm={12} className="text-center">
              <Button onClick={() => navigate('/login')}>Go Back</Button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

ForgetPasswordForm.propTypes = {
  layout: PropTypes.string
};

ForgetPasswordForm.defaultProps = { layout: 'simple' };

export default ForgetPasswordForm;
