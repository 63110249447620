import corner4 from 'assets/img/illustrations/corner-4.png';
import PageHeader from 'components/common/PageHeader';
import React from 'react';
import { Alert, Col, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import NotarySettings from './NotarySettings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const NotaryProfile = () => {
  const { userProfile, userMaster } = useSelector(state => state.app);
  const dispatch = useDispatch();
 
  const IconAlertCode = function IconAlertDemo({ variant, message }) {
    return (
      <Alert variant="info">
        <Alert.Heading>
          <FontAwesomeIcon icon="info-circle" className="fs-1" /> Important
          information:
        </Alert.Heading>
        <p>
          Your notary commission is unique to the state you are commissioned in.
          If your commission expires and you receive a new commission (sometimes
          referred to as renewing your commission), you may also receive a new
          commission number and expiration date. For that reason, we recommend
          that you create a new notary commission profile when you renew your
          commission (there is no need to delete your expired commission
          profile). Also, if you are commissioned in more than 1 state at the
          same time (referred to as being dual-commissioned), please add both
          active commissions and select whichever commission you consider your
          "primary" (state of residence, for example) as your default.
        </p>
        <hr />
        <p className="mb-0">
          Note - Your default commission profile is automatically populated on
          step 1 when creating a new journal entry. You can easily switch
          between notary commission profiles on step 1 as well, making it easy
          to properly track your journal entries
        </p>
      </Alert>
    );
  };
  return (
    <>
      <PageHeader
        preTitle="Information about your notary commission"
        title="Notary Commission"
        description="Edit the information of your notary commission here. If you hold a commission in more than one state, you can add/edit those commission details here as well"
        className="mb-3"
        titleTag="h2"
        image={corner4}
      />
      <IconAlertCode
        variant="info"
        message="Any saved drafts you may have are stored on your device only, until they are completed and uploaded to our servers."
      />
      <Row className="g-3">
        <Col lg={12}>
          {userProfile ? (
            <NotarySettings userProfile={userProfile} />
          ) : (
            <Spinner />
          )}
        </Col>
      </Row>
    </>
  );
};
 
export default NotaryProfile;
 