import {
  faCalendarAlt,
  faPause,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';
import { actionSetRawData } from 'stores/dashboard';
import c from 'util/const';
import logger from 'util/logger';

const GreetingsDateInput = forwardRef(({ value, onClick }, ref) => (
  <div className="position-relative">
    <Form.Control
      size="sm"
      ref={ref}
      onClick={onClick}
      value={value}
      className="ps-4"
      onChange={e => {
        logger.log({ e });
      }}
    />
    <FontAwesomeIcon
      icon="calendar-alt"
      className="text-primary position-absolute top-50 translate-middle-y ms-2"
    />
  </div>
));

const GreetingsDate = ({ setLoading, loading, setDaysRange }) => {
  const dispatch = useDispatch();
  let date = new Date();
  const [customRangeMode, setCustomRangeMode] = useState(false);
  const [rangeModeValue, setRangeModeValue] = useState('custom');
  // const [dateRange, setDateRange] = useState({
  //   start: moment().subtract(1, 'month').format(),
  //   end: moment().format()
  // });

  const [dateRange, setDateRange] = useState({
    start: null,
    end: null
  });
  const [calenderDate, setCalenderDate] = useState({
    start: moment(date).format('yyyy-MM-DD'),
    end: moment(date).format('yyyy-MM-DD')
  });
  const onCalenderDateChnage = (name, date) => {
    logger.log('name, date', name, date);
    setCalenderDate(calenderDate => ({
      ...calenderDate,
      //[name]: moment(date).format('yyyy-MM-DD')
      [name]: date
    }));
  };

  const applyDateFilter = () => {
    setDateRange(dateRange => ({
      ...dateRange,
      start: calenderDate.start || moment(date).format('yyyy-MM-DD'),
      end: calenderDate.end || moment(date).format('yyyy-MM-DD')
    }));
  };

  const onChange = (name, date) => {
    logger.log('name, date', name, date);
    setDateRange(dateRange => ({
      ...dateRange,
      //[name]: moment(date).format('yyyy-MM-DD')
      [name]: date
    }));
  };

  const handleDateRangeSelect = (count, format) => {
    logger.log('handleDateRangeSelect', count, format);
    setDaysRange(count);
    setRangeModeValue(count);
    format = format || 'day';
    if (count === 'current') {
      setDateRange({
        start: moment().startOf(format).format(),
        end: moment().format()
      });
    } else {
      setDateRange({
        start: moment().subtract(count, format).format(),
        end: moment().format()
      });
    }
  };

  useEffect(() => {
    handleDateRangeSelect(7);
  }, []);

  useEffect(() => {
    logger.log('dateRange', dateRange);
    if (dateRange.start != null && dateRange.end != null) {
      let fidelity = c.fidelity.Daily;
      let start = moment(dateRange.start).format('yyyy-MM-DD');
      let end = moment(dateRange.end).format('yyyy-MM-DD');
      let rangeDays = Math.abs(
        Math.floor(moment.duration(moment(start).diff(moment(end))).asDays())
      );
      let rangeWeeks = Math.abs(
        Math.floor(moment.duration(moment(start).diff(moment(end))).asWeeks())
      );
      if (rangeDays > 185) {
        fidelity = c.fidelity.Weekly;
      }
      if (rangeWeeks > 105) {
        fidelity = c.fidelity.Monthly;
      }
      setLoading(true);
      dispatch(actionSetRawData(start, end, fidelity))
        .then(res => {
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
        });
    }
  }, [dateRange]);
  return !customRangeMode ? (
    <Flex>
      <Button
        size="sm"
        className="d-flex align-items-center p-0  me-3 shadow-none"
        variant="default"
        onClick={event => handleDateRangeSelect('current', 'month')}
      >
        <FontAwesomeIcon
          icon="circle"
          className={`${
            rangeModeValue == 'current' ? 'text-primary' : 'text-300'
          } fs--2 me-1`}
        />
        Current month
      </Button>
      <Button
        size="sm"
        className="d-flex align-items-center p-0  me-3 shadow-none"
        variant="default"
        onClick={event => handleDateRangeSelect(7)}
      >
        <FontAwesomeIcon
          icon="circle"
          className={`${
            rangeModeValue == 7 ? 'text-primary' : 'text-300'
          } fs--2 me-1`}
        />
        Last 7 Days
      </Button>

      <Button
        size="sm"
        className="d-flex align-items-center p-0  me-3 shadow-none"
        variant="default"
        onClick={event => handleDateRangeSelect(30)}
      >
        <FontAwesomeIcon
          icon="circle"
          className={`${
            rangeModeValue == 30 ? 'text-primary' : 'text-300'
          } fs--2 me-1`}
        />
        Last 30 Days
      </Button>
      <Button
        size="sm"
        className="d-flex align-items-center p-0 shadow-none"
        variant="default"
        onClick={event => setCustomRangeMode(true)}
      >
        <FontAwesomeIcon icon={faCalendarAlt} />
      </Button>
    </Flex>
  ) : (
    <Flex alignItems={'center'}>
      {/* <div style={{ width: 127 }}><Form.Group controlId="dashboard-range-start">
        <DatePicker className='form-control' onChange={(date) => onChange('start', date)} selected={new Date(moment(dateRange.start))} value={moment(dateRange.start).format('MM/DD/YYYY')} dateFormat="MM/dd/YYYY" /></Form.Group>
      </div>
      <div className='mx-1'>To</div>
      <div style={{ width: 127 }}>
        <Form.Group controlId="dashboard-range-end">
          <DatePicker className='form-control' onChange={(date) => onChange('end', date)} selected={new Date(moment(dateRange.end))} value={moment(dateRange.end).format('MM/DD/YYYY')} dateFormat="MM/dd/YYYY" />
        </Form.Group>
      </div> */}
      <InputGroup className="">
        <Form.Group controlId="dashboard-range-start" style={{ width: 127 }}>
          <DatePicker
            className="form-control"
            onChange={date => onCalenderDateChnage('start', date)}
            selected={new Date(moment(calenderDate.start))}
            value={moment(calenderDate.start).format('MM/DD/YYYY')}
            dateFormat="MM/dd/YYYY"
          />
        </Form.Group>
        {/* <div className="mx-1">to</div> */}
        <Form.Group controlId="dashboard-range-end" style={{ width: 127 }}>
          <DatePicker
            className="form-control"
            onChange={date => onCalenderDateChnage('end', date)}
            selected={new Date(moment(calenderDate.end))}
            value={moment(calenderDate.end).format('MM/DD/YYYY')}
            dateFormat="MM/dd/YYYY"
          />
        </Form.Group>
        <Button variant="outline-primary" onClick={() => applyDateFilter()}>
          Go
        </Button>
        <Button
          variant="outline-danger"
          onClick={() => setCustomRangeMode(false)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </InputGroup>
    </Flex>
  );
  // <DatePicker
  //   selected={dateRange.start}
  //   onChange={onChange}
  //   startDate={dateRange.start}
  //   //formatWeekDay={day => day.slice(0, 3)}
  //   endDate={dateRange.end}
  //   selectsRange
  //   dateFormat="MMM dd yyyy"
  //   customInput={<GreetingsDateInput />}
  // />
};

GreetingsDateInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func
};

export default GreetingsDate;
