import {
  faExclamationTriangle,
  faUserCheck
} from '@fortawesome/free-solid-svg-icons';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import IconAlert from 'components/common/IconAlert';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Popover,
  Row,
  Spinner
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { refreshUserMasterDetails } from 'stores/app';
import api from 'util/api';
import logger from 'util/logger';

const ProfileContact = props => {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contactInfo, setContactInfo] = useState(null);

  const [showJournalNotes, setShowJournalNotes] = useState(false);

  const [formData, setFormData] = useState({
    GUID: null,
    Contact: ''
  });

  const { userMaster } = useSelector(state => state.app);

  useEffect(() => {
    initContactView();
  }, []);

  const initContactView = () => {
    setLoading(true);
    dispatch(refreshUserMasterDetails(userMaster.GUID))
      .then(res => {
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (userMaster) {
      setContactInfo(userMaster?.ContactInformation);
      setFormData({
        ...formData,
        GUID: userMaster?.GUID,
        Contact: userMaster?.ContactInformation
      });
    }
  }, [userMaster]);

  const save = () => {
    setSaving(true);
    api.UserMaster.changeContactInformation(formData.GUID, formData.Contact)
      .then(res => {
        setSaving(false);
        toast.success(`Contact information saved successfully`, {
          theme: 'colored',
          position: 'bottom-center',
          icon: faUserCheck,
          toastId: 'login-success'
        });
        initContactView();
        setShowJournalNotes(false);
      })
      .catch(err => {
        logger.log('err', err);
        setSaving(false);
        toast.error(`Unable to save contact information. Try again`, {
          theme: 'colored',
          position: 'bottom-center',
          icon: faExclamationTriangle,
          toastId: 'login-success'
        });
      });
  };

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
  };

  return (
    <Card {...props}>
      <FalconCardHeader title="Contact Information" />

      <Card.Body className="bg-light">
        <IconAlert variant={'info'}>
          <p className="mb-0">
            This contact information will appear in the receipt email thay your
            signer receives. We recommend ensuring your contact information
            includes your{' '}
            <OverlayTrigger
              trigger="hover"
              placement="top"
              overlay={
                <Popover id="notarization-date-time">
                  <Popover.Header as="h3">commission name</Popover.Header>
                  <Popover.Body>
                    Details about name of commission name
                  </Popover.Body>
                </Popover>
              }
              delay={500}
            >
              <u className="fw-semi-bold">commissioned name</u>
            </OverlayTrigger>{' '}
            as well as the{' '}
            <OverlayTrigger
              trigger="hover"
              placement="top"
              overlay={
                <Popover id="notarization-date-time">
                  <Popover.Header as="h3">Name of Business</Popover.Header>
                  <Popover.Body>Details about name of business</Popover.Body>
                </Popover>
              }
              delay={500}
            >
              <u className="fw-semi-bold">name of your business</u>
            </OverlayTrigger>{' '}
            or{' '}
            <OverlayTrigger
              trigger="hover"
              placement="top"
              overlay={
                <Popover id="notarization-date-time">
                  <Popover.Header as="h3">DBA</Popover.Header>
                  <Popover.Body>Details about DBA</Popover.Body>
                </Popover>
              }
              delay={500}
            >
              <u className="fw-semi-bold">DBA</u>
            </OverlayTrigger>{' '}
            if applicable, along with all{' '}
            <span className="fw-semi-bold">email addresses</span> and{' '}
            <span className="fw-semi-bold">phone numbers</span> to ensure the
            signer can reach you if needed.
          </p>
        </IconAlert>
        {showJournalNotes && (
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3 g-3">
              <Col sm={12}>
                <Form.Group className="mb-3" controlId="intro">
                  {/* <Form.Label>Intro</Form.Label> */}
                  <Form.Control
                    as="textarea"
                    rows={6}
                    placeholder="Write journal notes..."
                    value={formData.Contact}
                    name="Contact"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col sm={12} className="text-center">
                <Button
                  className="mx-1"
                  variant="primary"
                  type="submit"
                  disabled={saving || formData.Contact == contactInfo}
                  onClick={() => save()}
                >
                  {saving ? <Spinner size="sm" variant="light" /> : 'Update'}
                </Button>
                <Button
                  className="mx-1"
                  variant="danger"
                  type="button"
                  disabled={saving}
                  onClick={() => setShowJournalNotes(false)}
                >
                  Close
                </Button>
              </Col>
            </Row>
            {saving && (
              <Row>
                <Col
                  sm={12}
                  as={Flex}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Spinner size="sm" variant="primary" /> &nbsp; Saving...
                </Col>
              </Row>
            )}
          </Form>
        )}
        {!showJournalNotes && (
          <>
            {loading ? (
              <Flex
                justifyContent={'justify'}
                direction="column"
                alignContent="center"
                alignItems="center"
                className="text-center my-3 py-3"
              >
                <Spinner />
                <p className="mt-3 text-muted">Loading contact information</p>
              </Flex>
            ) : (
              <Col sm={12} md={12} className="pe-lg-2 mb-3">
                <Row>
                  <Col sm={12} className="my-3">
                    <Card>
                      <Card.Header>
                        <Flex style={{ justifyContent: 'space-between' }}>
                          <div>Contact information</div>
                          <div>
                            <Button
                              variant="primary"
                              onClick={() => setShowJournalNotes(true)}
                            >
                              Edit
                            </Button>
                          </div>
                        </Flex>
                      </Card.Header>
                      <Card.Body className="pt-0">
                        <p style={{ whiteSpace: 'pre-line' }}>
                          {userMaster?.ContactInformation ||
                            "Click 'Edit' button to add or change your notes"}
                        </p>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            )}
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default ProfileContact;
