import { faExclamationTriangle, faEye, faEyeSlash, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import store from 'app/store';
import AppStageView from 'components/common/AppStageView';
import Avatar from 'components/common/Avatar';
import Divider from 'components/common/Divider';
import Flex from 'components/common/Flex';
import ValidationErrors from 'components/utilities/ValidationErrors';
import segmentHelper from 'helpers/segment-helper';
import userHelper from 'helpers/userHelper';
import { useAuth } from 'hooks/useAuth';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CloseButton,
  Col,
  Form,
  InputGroup,
  Row,
  Spinner
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { cleanup, refreshAllAccountInfo, refreshDataExportMonths, setIsDeleteRequestAction, setStageValue } from 'stores/app';
import { setPreAuthEmail, setToken } from 'stores/authentication';
import api from 'util/api';
import c from 'util/const';
import juratLocalStorage from 'util/local-storage';
import logger from 'util/logger';

const LoginForm = ({ hasLabel, layout }) => {
  // State
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false,
    validationErr: [],
    loading: false
  });

  const auth = useAuth();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const isdeleterequest = params.get('isdeleterequest');
  const location = useLocation();
  const dispatch = useDispatch();

  const [selectedLogedInProfile, setSelectedLogedInProfile] = useState(null);
  const [quickSignIn, setQuickSignIn] = useState(true);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [deviceProfiles, setDeviceProfile] = useState([]);

  const { userMaster, stage } = useSelector(state => state.app);

  const loginProfiles =
    juratLocalStorage.get(c.actions.auth.logedinUserProfile) || [];

  const handleSubmit = e => {
    logger.log('Calling from login');
    e.preventDefault();
    let { email, password } = formData;

    if (selectedLogedInProfile) {
      email = selectedLogedInProfile.Email;
    }
    api.setToken(null);
    auth.setAuthToken(null);
    dispatch(setToken(null));
    juratLocalStorage.remove(c.storage.user);
    juratLocalStorage.remove(c.storage.token);
    setLoading(true);
    dispatch(setStageValue(c.stages.SIGNING));
    api.Token.login(email, password)
      .then(res => {
        logger.log('log in response', res);
        let token = res.Token;
        api.setToken(token);
        auth.setAuthToken(token);
        dispatch(setToken(token));
        dispatch(setPreAuthEmail(email));
        dispatch(refreshDataExportMonths());
        return dispatch(refreshAllAccountInfo());
      })
      .then((res) => {
        logger.log('Signin success');
        setLoginSuccess(true);
        dispatch(setStageValue(c.stages.NULL));
        setLoading(false);

        segmentHelper.identity();
        let user = store.getState().app.userMaster;
        segmentHelper.track(segmentHelper.events.SIGNIN_SUCCESS, { name: `${user.FirstName} ${user.LastName}`, email: user.Email, createdAt: user.Created, id: user.GUID });
        userHelper.saveUserLoginProfile(user);
        toast.success(`Welcome, ${user.FirstName} ${user.LastName}`, {
          theme: 'colored',
          position: 'bottom-center',
          icon: faUserCheck,
          toastId: 'login-success'
        });

        const redirectPath = location.state?.path || '/';
        if (isdeleterequest == 'true') {
          dispatch(setIsDeleteRequestAction(true));
          navigate('/account/delete-account', { replace: true });
        } else {
          dispatch(setIsDeleteRequestAction(false));
          navigate(redirectPath, { replace: true });
        }
      })
      .catch(err => {
        logger.log('Login failed catch on login page', err);
        if (err?.Success === false && err?.Code === 'LoginFailed' && err?.Data === 'InvalidPassword') {
          toast.warning('Authentication failed. Check username and Password', {
            theme: 'colored',
            position: 'bottom-center',
            icon: faExclamationTriangle,
            toastId: 'login-success'
          });
        }
        if (err?.Success === false && err?.Code === 'LoginFailed' && err?.Data === 'UserNotFound') {
          toast.warning('Authentication failed. Check username and Password', {
            theme: 'colored',
            position: 'bottom-center',
            icon: faExclamationTriangle,
            toastId: 'login-success'
          });
        }
        else if (err?.Success === false && err?.Code === 'LoginFailed' && err?.Data === 'InvalidToken') {
          toast.warning(err.Message, {
            theme: 'colored',
            position: 'bottom-center',
            icon: faExclamationTriangle,
            toastId: 'login-success'
          });
        }
        else if (err?.Success === false && err?.Code === 'LoginFailed') {
          toast.warning(err.Message, {
            theme: 'colored',
            position: 'bottom-center',
            icon: faExclamationTriangle,
            toastId: 'login-success'
          });
        }
        else {
          toast.error(
            "There's a problem with your account.  Please contact support@juratinc.com.",
            {
              theme: 'colored',
              position: 'bottom-center',
              icon: faExclamationTriangle,
              toastId: 'login-success'
            }
          );
        }
        setLoading(false);
        cleanup();
        api.setToken(null);
        setToken(null);

        let parsedData = _.get(err, 'Data');
        if (parsedData && parsedData === 'UserInactive') {
          //Can't log in.   User has been marked inactive.
          setFormData({
            ...formData,
            validationErr: [
              "There's a problem with your account.  Please contact support@juratinc.com."
            ]
          });
        } else if (err && err.Text) {
          setFormData({
            ...formData,
            validationErr: [err.Text]
          });
        } else if (err) {
          setFormData({
            ...formData,
            validationErr: [err]
          });
        } else {
          setFormData({
            ...formData,
            validationErr: [
              'There was a problem communicating with our server.  Please try again.'
            ]
          });
        }
        setLoading(false);
      });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const setLoading = type => {
    setFormData({
      ...formData,
      loading: type
    });
  };

  const removeLogedInProfile = profile => {
    userHelper.removeLogedInProfile(profile);
    const newProfiles =
      juratLocalStorage.get(c.actions.auth.logedinUserProfile) || [];
    setDeviceProfile(newProfiles);
  };

  const setBackToNormalLogin = () => {
    setQuickSignIn(false);
    setSelectedLogedInProfile(null);
  };

  const toggleShowpassword = () => {
    setShowPassword(!showPassword);
    setTimeout(() => {
      setShowPassword(false);
    }, 1200);
  }

  useEffect(() => {
    dispatch(setStageValue(c.stages.NULL));
    if (loginProfiles) {
      setDeviceProfile(loginProfiles);
    }
  }, []);

  // useEffect(() => {
  //   if (userMaster && loginSuccess) {
  //     segmentHelper.identity();
  //     segmentHelper.track(segmentHelper.events.SIGNIN_SUCCESS);
  //     userHelper.saveUserLoginProfile(userMaster);
  //     toast.success(`Welcome, ${userMaster.FirstName} ${userMaster.LastName}`, {
  //       theme: 'colored',
  //       position: 'bottom-center',
  //       icon: faUserCheck,
  //       toastId: 'login-success'
  //     });
  //   }
  // }, [userMaster, loginSuccess]);

  const getProfileView = (profile, isRemovable) => {
    return (
      <Card key={_.uniqueId()} className="my-1 px-3 pt-4 pb-4  h-100 border-right mx-1">
        <Flex justifyContent="between" direction="row">
          {isRemovable && (
            <div
              className="text-end d-flex justify-content-end"
              style={{ position: 'absolute', right: '8px', top: '8px' }}
            >
              <CloseButton
                onClick={() => removeLogedInProfile(profile)}
                aria-label="Hide"
                style={{
                  width: 4,
                  height: 4,
                  right: '5px',
                  top: '5px'
                }}
              />
            </div>
          )}

          <div
            className="text-center cursor-pointer"
            style={{ width: '125px' }}
            onClick={() => setSelectedLogedInProfile(profile)}
          >
            <Avatar
              size="3xl"
              name={profile ? profile.FirstName + ' ' + profile.LastName : ''}
            />
            <h5 className="mb-1 mt-2 text-truncate fs-0">
              {profile.FirstName + ' ' + profile.LastName}
            </h5>
            <p className="fs--1 mb-1 text-truncate">
              {profile.Email.split('@')[0]}
            </p>
            <p className="fs--1 mb-1 text-truncate text-primary">
              @{profile.Email.split('@')[1]}
            </p>
            {/* <p className="fs--2 mb-1 mt-2 text-truncate text-muted">
              Logedin: 2 Days ago
            </p> */}
          </div>
        </Flex>
      </Card>
    );
  };

  return (
    <>
      {quickSignIn && deviceProfiles?.length > 0 && (
        <Form onSubmit={handleSubmit}>
          {!selectedLogedInProfile && (
            <div>
              <hr />
              <Flex justifyContent="center" className="flex-wrap">
                {_.map(deviceProfiles, p => {
                  return getProfileView(p, true);
                })}
              </Flex>
              <Flex
                justifyContent="center"
                direction="column"
                alignItems="center"
              >
                <p className="text-muted fs--2 mb-1 mt-2">or</p>
                <p
                  className="fs--1 text-primary mb-1 cursor-pointer"
                  onClick={() => setBackToNormalLogin()}
                >
                  Login with a different account
                </p>
              </Flex>
            </div>
          )}
          {selectedLogedInProfile && (
            <div>
              <Flex justifyContent="center">
                {getProfileView(selectedLogedInProfile, false)}
              </Flex>
              <Form.Group className="mb-0 mt-3">
                {hasLabel && <Form.Label>Password</Form.Label>}
                <InputGroup>
                  <Form.Control
                    placeholder={
                      !hasLabel
                        ? 'Enter password for ' + selectedLogedInProfile.Email
                        : ''
                    }
                    value={formData.password}
                    name="password"
                    onChange={handleFieldChange}
                    type={showPassword ? "text" : "password"}
                    className="fs--1 text-center py-2"
                  />
                  <InputGroup.Text id="login-password-card" onClick={() => toggleShowpassword()} ><FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} /></InputGroup.Text>
                </InputGroup>
              </Form.Group>
              {formData.loading ? (
                <Flex
                  className="mt-3"
                  direction="column"
                  alignItems="center"
                  alignContent="center"
                  justifyContent="center"
                >
                  <Spinner size="sm" />
                  <AppStageView />{' '}
                </Flex>
              ) : (
                <Form.Group>
                  <Button
                    type="submit"
                    color="primary"
                    className="mt-3 w-100"
                    disabled={!selectedLogedInProfile || !formData.password}
                  >
                    Log in
                  </Button>
                </Form.Group>
              )}
              <p
                className="fs--1 text-primary text-center pt-3 mb-1 cursor-pointer"
                onClick={() => setBackToNormalLogin()}
              >
                Login with different account
              </p>
              <p
                className="fs--1 text-primary text-center mb-1 cursor-pointer"
                onClick={() => {
                  navigate('/forgot-password');
                }}
              >
                Forgot Password?
              </p>
            </div>
          )}
        </Form>
      )}
      {(!quickSignIn || deviceProfiles.length === 0) && (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            {hasLabel && <Form.Label>Email address</Form.Label>}
            <Form.Control
              placeholder={!hasLabel ? 'Email address' : ''}
              value={formData.email}
              name="email"
              onChange={handleFieldChange}
              type="email"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            {hasLabel && <Form.Label>Password</Form.Label>}
            <InputGroup>
              <Form.Control
                placeholder={!hasLabel ? 'Password' : ''}
                value={formData.password}
                name="password"
                onChange={handleFieldChange}
                type={showPassword ? "text" : "password"}
              />
              <InputGroup.Text id="login-password" onClick={() => toggleShowpassword()} ><FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} /></InputGroup.Text>
            </InputGroup>
          </Form.Group>

          <Row className="justify-content-between align-items-center">
            <Col xs="auto">
              <Form.Check type="checkbox" id="rememberMe" className="mb-0">
                <Form.Check.Input
                  type="checkbox"
                  name="remember"
                  defaultChecked={false}
                  onChange={e =>
                    setFormData({
                      ...formData,
                      remember: e.target.checked
                    })
                  }
                />
                <Form.Check.Label className="mb-0 text-700">
                  Remember me
                </Form.Check.Label>
              </Form.Check>
            </Col>

            <Col xs="auto">
              <Link className="fs--1 mb-0" to={`/forgot-password`}>
                Forgot Password?
              </Link>
            </Col>
          </Row>
          {formData.loading ? (
            <Flex
              direction="column"
              alignItems="center"
              alignContent="center"
              justifyContent="center"
            >
              <Spinner size="sm" />
              <AppStageView />{' '}
            </Flex>
          ) : (
            <Form.Group>
              <Button
                type="submit"
                color="primary"
                className="mt-3 w-100"
                disabled={!formData.email || !formData.password}
              >
                Log in
              </Button>
            </Form.Group>
          )}

          {/* <Divider className="mt-4">or log in with</Divider> */}

          {/* <SocialAuthButtons /> */}
          {formData.validationErr && (
            <div className="mt-3">
              <ValidationErrors cla errors={formData.validationErr} />
            </div>
          )}
        </Form>
      )}
    </>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
