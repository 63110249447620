import { createSlice } from '@reduxjs/toolkit';
import { capitalize } from 'helpers/utils';
import _ from 'lodash';
import moment from 'moment';
import idGenerator from 'util/idGenerator';

export const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    notifications: []
  },

  reducers: {
    addNotificationItem: (state, action) => {
      state.notifications = _.concat([], state.notifications, {
        ...action.payload.item
      });
    },

    markNotificationRead: (state, action) => {
      let updatedNotification = [];
      _.map(state.notifications, n => {
        if (n.id === action.payload || action.payload === null) {
          n.unread = false;
        }
        updatedNotification.push(n);
      });
      state.notifications = updatedNotification;
    },

    clearNotification: (state, action) => {
      state.notifications = [];
    }
  }
});

let { addNotificationItem, markNotificationRead, clearNotification } =
  notificationSlice.actions;

let actions = {
  actionAddNotification: messageObject => {
    return (dispatch, getState) => {
      return Promise.all([
        dispatch(
          addNotificationItem({
            item: {
              id: idGenerator.getNotificationItemId(),
              avatar: {
                name: messageObject.name || 'Jurat System',
                size: 'xl'
              },
              children: capitalize(messageObject.message.toLowerCase()),
              time: moment().utc().format(),
              emoji: '💬',
              className: 'rounded-0 border-x-0 border-300 border-bottom-0',
              unread: true,
              to: messageObject.to || '#!'
            }
          })
        )
      ]);
    };
  },

  actionMarkNotificationRead: id => {
    return (dispatch, getState) => {
      return Promise.all([dispatch(markNotificationRead(id))]);
    };
  },

  actionClearNotification: () => {
    return (dispatch, getState) => {
      return Promise.all([dispatch(clearNotification())]);
    };
  }
};

export const {
  actionAddNotification,
  actionMarkNotificationRead,
  actionClearNotification
} = actions;

export default notificationSlice.reducer;
