import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import * as echarts from 'echarts/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card, Form, Spinner } from 'react-bootstrap';

import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { capitalize, getColor, rgbaColor } from 'helpers/utils';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import logger from 'util/logger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { actionGetDashboardStats } from 'stores/dashboard';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent
]);

const getOptions = (dataValue, dataKeys) => {
  return {
    color: getColor('gray-100'),
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-100'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      formatter: params => {
        const { name, value } = params[0];
        return `${name} : $${value}`;
      },
      transitionDuration: 0
    },
    xAxis: {
      type: 'category',
      data: dataKeys,
      boundaryGap: false,
      axisPointer: {
        lineStyle: {
          color: getColor('gray-300'),
          type: 'dashed'
        }
      },
      splitLine: { show: false },
      axisLine: {
        lineStyle: {
          color: getColor('gray-300'),
          type: 'dashed'
        }
      },
      axisTick: { show: false },
      axisLabel: {
        color: getColor('gray-400'),
        formatter: value => `${value}`,
        margin: 15
      }
    },
    yAxis: {
      type: 'value',
      axisPointer: { show: false },
      splitLine: {
        lineStyle: {
          color: getColor('gray-300'),
          type: 'dashed'
        }
      },
      boundaryGap: false,
      axisLabel: {
        show: true,
        color: getColor('gray-400'),
        formatter: value => `$${value}`,
        margin: 15
      },
      axisTick: { show: false },
      axisLine: { show: false }
    },
    series: [
      {
        type: 'bar',
        data: dataValue,
        lineStyle: { color: getColor('primary') },
        itemStyle: {
          borderColor: getColor('primary'),
          borderWidth: 2
        },
        symbol: 'circle',
        symbolSize: 10,
        smooth: true,
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: rgbaColor(getColor('primary'), 0.2)
              },
              {
                offset: 1,
                color: rgbaColor(getColor('primary'), 0)
              }
            ]
          }
        }
      }
    ],
    grid: { right: 10, left: 0, bottom: 0, top: 10, containLabel: true }
  };
};

const NotaryMonthlyFee = ({ months }) => {
  logger.log('TotalSales months', months);
  const [month, setMonth] = useState('');
  const [notayFeeMonthly, setNotayFeeMonthly] = useState(null);
  const { rawData } = useSelector(state => state.dashboard);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const setChartsData = () => {
    if (month) {
      //logger.log('notayFeeMonthly data loaded', notayFeeMonthly);
      let startDate = moment(month).startOf('month').format('YYYY-MM-DD');
      let endDate = moment(month).endOf('month').format('YYYY-MM-DD HH:mm:ss');

      // let filterData = _.filter(notayFeeMonthly, (s) => {
      //   return moment(moment(s.date).format('YYYY-MM-DD')).isBetween(startDate, endDate);
      // });
      // setData(filterData);
      setLoading(true);
      dispatch(actionGetDashboardStats(startDate, endDate))
        .then(res => {
          // console.log('res', res);
          setLoading(false);
          let Slices = _.get(res, 'Slices');
          logger.log('Slices data for graph', Slices);
          let signingData = [];
          _.map(Slices, slice => {
            signingData.push({
              date: moment(slice.End).format('YYYY-MM-DD'),
              fee: slice.FeesCollected
            });
          });
          //setSigning(signingData);

          let filterData = signingData
            ? _.filter(signingData, s => {
                logger.log('Signing dates', s.date);
                return moment(s.date).isBetween(startDate, endDate);
              })
            : [];
          logger.log('filterData', filterData);
          setData(filterData);
        })
        .catch(err => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (month === '') {
      setMonth(moment().startOf('month').format('YYYY-MM-DD'));
    }
  }, []);

  useEffect(() => {
    setChartsData();
  }, [month]);

  // useEffect(() => {
  //   if (month === '') {
  //     setMonth(moment().startOf('month').format('YYYY-MM-DD'));
  //   }
  //   if (rawData) {
  //     logger.log('rawData', rawData);
  //     let Slices = _.get(rawData, 'Slices');
  //     logger.log('Slices data for graph', Slices);
  //     let signingData = [];
  //     _.map(Slices, slice => {
  //       signingData.push({
  //         date: moment(slice.End).format('YYYY-MM-DD'),
  //         fee: slice.FeesCollected
  //       });
  //     });
  //     setNotayFeeMonthly(signingData);
  //   }
  // }, [rawData]);

  return (
    <Card className="h-100">
      <FalconCardHeader
        title={'Notarization Fees at a Glance ($' + _.sumBy(data, 'fee') + ')'}
        titleTag="h6"
        className="pb-0"
        endEl={
          <Flex>
            <Form.Select
              size="sm"
              value={month}
              onChange={e => setMonth(e.target.value)}
              className="me-2"
            >
              {months &&
                months.map((i, index) => (
                  <option value={i.begin} key={i.begin}>
                    {i.title}
                  </option>
                ))}
            </Form.Select>
            {/* <CardDropdown /> */}
          </Flex>
        }
      />

      <Card.Body>
        {!loading && months && data.length > 0 && (
          <ReactEChartsCore
            echarts={echarts}
            option={getOptions(
              _.map(data, 'fee'),
              data.map(function (obj, index) {
                return moment(obj.date).format('MMM D');
              })
            )}
            style={{ height: '18.4375rem' }}
          />
        )}
        {loading && (
          <Flex justifyContent={'center'}>
            <Spinner />
          </Flex>
        )}
        {data.length == 0 && !loading && (
          <Flex
            justifyContent={'center'}
            alignItems={'center'}
            direction={'column'}
          >
            <FontAwesomeIcon icon={faInfoCircle} />
            <p>No data available</p>
          </Flex>
        )}
      </Card.Body>
    </Card>
  );
};

NotaryMonthlyFee.propTypes = {
  months: PropTypes.array.isRequired,
  loading: PropTypes.bool
};

export default NotaryMonthlyFee;
