import {
  faExclamationTriangle,
  faPlug,
  faPlus,
  faTrash,
  faUserCheck
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import SignatureScratchPad from 'components/common/SignatureScratchPad';
import AppContext from 'context/Context';
import draftEntryUiHelper from 'helpers/draft-entry-ui-helper';
import React from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Row,
  Spinner
} from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  addUserSignature,
  refreshUserSignature,
  removeUserSignature
} from 'stores/app';

const ProfileSignature = props => {
  const { userMaster, userSignature } = useSelector(state => state.app);
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showSignaturePad, setShowSignaturePad] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(refreshUserSignature())
      .then(res => {
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      });
  }, []);

  const setProfileSignature = signatureDataobj => {
    let signatureData = {
      Description: 'Signature',
      Blobs: [
        {
          ContentType: signatureDataobj.content_type,
          Name: 'Signature',
          Description: 'Signature',
          Data: signatureDataobj.data
        }
      ]
    };
    setSaving(true);
    dispatch(addUserSignature(signatureData))
      .then(res => {
        setSaving(false);
        toast.success(`Signature saved successfully`, {
          theme: 'colored',
          position: 'bottom-center',
          icon: faUserCheck,
          toastId: 'login-success'
        });
        setShowSignaturePad(false);
      })
      .catch(err => {
        setSaving(false);
        toast.error(`Unable to save signature. Try again`, {
          theme: 'colored',
          position: 'bottom-center',
          icon: faExclamationTriangle,
          toastId: 'login-success'
        });
      });
  };

  const confirmRemoveSignatre = GUID => {
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure you want to delete this signature?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => proceedToDeleteSignature(GUID)
        },
        {
          label: 'No',
          onClick: () => {
            return;
          }
        }
      ]
    });
  };

  const proceedToDeleteSignature = GUID => {
    setDeleting(GUID);
    dispatch(removeUserSignature(GUID))
      .then(res => {
        setDeleting(false);
      })
      .catch(err => {
        setDeleting(false);
      });
  };

  const handleSubmit = e => {
    e.preventDefault();
  };

  const CardDropdown = ({
    btnRevealClass,
    drop,
    children,
    icon = 'ellipsis-h',
    signature
  }) => {
    const {
      config: { isRTL }
    } = useContext(AppContext);

    return (
      <Dropdown
        className="font-sans-serif btn-reveal-trigger"
        align={isRTL ? 'start' : 'end'}
        drop={drop}
      >
        <Dropdown.Toggle
          variant="link"
          size="sm"
          data-boundary="viewport"
          className={classNames('text-600', {
            [btnRevealClass]: btnRevealClass,
            'btn-reveal': !btnRevealClass
          })}
        >
          <FontAwesomeIcon icon={icon} className="fs--2" />
        </Dropdown.Toggle>
        <Dropdown.Menu className="border py-0">
          {children}
          {!children && (
            <div className="py-2">
              <Dropdown.Item className="text-primary">Remove</Dropdown.Item>
            </div>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <Card {...props}>
      <FalconCardHeader title="Profile Signature" />
      <Card.Body className="bg-light">
        {showSignaturePad && (
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3 g-3">
              <Col sm={12}>
                <Flex
                  justifyContent={'center'}
                  alignContent={'center'}
                  alignItems={'center'}
                >
                  <div>
                    <SignatureScratchPad
                      setSignatureData={setProfileSignature}
                      signatureSignerId={userMaster.GUID}
                      closeEle={
                        <Button
                          className="mx-1"
                          variant="danger"
                          onClick={() => setShowSignaturePad(false)}
                        >
                          Close
                        </Button>
                      }
                    />
                  </div>
                </Flex>
              </Col>
            </Row>
            {saving && (
              <Row>
                <Col
                  sm={12}
                  as={Flex}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Spinner size="sm" variant="primary" /> &nbsp; Saving...
                </Col>
              </Row>
            )}
          </Form>
        )}
        {!showSignaturePad && (
          <>
            {loading ? (
              <Flex
                justifyContent={'justify'}
                direction="column"
                alignContent="center"
                alignItems="center"
                className="text-center my-3 py-3"
              >
                <Spinner />
                <p className="mt-3 text-muted">Loading profile signature</p>
              </Flex>
            ) : (
              <Col sm={12} md={12} className="pe-lg-2 mb-3">
                <Row>
                  {_.map(
                    _.filter(userSignature, p => p.Active),
                    signature => {
                      return (
                        <Col
                          key={signature.GUID}
                          sm={12}
                          md={6}
                          lg={4}
                          className="my-3"
                        >
                          <Card>
                            {/* <FalconCardHeader title={signature.Description}
                    titleTag="h6"
                    className="py-2"
                    light endEl={<CardDropdown signature={signature} />} /> */}
                            <Card.Header>
                              <Flex style={{ justifyContent: 'space-between' }}>
                                <div>{signature.Description}</div>
                                <div>
                                  <span
                                    onClick={() => {
                                      confirmRemoveSignatre(signature.GUID);
                                    }}
                                    className="text-danger cursor-pointer"
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </span>
                                </div>
                              </Flex>
                            </Card.Header>
                            <Card.Body className="pt-0">
                              <Flex
                                justifyContent={'center'}
                                alignContent={'center'}
                                alignItems={'center'}
                                style={{ height: 200 }}
                              >
                                {deleting === signature.GUID ? (
                                  <Spinner />
                                ) : (
                                  draftEntryUiHelper.getSignatureViewImage(
                                    signature?.Blobs[0]?.Data,
                                    180,
                                    200
                                  )
                                )}
                              </Flex>
                            </Card.Body>
                          </Card>
                        </Col>
                      );
                    }
                  )}
                  <Col
                    key={'add-new-signature'}
                    sm={12}
                    md={6}
                    lg={4}
                    className="my-3"
                  >
                    <Card
                      className="bg-primary text-light cursor-pointer"
                      onClick={() => setShowSignaturePad(true)}
                    >
                      <Card.Body>
                        <Flex
                          justifyContent={'center'}
                          alignContent={'center'}
                          alignItems={'center'}
                          direction={'column'}
                          style={{ height: 238 }}
                        >
                          <FontAwesomeIcon icon={faPlus} size="3x" />
                          <div className="my-3">Add New Signature</div>
                        </Flex>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            )}
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default ProfileSignature;
