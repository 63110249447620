import ActionButton from 'components/common/ActionButton';
import FalconComponentCard from 'components/common/FalconComponentCard';
import PageHeader from 'components/common/PageHeader';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  InputGroup,
  Offcanvas,
  Row,
  Spinner,
  Table
} from 'react-bootstrap';

import {
  faFileContract,
  faPlusCircle,
  faStar,
  faUserCheck
} from '@fortawesome/free-solid-svg-icons';
import { matchSorter } from 'match-sorter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import corner3 from 'assets/img/illustrations/corner-3.png';
import Flex from 'components/common/Flex';
import _ from 'lodash';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import SimpleBarReact from 'simplebar-react';
import { showAlert, showConfirm } from 'stores/modal';
import api from 'util/api';
import logger from 'util/logger';
import DocumentTypesForm from './DocumentTypesForm';
import segmentHelper from 'helpers/segment-helper';
import draftEntryUiHelper from 'helpers/draft-entry-ui-helper';

const DocumentTypes = () => {
  const [documentTypes, setDocumentTypes] = useState([]);
  const [documentTypesDisplay, setDocumentTypesDisplay] = useState([]);
  const [loading, setLoading] = useState(false);
  const [documentTypeLoadError, setDocumentTypesLoadError] = useState(false);

  const [sortBy, setSortBy] = useState('Created');
  const [isAsc, setIsAsc] = useState(false);

  const [showDocumentTypeForm, setShowDocumentTypesForm] = useState(false);
  const [formTitle, setFormTitle] = useState(null);

  const [toggleFavorite, setToggleFavorite] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const dispatch = useDispatch();

  const loadDocumentTypes = () => {
    setLoading(true);
    api.DocumentType.list()
      .then(res => {
        setLoading(false);
        setDocumentTypes(
          _.sortBy(res, d => {
            return -moment(d.Created).unix();
          })
        );
        setDocumentTypesLoadError(false);
      })
      .catch(err => {
        setLoading(false);
        logger.log('error loading documentTypes', err);
        setDocumentTypesLoadError(true);
      });
  };

  const onDocumentTypeAddedSuccess = () => {
    loadDocumentTypes();
    setShowDocumentTypesForm(false);
  };

  const onDocumentTypeAddedFailure = () => {
    loadDocumentTypes();
  };

  const updateDocumentType = e => {
    setToggleFavorite(e.GUID);
    let data = _.cloneDeep(e);
    data.IsFavorite = !e.IsFavorite;
    api.DocumentType.update(e.GUID, data)
      .then(res => {
        setToggleFavorite(false);
        loadDocumentTypes();
      })
      .catch(err => {
        setToggleFavorite(false);
      });
  };

  const deleteDocumentType = DocumentType => {
    dispatch(
      showConfirm(
        'Are you sure?',
        'Are you sure you want to remove this  Document Type?',
        res => {
          if (res) {
            api.DocumentType.remove(DocumentType.GUID)
              .then(res => {
                segmentHelper.track(segmentHelper.events.DOCUMENT_TYPE_DELETED);
                toast.success(`Document Type removed successfully`, {
                  theme: 'colored',
                  position: 'bottom-center',
                  icon: faUserCheck,
                  toastId: 'login-success'
                });
                loadDocumentTypes();
              })
              .catch(err => {
                showAlert(
                  'Error Deleting  documentType',
                  'There was a problem removing this l Document Type.  Please try again.'
                );
                loadDocumentTypes();
              });
          }
        }
      )
    );
  };

  const searchItems = e => {
    let keyword = e.target.value.toLowerCase();
    let filterDocuments = _.filter(
      documentTypes,
      d =>
        d.Value.toLowerCase().includes(keyword) ||
        d.NotarizationType.toLowerCase().includes(keyword) ||
        draftEntryUiHelper
          .getDateTimeWithTimeZone(d.Created)
          .format('l, LT')
          .includes(keyword)
    );
    let sortedDocs = matchSorter(filterDocuments, keyword, {
      keys: ['Value', 'NotarizationType']
    });
    setDocumentTypesDisplay(sortedDocs);
  };

  const addNewDocumentType = documentType => {
    setFormTitle('Add new Document Type');
    setShowDocumentTypesForm(true);
  };

  useEffect(() => {
    let sortedRecords = _.orderBy(
      documentTypesDisplay,
      [sortBy],
      [isAsc ? 'asc' : 'desc']
    );
    setDocumentTypesDisplay(sortedRecords);
  }, [sortBy, isAsc]);

  useEffect(() => {
    loadDocumentTypes();
  }, []);

  useEffect(() => {
    setDocumentTypesDisplay(documentTypes);
  }, [documentTypes]);

  return (
    <>
      <PageHeader
        preTitle="Document Types"
        title=" Document Type list"
        description=""
        className="mb-3"
        titleTag="h2"
        image={corner3}
      />

      <Row className="mb-3 g-3">
        <Col lg={12}>
          <FalconComponentCard noGuttersBottom>
            <FalconComponentCard.Header
              title=" Document Types"
              endEl={
                <Flex className={'g-2'}>
                  <div>
                    <Form as={Row} className="gx-2 d-flex align-items-center">
                      <Col xs="auto">
                        <small>Sort by:</small>
                      </Col>
                      <Col xs="auto">
                        <InputGroup>
                          <Form.Select
                            className="pe-5"
                            defaultValue="Created"
                            onChange={({ target }) => setSortBy(target.value)}
                          >
                            <option value="NotarizationType">Type</option>
                            <option value="Value">Name</option>
                            <option value="Created">Created</option>
                            <option value="IsFavorite">Favorite</option>
                          </Form.Select>
                          <InputGroup.Text
                            as={Button}
                            variant="link"
                            className="border border-300 text-700"
                            onClick={() => setIsAsc(!isAsc)}
                          >
                            <FontAwesomeIcon
                              icon={
                                isAsc ? 'sort-amount-up' : 'sort-amount-down'
                              }
                            />
                          </InputGroup.Text>
                        </InputGroup>
                      </Col>
                    </Form>
                  </div>
                  <div className="mx-1">
                    <Form.Control
                      onChange={e => searchItems(e)}
                      placeholder="search document..."
                      name="search"
                    ></Form.Control>
                  </div>
                  <div className="mx-1">
                    <Button
                      variant="primary"
                      onClick={() => addNewDocumentType()}
                    >
                      <FontAwesomeIcon icon={faPlusCircle} size="sm" /> Add New
                    </Button>
                  </div>
                </Flex>
              }
            />
            <FalconComponentCard.Body noLight className="py-0">
              {loading && (
                <Flex justifyContent="center" className="py-3">
                  <Spinner size="sm" />
                </Flex>
              )}
              {documentTypesDisplay.length !== 0 &&
                !documentTypeLoadError &&
                !loading && (
                  <SimpleBarReact>
                    <Table bordered>
                      <colgroup>
                        <col />
                        <col />
                        <col className="d-sm-none d-md-block" />
                        <col />
                      </colgroup>
                      <thead>
                        <tr>
                          <th className="text-center" scope="col">
                            Favorite
                          </th>
                          <th scope="col">Notarization Type</th>
                          <th scope="col">Document Title</th>
                          <th scope="col">Created</th>
                          <th className="text-end" scope="col">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.map(documentTypesDisplay, e => {
                          let createdDate = draftEntryUiHelper
                            .getDateTimeWithTimeZone(e.Created)
                            .format('l, LT z');

                          return (
                            <tr key={e.GUID}>
                              <td className="text-center cursor-pointer">
                                {toggleFavorite === e.GUID ? (
                                  <Spinner size="sm" />
                                ) : (
                                  <FontAwesomeIcon
                                    className={
                                      e.IsFavorite ? 'text-primary' : ''
                                    }
                                    icon={faStar}
                                    onClick={() => updateDocumentType(e)}
                                  />
                                )}
                              </td>
                              <td>{e.NotarizationType}</td>
                              <td>{e.Value}</td>
                              <td>{createdDate}</td>
                              <td className="text-end">
                                <ActionButton
                                  icon="trash-alt"
                                  title="Remove"
                                  label="Remove"
                                  variant="action"
                                  className="p-0 text-danger"
                                  onClick={() => deleteDocumentType(e)}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </SimpleBarReact>
                )}
              {documentTypes.length === 0 &&
                !loading &&
                (documentTypeLoadError ? (
                  <div className="text-center">
                    <FontAwesomeIcon icon={faFileContract} size="3x" />
                    <br />
                    <p className="my-3">
                      Unbale to load document type list. Try again
                    </p>
                  </div>
                ) : (
                  <div className="text-center">
                    <FontAwesomeIcon icon={faFileContract} size="3x" />
                    <br />
                    <p className="my-3">
                      You haven't create any signing packages yet. <br />
                      Click "Add new" to create new signing packages
                    </p>
                  </div>
                ))}
            </FalconComponentCard.Body>
          </FalconComponentCard>
        </Col>
      </Row>

      <Offcanvas
        id="offcanvas-address-form"
        show={showDocumentTypeForm}
        backdrop="static"
        onHide={() => setShowDocumentTypesForm(false)}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{formTitle}</Offcanvas.Title>
        </Offcanvas.Header>
        <hr className="my-0" />
        <Offcanvas.Body>
          <DocumentTypesForm
            setShowDocumentTypesForm={setShowDocumentTypesForm}
            onSuccess={onDocumentTypeAddedSuccess}
            onFailure={onDocumentTypeAddedFailure}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default DocumentTypes;
