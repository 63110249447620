import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import coverSrc from 'assets/img/jurat/profile-banner-min.png';
import avatar from 'assets/img/team/2.jpg';
import Flex from 'components/common/Flex';
import VerifiedBadge from 'components/common/VerifiedBadge';
import moment from 'moment';
import React, { useState } from 'react';
import { Card, Col, Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AccountSettings from './AccountSettings';
import ChangePassword from './ChangePassword';
import ProfileBanner from './ProfileBanner';
import ProfileSettings from './ProfileSettings';
import ProfileOathStatement from './ProfileOathStatement';
import ProfileSignature from './ProfileSignature';
import ProfileJournalNotes from './ProfileJournalNotes';
import ProfileOfficialStatement from './ProfileOfficialStatement';
import ProfileContact from './ProfileContact';
import TimezoneSettings from './TimezoneSettings';
import api from 'util/api';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { actionUpdateProfilePic } from 'stores/app';

const UserProfile = () => {
  const [loading, setLoading] = useState(false);
  const { userMaster } = useSelector(state => state.app);
  const dispatch = useDispatch();
  const [isOpenUploadModel, setIsOpenUploadModel] = useState(false);

  const uploadProficPic = data => {
    if (data) {
      setLoading(true);
      api.UserMaster.uploadProficPic(userMaster.GUID, data.split(',')[1])
        .then(userResponse => {
          dispatch(actionUpdateProfilePic(userResponse));
          setLoading(false);
          setIsOpenUploadModel(false);
        })
        .catch(err => {
          setLoading(false);
          toast.warning(
            `Unable to save', "There was a problem saving your information.  Please try again.`,
            {
              theme: 'colored'
            }
          );
        });
    }
  };

  return userMaster ? (
    <>
      <ProfileBanner user={userMaster}>
        <ProfileBanner.Header
          loading={loading}
          coverSrc={coverSrc}
          avatar={avatar}
          className="mb-8"
          user={userMaster}
          onUpload={uploadProficPic}
          isOpenUploadModel={isOpenUploadModel}
          setIsOpenUploadModel={setIsOpenUploadModel}
        />
        <ProfileBanner.Body>
          <Row>
            <Col lg={6}>
              <h4 className="mb-1">
                {userMaster ? (
                  userMaster.FirstName + ' ' + userMaster.LastName
                ) : (
                  <>
                    <Spinner size="sm" /> Loading...
                  </>
                )}{' '}
                <VerifiedBadge
                  classValue={
                    userMaster.Active ? 'text-success' : 'text-warning'
                  }
                  label={userMaster.Active ? 'Active' : 'Inactive'}
                />
              </h4>
              <h5 className="fs-0 fw-normal">{userMaster?.Email}</h5>
              <p className="text-500">
                {' '}
                {userMaster?.City} {userMaster?.Country}
              </p>

              <div className="border-dashed border-bottom my-4 d-lg-none" />
            </Col>
            <Col className="ps-2 ps-lg-3">
              <Link to="#!">
                <Flex alignItems="center" className="mb-2">
                  <FontAwesomeIcon
                    icon="user-circle"
                    className="fs-1 me-2 text-400"
                  />
                  <div className="flex-1">
                    <h6 className="mb-0">
                      Joined on{' '}
                      {userMaster
                        ? moment(userMaster.Created).format('MMMM DD, YYYY')
                        : ''}
                    </h6>
                  </div>
                </Flex>
              </Link>
            </Col>
          </Row>
        </ProfileBanner.Body>
      </ProfileBanner>
      <Row className="g-3">
        <Col lg={8}>
          <Card>
            <Card.Body>
              <Tabs
                variant="pills"
                defaultActiveKey="profile"
                id="profile-tab-panel"
                className="mb-3"
              >
                <Tab eventKey="profile" title="Profile" className="p-0">
                  <ProfileSettings />
                </Tab>
                <Tab
                  eventKey="journal-notes"
                  title="Journal Notes"
                  className="p-0"
                >
                  <ProfileJournalNotes className={'mt-4'} />
                </Tab>
                <Tab
                  eventKey="oath-statement"
                  title="Oath/Affirmation Scripts"
                  className="p-0"
                >
                  <ProfileOathStatement className={'mt-4'} />
                </Tab>
                <Tab
                  eventKey="official-statement"
                  title="Official Statement"
                  className="p-0"
                >
                  <ProfileOfficialStatement className={'mt-4'} />
                </Tab>
                <Tab eventKey="signature" title="Signature" className="p-0">
                  <ProfileSignature className={'mt-4'} />
                </Tab>
                <Tab eventKey="contact" title="Contact" className="p-0">
                  <ProfileContact className={'mt-4'} />
                </Tab>
              </Tabs>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar">
            <AccountSettings user={userMaster} />
            <TimezoneSettings user={userMaster} />
            <ChangePassword user={userMaster} />
          </div>
        </Col>
      </Row>
    </>
  ) : (
    <>
      <Flex justifyContent="center" alignContent="center">
        <Spinner />
      </Flex>
    </>
  );
};

export default UserProfile;
