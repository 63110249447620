import {
  faLifeRing,
  faShieldAlt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NotificationDropdown from 'components/navbar/top/NotificationDropdown';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import AppContext from 'context/Context';
import React, { useContext, useEffect, useState } from 'react';
import { Nav, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { updatePrivacyModeAction } from 'stores/app';
import NineDotMenu from './NineDotMenu';

const TopNavRightSideNavItem = () => {
  const {
    config: { isDark, isRTL, tourEnable },
    setConfig
  } = useContext(AppContext);

  const { userMaster, updatingPrivacyMode } = useSelector(state => state.app);
  const dispatch = useDispatch();

  const updatePrivacyMode = privacyMode => {
    let updatedUserMaster = Object.assign({}, userMaster);
    updatedUserMaster.PrivacyEnabled = privacyMode;
    dispatch(updatePrivacyModeAction(userMaster?.GUID, updatedUserMaster));
    //dispatch(updatePrivacyModeAction(userMaster?.GUID, privacyMode));
  };

  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      {userMaster && (
        <Nav.Item as={'li'}>
          <Nav.Link className="px-1 theme-control-toggle">
            <OverlayTrigger
              key="left"
              placement={isRTL ? 'bottom' : 'left'}
              overlay={
                <Tooltip id="tourView">
                  {userMaster?.PrivacyEnabled
                    ? 'Privacy mode enabled'
                    : 'Privacy mode disabled'}
                </Tooltip>
              }
            >
              {updatingPrivacyMode ? (
                <Spinner size="sm" />
              ) : (
                <div className="theme-control-toggle-label">
                  <FontAwesomeIcon
                    icon={faShieldAlt}
                    className={`fa-0 fs-0 ${userMaster?.PrivacyEnabled ? 'text-success' : 'text-secondary'
                      }`}
                    onClick={() => updatePrivacyMode(!userMaster?.PrivacyEnabled)}
                  />
                </div>
              )}
            </OverlayTrigger>
          </Nav.Link>
        </Nav.Item>
      )}

      {/* <Nav.Item as={'li'}>
        <Nav.Link
          className="px-1 theme-control-toggle"
          onClick={() => setConfig('tourEnable', !tourEnable)}
        >
          <OverlayTrigger
            key="left"
            placement={isRTL ? 'bottom' : 'left'}
            overlay={
              <Tooltip id="tourView">
                {tourEnable ? 'Get help about page' : 'Close help about page'}
              </Tooltip>
            }
          >
            <div className="theme-control-toggle-label">
              <FontAwesomeIcon
                icon={faLifeRing}
                className={`fa-0 fs-0 ${tourEnable ? 'text-danger' : 'text-primary'
                  }`}
              />
            </div>
          </OverlayTrigger>
        </Nav.Link>
      </Nav.Item> */}
      <Nav.Item as={'li'}>
        <Nav.Link
          className="px-1 theme-control-toggle"
          onClick={() => setConfig('isDark', !isDark)}
        >
          <OverlayTrigger
            key="left"
            placement={isRTL ? 'bottom' : 'left'}
            overlay={
              <Tooltip id="ThemeColor">
                {isDark ? 'Switch to light theme' : 'Switch to dark theme'}
              </Tooltip>
            }
          >
            <div className="theme-control-toggle-label">
              <FontAwesomeIcon
                icon={isDark ? 'sun' : 'moon'}
                className="fs-0"
              />
            </div>
          </OverlayTrigger>
        </Nav.Link>
      </Nav.Item>

      <NotificationDropdown />
      {/* <NineDotMenu /> */}
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
