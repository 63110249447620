import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import {
  faCheckCircle,
  faExclamationCircle,
  faPen,
  faStar
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import segmentHelper from 'helpers/segment-helper';
import userHelper from 'helpers/userHelper';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useState } from 'react';
import { Button, Col, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { refreshUserProfile } from 'stores/app';
import { showAlert, showConfirm } from 'stores/modal';
import api from 'util/api';
import logger from 'util/logger';

export const NotaryItem = ({ details, isLast, updateProfile, formRef }) => {
  const dispatch = useDispatch();
  const [isDeletingProfile, setDeletingProfile] = useState(null);
  const [toggleFavorite, setToggleFavorite] = useState(null);
  const goToFormRef = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  const isActive =
    details.Active &&
    moment()
      .startOf('day')
      .isSameOrBefore(moment(details.ExpirationDate).utc());
  const deleteNotaryProfileClick = profile => {
    dispatch(
      showConfirm(
        'Are you sure?',
        'Are you sure you want to delete this notary profile?',
        res => {
          if (res) {
            setDeletingProfile(profile.GUID);
            api.Profile.delete(profile.GUID)
              .then(res => {
                setDeletingProfile(null);
                toast.success(
                  `Notary commission profile deleted successfully`,
                  {
                    theme: 'colored'
                  }
                );
                return dispatch(refreshUserProfile());
              })
              .catch(err => {
                setDeletingProfile(null);
                logger.log('error deleting profile', err);
                dispatch(
                  showAlert(
                    'Error Deleting Profile',
                    'There was a problem while deleting your profile.  Please try again, or contact our support.'
                  )
                );
              });
          }
        }
      )
    );
  };

  const handleDefault = e => {
    setToggleFavorite(e.GUID);
    let data = _.cloneDeep(e);
    data.IsDefault = !e.IsDefault;
    let reqFieldsData = _.pickBy(data, function (v, k) {
      return [
        'CommissionedName',
        'CommissionNumber',
        'ExpirationDate',
        'CommissionAddress1',
        'CommissionAddress2',
        'CommissionAddress3',
        'CommissionCity',
        'CommissionState',
        'CommissionProvince',
        'CommissionCountry',
        'CommissionPostalCode',
        'Telephone',
        'LocalTimezone',
        'IsDefault'
      ].includes(k);
    });
    api.Profile.update(e.GUID, reqFieldsData)
      .then(res => {
        if (!e.editProfileGUID) {
          segmentHelper.track(segmentHelper.events.ADD_NEW_NOTARY_PROFILE);
        }
        logger.log('update profile success', res);
        return dispatch(refreshUserProfile());
      })
      .then(res => {
        dispatch(
          showAlert('Success', 'Commission profile saved successfully.')
        );
      })
      .catch(err => {
        console.warn('error saving profile', err);
        dispatch(
          showAlert(
            'Unable to save profile',
            'There was a problem saving your profile.  Please try again.'
          )
        );
      });
  };
  return (
    <tr className="align-middle">
      <td className="cursor-pointer">
        {toggleFavorite === details?.GUID ? (
          <Spinner size="sm" />
        ) : (
          <FontAwesomeIcon
            className={details?.IsDefault ? 'text-primary' : ''}
            icon={faStar}
            style={{ marginLeft: 10, fontSize: 18 }}
            onClick={() => handleDefault(details)}
          />
        )}
      </td>
      <td className="text-nowrap">
        <div className="d-flex align-items-center">
          <Avatar
            className={isActive ? 'status-online' : 'status-offline'}
            variant={isActive ? 'success' : 'dager'}
            size="l"
            name={details.CommissionedName}
          />
        </div>
      </td>
      {/* 'CommissionedName', 'CommissionNumber', 'ExpirationDate', 'CommissionAddress1', 'CommissionAddress2', 'CommissionAddress3', 'CommissionCity', 'CommissionState', 'CommissionProvince', 'CommissionCountry', 'CommissionPostalCode', 'Telephone' */}
      <td className="text-nowrap">
        {details.CommissionedName}
        <br />
        {userHelper.getTimezoneObj(details.LocalTimezone)?.name}
      </td>
      <td className="text-nowrap">{details.CommissionNumber}</td>
      <td className="text-nowrap">
        {details.CommissionAddress1} {details.CommissionAddress2}{' '}
        {details.CommissionCity} {details.CommissionState}-
        {details.CommissionPostalCode}
      </td>
      <td className="text-nowrap">
        <SoftBadge pill bg={isActive ? 'success' : 'danger'}>
          {isActive ? 'Active' : 'Expired'}
          <FontAwesomeIcon
            icon={isActive ? faCheckCircle : faExclamationCircle}
            className="ms-2"
          />
        </SoftBadge>
        <br />
        {moment(details.ExpirationDate).local().format('dddd, ll')}
      </td>
      <td className="text-end">
        <Button
          className="mx-1"
          variant="primary"
          ref={goToFormRef}
          type="button"
          onClick={() => updateProfile(details.GUID)}
        >
          <FontAwesomeIcon icon={faPen} />
        </Button>
        <Button
          className="mx-1"
          disabled={isDeletingProfile == details.GUID}
          variant="outline-danger"
          type="submit"
          onClick={() => deleteNotaryProfileClick(details)}
        >
          {isDeletingProfile == details.GUID ? (
            <Spinner size="sm" />
          ) : (
            <FontAwesomeIcon icon={faTrashAlt} />
          )}
        </Button>
      </td>
    </tr>
    // <Flex>
    //   <div className="flex-1 position-relative ps-3">
    //     <Flex>
    //       <Col sm={1}>
    //         <FontAwesomeIcon icon={isActive ? faCheckCircle : faExclamationCircle} className={isActive ? "text-success" : "text-danger"} /> {isActive ? "Active" : "Expired"}
    //       </Col>
    //       <Col sm={2}>
    //         <p className="text-1000 mb-0">Name: {details.CommissionedName}</p>
    //       </Col>
    //       <Col sm={6}>
    //         <p className="text-1000 mb-0">Commission Number: {details.CommissionNumber}</p>
    //         <p className="text-1000 mb-0">Expiration: {moment(details.ExpirationDate).format('dddd, ll')}</p>
    //       </Col>
    //       <Col sm={6}>
    //         <p className="text-1000 mb-0">State: {details.CommissionState}</p>
    //         <p className="text-1000 mb-0">County: {details.CommissionProvince}</p>
    //       </Col>
    //     </Flex>

    //     {!isLast && <div className="border-dashed border-bottom my-3"></div>}
    //   </div>
    //   <div className='position-relative ps-3 text-end'>
    //     <p>
    //       <Button variant="primary" ref={goToFormRef} type="button" onClick={() => updateProfile(details.GUID)}>
    //         <FontAwesomeIcon icon={faPen} /> Update
    //       </Button></p>
    //     <p><Button disabled={isDeletingProfile == details.GUID} variant="outline-danger" type="submit" onClick={() => deleteNotaryProfileClick(details)}>
    //       <FontAwesomeIcon icon={faTrashAlt} /> {isDeletingProfile == details.GUID ? "Deleting" : "Delete"}
    //     </Button></p>

    //   </div>
    // </Flex>
  );
};

NotaryItem.propTypes = {
  details: PropTypes.shape({
    logo: PropTypes.string,
    institution: PropTypes.string,
    degree: PropTypes.string,
    duration: PropTypes.string,
    location: PropTypes.string,
    verified: PropTypes.bool
  }),
  isLast: PropTypes.bool
};

export default NotaryItem;
