import config from './config';
import c from './const';
import juratCrypto from './crypto';
import storageHelper from './storage-helper';

const juratLocalStorage = {
  get(key) {
    if (!localStorage) {
      console.error('local storage does not exist');
      return null;
    }
    try {
      return config.cryptography
        ? JSON.parse(juratCrypto.decrypt(localStorage.getItem(key)) || null)
        : JSON.parse(localStorage.getItem(key) || null);
    } catch (e) {
      return null;
    }
  },

  set(key, val) {
    if (!localStorage) {
      console.error('local storage does not exist');
      return null;
    }
    try {
      return config.cryptography
        ? localStorage.setItem(key, juratCrypto.encrypt(JSON.stringify(val)))
        : localStorage.setItem(key, JSON.stringify(val));
    } catch (e) {
      console.error('local storage error', e);
      return null;
    }
  },

  remove(key) {
    if (!localStorage) {
      console.error('local storage does not exist');
      return null;
    }

    return localStorage.removeItem(key);
  },

  clear() {
    if (!localStorage) {
      console.error('local storage does not exist');
      return null;
    }

    return localStorage.clear();
  },

  testStorage() {
    return storageHelper.storageAvailable('localStorage');
  }
};

export default juratLocalStorage;
