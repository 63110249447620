import FalconCardHeader from 'components/common/FalconCardHeader';
import { useAuth } from 'hooks/useAuth';
import React, { useState } from 'react';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { cleanup } from 'stores/app';
import { showAlert } from 'stores/modal';
import api from 'util/api';

const ChangePassword = () => {
  const dispatch = useDispatch();
  const { userMaster } = useSelector(state => state.app);
  const auth = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    isUpdating: false
  });

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
  };

  const doValidation = () => {
    if (formData.oldPassword.length === 0) {
      toast.warning(`Please enter your Current Password.`, {
        theme: 'colored'
      });
    } else if (formData.newPassword.length === 0) {
      toast.warning(`Please enter your New Password.`, {
        theme: 'colored'
      });
    } else if (formData.confirmPassword.length === 0) {
      toast.warning(`Please confirm your New Password.`, {
        theme: 'colored'
      });
    } else if (formData.newPassword !== formData.confirmPassword) {
      toast.warning(`Your passwords do not match.`, {
        theme: 'colored'
      });
    } else return true;
  };

  const save = () => {
    if (!doValidation()) {
      return;
    }
    setFormData({
      ...formData,
      isUpdating: true
    });
    api.UserMaster.changePassword(
      userMaster.GUID,
      userMaster.Email,
      formData.oldPassword,
      formData.newPassword
    )
      .then(res => {
        setFormData({
          ...formData,
          isUpdating: false
        });
        dispatch(
          showAlert(
            'Success',
            'Your password was successfully reset.  Now please log in.',
            () => {
              dispatch(cleanup);
              auth.unsetAuth();
              navigate('/login');
            }
          )
        );
      })
      .catch(err => {
        setFormData({
          ...formData,
          isUpdating: false
        });
        toast.warning(
          `Unable to save', "There was a problem saving your information.  Please try again.`,
          {
            theme: 'colored'
          }
        );
      });
  };

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Change Password" />
      <Card.Body className="bg-light">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="oldPassword">
            <Form.Label>Old Password</Form.Label>
            <Form.Control
              type="password"
              value={formData.oldPassword}
              name="oldPassword"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="newPassword">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              type="password"
              value={formData.newPassword}
              name="newPassword"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="confirmPassword">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              value={formData.confirmPassword}
              name="confirmPassword"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3 d-flex justify-content-end">
            <Button
              variant="primary"
              type="submit"
              disabled={formData.isUpdating}
              onClick={save}
            >
              {formData.isUpdating ? (
                <Spinner size="sm" variant="light" />
              ) : (
                'Update Password'
              )}
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ChangePassword;
