const c = {
  juratCryptoKey:
    'AHdDCKJqURYB2BCNfMLO3KJPQBAVkruiZqUpFREFDHDKUvc94RHDT5EOS8swER5C',
  dateFormat: 'dddd, MMMM Do YYYY, h:mm:ss a z',
  actions: {
    app: {
      startup: 'app.actions.startup',
      setUser: 'app.actions.setUser',
      setSavedLocations: 'docs.actions.setSavedLocations',
      setUserProfile: 'app.actions.setUserProfile',
      setSubscriptions: 'app.actions.setSubscriptions',
      setBluetoothMgr: 'app.actions.setBluetoothMgr',
      setSegmentClient: 'app.actions.setSegmentClient',
      setExpectedOutsideNavigation: 'app.actions.setExpectedOutsideNavigation',
      setDraftSaveStatus: 'app.actions.setDraftSaveStatus',
      setHasLoadedAnEntryBefore: 'app.actions.setHasLoadedAnEntryBefore',
      cleanup: 'app.actions.cleanup',
      networkStatusOffline: 'app.action.networkStatusOffline',
      networkStatusOnline: 'app.action.networkStatusOnline',
      maxTrialSignings: 5,
      maxTrialDays: 14
    },
    docs: {
      setDocumentList: 'docs.actions.setDocumentList',
      setDocumentCollections: 'docs.actions.setDocumentCollections',
      cleanup: 'docs.actions.cleanup'
    },
    auth: {
      setEmail: 'auth.actions.setEmail',
      setOfflineMode: 'auth.actions.setOfflineMode',
      setToken: 'auth.actions.setToken',
      setDeviceGUID: 'auth.actions.setDeviceGUID',
      cleanup: 'auth.actions.cleanup',
      logedinUserProfile: 'auth.logedin.user.profile'
    },
    newEntry: {
      start: 'app.actions.startNewEntry',
      loadDraft: 'app.actions.loadDraft',

      setSigningFinished: 'app.actions.setSigningFinished',

      logSigningAction: 'app.action.logSigningAction',
      logMultipleSigningActions: 'app.action.logMultipleSigningActions',

      setLocationInfo: 'app.actions.setLocationInfo',
      addSignature: 'app.action.addSignature',
      addSignatureByMark: 'app.action.addSignatureByMark',
      removeSignature: 'app.action.removeSignature',
      addNotarizationDocuments: 'app.action.addNotarizationDocuments',
      updateNotarizationDocument: 'app.action.updateNotarizationDocument',
      removeNotarizationDocument: 'app.action.removeNotarizationDocument',
      addFingerprint: 'app.action.addFingerprint',
      removeFingerprint: 'app.action.removeFingerprint',
      setNotarizationDetails: 'app.action.setNotarizationDetails',

      addSigner: 'app.action.addSigner',
      removeSigner: 'app.action.removeSigner',
      cleanSigners: 'app.action.cleanSigners',

      addIdentification: 'app.action.addIdentification',
      removeIdentification: 'app.action.removeIdentification',
      setSignerName: 'app.actions.setSignerName',
      setSignerIdType: 'app.actions.setSignerIdType',

      addWitnessIdentification: 'app.action.addWitnessIdentification',
      setWitnessLocation: 'app.actions.setWitnessLocation',
      setWitnessSignature: 'app.actions.setWitnessSignature',

      addRecipientEmail: 'app.actions.addRecipientEmail',
      removeRecipientEmail: 'app.actions.removeRecipientEmail',

      reset: 'app.actions.reset'
    },
    settings: {
      privacyMode: 'app.settings.privacyMode',
      texasMode: 'app.settings.texasMode',
      oathScript: 'app.settings.oathScript'
    }
  },
  seo: {
    appName: 'Jurat Inc.',
    lang: 'en-US',
    title: 'Welcome to electronic notary journal',
    description:
      "Jurat Inc's electronic notary journal is the only notary journal you will need. The first notary journal app with digital thumbprint capture technology",
    keyword: 'electronic notary journal, digital thumbprint, capture technology'
  },
  querystring: {
    email: 'email',
    code: 'code',
    auth_token: 'auth',
    verifyType: 'verify_type',
    leadSource: 'leadsource',
    campaignId: 'campaignid',
    signingGUID: 'signing_guid',
    flow: 'flow'
  },
  entrySteps: {
    STEP_1: 'app.steps.step1',
    STEP_2: 'app.steps.step2',
    STEP_3: 'app.steps.step3',
    STEP_4: 'app.steps.step4',
    STEP_5: 'app.steps.step5',
    STEP_6: 'app.steps.step6'
  },
  draftStatus: {
    Saved: 'app.draftstatus.saved',
    Saving: 'app.draftstatus.saving',
    Error: 'app.draftstatus.error',
    None: 'app.draftstatus.none',
    Finished: 'app.draftstatus.finished',
    Completed: 'app.draftstatus.completed'
  },
  signaturePad: {
    signer: 'app.signatureId.signer',
    witness_one: 'app.signatureId.witness_one',
    witness_two: 'app.signatureId.witness_two',
    witness_two_two: 'app.signatureId.witness_two_two'
  },
  device: { status_blocked: 'device.status.blocked' },
  storage: {
    token: 'jurat.app.storage.token',
    user: 'jurat.app.storage.user',
    email: 'jurat.app.storage.email',
    touch_id_enabled: 'jurat.app.storage.touch_id_enabled',
    recent_device_ids: 'jurat.app.storage.recent_device_ids',

    lastSuccessfulAuthentication: 'app.storage.lastSuccessfulAuthentication',
    promptedToSetupTouchId: 'app.storage.promptedToSetupTouchId',

    previouslyAuthenticatedEmails: 'app.storage.previouslyAuthenticatedEmails',
    recent_document_types: 'app.storage.recent_document_types',
    recent_document_types_v2: 'app.storage.recent_document_types_v2',
    offlineEntryIdList: 'app.storage.offlineentrylist',
    offlineEntryIdPrefix: 'app.storage.offline_entry.',
    draftEntryIdList: 'app.storage.drafteentrylist',
    draftEntryIdPrefix: 'app.storage.draft_entry.',
    shownReleaseNotesForVersion: 'app.storage.shownReleaseNotesForVersion.',
    promptedForRating: 'app.storage.promptedForRating',

    currentUserBackup: 'app.storage.currentUserBackup',
    currentUserProfileBackup: 'app.storage.currentUserProfileBackup',
    subscriptionListBackup: 'app.storage.subscriptionListBackup',
    docListBackup: 'app.storage.docListBackup',
    docCollectionsBackup: 'app.storage.docCollectionsBackup',
    savedLocationsBackup: 'app.storage.savedLocationsBackup',

    offlineDocTypeIdList: 'app.storage.offlineDocTypeIdList',
    offlineDocTypeIdPrefix: 'app.storage.offlineDocTypeIdPrefix.',
    offlineSigningPackageIdList: 'app.storage.offlineSigningPackageIdList',
    offlineSigningPackageIdPrefix: 'app.storage.offlineSigningPackageIdPrefix.',

    draftCurrent: 'app.storage.draftCurrent'
  },
  links: {
    cancelSurveyUrl:
      'https://us7.list-manage.com/survey?u=7afefa97ed3d96aa6f528617c&id=17a36dc9cc&attribution=false',
    FAQUrl: 'https://www.juratinc.com/faq'
  },
  mobileApp: {
    store: {
      android: 'https://play.google.com/store/apps/details?id=com.jurat.mobile',
      ios: 'https://apps.apple.com/app/id1517094829'
    }
  },
  adminGuids: ['kevin', 'joel', 'nick'],
  limits: {
    MAX_COLLECTION_DOCS: 64,
    MAX_HOURS_SINCE_SUCCESSFUL_AUTH: 12,

    //This is the amount of time that must elapse before we validate your token with the
    //server again.
    //Important to note a balance with this setting.  If we're in offline mode, this also
    //will dictate how fast we notice that the network has been restored.
    //But on the same end, we don't want to validate the token too frequently,
    //Because it's expensive, and it also could kick off a startup sequence (refreshing user data).
    IGNORE_VALIDATE_TOKEN_THRESHOLD_SECONDS: 15
  },
  api: {
    token: 'x-token',
    email: 'x-email',
    password: 'x-password',
    timeout_ms: 30000,
    signing_upload_timeout_ms: 300000,
    reporting_timeout_ms: 300000,
    retry_count: 3,
    MAX_YEAR: 9999
  },
  packet: {
    ZERO: 0x00,
    CMD_GET_VERSION: 0x05,
    CMD_GET_IMAGE: 0x43
  },
  modal: {
    alert: 'c.modal.alert',
    confirm: 'c.modal.confirm',
    downgradeDialog: 'c.modal.downgradeDialog',
    editProfile: 'c.modal.editProfile',
    editUserMaster: 'c.modal.editUserMaster',
    changePassword: 'c.modal.changePassword',
    viewEntryDialog: 'c.modal.viewEntryDialog',
    submitFeedbackDialog: 'c.modal.submitFeedbackDialog',
    updateCardDialog: 'c.modal.updateCardDialog',
    upgradeDialog: 'c.modal.upgradeDialog',
    couponUpgradeDialog: 'c.modal.couponUpgradeDialog'
  },
  stages: {
    SIGNING: 'c.stage.signing',
    TOKEN: 'c.stage.token',
    PROFILE: 'c.stage.profile',
    DETAILS: 'c.stage.detail',
    SUBSCRIPTION: 'c.stage.subscription',
    PAYMENT: 'c.stage.payment',
    LOGOUT: 'c.stage.logout',
    DASHBOARD: 'c.stage.dashboard',
    NULL: null
  },
  subscriptionStatus: {
    INCOMPLETE: 'incomplete',
    INCOMPLETE_EXPIRED: 'incomplete_expired',
    TRIALING: 'trialing',
    ACTIVE: 'active',
    PAST_DUE: 'past_due',
    CANCELLED: 'canceled',
    UNPAID: 'unpaid',
    PAUSED: 'paused'
  },
  currency: [
    {
      code: 'USD',
      sign: '$'
    },
    {
      code: 'GBP',
      sign: '£'
    }
  ],
  timezones: [
    {
      name: 'Hawaii Time (HST)',
      zone: 'Pacific/Honolulu',
      abbreviation: 'HST',
      offset: '-10:00'
    },
    {
      name: 'Alaska Time (AKDT)',
      zone: 'America/Anchorage',
      abbreviation: 'AKDT',
      offset: '-08:00'
    },
    {
      name: 'Pacific Time (PST/PDT)',
      zone: 'America/Los_Angeles',
      abbreviation: 'PST',
      offset: '-07:00'
    },
    {
      name: 'Mountain Time (MST/MDT)',
      zone: 'America/Denver',
      abbreviation: 'MST',
      offset: '-06:00'
    },
    {
      name: 'Central Time (CST/CDT)',
      zone: 'America/Chicago',
      abbreviation: 'CST',
      offset: '-05:00'
    },

    {
      name: 'Eastern Time (EST/EDT)',
      zone: 'America/New_York',
      abbreviation: 'EST',
      offset: '-04:00'
    }
  ],
  months: [
    {
      number: '01',
      text: 'Jan'
    },
    {
      number: '02',
      text: 'Feb'
    },
    {
      number: '03',
      text: 'Mar'
    },
    {
      number: '04',
      text: 'Apr'
    },
    {
      number: '05',
      text: 'May'
    },
    {
      number: '06',
      text: 'Jun'
    },
    {
      number: '07',
      text: 'Jul'
    },
    {
      number: '08',
      text: 'Aug'
    },
    {
      number: '09',
      text: 'Sep'
    },
    {
      number: '10',
      text: 'Oct'
    },
    {
      number: '11',
      text: 'Nov'
    },
    {
      number: '12',
      text: 'Dec'
    }
  ],
  country: [
    {
      name: 'USA',
      text: 'USA'
    }
  ],
  states: [
    {
      name: 'Alabama',
      text: 'AL'
    },
    {
      name: 'Alaska',
      text: 'AK'
    },
    {
      name: 'American Samoa',
      text: 'AS'
    },
    {
      name: 'Arizona',
      text: 'AZ'
    },
    {
      name: 'Arkansas',
      text: 'AR'
    },
    {
      name: 'California',
      text: 'CA'
    },
    {
      name: 'Colorado',
      text: 'CO'
    },
    {
      name: 'Connecticut',
      text: 'CT'
    },
    {
      name: 'Delaware',
      text: 'DE'
    },
    {
      name: 'District Of Columbia',
      text: 'DC'
    },
    {
      name: 'Federated States Of Micronesia',
      text: 'FM'
    },
    {
      name: 'Florida',
      text: 'FL'
    },
    {
      name: 'Georgia',
      text: 'GA'
    },
    {
      name: 'Guam',
      text: 'GU'
    },
    {
      name: 'Hawaii',
      text: 'HI'
    },
    {
      name: 'Idaho',
      text: 'ID'
    },
    {
      name: 'Illinois',
      text: 'IL'
    },
    {
      name: 'Indiana',
      text: 'IN'
    },
    {
      name: 'Iowa',
      text: 'IA'
    },
    {
      name: 'Kansas',
      text: 'KS'
    },
    {
      name: 'Kentucky',
      text: 'KY'
    },
    {
      name: 'Louisiana',
      text: 'LA'
    },
    {
      name: 'Maine',
      text: 'ME'
    },
    {
      name: 'Marshall Islands',
      text: 'MH'
    },
    {
      name: 'Maryland',
      text: 'MD'
    },
    {
      name: 'Massachusetts',
      text: 'MA'
    },
    {
      name: 'Michigan',
      text: 'MI'
    },
    {
      name: 'Minnesota',
      text: 'MN'
    },
    {
      name: 'Mississippi',
      text: 'MS'
    },
    {
      name: 'Missouri',
      text: 'MO'
    },
    {
      name: 'Montana',
      text: 'MT'
    },
    {
      name: 'Nebraska',
      text: 'NE'
    },
    {
      name: 'Nevada',
      text: 'NV'
    },
    {
      name: 'New Hampshire',
      text: 'NH'
    },
    {
      name: 'New Jersey',
      text: 'NJ'
    },
    {
      name: 'New Mexico',
      text: 'NM'
    },
    {
      name: 'New York',
      text: 'NY'
    },
    {
      name: 'North Carolina',
      text: 'NC'
    },
    {
      name: 'North Dakota',
      text: 'ND'
    },
    {
      name: 'Northern Mariana Islands',
      text: 'MP'
    },
    {
      name: 'Ohio',
      text: 'OH'
    },
    {
      name: 'Oklahoma',
      text: 'OK'
    },
    {
      name: 'Oregon',
      text: 'OR'
    },
    {
      name: 'Palau',
      text: 'PW'
    },
    {
      name: 'Pennsylvania',
      text: 'PA'
    },
    {
      name: 'Puerto Rico',
      text: 'PR'
    },
    {
      name: 'Rhode Island',
      text: 'RI'
    },
    {
      name: 'South Carolina',
      text: 'SC'
    },
    {
      name: 'South Dakota',
      text: 'SD'
    },
    {
      name: 'Tennessee',
      text: 'TN'
    },
    {
      name: 'Texas',
      text: 'TX'
    },
    {
      name: 'Utah',
      text: 'UT'
    },
    {
      name: 'Vermont',
      text: 'VT'
    },
    {
      name: 'Virgin Islands',
      text: 'VI'
    },
    {
      name: 'Virginia',
      text: 'VA'
    },
    {
      name: 'Washington',
      text: 'WA'
    },
    {
      name: 'West Virginia',
      text: 'WV'
    },
    {
      name: 'Wisconsin',
      text: 'WI'
    },
    {
      name: 'Wyoming',
      text: 'WY'
    }
  ],
  additionFeeTypes: [
    'Travel fee',
    'Print fee',
    'Loan signing fee',
    'Document preparation fee',
    'Witness fee',
    'Courier/Delivery fee',
    'Parking fee',
    'Toll fee',
    'Waiting/Stand-by fee',
    'Other'
  ],
  fidelity: {
    Daily: 'Daily',
    Weekly: 'Weekly',
    Monthly: 'Monthly'
  }
};

export default c;
