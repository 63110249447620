import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useEffect } from 'react';
import { Col, Form } from 'react-bootstrap';
import { usePlacesWidget } from 'react-google-autocomplete';
import c from 'util/const';
import logger from 'util/logger';

const GooglePlaceSearch = ({ setSearchedAddress, col }) => {
  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    onPlaceSelected: place => drawGoogleAddress(place),
    debounce: 500,
    options: {
      types: ['street_number', 'route', 'locality', 'administrative_area_level_1', 'postal_code'],
      componentRestrictions: { country: 'us' },
      fields: ['ALL']
    }
  });

  const drawGoogleAddress = place => {
    if (place) {
      logger.log('Google address drawGoogleAddress', place);
      let parsed = parseGoogleResults(place.address_components);
      let translated = parseIntoServerTerms(parsed);
      logger.log('Google address translated', translated);
      setSearchedAddress(translated);
    }
  };

  const parseGoogleResults = address_components => {
    let components = {};
    _.each(address_components, function (k, v1) {
      _.each(address_components[v1].types, function (k2, v2) {
        components[address_components[v1].types[v2]] =
          address_components[v1].long_name;
      });
    });
    logger.log('Google address parseGoogleResults', components);
    return components;
  };

  const parseIntoServerTerms = res => {
    logger.log('Google address parseIntoServerTerms', res);
    let address_1 = '';
    let address_2 = '';
    let city = '';
    let state = '';
    let zip = '';
    let latitude = '';
    let longitude = '';
    let notarization_date = null;

    if (res.street_number) {
      address_1 += res.street_number;
    }

    if (res.route) {
      if (address_1.length > 0) {
        address_1 += ' ';
      }
      address_1 += res.route;
    }

    if (res.locality) {
      city = res.locality;
    }
    if (res.administrative_area_level_1) {
      state = lookupStateAbbreviation(res.administrative_area_level_1);
    }
    if (res.postal_code) {
      zip = res.postal_code;
    }

    return {
      address_1,
      address_2,
      city,
      state,
      zip,
      latitude: res.latitude,
      longitude: res.longitude
    };
  };

  const lookupStateAbbreviation = val => {
    let found = null;
    found = _.find(c.states, s => {
      return s.text === val.toUpperCase() || s.name === _.capitalize(val);
    });
    logger.log('State found', found, val);
    return found ? found.text : '';
  };

  return (
    <Col sm={col}>
      <Form.Control
        type="text"
        placeholder="Type to search your address here..."
        ref={ref}
      />
    </Col>
  );
};
GooglePlaceSearch.propTypes = {
  setSearchedAddress: PropTypes.func.isRequired,
  col: PropTypes.number
};
export default GooglePlaceSearch;
