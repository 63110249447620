import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Logo from 'components/common/Logo';
import Section from 'components/common/Section';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

const UnProtectedLayout = ({ children }) => {
    const navigate = useNavigate();
    const { token } = useAuth();

    // if (token !== null) {
    //     navigate('/');
    //     return;
    //     //return <Navigate to="/" />;
    // }

    return children;
};

export default UnProtectedLayout;
