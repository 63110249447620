import ActionButton from 'components/common/ActionButton';
import FalconComponentCard from 'components/common/FalconComponentCard';
import PageHeader from 'components/common/PageHeader';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  InputGroup,
  Offcanvas,
  Row,
  Spinner,
  Table
} from 'react-bootstrap';

import {
  faLocationArrow,
  faPlusCircle,
  faUserCheck
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import corner3 from 'assets/img/illustrations/corner-3.png';
import Flex from 'components/common/Flex';
import locationHelper from 'helpers/location-helper';
import _ from 'lodash';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import SimpleBarReact from 'simplebar-react';
import { showAlert, showConfirm } from 'stores/modal';
import api from 'util/api';
import logger from 'util/logger';
import FavoriteAddressForm from './FavoriteAddressForm';
import { matchSorter } from 'match-sorter';

const FavoriteLocations = () => {
  const [favoriteLocations, setFavoriteLocations] = useState([]);
  const [favoriteLocationsDisplay, setFavoriteLocationsDisplay] = useState([]);
  const [loading, setLoading] = useState(false);
  const [favoriteLocationLoadError, setFavoriteLocationsLoadError] =
    useState(false);

  const [sortBy, setSortBy] = useState('Created');
  const [isAsc, setIsAsc] = useState(false);

  const [preAddress, setPreAddress] = useState(null);
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [formTitle, setFormTitle] = useState(null);
  const [savingLocation, setSavingLocation] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const dispatch = useDispatch();

  const loadFavoriteLocations = () => {
    setLoading(true);
    api.SavedLocations.list()
      .then(res => {
        setLoading(false);
        setFavoriteLocations(
          _.sortBy(res, d => {
            return -moment(d.Created).unix();
          })
        );
        setFavoriteLocationsLoadError(false);
      })
      .catch(err => {
        setLoading(false);
        logger.log('error loading favorite locations', err);
        setFavoriteLocationsLoadError(true);
      });
  };

  const saveFavouriteLocation = location => {
    setSavingLocation(true);
    if (preAddress == null) {
      api.SavedLocations.add(
        location.location_name,
        location.address_1,
        location.address_2,
        location.city,
        location.state,
        location.zip
      )
        .then(res => {
          setSavingLocation(false);
          loadFavoriteLocations();
          setShowAddressForm(false);
        })
        .catch(err => {
          setSavingLocation(false);
          dispatch(
            showAlert(
              'Error Deleting Favorite location',
              'There was a problem removing this favoritel Location.  Please try again.'
            )
          );
          loadFavoriteLocations();
        });
    } else {
      api.SavedLocations.update(
        preAddress.GUID,
        location.location_name,
        location.address_1,
        location.address_2,
        location.city,
        location.state,
        location.zip
      )
        .then(res => {
          setSavingLocation(false);
          loadFavoriteLocations();
          setShowAddressForm(false);
        })
        .catch(err => {
          setSavingLocation(false);
          dispatch(
            showAlert(
              'Error Deleting Favorite location',
              'There was a problem removing this favoritel Location.  Please try again.'
            )
          );
          loadFavoriteLocations();
        });
    }
  };

  const MarkFavoriteLocation = favoriteLocation => {
    let location = Object.assign({}, favoriteLocation);

    api.SavedLocations.update(
      location.GUID,
      location.DisplayName,
      location.Address1,
      '',
      location.City,
      location.State,
      location.PostalCode,
      !location.IsFavorite
    )
      .then(res => {
        setSavingLocation(false);
        loadFavoriteLocations();
        setShowAddressForm(false);
      })
      .catch(err => {
        setSavingLocation(false);
        dispatch(
          showAlert(
            'Error marking Favorite location',
            'There was a problem marking this favoritel Location.  Please try again.'
          )
        );
        loadFavoriteLocations();
      });
  };

  const editFavoriteLocation = favoriteLocation => {
    setPreAddress(favoriteLocation);
    setFormTitle('Update location');
    setShowAddressForm(true);
  };

  const deleteFavoriteLocation = favoriteLocation => {
    dispatch(
      showConfirm(
        'Are you sure?',
        'Are you sure you want to remove this favorite location?',
        res => {
          if (res) {
            api.SavedLocations.remove(favoriteLocation.GUID)
              .then(res => {
                toast.success(`Location removed successfully`, {
                  theme: 'colored',
                  position: 'bottom-center',
                  icon: faUserCheck,
                  toastId: 'login-success'
                });
                loadFavoriteLocations();
              })
              .catch(err => {
                showAlert(
                  'Error Deleting Favorite location',
                  'There was a problem removing this favoritel Location.  Please try again.'
                );
                loadFavoriteLocations();
              });
          }
        }
      )
    );
  };

  const addNewLocation = location => {
    setPreAddress(null);
    setFormTitle('Add new location');
    setShowAddressForm(true);
  };

  const searchItems = e => {
    let keyword = e.target.value.toLowerCase();
    let filterFavoriteLocations = _.filter(
      favoriteLocations,
      d =>
        d.DisplayName.toLowerCase().includes(keyword) ||
        d.Address1.toLowerCase().includes(keyword) ||
        d.PostalCode.toLowerCase().includes(keyword) ||
        moment.utc(d.Created).local().format('l, LT').includes(keyword)
    );
    let sortedItems = matchSorter(filterFavoriteLocations, keyword, {
      keys: ['DisplayName', 'Address1', 'PostalCode']
    });
    setFavoriteLocationsDisplay(sortedItems);
  };

  useEffect(() => {
    let sortedFavoriteLocations = _.orderBy(
      favoriteLocationsDisplay,
      [sortBy],
      [isAsc ? 'asc' : 'desc']
    );
    setFavoriteLocationsDisplay(sortedFavoriteLocations);
  }, [sortBy, isAsc]);

  useEffect(() => {
    setFavoriteLocationsDisplay(favoriteLocations);
  }, [favoriteLocations]);

  useEffect(() => {
    loadFavoriteLocations();
  }, []);

  return (
    <>
      <PageHeader
        preTitle="Locations"
        title="Favorite Location list"
        description=""
        className="mb-3"
        titleTag="h2"
        image={corner3}
      />

      <Row className="mb-3 g-3">
        <Col lg={12}>
          <FalconComponentCard noGuttersBottom>
            <FalconComponentCard.Header
              title="Favorite Locations"
              endEl={
                <Flex className={'g-2'}>
                  <div>
                    <Form as={Row} className="gx-2 d-flex align-items-center">
                      <Col xs="auto">
                        <small>Sort by:</small>
                      </Col>
                      <Col xs="auto">
                        <InputGroup>
                          <Form.Select
                            className="pe-5"
                            defaultValue="Created"
                            onChange={({ target }) => setSortBy(target.value)}
                          >
                            <option value="DisplayName">Name</option>
                            <option value="IsFavorite">Favorite</option>
                            <option value="Created">Created</option>
                          </Form.Select>
                          <InputGroup.Text
                            as={Button}
                            variant="link"
                            className="border border-300 text-700"
                            onClick={() => setIsAsc(!isAsc)}
                          >
                            <FontAwesomeIcon
                              icon={
                                isAsc ? 'sort-amount-up' : 'sort-amount-down'
                              }
                            />
                          </InputGroup.Text>
                        </InputGroup>
                      </Col>
                    </Form>
                  </div>
                  <div className="mx-1">
                    <Form.Control
                      onChange={e => searchItems(e)}
                      placeholder="search location..."
                      name="search"
                    ></Form.Control>
                  </div>
                  <div className="mx-1">
                    <Button variant="primary" onClick={() => addNewLocation()}>
                      <FontAwesomeIcon icon={faPlusCircle} size="sm" /> Add New
                    </Button>
                  </div>
                </Flex>
              }
            />
            <FalconComponentCard.Body noLight className="py-0">
              {loading && (
                <Flex justifyContent="center" className="py-3">
                  <Spinner size="sm" />
                </Flex>
              )}
              {favoriteLocationsDisplay.length !== 0 &&
                !favoriteLocationLoadError &&
                !loading && (
                  <SimpleBarReact>
                    <Table bordered>
                      <colgroup>
                        <col />
                        <col />
                        <col className="d-sm-none d-md-block" />
                        <col />
                      </colgroup>
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Location</th>
                          <th scope="col">Created</th>
                          <th className="text-end" scope="col">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.map(favoriteLocationsDisplay, e => {
                          let createdDate = moment
                            .utc(e.Created)
                            .local()
                            .format('l, LT');

                          return (
                            <tr key={e.GUID}>
                              <td>{e.DisplayName}</td>
                              <td>
                                {e.Address1}, {e.City}{' '}
                                {locationHelper.getStateNameByCode(e.State)} -{' '}
                                {e.PostalCode}
                              </td>
                              <td>{createdDate}</td>
                              <td className="text-end">
                                <ActionButton
                                  icon="star"
                                  title={
                                    e.IsFavorite
                                      ? 'Remove Favorite'
                                      : 'Mark Favorite'
                                  }
                                  variant="action"
                                  className={
                                    e.IsFavorite
                                      ? 'p-0 me-3 text-primary'
                                      : 'p-0 me-3 text-secondary'
                                  }
                                  onClick={() => MarkFavoriteLocation(e)}
                                />

                                <ActionButton
                                  icon="edit"
                                  title="Edit"
                                  label="Edit"
                                  variant="action"
                                  className="p-0 me-3 text-primary"
                                  onClick={() => editFavoriteLocation(e)}
                                />
                                <ActionButton
                                  icon="trash-alt"
                                  title="Remove"
                                  label="Remove"
                                  variant="action"
                                  className="p-0 text-danger"
                                  onClick={() => deleteFavoriteLocation(e)}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </SimpleBarReact>
                )}
              {favoriteLocations.length === 0 &&
                !loading &&
                (favoriteLocationLoadError ? (
                  <div className="text-center">
                    <FontAwesomeIcon icon={faLocationArrow} size="3x" />
                    <br />
                    <p className="my-3">
                      Unbale to load favorite location list. Try again
                    </p>
                  </div>
                ) : (
                  <div className="text-center">
                    <FontAwesomeIcon icon={faLocationArrow} size="3x" />
                    <br />
                    <p className="my-3">
                      You don't have any favorite locations yet. <br />
                      Click "Add new" to add a location to your favorites. We
                      recommend adding your office or any regular place of
                      business where you normally meet with clients
                    </p>
                  </div>
                ))}
            </FalconComponentCard.Body>
          </FalconComponentCard>
        </Col>
      </Row>

      <Offcanvas
        id="offcanvas-address-form"
        show={showAddressForm}
        backdrop="static"
        onHide={() => setShowAddressForm(false)}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{formTitle}</Offcanvas.Title>
        </Offcanvas.Header>
        <hr className="my-0" />
        <Offcanvas.Body>
          <FavoriteAddressForm
            preAddress={preAddress}
            setAddressValue={saveFavouriteLocation}
            setShowAddressForm={setShowAddressForm}
            savingLocation={savingLocation}
            register={register}
            errors={{}}
            col={12}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default FavoriteLocations;
