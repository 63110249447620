import request from 'superagent';
import pkg from '../../package.json';
import apiHelper from './apiHelper';
import config from './config';
import c from './const';
import enums from './enums';
import filters from './filters';
import logger from './logger';
import utils from './utils';
import moment from 'moment';
import draftEntryHelper from 'helpers/draft-entry-helper';
import api from './api';

const apiReport = {};

apiReport.endpoint = config.reportEndpoint;
apiReport.token = null;

apiReport.hasAuthentication = () => {
  return !!apiReport.token;
};

apiReport.JournalReports = {
  uri: '/api/journal-export',

  url(action) {
    return apiReport.apiRoot(this.uri, action);
  },

  export(data) {
    return request
      .get(this.url())
      .timeout({ response: c.api.timeout_ms })
      .set(apiReport.globalHeaders())
      .send({})
      .query(data)
      .then(apiHelper.responseHdl)
      .catch(apiHelper.errorHdl);
  }
};

apiReport.setEndpoint = endpoint => {
  apiReport.endpoint = endpoint;
};

apiReport.apiRoot = (uri, action) => {
  return apiReport.endpoint + uri + (action ? `/${action}` : '');
};

apiReport.globalHeaders = () => {
  let headers = {};

  let token = api.token;
  if (token && token.length > 0) {
    headers[c.api.token] = token;
  }

  return headers;
};

apiReport.setToken = token => {
  apiReport.token = token;
};

apiReport.getToken = () => {
  return apiReport.token;
};

export default apiReport;
