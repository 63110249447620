import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import React, { useRef, useState } from 'react';
import { Button, Card, Collapse, Table } from 'react-bootstrap';
import NotaryItem from './NotaryItem';
import NotaryProfileForm from './NotaryProfileForm';
import logger from 'util/logger';

const NotarySettings = (props) => {
  const [profile, setProfile] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const formRef = useRef(null);


  const updateProfile = (GUID) => {
    let uProfile = _.filter(props.userProfile, (p) => {
      return p.GUID === GUID
    });
    logger.log("Updating profile", uProfile);
    setCollapsed(true);
    setProfile(uProfile[0]);
  }
  return (
    <Card className="mt-3">
      <FalconCardHeader title={null} />
      <Card.Body className="fs--1 bg-light">
        <div ref={formRef}>
          <Flex
            alignItems="center"
            className="mb-4 text-primary cursor-pointer fs-0"
            onClick={() => {
              setCollapsed(true);
              setProfile(null);
            }}
          >
            <span className="circle-dashed">
              <FontAwesomeIcon icon="plus" />
            </span>
            <span className="ms-3">Add new Notary Profile</span>
          </Flex>
          <Collapse in={collapsed}>
            <div>
              <NotaryProfileForm profile={profile}
                setCollapsed={setCollapsed} />
              <div className="border-dashed border-bottom my-3" />
            </div>
          </Collapse>
        </div>

        <Table responsive striped hover>
          <thead>
            <tr>
              <th scope="col">Default</th>
              <th scope="col">Status</th>
              <th scope="col">Name</th>
              <th scope="col">C Number</th>
              <th scope="col">Address</th>
              <th scope="col">Expiration</th>
              <th className="text-end" scope="col">
                Action
              </th>
            </tr>
          </thead>
          {props.userProfile.length ? <tbody>
            {props.userProfile.map((item, index) => (
              <NotaryItem
                key={item.GUID}
                details={item}
                isLast={index === props.userProfile.length - 1}
                updateProfile={updateProfile}
                formRef={formRef}
              />
            ))}
          </tbody> : <tr>
            <td colSpan={6} scope="col"><div className="text-center">
              <h4>
                <FontAwesomeIcon name="user" />
              </h4>
              <p className="my-3">
                You haven't created your Notary profile yet.
              </p>
              <Button onClick={() => {
                setCollapsed(!collapsed);
              }}
                className="ml-2"
                color="primary">Create Notary Profile</Button>
            </div></td></tr>}
        </Table>

      </Card.Body>
    </Card>
  );
};

export default NotarySettings;
