import { createSlice } from '@reduxjs/toolkit';
import api from '../util/api';
import logger from '../util/logger';
import { authenticationSlice } from './authentication';
import moment from 'moment';
import _ from 'lodash';
import draftEntryHelper from 'helpers/draft-entry-helper';
import { DeviceIcon } from 'data/common/common';
import { reject } from 'lodash';
import { listDraftFromServer } from './app';
import { resolve } from 'superagent/lib/request-base';

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    rawData: [],
    signing: [],
    draft: [],
    notaryProfile: [],
    documentType: [],
    signingPackage: [],
    location: [],
    journalExport: [],
    device: []
  },
  reducers: {
    cleanupState: (state, action) => {
      state.rawData = [];
      state.draft = [];
      state.notaryProfile = [];
      state.documentType = [];
      state.signingPackage = [];
      state.location = [];
      state.journalExport = [];
      state.device = [];
    },

    setRawData: (state, action) => {
      state.rawData = action.payload.data;
    },
    setEntriesDraft: (state, action) => {
      state.draft = action.payload.data;
    },
    setEntriesNotaryProfile: (state, action) => {
      state.notaryProfile = action.payload.data;
    },
    setEntriesDocumentType: (state, action) => {
      state.documentType = action.payload.data;
    },
    setEntriesSigningPackage: (state, action) => {
      state.signingPackage = action.payload.data;
    },
    setEntriesLocation: (state, action) => {
      state.location = action.payload.data;
    },
    setEntriesJournalExport: (state, action) => {
      state.journalExport = action.payload.data;
    },
    setEntriesDevice: (state, action) => {
      state.device = action.payload.data;
    }
  }
});

let {
  setRawData,
  setEntriesDraft,
  setEntriesNotaryProfile,
  setEntriesDocumentType,
  setEntriesSigningPackage,
  setEntriesLocation,
  setEntriesJournalExport,
  setEntriesDevice,
  setLoadError,
  cleanupState
} = dashboardSlice.actions;

let actions = {
  actionSetRawData: (Start, End, Fidelity) => {
    return async (dispatch, getState) => {
      try {
        let StartDate =
          Start || moment().subtract(7, 'days').endOf('day').format('YYYY-MM-DD');
        let EndDate = End
          ? moment(End).endOf('day').format('YYYY-MM-DD HH:mm:ss')
          : moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
        let FidelityInterval = Fidelity || 'Daily';
        return await new Promise((resolve, reject) => {
          api.Reports.signing(StartDate, EndDate, FidelityInterval).then(
            res => {
              dispatch(setRawData({ data: res || {} }));
              resolve(true);
            }
          );
        });
      } catch (err) {
        logger.log(err);
        dispatch(setEntriesSigning({ data: [] }));
      }
    };
  },

  actionGetDashboardStats: (Start, End, Fidelity) => {
    return async (dispatch, getState) => {
      try {
        let StartDate =
          Start || moment().subtract(1, 'months').format('YYYY-MM-DD');
        let EndDate = End
          ? moment(End).endOf('day').format('YYYY-MM-DD HH:mm:ss')
          : moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
        let FidelityInterval = Fidelity || 'Daily';
        return await new Promise((resolve, reject) => {
          api.Reports.signing(StartDate, EndDate, FidelityInterval).then(
            res => {
              resolve(res);
            }
          );
        });
      } catch (err) {
        reject(false);
      }
    };
  },

  actionSetEntriesDraft: () => {
    logger.log('actionSetEntriesDraft calling');
    return async (dispatch, getState) => {
      let userMaster = _.get(getState(), 'app.userMaster');
      if (userMaster) {
        try {
          dispatch(listDraftFromServer()).then(res => {
            dispatch(setEntriesDraft({ data: res }));
          });
        } catch (err) {
          reject(false);
        }
        // draftEntryHelper.getAllDrafts(userMaster.GUID).then(rawList => {
        //   if (rawList?.length > 0) {
        //     let itemData = [];
        //     _.map(rawList, item => {
        //       itemData.push({
        //         created: moment(item.draft_metadata.created)
        //           .utc()
        //           .format('MM/DD/YYYY')
        //       });
        //     });
        //     dispatch(setEntriesDraft({ data: itemData }));
        //   }
        // });
      }
    };
  },

  actionSetEntriesNotaryProfile: () => {
    return (dispatch, getState) => {
      let rawList = _.get(getState(), 'app.userProfile');
      let itemData = [];
      if (rawList) {
        _.map(rawList, item => {
          itemData.push({
            name: item.CommissionedName,
            expirationDate:
              moment(item.ExpirationDate).format('MM/DD/YYYY') || null
          });
        });
        dispatch(setEntriesNotaryProfile({ data: itemData }));
      }
    };
  },

  actionSetEntriesDocumentType: () => {
    return async (dispatch, getState) => {
      try {
        return await new Promise((resolve, reject) => {
          api.DocumentType.list().then(res => {
            let rawList = _.sortBy(res, d => {
              return -moment(d.Created).unix();
            });
            let itemData = [];
            if (rawList) {
              _.map(rawList, item => {
                itemData.push({
                  name: item.Value,
                  created: moment(item.Created).format('MM/DD/YYYY') || null
                });
              });
              dispatch(setEntriesDocumentType({ data: itemData }));
            }
            resolve(true);
          });
        });
      } catch (err) {
        logger.log(err);
        dispatch(setEntriesDocumentType({ data: [] }));
      }
    };
  },

  actionSetEntriesSigningPackage: () => {
    return async (dispatch, getState) => {
      try {
        return await new Promise((resolve, reject) => {
          api.DocumentCollection.list().then(res => {
            let rawList = _.sortBy(res, d => {
              return d.Name;
            });

            let itemData = [];
            if (rawList) {
              _.map(rawList, item => {
                itemData.push({
                  name: item.Name,
                  created: moment(item.Created).format('MM/DD/YYYY') || null
                });
              });
              dispatch(setEntriesSigningPackage({ data: itemData }));
            }
            resolve(true);
          });
        });
      } catch (err) {
        logger.log(err);
        dispatch(setEntriesSigningPackage({ data: [] }));
      }
    };
  },

  actionSetEntriesLocation: () => {
    return async (dispatch, getState) => {
      try {
        return await new Promise((resolve, reject) => {
          api.SavedLocations.list().then(res => {
            let rawList = _.sortBy(res, d => {
              return -moment(d.Created).unix();
            });

            let itemData = [];
            if (rawList) {
              _.map(rawList, item => {
                itemData.push({
                  name: item.DisplayName,
                  created: moment(item.Created).format('MM/DD/YYYY') || null
                });
              });
              dispatch(setEntriesLocation({ data: itemData }));
            }
            resolve(true);
          });
        });
      } catch (err) {
        logger.log(err);
        dispatch(setEntriesDocumentType({ data: [] }));
      }
    };
  },

  actionSetEntriesJournalExport: () => {
    return (dispatch, getState) => {
      let now = moment().utc();
      let userMaster = _.get(getState(), 'app.userMaster');
      if (userMaster) {
        let userCreatedMonth = moment.utc(userMaster.Created).startOf('month');
        let displayDateRanges = [];
        let curMonthEnd = moment(now);
        let curMonthStart = moment(now).startOf('month');
        while (curMonthStart.isSameOrAfter(userCreatedMonth, 'month')) {
          displayDateRanges.push({
            displayKey: displayDateRanges.length + 1,
            begin: curMonthStart.toString(),
            end: curMonthEnd.toString(),
            month: moment(curMonthStart).format('MMMM'),
            viewDate: moment(curMonthStart).format('YYYY-MM-DD')
          });

          curMonthEnd = moment(curMonthEnd).subtract(1, 'month').endOf('month');
          curMonthStart = moment(curMonthStart)
            .subtract(1, 'month')
            .startOf('month');
        }

        dispatch(setEntriesJournalExport({ data: displayDateRanges }));
      }
    };
  },
  actionSetEntriesDevice: () => {
    return async (dispatch, getState) => {
      try {
        return await new Promise((resolve, reject) => {
          api.Devices.list().then(res => {
            let rawList = _.sortBy(res, d => {
              return -moment(d.LastSeen).unix();
            });

            let itemData = [];
            if (rawList) {
              _.map(rawList, item => {
                itemData.push({
                  name: `${item.Manufacturer} ${item.Model} `,
                  created: moment(item.Created).format('MM/DD/YYYY') || null,
                  Lastseen: moment(item.LastSeen).format('MM/DD/YYYY') || null,
                  blocked: item.Blocked,
                  icon: DeviceIcon(item, true)
                });
              });
              dispatch(setEntriesDevice({ data: itemData }));
            }
            resolve(true);
          });
        });
      } catch (err) {
        logger.log(err);
        dispatch(setEntriesDevice({ data: [] }));
      }
    };
  },

  refreshDashboardData: () => {
    return async (dispatch, getState) => {
      const res = await new Promise((resolve, reject) => {
        dispatch(actions.actionSetRawData(null, null, null))
          .then(res => {
            dispatch(actions.actionSetEntriesDraft());
            dispatch(actions.actionSetEntriesNotaryProfile());
            dispatch(actions.actionSetEntriesDocumentType());
            dispatch(actions.actionSetEntriesSigningPackage());
            dispatch(actions.actionSetEntriesLocation());
            dispatch(actions.actionSetEntriesJournalExport());
            dispatch(actions.actionSetEntriesDevice());
            resolve(res);
          })
          .catch(err => {
            reject(false);
          });
      });
    };
  },

  // refreshDashboardData: () => {
  //   return async (dispatch, getState) => {
  //     const res = await new Promise((resolve, reject) => {
  //       dispatch(actions.actionSetRawData(null, null, null));
  //     }).then(res => {
  //       return Promise.all([
  //         dispatch(actions.actionSetEntriesDraft()),
  //         dispatch(actions.actionSetEntriesNotaryProfile()),
  //         dispatch(actions.actionSetEntriesDocumentType()),
  //         dispatch(actions.actionSetEntriesSigningPackage()),
  //         dispatch(actions.actionSetEntriesLocation()),
  //         dispatch(actions.actionSetEntriesJournalExport()),
  //         dispatch(actions.actionSetEntriesDevice())
  //       ]).then(res => {
  //         resolve(res);
  //       })
  //     }).catch(err => {
  //       reject(false);
  //     })
  //   };
  // },

  dashboardCleanup: () => {
    return (dispatch, getState) => {
      dispatch(cleanupState());
    };
  }
};

export const {
  actionSetRawData,
  actionSetEntriesDraft,
  actionSetEntriesNotaryProfile,
  actionSetEntriesDocumentType,
  actionSetEntriesSigningPackage,
  actionSetEntriesLocation,
  actionSetEntriesJournalExport,
  refreshDashboardData,
  dashboardCleanup,
  actionGetDashboardStats
} = actions;

export default dashboardSlice.reducer;
