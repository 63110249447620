import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Modal, Row, Spinner } from 'react-bootstrap';
import Background from 'components/common/Background';
import classNames from 'classnames';
import Avatar from 'components/common/Avatar';
import FalconDropzone from 'components/common/FalconDropzone';
import { isIterableArray } from 'helpers/utils';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import config from 'util/config';
import 'react-image-crop/dist/ReactCrop.css';
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';

const ProfileBannerHeader = ({
  loading = false,
  avatar,
  coverSrc,
  className,
  user,
  onUpload,
  isOpenUploadModel,
  setIsOpenUploadModel
}) => {
  const [image, setImage] = useState([]);
  const [isDisplayProfilePic, SetIsDisplayProfilePic] = useState(false);
  const [crop, setCrop] = useState();
  const imgRef = useRef(null);
  const aspect = 16 / 9;

  const getCroppedImg = (image, crop) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    const base64Image = canvas.toDataURL('image/jpeg');
    return base64Image;
  };

  useEffect(() => {
    if (!loading) {
      setImage([]);
      setIsOpenUploadModel(false);
      setCrop({});
      imgRef.current = null;
    }
  }, [user?.Blob?.GUID]);

  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    const widthInPixels = (mediaWidth * 90) / 100;

    return centerCrop(
      makeAspectCrop(
        {
          unit: 'px',
          width: widthInPixels
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }
  const onImageLoad = e => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };

  const makeClientCrop = async () => {
    if (imgRef.current && crop) {
      const croppedImageBase64 = getCroppedImg(imgRef.current, crop);
      onUpload(croppedImageBase64);
    }
  };

  return (
    <Card.Header
      className={classNames(className, 'position-relative min-vh-25 mb-7')}
    >
      <Background image={coverSrc} className="rounded-3 rounded-bottom-0" />
      {onUpload && (
        <FalconDropzone
          files={avatar}
          onChange={files => {
            setImage(files);
            setIsOpenUploadModel(true);
          }}
          multiple={false}
          accept="image/*"
          placeholder={
            <Avatar
              size="l"
              className="plus-avatar-profile"
              icon={
                isIterableArray(image) || user?.Blob?.GUID
                  ? faPencilAlt
                  : 'plus'
              }
              mediaClass="img-thumbnail shadow-sm"
            />
          }
        />
      )}
      <div className="pointer" onClick={() => SetIsDisplayProfilePic(true)}>
        <Avatar
          size="5xl"
          className="avatar-profile"
          src={
            user?.Blob?.GUID
              ? `${config.endpoint}/blob/${user?.Blob?.GUID}`
              : ''
          }
          name={user ? user.FirstName + ' ' + user.LastName : ''}
          mediaClass="img-thumbnail shadow-sm"
        />
      </div>
      {isDisplayProfilePic && (
        <Lightbox
          mainSrc={
            user?.Blob?.GUID
              ? `${config.endpoint}/blob/${user?.Blob?.GUID}`
              : '/default_user_profile.jpg'
          }
          onImageLoad={() => {
            window.dispatchEvent(new Event('resize'));
          }}
          reactModalStyle={{ overlay: { zIndex: 999999 } }}
          onCloseRequest={() => SetIsDisplayProfilePic(false)}
        />
      )}
      <Modal
        show={isOpenUploadModel}
        onHide={() => {
          setIsOpenUploadModel(false);
          setCrop({});
          imgRef.current = null;
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Set Profile Image
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <ReactCrop crop={crop} onChange={c => setCrop(c)}>
                <img ref={imgRef} src={image[0]?.base64} onLoad={onImageLoad} />
              </ReactCrop>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setIsOpenUploadModel(false);
              setCrop();
              imgRef.current = null;
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={() => makeClientCrop()}>
            {loading ? (
              <>
                <Spinner size="sm" /> Uploading...
              </>
            ) : (
              'Upload'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </Card.Header>
  );
};

const ProfileBannerBody = ({ children }) => {
  return <Card.Body>{children}</Card.Body>;
};

const ProfileBanner = ({ children }) => {
  return <Card className="mb-3">{children}</Card>;
};

ProfileBanner.Header = ProfileBannerHeader;
ProfileBanner.Body = ProfileBannerBody;

ProfileBannerHeader.propTypes = {
  avatar: PropTypes.string.isRequired,
  coverSrc: PropTypes.string.isRequired,
  className: PropTypes.string,
  user: PropTypes.object.isRequired,
  onUpload: PropTypes.func,
  loading: PropTypes.bool
};

ProfileBannerBody.propTypes = {
  children: PropTypes.node.isRequired
};

ProfileBanner.propTypes = {
  children: PropTypes.node.isRequired
};

export default ProfileBanner;
