import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormGroup, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logoutImg from 'assets/img/icons/spot-illustrations/45.png';
import Flex from 'components/common/Flex';
import {
  faCheckCircle,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { Badge } from 'reactstrap';
import SoftBadge from 'components/common/SoftBadge';
import logger from 'util/logger';

const VerificationMobile = ({
  stateData,
  setStateData,
  userMaster,
  TitleTag,
  startSMSVerification,
  updateMobileNumber,
  submitSMSCode
}) => {
  logger.log('userMaster', userMaster);
  return (
    <>
      {userMaster.SmsVerified ||
        (userMaster.SmsOptIn && (
          <Flex direction="column">
            <p>
              <img
                className="d-block mx-auto mb-4"
                src={logoutImg}
                alt="shield"
                width={100}
              />
            </p>
            <TitleTag className="text-success fw-bold">
              <FontAwesomeIcon icon={faCheckCircle} className="text-success" />{' '}
              Mobile Verified
            </TitleTag>
            <p>
              Your sms number{' '}
              <span className="fw-bold">{userMaster.Telephone}</span> has been
              verified. Thank you!
            </p>
          </Flex>
        ))}

      {!(userMaster.SmsVerified || userMaster.SmsOptIn) && (
        <Flex direction="column">
          <p>
            <img
              className="d-block mx-auto mb-4"
              src={logoutImg}
              alt="sent"
              width={100}
            />
          </p>
          {userMaster?.SmsVerificationRequested && (
            <Flex direction="column">
              <TitleTag className="text-warning fw-bold">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="text-warning"
                />{' '}
                Mobile Unverified
              </TitleTag>
              <p>
                A verification message has been sent to :{' '}
                <span className="fw-bold">{userMaster.Telephone}</span>.
              </p>
              <div className="text-center mt-3">
                <Form.Group className="mb-3">
                  <Form.Label>Verification Code</Form.Label>
                  <Form.Control
                    placeholder="Enter Verification Code"
                    value={stateData.smsVerificationCode}
                    name="password"
                    onChange={evt =>
                      setStateData({
                        ...stateData,
                        smsVerificationCode: evt.target.value
                      })
                    }
                    type="tel"
                    pattern="[0-9]*"
                    inputMode="numeric"
                  />
                </Form.Group>
              </div>
              <div className="mt-0">
                <Form.Check
                  type="checkbox"
                  name="SmsOptIn"
                  onChange={evt =>
                    setStateData({ ...stateData, SmsOptIn: evt.target.checked })
                  }
                  id="checkedCheckbox"
                  label="Tick this box to opt-in to receive SMS text messages from Jurat inc. We only send SMS text messages for account verification purposes Standard rates and data may apply. Reply STOP to opt out."
                  defaultChecked={stateData?.SmsOptIn}
                />
              </div>
              <div className="text-center mt-3">
                {stateData.isFinishingSMSVerification ? (
                  <Spinner size="sm" variant="primary" />
                ) : (
                  <Button
                    onClick={() => submitSMSCode()}
                    disabled={
                      stateData.didSendSMSVerification || !stateData?.SmsOptIn
                    }
                    className=""
                    color="primary"
                  >
                    Verify Code
                  </Button>
                )}
              </div>
              <div className="text-center mt-2">
                <Button
                  variant="outline-primary"
                  size="sm"
                  onClick={() => startSMSVerification()}
                  className=""
                  color="primary"
                >
                  Resend verification code
                </Button>
              </div>
            </Flex>
          )}

          {userMaster?.Telephone && !userMaster?.SmsVerificationRequested && (
            <Flex direction="column">
              {!userMaster.SmsOptIn && (
                <p>
                  {' '}
                  Secure your account by verifying your SMS number:{' '}
                  <span className="font-weight-bold">
                    {userMaster.Telephone}
                  </span>
                </p>
              )}

              <div className="text-center mt-2">
                {stateData.isFinishingSMSVerification ? (
                  <Spinner size="sm" variant="primary" />
                ) : (
                  <Button
                    onClick={() => startSMSVerification()}
                    disabled={
                      stateData.didSendSMSVerification || !stateData?.SmsOptIn
                    }
                    className=""
                    color="primary"
                  >
                    Verify SMS Number
                  </Button>
                )}
              </div>

              <div className="mt-3">
                {!userMaster?.SmsOptIn && (
                  <Form.Group>
                    <Form.Check
                      type="checkbox"
                      name="SmsOptIn"
                      onChange={evt =>
                        setStateData({
                          ...stateData,
                          SmsOptIn: evt.target.checked
                        })
                      }
                      id="checkedCheckbox"
                      label="Tick this box to opt-in to receive SMS text messages from Jurat inc. We only send SMS text messages for account verification purposes Standard rates and data may apply. Reply STOP to opt out."
                      defaultChecked={stateData?.SmsOptIn}
                    />
                  </Form.Group>
                )}
                {!!userMaster?.SmsOptIn && (
                  <SoftBadge bg="success" className="me-2">
                    <FontAwesomeIcon icon={faCheckCircle} /> You have opted in
                    to receive SMS text messages from Jurat inc.
                  </SoftBadge>
                )}
              </div>
            </Flex>
          )}

          {!userMaster.Telephone && !userMaster.SmsVerificationRequested && (
            <Flex direction="column">
              <TitleTag className="text-warning fw-bold">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="text-warning"
                />{' '}
                Mobile Phone Number Required
              </TitleTag>
              <p>
                A verification message has been sent to your given mobile
                number.
              </p>
              <div className="text-center mt-3">
                <Form.Group className="mb-3">
                  <Form.Label>Mobile number</Form.Label>
                  <Form.Control
                    placeholder="Enter mobile number"
                    value={stateData.Telephone}
                    name="password"
                    onChange={evt =>
                      setStateData({
                        ...stateData,
                        Telephone: evt.target.value
                      })
                    }
                    type="tel"
                    pattern="[0-9]*"
                    maxLength={10}
                    inputMode="numeric"
                  />
                </Form.Group>
              </div>
              <div className="mt-0">
                <Form.Check
                  type="checkbox"
                  name="SmsOptIn"
                  onChange={evt =>
                    setStateData({ ...stateData, SmsOptIn: evt.target.checked })
                  }
                  id="checkedCheckbox"
                  label="Tick this box to opt-in to receive SMS text messages from Jurat inc. We only send SMS text messages for account verification purposes Standard rates and data may apply. Reply STOP to opt out."
                  defaultChecked={stateData?.SmsOptIn}
                />
              </div>
              <div className="text-center mt-3">
                {stateData.updatingTelephone ? (
                  <Spinner size="sm" variant="primary" />
                ) : (
                  <Button
                    onClick={() => updateMobileNumber()}
                    disabled={stateData.Telephone?.length != 10}
                    className=""
                    color="primary"
                  >
                    Proceed
                  </Button>
                )}
              </div>
            </Flex>
          )}
        </Flex>
      )}
    </>
  );
};

VerificationMobile.propTypes = {
  userMaster: PropTypes.object.isRequired,
  startSMSVerification: PropTypes.func.isRequired,
  submitSMSCode: PropTypes.func.isRequired
};

VerificationMobile.defaultProps = {
  TitleTag: 'h4'
};

export default VerificationMobile;
