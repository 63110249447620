import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faIdBadge,
  faPlus,
  faPlusCircle,
  faUser,
  faUserCheck,
  faUserFriends
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCardHeader from 'components/common/FalconCardHeader';
import FalconCloseButton from 'components/common/FalconCloseButton';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import SoftBadge from 'components/common/SoftBadge';
import IconItem from 'components/common/icon/IconItem';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  ListGroup,
  Row,
  Spinner
} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { showAlert } from 'stores/modal';
import {
  actionAddIdentification,
  actionAddWitnessIdentification,
  actionLogSigningAction,
  actionMapSignerToAllDocs,
  actionRemoveIdentification,
  actionSetSignerIdType,
  actionSetSignerName,
  actionSetWitnessLocation,
  actionSetWitnessSignature
} from 'stores/signing';
import enums from 'util/enums';
import filters from 'util/filters';
import idGenerator from 'util/idGenerator';
import logger from 'util/logger';
import AddressView from '../comp/AddressView';
import IdentificationView from '../comp/IdentificationView';
import SignatureModal from '../comp/SignatureModal';
import Colors from 'util/Colors';
import draftEntryUiHelper from 'helpers/draft-entry-ui-helper';
import segmentHelper from 'helpers/segment-helper';
import IconAlert from 'components/common/IconAlert';

const NotarySignerForm = ({
  signerData,
  setSignerData,
  setCollapsed,
  formRef,
  signers,
  witnesses,
  identifications,
  signatures,
  openSignerIdentityForm,
  openWitnessAddressForm,
  openWitnessIdentityForm,
  resetSignerState
}) => {
  const [showFullName, setShowFullName] = useState(false);
  const [showSignatureForm, setShowSignatureForm] = useState(false);

  const [signatureForId, setSignatureForId] = useState(null);
  const [author, setAuthor] = useState(null);
  const [signatureData, setSignatureData] = useState({});
  const [formTitle, setFormTitle] = useState('Add New Signer');

  const dispatch = useDispatch();

  useEffect(() => {
    if (signerData?.signer_id) {
      let typePerson = 'Signer';
      if (signerData.witnessChoice === enums.UI_FLOW_TYPE.SIGNER) {
        typePerson = 'Signer';
      } else if (signerData.witnessChoice === enums.UI_FLOW_TYPE.WITNESS_ONE) {
        typePerson = 'One Witness';
      } else if (signerData.witnessChoice === enums.UI_FLOW_TYPE.WITNESS_TWO) {
        typePerson = 'Two Witness';
      } else if (
        signerData.witnessChoice === enums.UI_FLOW_TYPE.PERSONALLY_KNOWN
      ) {
        typePerson = 'Personally Known';
      }
      setFormTitle(typePerson);
    }
  }, [signerData]);

  // useEffect(() => {
  //   let loadedSignerId = signerData.signer_id;
  //   let foundSigner = _.find(signers, s => loadedSignerId === s.signer_id);
  //   let signerWitnesses = _.filter(
  //     witnesses,
  //     w => w.signer_id === loadedSignerId
  //   );

  //   let signerIdentifications = _.filter(
  //     identifications,
  //     i => i.signer_id === loadedSignerId
  //   );
  //   let witnessIdentifications = [];
  //   let witnessSignatures = [];

  //   _.each(signerWitnesses, w => {
  //     logger.log('onPageLoad loading witness', w);
  //     witnessIdentifications = _.concat(
  //       [],
  //       _.filter(identifications, i => i.witness_id === w.witness_id),
  //       witnessIdentifications
  //     );

  //     witnessSignatures = _.concat(
  //       [
  //         {
  //           witness_id: w.witness_id,
  //           signature: _.get(w, 'signature')
  //         }
  //       ],
  //       witnessSignatures
  //     );
  //   });

  //   let sortedWitnessIds = null;
  //   let witnessChoice = enums.UI_FLOW_TYPE.SIGNER;
  //   if (signerWitnesses.length === 0) {
  //     witnessChoice = enums.UI_FLOW_TYPE.SIGNER;
  //   } else if (signerWitnesses.length === 1) {
  //     witnessChoice = enums.UI_FLOW_TYPE.WITNESS_ONE;
  //     sortedWitnessIds = [signerWitnesses[0].witness_id];
  //   } else if (signerWitnesses.length === 2) {
  //     witnessChoice = enums.UI_FLOW_TYPE.WITNESS_TWO;
  //     sortedWitnessIds = _.sortBy(
  //       [signerWitnesses[0].witness_id, signerWitnesses[1].witness_id],
  //       w => {
  //         return w.witness_id;
  //       }
  //     );
  //   }

  //   if (foundSigner) {
  //     setSignerData(signerData => ({
  //       ...signerData,
  //       isEditMode: true,
  //       signer_id: loadedSignerId,
  //       signer_first_name: foundSigner.first_name,
  //       signer_last_name: foundSigner.last_name,
  //       signer_middle_name: foundSigner.middle_name,
  //       signer_suffix: foundSigner.suffix,
  //       signer_prefix: foundSigner.prefix,
  //       witnessChoice: witnessChoice,
  //       validationErr: [],

  //       sorted_witness_ids: sortedWitnessIds,
  //       witnessIdentifications: _.map(witnessIdentifications, id => {
  //         return {
  //           signer_id: id.signer_id,
  //           witness_id: id.witness_id,
  //           identification: id
  //         };
  //       }),
  //       witnessSignatureResults: witnessSignatures,
  //       witnessNameLocationResults: _.concat([], signerWitnesses),
  //       signerIdentifications: _.map(signerIdentifications, id => {
  //         return {
  //           signer_id: id.signer_id,
  //           witness_id: id.witness_id,
  //           identification: id
  //         };
  //       })
  //     }));
  //   }
  // }, [signerData.signer_id]);

  const handleChange = e => {
    setSignerData({
      ...signerData,
      [e.target.name]: e.target.value
    });
  };

  const resetProfileForm = collapse => {
    setSignerData(signerData => ({
      ...signerData,
      signer_id: null
    }));
    setCollapsed(!collapse);
  };

  const addOneCreditWitness = () => {
    segmentHelper.track(
      segmentHelper.events.SIGNING_ADD_PERSON_OPTION_SELECTED +
        enums.UI_FLOW_TYPE.WITNESS_ONE
    );
    setSignerData(signerData => ({
      ...signerData,
      witnessChoice: enums.UI_FLOW_TYPE.WITNESS_ONE,
      sorted_witness_ids: [idGenerator.getWitnessId()]
    }));
    //setIdentificationFlow(enums.UI_FLOW_TYPE.WITNESS_ONE);
  };

  const addTwoCreditWitness = () => {
    segmentHelper.track(
      segmentHelper.events.SIGNING_ADD_PERSON_OPTION_SELECTED +
        enums.UI_FLOW_TYPE.WITNESS_TWO
    );
    setSignerData(signerData => ({
      ...signerData,
      witnessChoice: enums.UI_FLOW_TYPE.WITNESS_TWO,
      sorted_witness_ids: _.sortBy(
        [idGenerator.getWitnessId(), idGenerator.getWitnessId()],
        w => {
          return w;
        }
      )
    }));
    //setIdentificationFlow(enums.UI_FLOW_TYPE.WITNESS_TWO);
  };

  const addPersonallyKnown = () => {
    segmentHelper.track(
      segmentHelper.events.SIGNING_ADD_PERSON_OPTION_SELECTED +
        enums.UI_FLOW_TYPE.PERSONALLY_KNOWN
    );
    setSignerData(signerData => ({
      ...signerData,
      witnessChoice: enums.UI_FLOW_TYPE.PERSONALLY_KNOWN
    }));
    //setIdentificationFlow(enums.UI_FLOW_TYPE.PERSONALLY_KNOWN);
  };

  const resetWitnessTypeSigner = () => {
    setSignerData(signerData => ({
      ...signerData,
      witnessChoice: enums.UI_FLOW_TYPE.SIGNER,
      sorted_witness_ids: []
    }));
  };

  const openWitnessSignatureForm = id => {
    setSignatureForId(id);
    setAuthor(formTitle);
    setShowSignatureForm(true);
  };

  const saveSigner = () => {
    if (!signerData.signer_first_name || !signerData.signer_last_name) {
      toast.info("Signer's First & Last name required", {
        theme: 'colored',
        position: 'bottom-center',
        icon: faExclamationTriangle,
        toastId: 'signer-form'
      });
      return;
    }
    if (!doValidation()) {
      return;
    }
    //Totally Unused Code
    // signerData.signer_id = idGenerator.getSignerId();
    // signerData.witnessChoice  = 'signer';
    // signerData.display_name = _.trim(signerData.signer_prefix + ' ' + signerData.signer_first_name + ' ' + signerData.signer_middle_name + ' ' + signerData.signer_last_name + ' ' + signerData.signer_suffix);
    setSignerData(signerData => ({
      ...signerData,
      isSaving: true
    }));

    logger.log('SignerData before Saving Signer to state', signerData);
    logAllChanges();
    removeSignerFromState(signerData.signer_id)
      .then(() => {
        //Yeah it's a little weird that this is a promise.  It is a limitation of some old-ass code in
        //newEntry actions.  Some data created in the call, that we map to later.
        //should be further investigated with all of that free time we'll have someday.
        setTimeout(() => {
          return dispatch(
            actionSetSignerIdType({
              signer_id: signerData.signer_id,
              identificationFlow: signerData.witnessChoice
            })
          );
        }, 100);
      })
      .then(() => {
        setTimeout(() => {
          dispatch(
            actionSetSignerName({
              signer: {
                signer_id: signerData.signer_id,
                first_name: signerData.signer_first_name,
                middle_name: signerData.signer_middle_name,
                last_name: signerData.signer_last_name,
                prefix: signerData.signer_prefix,
                suffix: signerData.signer_suffix
              }
            })
          ).then(() => {
            return saveIdentificationUIResults();
          });
        }, 100);
      })
      .then(() => {
        setTimeout(() => {
          segmentHelper.track(
            segmentHelper.events.SIGNING_SIGNER_ADDED + signerData.witnessChoice
          );
          saveWitnessNameLocationResults();
          saveWitnessSignatureResults();
          setSignerData(signerData => ({
            ...signerData,
            isSaving: false
          }));
          resetSignerState();
          setCollapsed(false);
        }, 100);
      });
  };

  const saveIdentificationUIResults = async () => {
    let { signerIdentifications, witnessIdentifications, witnessChoice } =
      signerData;

    let addDummyPromise = Promise.resolve(true);
    if (witnessChoice === enums.UI_FLOW_TYPE.PERSONALLY_KNOWN) {
      addDummyPromise = addIdentificationTypeForName(
        enums.ID_TYPES.PERSONALLY_KNOWN
      );
    } else if (
      signerIdentifications.length === 0 &&
      witnessIdentifications.length === 0
    ) {
      //If we're here, then we're past validation, which means we have a signer name at least.
      //Just add a "No Identification" type as a courtesy to the user.  Then can go back and edit it later.
      addDummyPromise = addIdentificationTypeForName(enums.ID_TYPES.NONE);
    }
    logger.log('addIdentificationTypeForName', signerData);
    addDummyPromise.then(() => {
      logger.log('After adding dummy identification', signerData);
      let identificationPromise = Promise.resolve(true);
      identificationPromise = addSignerIdentificationsResults();

      Promise.all([identificationPromise]).then(() => {
        addWitnessIdentificationsResults();
      });
    });
  };

  const addSignerIdentificationsResults = () => {
    return new Promise((resolve, reject) => {
      let { signerIdentifications } = signerData;

      _.each(signerIdentifications, sResult => {
        logger.log('Saving Signer Identification', sResult);
        if (!sResult.witness_id) {
          //Only add if no witness
          dispatch(
            actionAddIdentification({
              signer_id: sResult.signer_id,
              identification_id: sResult.identification.identification_id,
              first_name: sResult.identification.id_first_name,
              middle_name: sResult.identification.id_middle_name,
              last_name: sResult.identification.id_last_name,
              prefix: sResult.identification.id_prefix,
              suffix: sResult.identification.id_suffix,
              id_type: sResult.identification.id_type,
              id_type_other: sResult.identification.id_type_other,
              id_number: sResult.identification.id_number,
              id_issued_date: sResult.identification.id_issued_date,
              id_expiration_date: sResult.identification.id_expiration_date,
              date_of_birth: sResult.identification.date_of_birth,
              id_issuing_state: sResult.identification.id_issuing_state,
              id_address_1: sResult.identification.id_address_1,
              id_address_2: sResult.identification.id_address_2,
              id_city: sResult.identification.id_city,
              id_state: sResult.identification.id_state,
              id_postal_code: sResult.identification.id_postal_code,
              notes: sResult.identification.notes,
              scan_vendor: sResult.identification.scan_vendor,
              scan_data: sResult.identification.scan_data
            })
          );

          dispatch(actionMapSignerToAllDocs({ signer_id: sResult.signer_id }));
        }
      });

      resolve(true);
    });
  };

  const addWitnessIdentificationsResults = () => {
    return new Promise((resolve, reject) => {
      let { witnessIdentifications } = signerData;

      _.each(witnessIdentifications, wResult => {
        dispatch(
          actionAddWitnessIdentification({
            signer_id: wResult.signer_id,
            witness_id: wResult.witness_id,
            witness_name: {
              first_name: wResult.identification.id_first_name,
              middle_name: wResult.identification.id_middle_name,
              last_name: wResult.identification.id_last_name,
              suffix: wResult.identification.id_suffix,
              prefix: wResult.identification.id_prefix
            },
            identification: wResult.identification
          })
        );
      });

      resolve(true);
    });
  };

  const saveWitnessNameLocationResults = () => {
    let { witnessNameLocationResults } = signerData;

    _.each(witnessNameLocationResults, wResult => {
      if (wResult.witness_id) {
        dispatch(
          actionSetWitnessLocation({
            witness_id: wResult.witness_id,
            location: wResult.location
          })
        );
      }
    });
  };

  const saveWitnessSignatureResults = () => {
    let { witnessSignatureResults } = signerData;

    _.each(witnessSignatureResults, wResult => {
      logger.log('saving witness signature', wResult);
      if (wResult.witness_id) {
        dispatch(
          actionSetWitnessSignature({
            witness_id: wResult.witness_id,
            signature: wResult.signature
          })
        );
      }
    });
  };

  const doValidation = () => {
    let {
      witnessChoice,
      signer_first_name,
      signer_last_name,

      signerIdentifications,
      witnessIdentifications,

      witnessNameLocationResults,
      witnessSignatureResults
    } = signerData;

    let err = [];

    logger.log(
      'step two do validation',
      witnessChoice,
      signerIdentifications,
      witnessIdentifications,
      witnessNameLocationResults,
      witnessSignatureResults
    );
    if (witnessChoice === enums.UI_FLOW_TYPE.SIGNER) {
      if (signer_last_name.length === 0) {
        err.push("Please enter the signer's last name");
      }
      if (signer_first_name.length === 0) {
        err.push("Please enter the signer's first name");
      }
      //we add a dummy id now as long as they have a name.
      // if (signerIdentifications.length === 0) {
      //   err.push('Please add at least one form of identification.');
      // }
    } else if (witnessChoice === enums.UI_FLOW_TYPE.PERSONALLY_KNOWN) {
      if (signer_last_name.length === 0) {
        err.push("Please enter the signer's last name");
      }
      if (signer_first_name.length === 0) {
        err.push("Please enter the signer's first name");
      }
    } else if (witnessChoice === enums.UI_FLOW_TYPE.WITNESS_ONE) {
      if (signer_last_name.length === 0) {
        err.push("Please enter the signer's last name");
      }
      if (signer_first_name.length === 0) {
        err.push("Please enter the signer's first name");
      }

      if (witnessIdentifications.length < 1) {
        err.push('Please enter your witness details');
      } else {
        let witnessOne = witnessIdentifications[0];
        let witnessOneSignature = _.filter(
          witnessSignatureResults,
          r => r.witness_id === witnessOne.witness_id
        );
        if (witnessOneSignature.length === 0) {
          err.push('A signature is required for your witness.');
        }
      }
    } else if (witnessChoice === this.TWO_WITNESSES) {
      if (signer_last_name.length === 0) {
        err.push("Please enter the signer's last name");
      }
      if (signer_first_name.length === 0) {
        err.push("Please enter the signer's first name");
      }

      if (witnessIdentifications.length < 1) {
        err.push('Please enter your first witness details');
      } else {
        let witnessOne = witnessIdentifications[0];
        let witnessOneSignature = _.filter(
          witnessSignatureResults,
          r => r.witness_id === witnessOne.witness_id
        );

        if (witnessOneSignature.length === 0) {
          err.push('A signature is required for your first witness.');
        }
      }

      if (witnessIdentifications.length < 2) {
        err.push('Please enter your second witness details');
      } else {
        let witnessTwo = witnessIdentifications[1];
        let witnessTwoSignature = _.filter(
          witnessSignatureResults,
          r => r.witness_id === witnessTwo.witness_id
        );

        if (witnessTwoSignature.length === 0) {
          err.push('A signature is required for your second witness.');
        }
      }
    }

    if (err.length !== 0) {
      toast.warning(err[0], {
        theme: 'colored',
        position: 'bottom-center',
        icon: faExclamationCircle,
        toastId: 'signer-form'
      });
    }
    return err.length === 0;
  };

  const addIdentificationTypeForName = id_type => {
    logger.log(
      'addIdentificationTypeForName adding dummy identification',
      id_type
    );
    return new Promise((resolve, reject) => {
      let {
        signer_id,
        signer_first_name,
        signer_middle_name,
        signer_last_name,
        signer_prefix,
        signer_suffix
      } = signerData;

      //Instead of Saving Dummy identification to current signer in local state i am saving directly to redux
      // let signerIdentificationsUpdate = _.concat(
      //   [],
      //   signerData.signerIdentifications || []
      // );
      // signerIdentificationsUpdate.push({
      //   signer_id,
      //   identification: {
      //     identification_id: idGenerator.getIdentificationId(),
      //     first_name: signer_first_name,
      //     middle_name: signer_middle_name,
      //     last_name: signer_last_name,
      //     prefix: signer_prefix,
      //     suffix: signer_suffix,
      //     id_first_name: signer_first_name,
      //     id_middle_name: signer_middle_name,
      //     id_last_name: signer_last_name,
      //     id_type
      //   }
      // });
      // logger.log("Updating signerIdentificationsUpdate", signerIdentificationsUpdate);
      // setSignerData(signerData => ({
      //   ...signerData,
      //   signerIdentifications: signerIdentificationsUpdate
      // }));

      dispatch(
        actionAddIdentification({
          signer_id: signer_id,
          identification_id: idGenerator.getIdentificationId(),
          first_name: signer_first_name,
          middle_name: signer_middle_name,
          last_name: signer_last_name,
          prefix: signer_prefix,
          suffix: signer_suffix,
          id_type: id_type
        })
      );
      dispatch(actionMapSignerToAllDocs(signer_id));

      setTimeout(() => {
        logger.log(
          'addIdentificationTypeForName after adding dummy identification signerData',
          signerData
        );
        resolve(true);
      }, 100);
    });
  };

  const removeSignerFromState = signer_id => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(true);
      }, 50);
    });
  };

  useEffect(() => {
    if (signatureData && signatureData?.signatureForId) {
      logger.log('signatureData found', signatureData);
      //let witnessSignatureUpdate = _.concat([], signerData.witnessSignatureResults);
      let witnessSignatureUpdate = signerData.witnessSignatureResults;
      _.remove(
        witnessSignatureUpdate,
        wid => wid.witness_id === signatureData.signatureForId
      );
      witnessSignatureUpdate.push({
        witness_id: signatureData.signatureForId,
        signature: signatureData
      });
      setSignerData(signerData => ({
        ...signerData,
        witnessSignatureResults: witnessSignatureUpdate
      }));
    }
  }, [signatureData]);

  const logAllChanges = () => {
    let {
      signerIdentifications,
      witnessIdentifications,
      witnessChoice,
      signer_id
    } = signerData;

    let auditLogActions = [];

    //formatNameObj has checks for signer_* format of names that we use in state here.

    let fullSignerName = filters.formatName(
      signerData.signer_prefix,
      signerData.signer_first_name,
      signerData.signer_middle_name,
      signerData.signer_last_name,
      signerData.signer_suffix
    );
    logger.log('fullSignerName', fullSignerName, signerData);
    if (signerData.isEditMode) {
      //detect name change
      let foundSigner = _.find(signers, s => s.signer_id === signer_id);
      let oldSignerFullName = filters.formatNameObj(foundSigner);
      if (oldSignerFullName !== fullSignerName) {
        auditLogActions.push(
          `Signer name changed from ${oldSignerFullName} to ${fullSignerName}.`
        );
      }

      //Detect adding or removing IDs
      let oldIdentificationIds = [];
      _.each(identifications, i => {
        if (i.signer_id === signer_id && !i.witness_id) {
          oldIdentificationIds.push(i.identification_id);
        }
      });
      let newIdentificationIds = [];
      _.each(signerIdentifications, sResult => {
        if (!sResult.witness_id) {
          newIdentificationIds.push(
            _.get(sResult, 'identification.identification_id')
          );
        }
      });
      _.each(oldIdentificationIds, oid => {
        if (!_.find(newIdentificationIds, nid => oid === nid)) {
          //then this old id was removed
          auditLogActions.push(`Signer ${fullSignerName} ID removed.`);
        }
      });
      _.each(newIdentificationIds, nid => {
        if (!_.find(oldIdentificationIds, oid => oid === nid)) {
          //then this old id was removed
          auditLogActions.push(`Signer ${fullSignerName} ID added.`);
        }
      });

      //detect witness choice changes
      let oldWitnessIds = [];
      _.each(witnesses, w => {
        if (w.signer_id === signer_id) {
          oldWitnessIds.push(w.witness_id);
        }
      });
      let newWitnessIds = [];
      _.each(witnessIdentifications, wResult => {
        newWitnessIds.push(wResult.witness_id);
      });
      _.each(oldWitnessIds, owd => {
        if (!_.find(newWitnessIds, nwd => owd === nwd)) {
          //then this old id was removed
          auditLogActions.push(`Signer ${fullSignerName} witness removed.`);
        }
      });
      _.each(newWitnessIds, nwd => {
        if (!_.find(oldWitnessIds, owd => owd === nwd)) {
          //then this old id was removed
          auditLogActions.push(`Signer ${fullSignerName} witness added.`);
        }
      });
    } else {
      //new signer added, all actions are new
      auditLogActions.push(`New Signer ${fullSignerName} added.`);
      if (signerData.witnessChoice === enums.UI_FLOW_TYPE.PERSONALLY_KNOWN) {
        //The app would currently add a new ID for them of this type, but this is
        //not present in the signerIdentifications list
        //explicit add here
        //Comment by Kaushal to Intentionally log diff log for personally known type

        //auditLogActions.push(`Signer ${fullSignerName} ID added.`);
        auditLogActions.push(
          `Notary verified Signer ${fullSignerName} as personally known.`
        );
      } else if (
        signerData.signerIdentifications.length === 0 &&
        witnessIdentifications.length === 0
      ) {
        //The app would currently add a NO IDENTIFICATION type id for them, but this is
        //not present in the signerIdentifications list
        //explicit add here
        auditLogActions.push(`Signer ${fullSignerName} ID added.`);
      } else {
        if (witnessChoice === enums.UI_FLOW_TYPE.SIGNER) {
          _.each(signerIdentifications, sResult => {
            if (!sResult.witness_id) {
              let scan_time = _.get(sResult, 'identification.scan_time');
              if (scan_time) {
                auditLogActions.push(
                  `Signer ${fullSignerName} ID added with ID scan performed at ${moment(
                    scan_time
                  )
                    .local()
                    .format('lll')}.`
                );

                //Add any identification related changes they may have done.
                //this is mostly special handling for the rescan/diff process.
                let auditLogChanges = _.get(
                  sResult,
                  'identification.auditLogEntries',
                  []
                );
                if (auditLogChanges.length > 0) {
                  _.each(auditLogChanges, changeText => {
                    auditLogActions.push(changeText);
                  });
                }
              } else {
                auditLogActions.push(`Signer ${fullSignerName} ID added.`);
              }
            }
          });
        } else if (
          witnessChoice === enums.UI_FLOW_TYPE.WITNESS_ONE ||
          witnessChoice === enums.UI_FLOW_TYPE.WITNESS_TWO
        ) {
          _.each(witnessIdentifications, wResult => {
            let witnessFullName = filters.formatNameObj(
              _.get(wResult, 'identification', {})
            );
            auditLogActions.push(
              `New Witness ${witnessFullName} added for Signer ${fullSignerName}.`
            );
          });
        }
      }
    }

    if (auditLogActions.length > 0) {
      _.map(auditLogActions, action => {
        dispatch(actionLogSigningAction(action));
      });
    }
  };

  // useEffect(() => {
  //   if (signerUser) {
  //     signerUser.map((sUser) => {
  //       let isIdentityExist = _.find(identifications, (d) => {
  //         return d.signer_id === sUser.signer_id;
  //       })
  //       if (!isIdentityExist) {
  //         let newIdentity = {
  //           signer_id: sUser.signer_id,
  //           identification_id: idGenerator.getIdentificationId(),
  //           first_name: sUser.first_name,
  //           middle_name: sUser.middle_name,
  //           last_name: sUser.last_name,
  //           prefix: sUser.prefix,
  //           suffix: sUser.suffix,
  //           id_type: sUser.IdType,
  //           display_name: sUser.display_name,
  //         }

  //         dispatch(actionAddIdentification(newIdentity));
  //       }
  //     })
  //   }
  // }, [signerUser])

  const openIdentityViewModel = (title, identification) => {
    dispatch(
      showAlert(
        title,
        <div>
          <IdentificationView
            title={title}
            identification={identification}
            allowRemove={true}
          />
        </div>,
        res => {
          if (res) {
            //setStartSavingFavourite(startSavingFavourite + 1);
          }
        }
      )
    );
  };

  const openIdentityViewModelFromState = (title, identification) => {
    let localIdentifications = [];
    _.map(identification, i => {
      localIdentifications.push(i.identification);
    });
    dispatch(
      showAlert(
        title,
        <div>
          <IdentificationView
            title={title}
            identification={localIdentifications}
            allowRemove={false}
          />
        </div>,
        res => {
          if (res) {
            //setStartSavingFavourite(startSavingFavourite + 1);
          }
        }
      )
    );
  };

  const removeWitnessAddress = witness_id => {
    let witnessNameLocationUpdate = signerData.witnessNameLocationResults || [];
    if (witnessNameLocationUpdate.length) {
      _.remove(witnessNameLocationUpdate, wid => wid.witness_id === witness_id);
    }
    setSignerData(signerData => ({
      ...signerData,
      witnessNameLocationResults: witnessNameLocationUpdate
    }));
  };

  const openAddressViewModel = (title, addresses) => {
    dispatch(
      showAlert(
        title,
        <div>
          <AddressView
            title={title}
            addresses={addresses}
            removeAddress={removeWitnessAddress}
          />
        </div>,
        res => {
          if (res) {
            //setStartSavingFavourite(startSavingFavourite + 1);
          }
        }
      )
    );
  };

  const renderWitness = (title, witness_id) => {
    let identification = _.filter(
      signerData.witnessIdentifications,
      w => w.witness_id === witness_id
    );
    let witnessAddress = _.filter(
      signerData.witnessNameLocationResults,
      w => w.witness_id === witness_id
    );

    let witnessSignature = _.filter(
      signerData.witnessSignatureResults,
      w => w.witness_id === witness_id
    );
    return (
      <div>
        <h5 className="mb-2">{title}</h5>
        <Flex className="row">
          {showAddedIdentifications(identification, false)}
          {witnessAddress.length > 0 ? (
            showWitnessAddress(witnessAddress)
          ) : (
            <></>
          )}
          {witnessSignature.length > 0 ? (
            showWitnessSignature(witnessSignature)
          ) : (
            <></>
          )}
        </Flex>
        <ListGroup>
          <ListGroup.Item>
            <Flex justifyContent="between" alignItems="center">
              <div>
                <FontAwesomeIcon
                  color={
                    witnessAddress.length > 0
                      ? Colors.success.color
                      : Colors.gray.lightColor
                  }
                  icon={faCheckCircle}
                />{' '}
                Enter Witness Address
              </div>
              <div>
                {witnessAddress.length ? (
                  <>
                    <Button
                      className="ms-1"
                      variant="primary"
                      size="sm"
                      onClick={() =>
                        openAddressViewModel('Witness Address', witnessAddress)
                      }
                    >
                      <FontAwesomeIcon icon={faIdBadge} /> View
                    </Button>
                    <Button
                      className="ms-1"
                      variant="outline-primary"
                      size="sm"
                      onClick={() => openWitnessAddressForm(witness_id)}
                    >
                      Change
                    </Button>
                  </>
                ) : (
                  <Button
                    className="ms-1"
                    variant="outline-primary"
                    size="sm"
                    onClick={() => openWitnessAddressForm(witness_id)}
                  >
                    <FontAwesomeIcon icon={faPlusCircle} /> Add
                  </Button>
                )}
              </div>
            </Flex>
          </ListGroup.Item>
          <ListGroup.Item>
            <Flex justifyContent="between" alignItems="center">
              <div>
                <FontAwesomeIcon
                  color={
                    identification.length > 0
                      ? Colors.success.color
                      : Colors.gray.lightColor
                  }
                  icon={faCheckCircle}
                />{' '}
                {identification.length ? 'Added' : 'Enter'} Identification
              </div>
              <div>
                {identification.length ? (
                  <>
                    <Button
                      className="ms-1"
                      variant="primary"
                      size="sm"
                      onClick={() =>
                        openIdentityViewModelFromState(
                          'Witness identification',
                          identification
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faIdBadge} /> View
                    </Button>
                    <Button
                      className="ms-1"
                      variant="outline-primary"
                      size="sm"
                      onClick={() =>
                        openWitnessIdentityForm(
                          signerData.signer_id,
                          witness_id
                        )
                      }
                    >
                      Change
                    </Button>
                  </>
                ) : (
                  <Button
                    className="ms-1"
                    variant="outline-primary"
                    size="sm"
                    onClick={() =>
                      openWitnessIdentityForm(signerData.signer_id, witness_id)
                    }
                  >
                    <FontAwesomeIcon icon={faPlusCircle} /> Add
                  </Button>
                )}
              </div>
            </Flex>
          </ListGroup.Item>
          <ListGroup.Item>
            <Flex justifyContent="between" alignItems="center">
              <div>
                <FontAwesomeIcon
                  color={
                    witnessSignature.length > 0
                      ? Colors.success.color
                      : Colors.gray.lightColor
                  }
                  icon={faCheckCircle}
                />{' '}
                Enter Signature
              </div>
              <div>
                <Button
                  className="ms-1"
                  variant="outline-primary"
                  size="sm"
                  onClick={() => openWitnessSignatureForm(witness_id)}
                >
                  {witnessSignature.length > 0 ? (
                    'Update'
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faPlusCircle} /> Add
                    </>
                  )}
                </Button>
              </div>
            </Flex>
          </ListGroup.Item>
        </ListGroup>
      </div>
    );
  };

  const removeSignerIdentificationFromState = identification_id => {
    let signerIdentificationsUpdate = _.filter(
      signerData.signerIdentifications,
      s => s.identification.identification_id !== identification_id
    );
    setSignerData(signerData => ({
      ...signerData,
      signerIdentifications: signerIdentificationsUpdate
    }));
    //dispatch(actionRemoveIdentification(identification_id));
  };

  const showAddedIdentifications = (identification, removeMethod) => {
    logger.log(identification);
    return _.map(identification, i => {
      let identity = i.identification;
      return draftEntryUiHelper.getIdentificationViewCard(
        identity,
        removeMethod
      );
    });
  };

  const showWitnessAddress = addressList => {
    return _.map(addressList, addressItem => {
      let address = addressItem.location;
      return draftEntryUiHelper.getWitnessAddressViewCards(address);
    });
  };

  const showWitnessSignature = signatureList => {
    return _.map(signatureList, signatureItem => {
      let signature = signatureItem.signature;
      return signature ? (
        <Col lg={4} className="mb-3">
          <Card className="h-md-100">
            <Card.Header className="pb-0">
              <Flex
                justifyContent="between"
                alignContent="center"
                alignItems="center"
              >
                <Flex
                  justifyContent="center"
                  alignContent="center"
                  alignItems="center"
                >
                  <FontAwesomeIcon
                    icon={faIdBadge}
                    size="lg"
                    className="me-3"
                  />
                  <h5 className="mb-0 mb-1">Witness Signature</h5>
                </Flex>
              </Flex>
              <hr className="my-0" />
            </Card.Header>

            <Card.Body as={Flex} justifyContent="between" className="pt-1">
              <div className="fs--1">
                <img
                  style={{ height: 150 }}
                  src={'data:image/png;base64,' + signature.data}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      ) : (
        <></>
      );
    });
  };

  return (
    <>
      <Card>
        <FalconCardHeader
          title={formTitle + ' Information'}
          endEl={
            <Form.Check
              type="switch"
              id="show-full-name"
              label="Show full name"
              className="form-label-nogutter"
              name="showFullName"
              onChange={() => setShowFullName(!showFullName)}
              checked={showFullName}
            />
          }
        ></FalconCardHeader>
        <hr className="my-0" />
        <Card.Body className="bg-light">
          <Row className="mb-3 g-3">
            {showFullName && (
              <Form.Group as={Col} lg={4} controlId="signer_prefix">
                <Form.Label>Prefix</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter prefix name"
                  value={signerData.signer_prefix}
                  name="signer_prefix"
                  onChange={handleChange}
                />
              </Form.Group>
            )}

            <Form.Group as={Col} lg={4} controlId="signer_first_name">
              <Form.Label>First name*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter first name - (Required)"
                value={signerData.signer_first_name}
                name="signer_first_name"
                onChange={handleChange}
              />
            </Form.Group>

            {showFullName && (
              <Form.Group as={Col} lg={4} controlId="middle_name">
                <Form.Label>Middle name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter middle name"
                  value={signerData.signer_middle_name}
                  name="signer_middle_name"
                  onChange={handleChange}
                />
              </Form.Group>
            )}

            <Form.Group as={Col} lg={4} controlId="signer_last_name">
              <Form.Label>Last name*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter last name - (Required)"
                value={signerData.signer_last_name}
                name="signer_last_name"
                onChange={handleChange}
              />
            </Form.Group>

            {showFullName && (
              <Form.Group as={Col} lg={4} controlId="signer_suffix">
                <Form.Label>Sufix</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter name sufix"
                  value={signerData.signer_suffix}
                  name="signer_suffix"
                  onChange={handleChange}
                />
              </Form.Group>
            )}

            {(signerData.witnessChoice === enums.UI_FLOW_TYPE.SIGNER ||
              signerData.witnessChoice ===
                enums.UI_FLOW_TYPE.PERSONALLY_KNOWN) && (
              <Form.Group as={Col} lg={4} className="d-flex">
                <div className="align-self-end d-flex w-100">
                  <Button
                    className="w-100"
                    variant="outline-primary"
                    onClick={() =>
                      openSignerIdentityForm(signerData.signer_id, false)
                    }
                  >
                    <FontAwesomeIcon icon={faPlus} /> Add Identification
                  </Button>
                </div>
              </Form.Group>
            )}
          </Row>

          <Row>
            {showAddedIdentifications(
              signerData.signerIdentifications,
              removeSignerIdentificationFromState
            )}
          </Row>

          <Row>
            {signerData.witnessChoice ===
              enums.UI_FLOW_TYPE.PERSONALLY_KNOWN && (
              <div className="mt-3">
                <Alert
                  variant="primary"
                  onClose={() => resetWitnessTypeSigner()}
                  dismissible
                >
                  <Alert.Heading>
                    Personally Known Signer Selected
                  </Alert.Heading>
                  <p>
                    This method requires the notary to personally know the
                    signer. Please check your state notary laws for further
                    guidance.
                  </p>
                  <IconAlert variant={'warning'}>
                    <p className="mb-0">
                      By verifying that this signer is personally known to the
                      notary, you as the notary are solely responsible for the
                      implications of identifying a signer with this method.
                    </p>
                  </IconAlert>
                </Alert>
              </div>
            )}
            {signerData.witnessChoice === enums.UI_FLOW_TYPE.WITNESS_ONE && (
              <div className="mt-3">
                <Card>
                  <Card.Header className="justify-content-between d-flex">
                    <div className="text-nowrap d-flex">
                      <IconItem
                        icon="user"
                        bg={`soft-primary`}
                        color="primary"
                        size="sm"
                        iconClass="fs--2"
                        className="me-2 shadow-none"
                      />{' '}
                      One Credible Witness
                    </div>
                    <FalconCloseButton
                      size="sm"
                      noOutline
                      onClick={() => resetWitnessTypeSigner()}
                    />
                  </Card.Header>
                  <hr className="my-0" />
                  <Card.Body>
                    {renderWitness(
                      'One witness information',
                      signerData.sorted_witness_ids[0]
                    )}
                  </Card.Body>
                </Card>
              </div>
            )}

            {signerData.witnessChoice === enums.UI_FLOW_TYPE.WITNESS_TWO && (
              <div className="mt-3">
                <Card>
                  <Card.Header className="justify-content-between d-flex">
                    <div className="text-nowrap d-flex">
                      <IconItem
                        icon={faUserFriends}
                        bg={`soft-primary`}
                        color="primary"
                        size="sm"
                        iconClass="fs--2"
                        className="me-2 shadow-none"
                      />{' '}
                      Two Credible Witness
                    </div>
                    <FalconCloseButton
                      size="sm"
                      noOutline
                      onClick={() => resetWitnessTypeSigner()}
                    />
                  </Card.Header>
                  <hr className="my-0" />
                  <Card.Body>
                    {renderWitness(
                      'First witness information',
                      signerData.sorted_witness_ids[0]
                    )}
                    <hr />
                    {renderWitness(
                      'Second witness information',
                      signerData.sorted_witness_ids[1]
                    )}
                  </Card.Body>
                </Card>
              </div>
            )}
          </Row>
          <Row className="mt-3">
            <Col sm={12}>
              <div className="text-end">
                {signerData.isSaving && (
                  <Button type="button" variant="outline-default">
                    <Spinner size="sm" /> Saving...
                  </Button>
                )}
                {signerData.signer_first_name &&
                  signerData.signer_last_name && (
                    <Button
                      variant="primary"
                      type="button"
                      className="mx-1"
                      disabled={
                        signerData.isSaving ||
                        !signerData.signer_first_name ||
                        !signerData.signer_last_name
                      }
                      onClick={() => saveSigner()}
                    >
                      {signerData.witnessChoice ===
                      enums.UI_FLOW_TYPE.PERSONALLY_KNOWN ? (
                        'Verify as ' + formTitle
                      ) : (
                        <>
                          {signerData.isEditMode ? 'Update' : 'Save'}{' '}
                          {formTitle}{' '}
                          {signerData.signerIdentifications?.length === 0 &&
                          signerData.witnessChoice === enums.UI_FLOW_TYPE.SIGNER
                            ? ' Without Identification'
                            : 'Information'}
                        </>
                      )}
                    </Button>
                  )}
                <Button
                  variant="outline-danger"
                  className="mx-1"
                  onClick={() => resetProfileForm(true)}
                >
                  Close
                </Button>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mt-2">
        {!signerData?.signerIdentifications?.length && (
          <>
            <Card.Header>
              <div className="text-start mt-0">
                <p className="text-center fs--1 my-0 text-muted">
                  - or Select Other identification options below
                </p>
              </div>
            </Card.Header>
            <hr className="my-0" />
            <Card.Body>
              <Row>
                <Col
                  lg={4}
                  className="border-bottom border-lg-0 border-lg-end pb-3 pb-lg-0"
                >
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    className="mb-3 position-static"
                  >
                    <IconItem
                      tag="div"
                      icon={faUser}
                      bg={`soft-primary`}
                      color="primary"
                      size="sm"
                      iconClass="fs--2"
                      className="me-1 shadow-none"
                    />
                    <h6 className="mb-0 flex-1">One Credible Witness</h6>
                    {signerData.witnessChoice ===
                    enums.UI_FLOW_TYPE.WITNESS_ONE ? (
                      <Button
                        variant="outline-warning"
                        size="sm"
                        transform="shrink-3"
                        onClick={() => resetWitnessTypeSigner()}
                      >
                        Unselect
                      </Button>
                    ) : (
                      <IconButton
                        variant="primary"
                        size="sm"
                        icon="plus"
                        transform="shrink-3"
                        onClick={() => addOneCreditWitness()}
                      >
                        Add
                      </IconButton>
                    )}
                  </Flex>
                  <Flex justifyContent="column">
                    <div className="d-flex flex-column">
                      <p className="fs--2 mb-0 mt-0">
                        This method requires the witnesses to be known by both
                        the Notary Public and Signer Valid ID recommended.
                      </p>
                    </div>
                  </Flex>
                </Col>
                <Col
                  lg={4}
                  className="border-bottom border-lg-0 border-lg-end py-3 py-lg-0"
                >
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    className="mb-3 position-static"
                  >
                    <IconItem
                      tag="div"
                      icon={faUserFriends}
                      bg={`soft-primary`}
                      color="primary"
                      size="sm"
                      iconClass="fs--2"
                      className="me-1 shadow-none"
                    />
                    <h6 className="mb-0 flex-1">Two Credible Witnesses</h6>
                    {signerData.witnessChoice ===
                    enums.UI_FLOW_TYPE.WITNESS_TWO ? (
                      <Button
                        variant="outline-warning"
                        size="sm"
                        transform="shrink-3"
                        onClick={() => resetWitnessTypeSigner()}
                      >
                        Unselect
                      </Button>
                    ) : (
                      <IconButton
                        variant="primary"
                        size="sm"
                        icon="plus"
                        transform="shrink-3"
                        onClick={() => addTwoCreditWitness()}
                      >
                        Add
                      </IconButton>
                    )}
                  </Flex>
                  <Flex justifyContent="column">
                    <div className="d-flex flex-column">
                      <p className="fs--2 mb-0 mt-0">
                        This method requires both witnesses to be known by the
                        signer, but does not require them to be known to the
                        Notary Public. Valid ID required.
                      </p>
                    </div>
                  </Flex>
                </Col>
                <Col lg={4} className="pt-3 pt-lg-0">
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    className="mb-3 position-static"
                  >
                    <IconItem
                      tag="div"
                      icon={faUserCheck}
                      bg={`soft-primary`}
                      color="primary"
                      size="sm"
                      iconClass="fs--2"
                      className="me-1 shadow-none"
                    />
                    <h6 className="mb-0 flex-1">Personally Known</h6>
                    {signerData.witnessChoice ===
                    enums.UI_FLOW_TYPE.PERSONALLY_KNOWN ? (
                      <Button
                        variant="outline-warning"
                        size="sm"
                        transform="shrink-3"
                        onClick={() => resetWitnessTypeSigner()}
                      >
                        Unselect
                      </Button>
                    ) : (
                      <IconButton
                        variant="primary"
                        size="sm"
                        icon="plus"
                        transform="shrink-3"
                        onClick={() => addPersonallyKnown()}
                      >
                        Add
                      </IconButton>
                    )}
                  </Flex>
                  <Flex justifyContent="column">
                    <div className="d-flex flex-column">
                      <p className="fs--2 mb-0 mt-0">
                        This method requires the notary to personally know the
                        signer. Please check your state notary laws for further
                        guidance.
                      </p>
                    </div>
                  </Flex>
                </Col>
              </Row>
            </Card.Body>
          </>
        )}

        {signerData.validationErr.length > 0 && (
          <Card.Body>
            <Row className="mb-3 g-3">
              <ValidationErrors errors={signerData.validationErr} />
            </Row>
          </Card.Body>
        )}
      </Card>
      {signatureForId && (
        <SignatureModal
          author={author}
          signatureForId={signatureForId}
          showSignatureForm={showSignatureForm}
          setShowSignatureForm={setShowSignatureForm}
          setSignatureData={setSignatureData}
        />
      )}
    </>
  );
};
export default NotarySignerForm;
