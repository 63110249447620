import {
  faCheckCircle,
  faExclamationCircle
} from '@fortawesome/free-solid-svg-icons';
import proIcon from 'assets/img/icons/pro.svg';
import rocketIcon from 'assets/img/icons/rocket.svg';
import Flex from 'components/common/Flex';
import PageHeader from 'components/common/PageHeader';
import CurrentSubscriptionView from 'components/pages/dashboard/default/CurrentSubscriptionView';
import FaqBasicCard from 'components/pages/knowledge-center/faq/FaqBasicCard';
import AddPaymentCard from 'components/stripe/AddPaymnetCard';
import AppContext from 'context/Context';
import { faqs as faqsData } from 'data/faqs-subscription';
import { capitalize } from 'helpers/utils';
import _ from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Form,
  FormControl,
  InputGroup,
  ListGroup,
  Modal,
  Row,
  Spinner
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { refreshAllAccountInfo } from 'stores/app';
import {
  paymentMethodGet,
  proceedToSubscribePlan,
  refreshSubscriptionTier
} from 'stores/subscription';
import api from 'util/api';
import logger from 'util/logger';
import SubscriptionDefaultHeader from './SubscriptionDefaultHeader';
import SubscriptionV1Card from './SubscriptionV1Card';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ValidationErrors from 'components/utilities/ValidationErrors';
import filters from 'util/filters';
import Lottie from 'lottie-react';
import celebrationAnimation from 'assets/img/animated-icons/animation-with-strips.json';
import segmentHelper from 'helpers/segment-helper';

const Subscriptions = () => {
  const [faqs] = useState(faqsData);

  const [loadingSubscription, setLoadingSubscription] = useState(false);
  const [subscriptionProgressState, setSubscriptionProgressState] =
    useState(null);
  const [proceedToSubscribeWithPrice, setProceedToSubscribeWithPrice] =
    useState(null);
  const [savingCard, setSavingCard] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [addCardMessage, setAddCardMessage] = useState(null);
  const [modalJourny, setModalJourny] = useState(null);
  const [paymentCardToCheckout, setPaymentCardToCheckout] = useState(null);
  const [paymentPlanToCheckout, setPaymentPlanToCheckout] = useState(null);

  const [applyingCoupon, setApplyingCoupon] = useState(false);
  const [validCoupon, setValidCoupon] = useState(null);
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [validationErr, setValidationErr] = useState([]);
  const [couponCodeText, setCouponCodeText] = useState('');

  const { subscriptionStatus, subscriptionTier, paymentCards } = useSelector(
    state => state.subscription
  );
  const userMaster = useSelector(state => state.app?.userMaster);
  let activeSubscriptions = _.get(subscriptionStatus, 'Active', []);
  let currentSubscriptions = _.get(subscriptionStatus, 'Current', null);
  const dispatch = useDispatch();
  const {
    config: { isRTL }
  } = useContext(AppContext);

  const setResponseMessage = (message, variant) => {
    setAddCardMessage(
      message
        ? { message: capitalize(message), variant: variant || 'primary' }
        : null
    );
  };

  const initPricingPlans = () => {
    setLoadingSubscription(true);
    return new Promise((resolve, reject) => {
      dispatch(refreshSubscriptionTier()).then(res => {
        setLoadingSubscription(false);
        dispatch(paymentMethodGet());
      });
    });
  };

  const confirmUpgradePlan = planGUID => {
    setModalShow(true);
    setPaymentPlanToCheckout(planGUID);
  };

  const subscribePlan = (planGUID, cardGUID) => {
    setSubscriptionProgressState('Subscription in progress...');
    if (
      !planGUID &&
      !paymentPlanToCheckout &&
      !validCoupon &&
      !validCoupon.GUID
    ) {
      toast.warning('This plan is not selected/available to subscribe', {
        theme: 'colored',
        position: 'bottom-center',
        icon: faExclamationCircle,
        toastId: 'subscription'
      });
      setSubscriptionProgressState(null);
      return;
    }

    if (!paymentCardToCheckout && !cardGUID) {
      toast.warning('Payment method is missing', {
        theme: 'colored',
        position: 'bottom-center',
        icon: faExclamationCircle,
        toastId: 'subscription'
      });
      setSubscriptionProgressState(null);
      return;
    }
    if (validCoupon) {
      proceedToSubscriptionActivation(
        cardGUID || paymentCardToCheckout,
        validCoupon.GUID,
        couponCodeText
      );
    } else {
      proceedToSubscriptionActivation(
        cardGUID || paymentCardToCheckout,
        planGUID || paymentPlanToCheckout
      );
    }
  };

  const proceedToSubscriptionActivation = (cardGUID, planGUID, couponCode) => {
    let selectedSubscriptionPlan = _.find(
      subscriptionTier,
      s => s.GUID === planGUID
    );
    setSavingCard(true);
    dispatch(proceedToSubscribePlan(cardGUID, planGUID, couponCode))
      .then(res => {
        if (currentSubscriptions) {
          segmentHelper.track(
            segmentHelper.events.SUBSCRIPTION_UPGRADED +
              ' from ' +
              (currentSubscriptions.Type + '/' + currentSubscriptions.Recur) +
              ' to ' +
              (selectedSubscriptionPlan.Type +
                '/' +
                selectedSubscriptionPlan.Recur),
            {
              planId: planGUID,
              paymentId: cardGUID,
              email: userMaster.Email,
              name: userMaster.FullName,
              guid: userMaster.GUID
            }
          );

          if (currentSubscriptions.Type === 'Trial') {
            segmentHelper.track(segmentHelper.events.SUBSCRIBED_FREE_TO_PAID, {
              email: userMaster.Email,
              name: userMaster.FullName,
              guid: userMaster.GUID
            });
          }
        }
        setSubscriptionProgressState(
          'Subscription plan activated successfully'
        );

        cancelSubscriptionModal();
        toast.success('Subscription plan activated successfully', {
          theme: 'colored',
          position: 'bottom-center',
          icon: faCheckCircle,
          toastId: 'subscription'
        });

        if (couponCode) {
          segmentHelper.track(
            segmentHelper.events.SUBSCRIPTION_UPGRADED_WITH_COUPON,
            {
              couponCode: couponCode,
              planId: planGUID,
              paymentId: cardGUID,
              email: userMaster.Email,
              name: userMaster.FullName,
              guid: userMaster.GUID
            }
          );
        } else {
          segmentHelper.track(segmentHelper.events.SUBSCRIPTION_UPGRADED, {
            planId: planGUID,
            paymentId: cardGUID,
            email: userMaster.Email,
            name: userMaster.FullName,
            guid: userMaster.GUID
          });
        }

        //Require to track Add Upgrade subscription

        dispatch(refreshSubscriptionTier()).then(res => {
          dispatch(refreshAllAccountInfo());
          setSubscriptionProgressState(null);
        });
      })
      .catch(err => {
        logger.log('err', err);
        setValidationErr(['Unable to upgrade subscription.']);
        setSubscriptionProgressState(null);
        setSavingCard(false);
        //setPaymentCardToCheckout(null);
        //setPaymentPlanToCheckout(null);
      });
  };

  const handlePaymentIdMethod = async res => {
    setValidationErr([]);
    logger.log('handlePaymentIdMethod res', res);
    setSavingCard(true);
    if (res) {
      setSubscriptionProgressState('Saving payment method...');
      try {
        new Promise((resolve, reject) => {
          if (res) resolve(api.PaymentCardV2.create(res.id));
          else reject(false);
          //resolve(api.PaymentCardV2.create('pm_card_visa'))
        })
          .then(paymentCardRes => {
            segmentHelper.track(segmentHelper.events.PAYMENT_METHOD_ADDED, {
              email: userMaster.Email,
              name: userMaster.FullName,
              guid: userMaster.GUID
            });
            setSubscriptionProgressState('Payment method saved');
            logger.log('handlePaymentIdMethod paymentCardRes', paymentCardRes);
            setPaymentCardToCheckout(paymentCardRes.GUID);
            subscribePlan(null, paymentCardRes.GUID);
          })
          .catch(err => {
            setSavingCard(false);
            setSubscriptionProgressState(null);
            setValidationErr(['Unable to create payment method. Try again!']);
          });
      } catch (err) {
        setSavingCard(false);
        setSubscriptionProgressState(null);
        logger.log('payment update error', err);
        setResponseMessage(
          err.Message ||
            'There was a problem updating your subscription.  Please try again.',
          'danger'
        );
        setModalJourny(
          err.Message ||
            'There was a problem updating your subscription.  Please try again.'
        );
      }
    } else {
      setSavingCard(false);
      setSubscriptionProgressState(null);
      setValidationErr(['Unable to save payment method.']);
    }
  };

  const cancelSubscriptionModal = () => {
    setModalShow(false);
    setPaymentCardToCheckout(null);
    setPaymentPlanToCheckout(null);
    setValidCoupon(null);
  };

  const openCouponModal = () => {
    logger.log('userMaster', userMaster);
    segmentHelper.track(segmentHelper.events.CLICKED_I_HAVE_A_COUPON, {
      email: userMaster.Email,
      name: userMaster.FullName,
      guid: userMaster.GUID
    });
    setCouponCodeText('');
    setShowCouponModal(true);
  };

  const cancelCouponModal = () => {
    setValidationErr([]);
    setShowCouponModal(false);
  };

  const doCouponValidation = () => {
    let err = [];
    if (!couponCodeText || couponCodeText.length === 0) {
      err.push('Please enter your coupon code');
    }
    logger.log('doCouponValidation err', err);
    setValidationErr(err);
    return err.length === 0;
  };

  const submitCoupon = () => {
    if (!doCouponValidation()) {
      return;
    }
    logger.log('Applying Coupon code', couponCodeText);
    setApplyingCoupon(true);
    api.SubscriptionTier.testCoupon(couponCodeText)
      .then(res => {
        logger.log('test coupon res', res);
        if (!res || res.length === 0) {
          setValidationErr(['This coupon is not valid.']);
          setCouponCodeText('');
          //setValidCoupon(TestCoupon);
        } else {
          segmentHelper.track(segmentHelper.events.COUPON_CONFIRMED, {
            email: userMaster.Email,
            name: userMaster.FullName,
            guid: userMaster.GUID
          });
          setValidCoupon(res.length > 0 ? res[0] : null);
        }
        setApplyingCoupon(false);
      })
      .catch(err => {
        logger.log('error testing coupon', err);
        setCouponCodeText('');
        setApplyingCoupon(false);
        setValidationErr([
          'There was a problem checking your coupon.  Please try again..'
        ]);
      });
  };

  useEffect(() => {
    if (validCoupon) {
      cancelCouponModal();
      setModalShow(true);
    }
  }, [validCoupon]);

  useEffect(() => {
    setValidationErr([]);
  }, [couponCodeText, paymentCardToCheckout]);

  useEffect(() => {
    setProceedToSubscribeWithPrice(null);
    initPricingPlans();
  }, []);

  const PaymentCardsList = () => {
    return (
      <ListGroup>
        {_.map(paymentCards, card => {
          return (
            <ListGroup.Item key={card.GUID}>
              <Flex style={{ justifyContent: 'space-between' }}>
                <div className="mr-1">
                  <Form.Check
                    className="mb-0"
                    type="radio"
                    checked={paymentCardToCheckout === card.GUID}
                    onChange={() => setPaymentCardToCheckout(card.GUID)}
                    id={_.uniqueId('payment-card') + card.GUID}
                    label={`Card ending with .... ${card.CardLast4}`}
                    name="radio"
                  />{' '}
                </div>
                <div className="ml-1">
                  {' '}
                  {card.ExpMonth}/{card.ExpYear}
                </div>
              </Flex>
            </ListGroup.Item>
          );
        })}
        <ListGroup.Item key="new-card">
          <Flex style={{ justifyContent: 'space-between' }}>
            <div className="mr-1">
              <Form.Check
                className="mb-0"
                type="radio"
                checked={paymentCardToCheckout === ''}
                onChange={() => setPaymentCardToCheckout('')}
                id={_.uniqueId('payment-card')}
                label={`Add new card`}
                name="radio"
              />{' '}
            </div>
            <div className="ml-1">
              {' '}
              <Badge variant="info">New</Badge>
            </div>
          </Flex>
        </ListGroup.Item>
      </ListGroup>
    );
  };

  return (
    <>
      <PageHeader
        title="Subscription Management"
        description="Manage your subscription and billing details below."
        className="mb-3"
        titleTag="h2"
      ></PageHeader>

      <Col sm={12} className="pe-lg-2 mb-3">
        <CurrentSubscriptionView />
      </Col>

      <Row className="g-0">
        <Col sm={12}>
          <Card className="mb-3">
            <Card.Body>
              <Row className="g-0">
                <SubscriptionDefaultHeader openCouponModal={openCouponModal} />
                <Flex justifyContent="center">
                  <>
                    {loadingSubscription ? (
                      <Col xs={12} lg={9} xl={10} xxl={8} className="py-4">
                        <Spinner
                          className="position-absolute start-50"
                          animation="grow"
                        />
                      </Col>
                    ) : (
                      <Col xs={12} lg={11} xl={11} xxl={11}>
                        <Row>
                          <Col xs={12}>
                            {addCardMessage && (
                              <div className="flex-1 mt-1">
                                <Alert
                                  variant={addCardMessage.variant}
                                  onClose={() => setAddCardMessage(null)}
                                  dismissible
                                >
                                  <p className="py-0 my-0">
                                    {addCardMessage.message}
                                  </p>
                                </Alert>
                              </div>
                            )}
                          </Col>
                        </Row>
                        <Row className="d-flex justify-content-center">
                          <Col md={5} lg={4}>
                            <Card bg="primary" text="light">
                              <Card.Header className="pb-0">
                                <h5 className="text-light">
                                  All plans come fully loaded with all of our
                                  features, including
                                </h5>
                              </Card.Header>
                              <hr />
                              <Card.Body>
                                <ul className="list-unstyled">
                                  <li
                                    className={classNames(
                                      'border-bottom py-2',
                                      `text-priamry`
                                    )}
                                  >
                                    {' '}
                                    <FontAwesomeIcon
                                      icon="check"
                                      transform="shrink-2"
                                    />{' '}
                                    No limit on new entries being created
                                  </li>
                                  <li
                                    className={classNames(
                                      'border-bottom py-2',
                                      `text-priamry`
                                    )}
                                  >
                                    {' '}
                                    <FontAwesomeIcon
                                      icon="check"
                                      transform="shrink-2"
                                    />{' '}
                                    Unlimited drafts
                                  </li>
                                  <li
                                    className={classNames(
                                      'border-bottom py-2',
                                      `text-priamry`
                                    )}
                                  >
                                    {' '}
                                    <FontAwesomeIcon
                                      icon="check"
                                      transform="shrink-2"
                                    />{' '}
                                    Smart location features
                                  </li>
                                  <li
                                    className={classNames(
                                      'border-bottom py-2',
                                      `text-priamry`
                                    )}
                                  >
                                    {' '}
                                    <FontAwesomeIcon
                                      icon="check"
                                      transform="shrink-2"
                                    />{' '}
                                    Fraud-deterring ID scan technology
                                  </li>
                                  <li
                                    className={classNames(
                                      'border-bottom py-2',
                                      `text-priamry`
                                    )}
                                  >
                                    {' '}
                                    <FontAwesomeIcon
                                      icon="check"
                                      transform="shrink-2"
                                    />{' '}
                                    Digital thumbprint capture (requires
                                    purchase of Bluetooth scanner)
                                  </li>
                                  <li
                                    className={classNames(
                                      'border-bottom py-2',
                                      `text-priamry`
                                    )}
                                  >
                                    {' '}
                                    <FontAwesomeIcon
                                      icon="check"
                                      transform="shrink-2"
                                    />{' '}
                                    Customizable document library & signing
                                    packages
                                  </li>
                                  <li
                                    className={classNames(
                                      'border-bottom py-2',
                                      `text-priamry`
                                    )}
                                  >
                                    {' '}
                                    <FontAwesomeIcon
                                      icon="check"
                                      transform="shrink-2"
                                    />{' '}
                                    And much more
                                  </li>
                                </ul>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col md={7} lg={8}>
                            <Row className="d-flex justify-content-center">
                              {subscriptionTier &&
                                subscriptionTier.map(pricing => (
                                  <SubscriptionV1Card
                                    key={pricing.GUID}
                                    pricing={pricing}
                                    icon={
                                      pricing?.Recur === 'Year'
                                        ? rocketIcon
                                        : proIcon
                                    }
                                    confirmUpgradePlan={confirmUpgradePlan}
                                    activeSubscriptions={activeSubscriptions}
                                  />
                                ))}
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    )}
                  </>
                </Flex>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <FaqBasicCard
        faqs={faqs}
        header
        headerText="Frequently asked questions"
        bodyClass="bg-light"
        faqIdentity="Subscription page"
      />

      <Modal
        show={modalShow}
        onHide={() => cancelSubscriptionModal()}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Add Card</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {validCoupon && (
            <Card
              className="mb-4 text-dark"
              bg="success"
              style={{
                border: 1,
                borderStyle: 'dashed',
                borderWidth: 4,
                borderColor: 'teal'
              }}
            >
              <Card.Header className="text-center text-dark fs-3">
                <FontAwesomeIcon icon={faCheckCircle} /> Coupon Confirmed!
              </Card.Header>
              <Card.Body className="pt-0">
                <div className="text-center">
                  <Badge className="text-uppercase" variant="info">
                    {couponCodeText}
                  </Badge>
                </div>
                <div className="fs-1 text-center">{validCoupon?.Name}</div>

                {_.isNumber(validCoupon?.InitialAmount) && (
                  <div>
                    <p className="text-center mb-0">
                      This plan costs{' '}
                      {filters.formatCurrencyFromServer(
                        validCoupon.InitialAmount
                      )}{' '}
                      for the first{' '}
                      {_.get(validCoupon, 'Recur', '').toLowerCase()}, then{' '}
                      {filters.formatCurrencyFromServer(validCoupon.Amount)} per{' '}
                      {_.get(validCoupon, 'Recur', '').toLowerCase()} after
                      that.
                    </p>
                  </div>
                )}
                {!_.isNumber(validCoupon?.InitialAmount) && (
                  <p className="text-center mb-1">
                    ${validCoupon.Amount} / {validCoupon.Recur}
                  </p>
                )}
              </Card.Body>
              <Lottie
                animationData={celebrationAnimation}
                loop={true}
                style={{
                  height: '100%',
                  width: '100%',
                  zIndex: 0,
                  position: 'absolute'
                }}
              ></Lottie>
            </Card>
          )}
          {subscriptionProgressState == null ? (
            <Row>
              <Col sm={12}>
                {paymentCards?.length > 0 ? (
                  <>
                    <PaymentCardsList />
                    {paymentCardToCheckout === '' && (
                      <div className="mt-3">
                        <AddPaymentCard
                          handlePaymentIdMethod={handlePaymentIdMethod}
                          isSaving={savingCard}
                          setSaving={setSavingCard}
                        />
                      </div>
                    )}
                    {paymentCardToCheckout !== '' && (
                      <Flex className={'my-3'} justifyContent={'center'}>
                        <Button
                          onClick={() => {
                            subscribePlan();
                          }}
                          disabled={savingCard}
                          className="mt-3"
                          variant="primary"
                        >
                          {savingCard
                            ? 'Upgrading...'
                            : 'Select Card & Proceed'}
                        </Button>
                      </Flex>
                    )}
                  </>
                ) : (
                  <>
                    <AddPaymentCard
                      handlePaymentIdMethod={handlePaymentIdMethod}
                      isSaving={savingCard}
                      setSaving={setSavingCard}
                    />
                  </>
                )}
              </Col>
              {addCardMessage && (
                <div className="flex-1 mt-1">
                  <Alert variant={addCardMessage.variant}>
                    <p className="py-0 my-0">{addCardMessage.message}</p>
                  </Alert>
                </div>
              )}
            </Row>
          ) : (
            <Row>
              <Col sm={12}>
                <p className="text-center">{subscriptionProgressState}</p>
              </Col>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          {validationErr.length > 0 && (
            <div className="w-100">
              <ValidationErrors errors={validationErr} />
            </div>
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        show={showCouponModal}
        onHide={() => cancelCouponModal()}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Coupon Code
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
            <FormControl
              disabled={applyingCoupon}
              placeholder="Enter Coupon Code"
              aria-label="Enter your Coupon Code below"
              name="coupon_code"
              value={couponCodeText}
              onChange={e => setCouponCodeText(e.target.value)}
            />
            <Button
              disabled={applyingCoupon}
              onClick={() => submitCoupon()}
              variant={couponCodeText ? 'outline-primary' : 'outline-secondary'}
            >
              Apply
            </Button>
          </InputGroup>
          {applyingCoupon && (
            <Flex justifyContent={'center'}>
              <Spinner />
            </Flex>
          )}
        </Modal.Body>
        <Modal.Footer>
          {validationErr.length > 0 && (
            <div className="w-100">
              <ValidationErrors errors={validationErr} />
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Subscriptions;
