import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import ReactSignatureCanvas from 'react-signature-canvas';
import { showAlert } from 'stores/modal';

const SignatureScratchPad = ({
    setSignatureData,
    signatureSignerId,
    closeEle
}) => {
    const [enableSignatureSave, setEnableSignatureSave] = useState(false);
    const [strokeWidth, setStrokWidth] = useState(1.5);

    let signaturePad = {};

    const clearSignaturePad = () => {
        signaturePad.clear();
    };

    const saveSignerSignature = () => {
        let signatureImage = signaturePad.getTrimmedCanvas().toDataURL('image/png');
        if (signatureImage.length === 0) {
            dispatch(
                showAlert(
                    'Unable to get Signature',
                    'There was a problem generating this signature image.  Please try again.'
                )
            );
            return;
        }

        let signatureDataObject = {
            signatureForId: signatureSignerId || null,
            data: _.replace(signatureImage, 'data:image/png;base64,', ''),
            content_type: 'image/png',
            dataUri: signatureImage
        };
        setSignatureData(signatureDataObject);
        signaturePad.clear();
    };

    return (
        <Flex justifyContent="center" direction={'column'}>
            <div>
                <ReactSignatureCanvas
                    ref={ref => (signaturePad = ref)}
                    penColor="black"
                    minWidth={strokeWidth - 1}
                    maxWidth={strokeWidth + 1}
                    dotSize={1.5}
                    canvasProps={{ width: 430, height: 300, className: 'sigCanvas' }}
                    onEnd={() => setEnableSignatureSave(true)}
                />
            </div>
            <div className="w-100">
                <Form.Range
                    min={1.5}
                    max={3.5}
                    step={0.1}
                    onChange={e => setStrokWidth(e.target.value)}
                />
            </div>
            <div className="w-100">
                <Flex justifyContent="center">
                    <Button
                        className="mx-1"
                        variant="secondary"
                        onClick={() => clearSignaturePad()}
                    >
                        Clear
                    </Button>
                    {enableSignatureSave && (
                        <Button
                            className="mx-1"
                            variant="primary"
                            onClick={() => saveSignerSignature()}
                        >
                            Save
                        </Button>
                    )}
                    {closeEle}
                </Flex>
            </div>
        </Flex>

    );
};
SignatureScratchPad.propTypes = {
    setSignatureData: PropTypes.func.isRequired,
    signatureSignerId: PropTypes.any,
    closeEle: PropTypes.element
};

export default SignatureScratchPad;
