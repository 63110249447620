import PageHeader from 'components/common/PageHeader';
import React from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import VerificationEmail from './VerificatinEmail';
import VerificationMobile from './VerificationMobile';
import corner1 from 'assets/img/illustrations/corner-1.png';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { showAlert } from 'stores/modal';
import api from 'util/api';
import { useState } from 'react';
import { refreshAllAccountInfo, refreshUserMaster } from 'stores/app';
import { useEffect } from 'react';
import logger from 'util/logger';
import moment from 'moment';

const Verification = () => {

    const [stateData, setStateData] = useState({
        validationErr: [],
        isUpdatingAccount: false,
        Telephone: '',
        SmsOptIn: false,
        updatingTelephone: false,

        didSendEmailVerification: false,
        didSendSMSVerification: false,

        isFinishingEmailVerification: false,
        isFinishingSMSVerification: false,
        smsVerificationCode: '',

        otherPlansIsExpanded: false,
        visibleActivePlan: null,

        devices: [],
        deviceLoadError: false,

        paymentHistory: [],
        paymentHistoryLoadError: false
    });

    const { userMaster } = useSelector((state) => state.app);
    const dispatch = useDispatch();

    useEffect(() => {
        logger.log('stateData', stateData);
    }, [stateData])
    useEffect(() => {
        if (userMaster)
            setStateData({ ...stateData, Telephone: userMaster.Telephone, SmsOptIn: userMaster.SmsOptIn, });
    }, [userMaster])

    const startEmailVerification = () => {
        setStateData({ ...stateData, isFinishingEmailVerification: true });
        api.Verify.startEmail()
            .then((res) => {
                dispatch(showAlert('Success', "A verification link has been sent to your email address.  Please check your email.  If more than one verification email was sent, you must use the latest."));
                setStateData({ ...stateData, didSendEmailVerification: true })
                setStateData({ ...stateData, isFinishingEmailVerification: false });
                return dispatch(refreshUserMaster());
            })
            .catch((err) => {
                setStateData({ ...stateData, isFinishingEmailVerification: false });
                dispatch(showAlert('Error Verifying Email', "There was a problem sending your verification email.  Please try again, or contact our support."));
            })
    }

    const updateMobileNumber = () => {
        let updateParams = Object.assign({}, userMaster);
        setStateData({ ...stateData, updatingTelephone: true });
        updateParams.SmsOptIn = stateData.SmsOptIn ? moment().utc().format() : null;
        updateParams.Telephone = stateData.Telephone;

        api.UserMaster.update(userMaster.GUID, updateParams)
            .then((res) => {
                setFormData({ ...formData, updatingTelephone: false });
                return dispatch(refreshUserMaster());
            })
            .then((res) => {
                setFormData({ ...formData, updatingTelephone: false });
                toast.success(`Mobile number updated successfully`, {
                    theme: 'colored'
                });
            })
            .catch((err) => {
                logger.log(err);
                if (err) {
                    setFormData({ ...formData, updatingTelephone: false });
                    toast.error(`Unable to save', "There was a problem saving your information.  Please try again.`, {
                        theme: 'colored'
                    });
                }
            })
    }

    const startSMSVerification = () => {

        if (!userMaster.Telephone) {
            dispatch(showAlert('No SMS Number', "There is no SMS number associated with your account yet.  Please add one to your profile and try again."));
            return;
        }

        api.Verify.startSMS()
            .then((res) => {
                dispatch(showAlert('Success', "A verification code has been sent to " + userMaster.Telephone + ".  Please check your device.  If more than one verification text was sent, you must use the latest."));
                setStateData({ ...stateData, didSendSMSVerification: true });
                return dispatch(refreshUserMaster());
            })
            .catch((err) => {
                dispatch(showAlert('Error Verifying SMS', "There was a problem while verifying your sms number.  Please try again, or contact our support."));
            })
    }

    const submitSMSCode = () => {
        let { smsVerificationCode } = stateData;

        setStateData({ ...stateData, isFinishingSMSVerification: true })
        api.Verify.finishSMS(smsVerificationCode, stateData.SmsOptIn ? moment().utc().format() : null)
            .then((res) => {
                return dispatch(refreshUserMaster());
            })
            .then(() => {
                // Do something
            })
            .catch((err) => {
                dispatch(showAlert('Error Verifying SMS', "There was a problem while verifying your sms number.  If more than one verification text was sent, you must use the latest.  Please try again, or contact our support."));
            })
            .finally(() => {
                setStateData({ ...stateData, isFinishingSMSVerification: false })
            })

    }

    return (
        <>
            <PageHeader
                preTitle=""
                title="Account Verification"
                description=""
                className="mb-3"
                titleTag="h2"
                image={corner1}
            >
            </PageHeader>
            {userMaster ? <Row className="g-3 mb-3">
                <Col lg={6}>
                    <Card className="h-lg-100">
                        <Card.Body className="p-4 p-sm-5 text-center">
                            <VerificationMobile stateData={stateData} setStateData={setStateData} userMaster={userMaster} startSMSVerification={startSMSVerification} updateMobileNumber={updateMobileNumber} submitSMSCode={submitSMSCode} />
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Card className="h-lg-100">
                        <Card.Body className="p-4 p-sm-5 text-center">
                            <VerificationEmail stateData={stateData} setStateData={setStateData} userMaster={userMaster} startEmailVerification={startEmailVerification} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row> :
                <Row>
                    <Col lg={12}>
                        <Card className="h-lg-100">
                            <Card.Body className="p-4 p-sm-5 text-center">
                                <Spinner />
                            </Card.Body>
                        </Card>
                    </Col></Row>}
        </>
    );
};

export default Verification;
