import _ from 'lodash';

export const getColorName = () => {
  let c = ['primary', 'secondary', 'success', 'info', 'warning', 'danger'];
  return c[_.random(1, c.length - 1)];
};
const Colors = {
  primary: {
    color: '#2C7BE5',
    lightColor: '#D5E5FA'
  },
  success: {
    color: '#00D27A',
    lightColor: '#CCF6E4'
  },
  danger: {
    color: '#E63757',
    lightColor: '#FAD7DD'
  },
  info: {
    color: '#27BCFD',
    lightColor: '#D4F2FF'
  },
  warning: {
    color: '#F5803E',
    lightColor: '#FDE6D8'
  },
  gray: {
    color: '#0B1727',
    lightColor: '#CED1D4'
  },
  android: {
    color: '#3DDC84'
  },
  apple: {
    color: '#555555'
  },
  windows: {
    color: '#00BDFF'
  },
  mac: {
    color: '#D3B2A1'
  }
};
export default Colors;
