import PageHeader from 'components/common/PageHeader';
import { faqs as faqsData } from 'data/faqs';
import React, { useState } from 'react';
import FaqAccordion from './FaqAccordion';

const Faqs = () => {
    const [faqs] = useState(faqsData);

    return (
        <>
            <PageHeader
                title="Frequently Asked Questions"
                description={`For more information kindly visit <a href="https://www.juratinc.com/faq">https://www.juratinc.com/faq</a>`}
                className="mb-3"
            ></PageHeader>
            <FaqAccordion faqs={faqs} />
        </>
    );
};

export default Faqs;
