import React, { useEffect, useState } from 'react';
import PageHeader from 'components/common/PageHeader';
import { Button, Card, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { useNavigate } from 'react-router-dom';
import api from 'util/api';
import { useSelector } from 'react-redux';
import config from 'util/config';
import { toast } from 'react-toastify';
import { refreshUserMasterDetails, setIsDeleteRequestAction } from 'stores/app';
import { useDispatch } from 'react-redux';
import { deleteSubscribedPlan } from 'stores/subscription';

const DeleteMyAccount = () => {
  const appData = useSelector(state => state.app);
  const dispatch = useDispatch();
  const { userMaster, isdeleterequest } = appData;
  const navigator = useNavigate();
  const [selectedMethod, setSelectedMethod] = useState('');
  const [loading, setLoading] = useState(false);
  const [isOpenDeleteconfirmModel, setIsOpenDeleteconfirmModel] =
    useState(false);
  const { subscriptionStatus } = useSelector(state => state.subscription);
  let currentSubscriptions = _.get(subscriptionStatus, 'Current', null);
  let activeSubscriptions = currentSubscriptions ? [currentSubscriptions] : [];

  const handleSelection = e => {
    setSelectedMethod(e.target.value);
  };
  const handleConfirm = () => {
    if (selectedMethod) {
      setIsOpenDeleteconfirmModel(!isOpenDeleteconfirmModel);
    }
  };
  const toastSuccess = message => {
    toast.success(message, {
      theme: 'colored'
    });
  };

  const redirectToMobile = url => {
    if (isdeleterequest) {
      dispatch(setIsDeleteRequestAction(false));
      window.location.href = `${config.appRedirectEndpont}/${url}`;
    } else {
      navigator('/account/user-profile');
    }
  };
  const handleConfirmDelete = () => {
    if (selectedMethod && selectedMethod !== 'SubscriptionCancellation') {
      setLoading(true);
      api.User.delete(
        userMaster.GUID,
        selectedMethod == 'subscriptionCycleDeletion'
          ? 'AfterDeletion'
          : 'ImmmediateDeletion'
      )
        .then(() => {
          setTimeout(() => {
            setLoading(false);
            setIsOpenDeleteconfirmModel(false);
            if (selectedMethod === 'subscriptionCycleDeletion') {
              toastSuccess(
                `Your subscription has been canceled and you have initiated the account deletion process`
              );
              redirectToMobile('deletion-subscription-cycle-ends-success');
            } else if (selectedMethod === 'immediateDeletion') {
              toastSuccess(`You have initiated the account deletion process`);
              redirectToMobile('immediate-deletion-success');
            }
          });
          setLoading(false);
        })
        .catch(() => setLoading(false));
    } else {
      proceddToCancelSubscription();
    }
  };
  const proceddToCancelSubscription = () => {
    dispatch(
      deleteSubscribedPlan(
        currentSubscriptions.GUID,
        currentSubscriptions.PaymentCardGUID,
        currentSubscriptions.SubscriptionTierGUID
      )
    )
      .then(res => {
        setLoading(false);
        setIsOpenDeleteconfirmModel(false);
        toastSuccess(`Your subscription has been canceled`);
        redirectToMobile('cancel-subscription-success');
      })
      .catch(error => {
        if (error?.Code == 'AlreadyCanceled') {
          toastSuccess(`Your subscription has been canceled`);
          redirectToMobile('cancel-subscription-success');
        }
      });
  };

  useEffect(() => {
    setLoading(true);
    userMaster?.GUID &&
      dispatch(refreshUserMasterDetails(userMaster?.GUID))
        .then(res => {
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
        });
  }, []);
  return (
    <>
      <Form>
        <PageHeader
          title="Delete My Account"
          description="Permanently Delete Your Account and All Associated Data"
          className="mb-3"
          titleTag="h2"
        ></PageHeader>
        <Card>
          <FalconCardHeader title="Important Account Deletion Notice" />

          {/* only subscription delete*/}
          <Card className="mb-1">
            <Card.Body>
              <Card.Subtitle className="text-info mb-2">
                <Form.Check
                  type="radio"
                  id="SubscriptionCancellation"
                  label="Cancel my subscription (Recommended)"
                  value="SubscriptionCancellation"
                  name="deletionMethod"
                  onChange={handleSelection}
                  checked={selectedMethod === 'SubscriptionCancellation'}
                />
                {/* Deletion After Subscription Cycle Ends */}
              </Card.Subtitle>
              <Card.Text>
                <ul>
                  <li>
                    You will retain full access to our web & mobile platforms
                    through the remainder of your billing cycle.
                  </li>
                  <li>
                    Once your billing cycle ends, your account will move from an
                    active state to an inactive state and you will lose access
                    to our mobile applications
                  </li>
                  <li>
                    You will retain access to your account via the web, but
                    certain features & data will be restricted.
                  </li>
                  <li>
                    Your journal entries will remain securely stored on our
                    servers.
                  </li>
                  <li>
                    You can regain full access to account, including your
                    existing entries at any point in time by reactivating your
                    subscription
                  </li>
                  <li>
                    Please review our terms and conditions for additional
                    details, including our no refund policy
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>

          {/* Deletion After Subscription Cycle Ends Section */}
          <Card className="mb-1">
            <Card.Body>
              <Card.Subtitle className="text-warning mb-2">
                <Form.Check
                  type="radio"
                  id="subscriptionCycleDeletion"
                  label="Delete my account after my subscription cycle ends"
                  value="subscriptionCycleDeletion"
                  name="deletionMethod"
                  onChange={handleSelection}
                  checked={selectedMethod === 'subscriptionCycleDeletion'}
                />
              </Card.Subtitle>
              <Card.Text>
                <ul>
                  <li>
                    You will retain full access to our web & mobile platforms
                    through the remainder of your billing cycle.
                  </li>
                  <li>
                    Once your billing cycle ends, your account will move from an
                    active state to an inactive state and you will lose access
                    to our mobile applications
                  </li>
                  <li>
                    You will retain access to your account via the web, but
                    certain features & data will be restricted.
                  </li>
                  <li>
                    Your account will be queued for deletion and you will have a
                    30 day courtesy period to access your account from the web
                    to export your journal entries
                  </li>
                  <li>
                    Your journal entries will remain securely stored on our
                    servers until the end of the courtesy period
                  </li>
                  <li>
                    At the end of the courtesy period, your account and all
                    associated data (including all journal entries) will be
                    permanently deleted
                  </li>
                  <li>
                    Once initiated, this action can be undone up until the 29th
                    day of the courtesy period
                  </li>
                  <li>
                    After the courtesy period, this action can not be undone
                  </li>
                  <li>
                    Please review our terms and conditions for additional
                    details, including our no refund policy
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          {/* Immediate Deletion Section */}
          <Card className="mb-1">
            <Card.Body>
              <Card.Subtitle className="text-danger mb-2">
                <Form.Check
                  type="radio"
                  id="immediateDeletion"
                  label="Delete my account immediately"
                  value="immediateDeletion"
                  name="deletionMethod"
                  onChange={handleSelection}
                  checked={selectedMethod === 'immediateDeletion'}
                />
              </Card.Subtitle>
              <Card.Text>
                <ul>
                  <li>
                    You will immediately lose access to our mobile application,
                    and your account will immediately be moved from an active
                    state to an inactive state.
                  </li>
                  <li>
                    You will retain access to your account via the web, but
                    certain features & data will be restricted.
                  </li>
                  <li>
                    Your account will be queued for deletion and you will have a
                    30 day courtesy period to access your account from the web
                    to export your journal entries
                  </li>
                  <li>
                    Your journal entries will remain securely stored on our
                    servers until the end of the courtesy period{' '}
                  </li>
                  <li>
                    At the end of the courtesy period, your account and all
                    associated data (including all journal entries) will be
                    permanently deleted
                  </li>
                  <li>
                    Once initiated, this action can be undone up until the 29th
                    day of the courtesy period
                  </li>
                  <li>
                    After the courtesy period, this action can not be undone
                  </li>
                  <li>
                    Please review our terms and conditions for additional
                    details, including our no refund policy
                  </li>
                </ul>
              </Card.Text>
              {/* <Button variant="danger">Cancel Deletion</Button> */}
            </Card.Body>
          </Card>

          <div className="d-flex justify-content-end m-4">
            <Button
              className="mx-2"
              variant="secondary"
              onClick={() => {
                setSelectedMethod('');
                redirectToMobile('cancel');
              }}
            >
              Cancel
            </Button>
            {selectedMethod && (
              <Button
                variant="danger"
                onClick={handleConfirm}
                disabled={!selectedMethod}
              >
                {selectedMethod === 'SubscriptionCancellation'
                  ? 'Cancel my subscription'
                  : selectedMethod === 'subscriptionCycleDeletion'
                  ? 'Delete my account after my subscription cycle ends'
                  : selectedMethod === 'immediateDeletion'
                  ? 'Delete my account immediately'
                  : ''}
              </Button>
            )}
          </div>
        </Card>
      </Form>

      <Modal
        show={isOpenDeleteconfirmModel}
        onHide={() => {
          setIsOpenDeleteconfirmModel(!isOpenDeleteconfirmModel);
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {selectedMethod == 'SubscriptionCancellation'
              ? `Confirm Subscription Cancellation`
              : `Confirm Account Deletion`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              {selectedMethod == 'SubscriptionCancellation'
                ? `By canceling your subscription, you will lose access to premium features and content. However, your account and data will remain intact. You can resubscribe at any time to regain full access. Would you like to proceed with canceling your subscription?`
                : `By clicking Confirm, you agree to proceed with the selected
              deletion method (${
                selectedMethod === 'subscriptionCycleDeletion'
                  ? 'Delete my account after my subscription cycle ends'
                  : 'Delete my account immediately'
              }). This action is irreversible,
              and all your data will be lost permanently once the deletion
              process is completed.`}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setIsOpenDeleteconfirmModel(!isOpenDeleteconfirmModel);
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleConfirmDelete();
            }}
          >
            {selectedMethod == 'SubscriptionCancellation' ? (
              'Confirm Cancel Subscription'
            ) : loading ? (
              <>
                <Spinner size="sm" /> Deleting...
              </>
            ) : (
              'Confirm Delete Account'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteMyAccount;
