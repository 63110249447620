import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

const idGenerator = {
  //Looks weird, like it won't work.  I use lodash ids throughout the ui
  //These IDs are only valid within the javascript session, and then the count resets.
  //Since this will die with the session, and the only real important thing here is that
  //there aren't duplicate ids, we can just set a variable that also won't get reset until the
  //session is.
  //The reason this is necessary is drafts.  If you save a signing session and restore it,
  //you could be restoring IDs used in a previous lodash session, and end up with dupes.
  sess_id: null,
  _sessionId() {
    if (!this.sess_id) {
      this.sess_id = uuidv4();
    }
    return this.sess_id;
  },

  getIdentificationId() {
    return _.uniqueId(`${this._sessionId() + '_ui_identification_'}`);
  },

  getDocumentTypeId() {
    return _.uniqueId(`${this._sessionId() + '_ui_doc_type_'}`);
  },

  getSigningPackageId() {
    return _.uniqueId(`${this._sessionId() + '_ui_signing_package_'}`);
  },

  getSignerId() {
    return _.uniqueId(`${this._sessionId() + '_ui_signer_'}`);
  },

  getSignatureId() {
    return _.uniqueId(`${this._sessionId() + '_ui_signature_'}`);
  },

  getDocumentId() {
    return _.uniqueId(`${this._sessionId() + '_ui_document_'}`);
  },

  getFingerprintId() {
    return _.uniqueId(`${this._sessionId() + '_ui_fingerprint_'}`);
  },

  getWitnessId() {
    return _.uniqueId(`${this._sessionId() + '_ui_witness_'}`);
  },

  getMarkWitnessId() {
    return _.uniqueId(`${this._sessionId() + '_ui_mark_witness_'}`);
  },

  getAuditLogItemId() {
    return _.uniqueId(`${this._sessionId() + '_ui_audit_log_id_'}`);
  },

  getNotificationItemId() {
    return _.uniqueId(`${this._sessionId() + '_ui_notification_id_'}`);
  }
};

export default idGenerator;
