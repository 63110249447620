import { faNetworkWired } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

const OfflineNetwork = () => {
  const retryDelay = 60;
  const [retryRemain, setRetryRemain] = useState(retryDelay);
  useEffect(() => {
    if (retryRemain === 0) {
      window.location.reload();
      return;
    }
    setTimeout(() => {
      setRetryRemain(retryRemain - 1);
    }, 1000);
  }, [retryRemain]);

  return (
    <Flex
      className="container vh-100"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
    >
      <Flex
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        className="flex-center text-center"
        direction="column"
      >
        <div>
          <FontAwesomeIcon icon={faNetworkWired} size="3x" />
        </div>
        <div className="my-2">
          You are Offline
          <br />
          Check your internet connection and refresh the page
        </div>
        <div className="my-2">
          <Button
            variant="outline-primary"
            onClick={() => {
              window.location.reload();
            }}
          >
            Refresh
          </Button>
        </div>
        <div className="text-muted fs--2">
          System will automatically try to reconnect in {retryRemain} second
          {retryRemain > 1 ? 's' : ''}
        </div>
      </Flex>
    </Flex>
  );
};

export default OfflineNetwork;
