import React from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TableRow = ({ data, labelPrefix }) => {
  const { icon, label, value, color, progress, progressValue, progressPercent } = data;
  return (
    <tr>
      <td className="py-2">
        <Flex alignItems="center">
          <FontAwesomeIcon icon={icon} style={{ color: color }} />
          <h6 className="text-600 mb-0 ms-2">{label}</h6>
        </Flex>
      </td>
      <td className="py-2">
        <Flex alignItems="center">
          <h6 className="text-600 fw-normal mb-0">{labelPrefix || ''}{value}</h6>
        </Flex>
      </td>
      <td className="py-2">
        <Flex alignItems="center" justifyContent="end">
          <FontAwesomeIcon
            icon={progressValue == 0 ? 'caret-right' : (progressValue > 0 ? 'caret-up' : 'caret-down')}
            className={progressValue == 0 ? 'text-primary' : (progressValue > 0 ? 'text-success' : 'text-danger')}
          />
          <h6 className="fs--2 text-700 mb-0 ms-2">{progressPercent}</h6>
        </Flex>
      </td>
    </tr>
  );
};

TableRow.propTypes = {
  data: PropTypes.shape({
    icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    progress: PropTypes.bool.isRequired,
    color: PropTypes.string.isRequired,
    progressValue: PropTypes.string.isRequired
  }).isRequired
};

export default TableRow;
