import ActionButton from 'components/common/ActionButton';
import FalconComponentCard from 'components/common/FalconComponentCard';
import PageHeader from 'components/common/PageHeader';
import React, { useEffect, useState } from 'react';
import {
  Col,
  OverlayTrigger,
  Popover,
  Row,
  Spinner,
  Table
} from 'react-bootstrap';

import corner3 from 'assets/img/illustrations/corner-3.png';
import api from 'util/api';
import _ from 'lodash';
import logger from 'util/logger';
import { showAlert, showConfirm } from 'stores/modal';
import moment from 'moment';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAndroid, faApple } from '@fortawesome/free-brands-svg-icons';
import SimpleBarReact from 'simplebar-react';
import Colors from 'util/Colors';
import { useDispatch } from 'react-redux';
import { DeviceIcon } from 'data/common/common';

const DevicesList = () => {
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [devicesLoadError, setDevicesLoadError] = useState(false);
  const dispatch = useDispatch();

  const loadDevices = () => {
    setLoading(true);
    api.Devices.list()
      .then(res => {
        setLoading(false);
        setDevices(
          _.sortBy(res, d => {
            return -moment(d.LastSeen).unix();
          })
        );
        setDevicesLoadError(false);
      })
      .catch(err => {
        setLoading(false);
        logger.log('error loading devices', err);
        setDevicesLoadError(true);
      });
  };

  const deleteDevice = device => {
    dispatch(
      showConfirm(
        'Are you sure?',
        'Are you sure you want to remove this device?',
        res => {
          if (res) {
            api.Devices.delete(device.GUID)
              .then(res => {
                loadDevices();
              })
              .catch(err => {
                showAlert(
                  'Error Deleting Device',
                  'There was a problem removing this device.  Please try again.'
                );
                loadDevices();
              });
          }
        }
      )
    );
  };

  const blockDevice = device => {
    dispatch(
      showConfirm(
        'Are you sure?',
        'Are you sure you want to block this device?  Blocking a device will prevent anyone from using it to sign in via the mobile app.',
        res => {
          if (res) {
            api.Devices.block(device.GUID)
              .then(res => {
                logger.log('block device res', res);
                loadDevices();
              })
              .catch(err => {
                showAlert(
                  'Error Blocking Device',
                  'There was a problem blocking this device.  Please try again.'
                );
                loadDevices();
              });
          }
        }
      )
    );
  };

  const unblockDevice = device => {
    api.Devices.unblock(device.GUID)
      .then(res => {
        logger.log('unblock device res', res);
        loadDevices();
      })
      .catch(err => {
        dispatch(
          showAlert(
            'Error Unblocking Device',
            'There was a problem unblocking this device.  Please try again.'
          )
        );
        loadDevices();
      });
  };

  const DevicePopover = e => {
    return (
      <Popover id="device-popover">
        <Popover.Header as="h3" className="d-inline-block">
          <FontAwesomeIcon
            size="sm"
            color={e.Blocked ? Colors.warning.color : Colors.success.color}
            icon="circle"
          />{' '}
          <spane className="text-truncate">
            {e.Blocked ? 'Blocked' : 'Active'} Device
          </spane>
        </Popover.Header>
        <Popover.Body className="p-0">
          <Table bordered responsive className="fs--1">
            <tbody>
              <tr>
                <td scope="col">Device</td>
                <td scope="col">
                  {e.Manufacturer} {e.Model}
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="fs--2">
                  {e.Identifier}
                </td>
              </tr>
              <tr>
                <td>Version</td>
                <td>{e.AppVersion}</td>
              </tr>
              <tr>
                <td>IP</td>
                <td>{e.LastIpAddress}</td>
              </tr>
            </tbody>
          </Table>
        </Popover.Body>
      </Popover>
    );
  };

  useEffect(() => {
    loadDevices();
  }, []);

  return (
    <>
      <PageHeader
        preTitle="Active Devices"
        title="Devices"
        description="You have successfully logged in to The Notary eJournal from these devices. You can block or remove devices at any time, giving you more control over the security of your account"
        className="mb-3"
        titleTag="h2"
        image={corner3}
      />

      <Row className="mb-3 g-3">
        <Col lg={12}>
          <FalconComponentCard noGuttersBottom>
            <FalconComponentCard.Header title="Devices" />
            <FalconComponentCard.Body noLight className="py-0">
              {loading && (
                <Flex justifyContent="center" className="py-3">
                  <Spinner size="sm" />
                </Flex>
              )}
              {devices && !loading && (
                <SimpleBarReact>
                  <Table bordered>
                    <colgroup>
                      <col />
                      <col />
                      <col className="d-sm-none d-md-block" />
                      <col className="d-sm-none d-md-block" />
                      <col />
                    </colgroup>
                    <thead>
                      <tr>
                        <th scope="col">Manufacturer</th>
                        <th scope="col">Model</th>
                        <th scope="col">Created</th>
                        <th scope="col">Last used</th>
                        <th className="text-end" scope="col">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.map(devices, e => {
                        let createdDate = moment
                          .utc(e.Created)
                          .local()
                          .format('l, LT');
                        let lastUsedDate = moment
                          .utc(e.LastSeen)
                          .local()
                          .format('l, LT');

                        return (
                          <tr key={e.GUID}>
                            <td>
                              {DeviceIcon(e)}{' '}
                              <OverlayTrigger
                                trigger="click"
                                placement="top"
                                rootClose
                                overlay={DevicePopover(e)}
                              >
                                <span>{e.Manufacturer}</span>
                              </OverlayTrigger>{' '}
                            </td>
                            <td>{e.Model}</td>
                            <td>{createdDate}</td>
                            <td>{lastUsedDate}</td>
                            <td className="text-end">
                              {e.Blocked && (
                                <ActionButton
                                  icon="check-circle"
                                  title="Unblock"
                                  label="Unblock"
                                  variant="action"
                                  className="p-0 me-3 text-primary"
                                  onClick={() => unblockDevice(e)}
                                />
                              )}
                              {!e.Blocked && (
                                <ActionButton
                                  icon="ban"
                                  title="Block"
                                  label="Block"
                                  variant="action"
                                  className="p-0 me-3 text-warning"
                                  onClick={() => blockDevice(e)}
                                />
                              )}
                              <ActionButton
                                icon="trash-alt"
                                title="Remove"
                                label="Remove"
                                variant="action"
                                className="p-0 text-danger"
                                onClick={() => deleteDevice(e)}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </SimpleBarReact>
              )}
            </FalconComponentCard.Body>
          </FalconComponentCard>
        </Col>
      </Row>
    </>
  );
};

export default DevicesList;
