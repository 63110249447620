import _ from 'lodash'

class DraftEntry {

  draft_id = null;
  entry_data = null;
  draft_metadata = null;

  constructor(draft_id) {
    this.draft_id = draft_id;
  }

  getDraftId() {
    return this.draft_id;
  }

  setEntryData(data) {
    this.entry_data = data;
  }

  getEntryData() {
    return this.entry_data;
  }

  setMetadata(data) {
    this.draft_metadata = data;
  }

  getMetadata() {
    return this.draft_metadata;
  }

  getDraftStep() {
    return _.get(this, 'draft_metadata.entry_step');
  }

  getCompletedAtDate() {
    return _.get(this, 'draft_metadata.completed_at', null)
  }

  getLastUpdatedDate() {
    return _.get(this, 'draft_metadata.last_updated', null);
  }

  getCreatedDate() {
    return _.get(this, 'draft_metadata.created', null);
  }
}

export default DraftEntry;
