import _ from 'lodash';
import moment from 'moment';
import c from 'util/const';
import juratLocalStorage from 'util/local-storage';
import logger from 'util/logger';

const saveUserLoginProfile = user => {
  let loginProfiles =
    juratLocalStorage.get(c.actions.auth.logedinUserProfile) || [];

  let profile = _.find(loginProfiles, s => {
    return s.GUID === user.GUID;
  });

  if (!profile || profile.GUID !== user.GUID) {
    loginProfiles.push({
      GUID: user.GUID,
      FirstName: user.FirstName,
      LastName: user.LastName,
      Email: user.Email
    });
    juratLocalStorage.set(c.actions.auth.logedinUserProfile, loginProfiles);
  }
};

const removeLogedInProfile = profile => {
  let loginProfiles =
    juratLocalStorage.get(c.actions.auth.logedinUserProfile) || [];

  logger.log('loginProfiles', loginProfiles);
  let remainigProfiles = _.filter(loginProfiles, s => {
    return s.GUID !== profile.GUID;
  });
  juratLocalStorage.set(c.actions.auth.logedinUserProfile, remainigProfiles);
};

const dateViewFull = date => {
  return date
    ? moment(date).utc().local().format('MM/DD/YYYY ddd hh:mm:ss A')
    : '';
};

const getTimezoneObj = timezone => {
  return _.find(
    c.timezones,
    zone => zone.offset == timezone || zone.abbreviation == timezone
  );
};
const getTimezoneVal = timezone => {
  return timezone == '+00:00' ? null : timezone;
};

let userHelper = {
  saveUserLoginProfile,
  removeLogedInProfile,
  dateViewFull,
  getTimezoneObj,
  getTimezoneVal
};

export default userHelper;
