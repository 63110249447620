import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useSelector } from 'react-redux';
import Flex from './Flex';
import c from 'util/const';
import { faCreditCard, faDollarSign, faLeaf, faLock, faPenNib, faShieldAlt, faSignInAlt, faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { ProgressBar } from 'react-bootstrap';
import { useState } from 'react';
import { useEffect } from 'react';
const AppStageView = () => {
  const { stage } = useSelector((state) => state.app);

  const getAvatar = () => {
    let s = null;
    if (stage === c.stages.NULL)
      s = null;
    if (stage === c.stages.TOKEN)
      s = { label: "Verifying token", icon: faShieldAlt, progress: 20 };
    if (stage === c.stages.SIGNING)
      s = { label: "Authenticating", icon: faSignInAlt, progress: 10 };
    if (stage === c.stages.DETAILS)
      s = { label: "Getting account details", icon: faUser, progress: 40 };
    if (stage === c.stages.PROFILE)
      s = { label: "Loading profile", icon: faPenNib, progress: 60 };
    if (stage === c.stages.SUBSCRIPTION)
      s = { label: "Checking subscription", icon: faLeaf, progress: 80 };
    if (stage === c.stages.PAYMENT)
      s = { label: "Verifying payment", icon: faCreditCard, progress: 95 };
    if (stage === c.stages.LOGOUT)
      s = { label: "Signing out", icon: faSignOutAlt, progress: 0 };

    return s ? (<Flex justifyContent='center' alignItems='center' direction="column"><div><FontAwesomeIcon icon={s.icon} size='sm' /> {s.label}</div><div><ProgressBar style={{ width: '300px', height: '2px', marginTop: '8px' }} now={s.progress} /></div></Flex>) : <></>;
  };

  return <>{getAvatar()}</>;
};

export default AppStageView;
