import {
  faExclamationTriangle,
  faUserCheck
} from '@fortawesome/free-solid-svg-icons';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import IconAlert from 'components/common/IconAlert';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  addUserJournalNotes,
  refreshUserMasterDetails,
  refreshUserJournalNotes,
  updateUserMaster,
  updateUserJournalNotes
} from 'stores/app';
import logger from 'util/logger';

const ProfileJournalNotes = props => {
  const { userMaster } = useSelector(state => state.app);
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [oathStatement, setJournalNotes] = useState(null);
  const [showJournalNotes, setShowJournalNotes] = useState(false);

  const [formData, setFormData] = useState({
    GUID: null,
    Statement: ''
  });

  useEffect(() => {
    setLoading(true);
    dispatch(refreshUserMasterDetails(userMaster.GUID))
      .then(res => {
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (userMaster?.JournalNotes) {
      setJournalNotes(userMaster?.JournalNotes);
      setFormData({
        ...formData,
        GUID: userMaster?.GUID,
        Statement: userMaster?.JournalNotes
      });
    }
  }, [userMaster?.JournalNotes]);

  const save = () => {
    setSaving(true);
    dispatch(
      updateUserMaster(userMaster.GUID, { JournalNotes: formData.Statement })
    )
      .then(res => {
        setSaving(false);
        toast.success(`Journal notes saved successfully`, {
          theme: 'colored',
          position: 'bottom-center',
          icon: faUserCheck,
          toastId: 'login-success'
        });
        dispatch(refreshUserMasterDetails());
        setShowJournalNotes(false);
      })
      .catch(err => {
        logger.log('err', err);
        setSaving(false);
        toast.error(`Unable to save Journal notes. Try again`, {
          theme: 'colored',
          position: 'bottom-center',
          icon: faExclamationTriangle,
          toastId: 'login-success'
        });
      });
  };

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
  };

  return (
    <Card {...props}>
      <FalconCardHeader title="Journal Notes" />

      <Card.Body className="bg-light">
        <IconAlert variant={'info'}>
          <p className="mb-0">
            Use this section to explain any uncommon abbreviations and symbols
            used in this journal, or add any general notes that may help you in
            your notarial business
          </p>
        </IconAlert>
        {showJournalNotes && (
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3 g-3">
              <Col sm={12}>
                <Form.Group className="mb-3" controlId="intro">
                  {/* <Form.Label>Intro</Form.Label> */}
                  <Form.Control
                    as="textarea"
                    rows={16}
                    placeholder="Write journal notes..."
                    value={formData.Statement}
                    name="Statement"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col sm={12} className="text-center">
                <Button
                  className="mx-1"
                  variant="primary"
                  type="submit"
                  disabled={saving || formData.Statement == oathStatement}
                  onClick={() => save()}
                >
                  {saving ? <Spinner size="sm" variant="light" /> : 'Update'}
                </Button>
                <Button
                  className="mx-1"
                  variant="danger"
                  type="button"
                  disabled={saving}
                  onClick={() => setShowJournalNotes(false)}
                >
                  Close
                </Button>
              </Col>
            </Row>
            {saving && (
              <Row>
                <Col
                  sm={12}
                  as={Flex}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Spinner size="sm" variant="primary" /> &nbsp; Saving...
                </Col>
              </Row>
            )}
          </Form>
        )}
        {!showJournalNotes && (
          <>
            {loading ? (
              <Flex
                justifyContent={'justify'}
                direction="column"
                alignContent="center"
                alignItems="center"
                className="text-center my-3 py-3"
              >
                <Spinner />
                <p className="mt-3 text-muted">Loading profile Journal Notes</p>
              </Flex>
            ) : (
              <Col sm={12} md={12} className="pe-lg-2 mb-3">
                <Row>
                  <Col sm={12} className="my-3">
                    <Card>
                      <Card.Header>
                        <Flex style={{ justifyContent: 'space-between' }}>
                          <div>Journal Notes</div>
                          <div>
                            <Button
                              variant="primary"
                              onClick={() => setShowJournalNotes(true)}
                            >
                              Edit
                            </Button>
                          </div>
                        </Flex>
                      </Card.Header>
                      <Card.Body className="pt-0">
                        <p style={{ whiteSpace: 'pre-line' }}>
                          {oathStatement ||
                            "Click 'Edit' button to add or change your notes"}
                        </p>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            )}
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default ProfileJournalNotes;
