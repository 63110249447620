import {
  faPlusCircle,
  faTrash,
  faUser,
  faUserTie
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/common/Avatar';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { AuthWizardContext } from 'context/Context';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Card,
  Col,
  Collapse,
  Offcanvas,
  Row,
  Table
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { showAlert } from 'stores/modal';
import {
  actionAddIdentification,
  actionLogSigningAction,
  actionMapSignerToAllDocs,
  actionRemoveIdentification,
  actionRemoveSigner
} from 'stores/signing';
import enums from 'util/enums';
import idGenerator from 'util/idGenerator';
import logger from 'util/logger';
import GenericAddressForm from './comp/GenericAddressForm';
import IdentificationView from './comp/IdentificationView';
import IdentificationForm from './s2/IdentificationForm';
import NotarySignerForm from './s2/NotarySignerForm';
import Flex from 'components/common/Flex';
import classNames from 'classnames';
import draftEntryUiHelper from 'helpers/draft-entry-ui-helper';
import { filter } from 'lodash';
import filters from 'util/filters';
import _ from 'lodash';
import segmentHelper from 'helpers/segment-helper';

const Step2 = ({
  register,
  errors,
  setValue,
  saveSigningToDraft,
  setPreviousAddress
}) => {
  const signerInit = {
    isSaving: false,
    isEditMode: false,
    validationErr: [],
    witnessChoice: enums.UI_FLOW_TYPE.SIGNER,
    sorted_witness_ids: null,
    signer_id: null,
    signer_prefix: '',
    signer_first_name: '',
    signer_middle_name: '',
    signer_last_name: '',
    signer_suffix: '',
    signerIdentifications: [],
    witnessIdentifications: [],
    witnessNameLocationResults: [],
    witnessSignatureResults: []
  };

  const { user } = useContext(AuthWizardContext);
  const [collapsed, setCollapsed] = useState(false);
  const [showIdentityForm, setShowIdentityForm] = useState(false);
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [identityItem, setIdentityItem] = useState();
  const [addressValue, setAddressValue] = useState();
  const [witnessId, setWitnessId] = useState(null);
  const [signerData, setSignerData] = useState(signerInit);
  const [isSaveIdentityToRedux, setSaveIdentityToRedux] = useState(false);
  const [identityForUser, setIdentityForUser] = useState(null);

  const { signers, witnesses, identifications, signatures, location } =
    useSelector(state => state.signing);
  const dispatch = useDispatch();

  useEffect(() => {
    setPreviousAddress(location);
  }, [location]);

  const removeSigner = signer_id => {
    let foundSigner = _.find(signers, s => s.signer_id === signer_id);
    dispatch(
      actionLogSigningAction(
        `Signer ${filters.formatNameObj(foundSigner)} removed.`
      )
    );
    dispatch(actionRemoveSigner(signer_id));
    segmentHelper.track(segmentHelper.events.SIGNING_REMOVE_SIGNER_CLICK);
  };
  const removeSignerIdentificationFromRedux = identification_id => {
    dispatch(actionRemoveIdentification({ identification_id }));
    segmentHelper.track(segmentHelper.events.SIGNING_REMOVED_IDENTIFICATION);
  };
  const openSignerForm = signer_id => {
    if (signer_id === null) {
      setSignerData(signerData => ({
        ...signerData,
        signer_id: idGenerator.getSignerId(),
        sorted_witness_ids: [],
        isEditMode: false,
        signer_first_name: '',
        signer_middle_name: '',
        signer_last_name: '',
        signer_prefix: '',
        signer_suffix: '',
        witnessChoice: enums.UI_FLOW_TYPE.SIGNER,
        validationErr: []
      }));
    } else {
      let signer = _.find(signers, s => {
        return s.signer_id === signer_id;
      });
      if (signer) {
        setSignerData(_.extend(signerInit, signer));
      }
    }
    setCollapsed(true);
  };

  const resetSignerState = () => {
    setSignerData(signerInit);
  };

  //This useEffect will store Identification in localState we will push to Redux on Signer Save
  useEffect(() => {
    if (identityItem) {
      //logger.log('Received Identity', identityItem);
      if (identityItem.witness_id) {
        let witnessIdentificationUpdate = _.concat(
          [],
          signerData.witnessIdentifications || []
        );
        _.remove(
          witnessIdentificationUpdate,
          wid => wid.witness_id === identityItem.witness_id
        );
        witnessIdentificationUpdate.push({
          signer_id: identityForUser,
          witness_id: identityItem.witness_id,
          identification: identityItem
        });
        setSignerData(signerData => ({
          ...signerData,
          witnessIdentifications: witnessIdentificationUpdate
        }));
      } else {
        if (isSaveIdentityToRedux) {
          dispatch(
            actionAddIdentification({
              signer_id: identityForUser,
              identification_id: identityItem.identification_id,
              first_name: identityItem.id_first_name,
              middle_name: identityItem.id_middle_name,
              last_name: identityItem.id_last_name,
              id_first_name: identityItem.id_first_name,
              id_middle_name: identityItem.id_middle_name,
              id_last_name: identityItem.id_last_name,
              prefix: identityItem.id_prefix,
              suffix: identityItem.id_suffix,
              id_type: identityItem.id_type,
              id_type_other: identityItem.id_type_other,
              id_number: identityItem.id_number,
              id_issued_date: identityItem.id_issued_date,
              id_expiration_date: identityItem.id_expiration_date,
              date_of_birth: identityItem.date_of_birth,
              id_issuing_state: identityItem.id_issuing_state,
              id_address_1: identityItem.id_address_1,
              id_address_2: identityItem.id_address_2,
              id_city: identityItem.id_city,
              id_state: identityItem.id_state,
              id_postal_code: identityItem.id_postal_code,
              notes: identityItem.notes,
              scan_vendor: identityItem.scan_vendor,
              scan_data: identityItem.scan_data
            })
          );
          dispatch(actionMapSignerToAllDocs(identityForUser));
        } else {
          let signerIdentificationsUpdate =
            signerData.signerIdentifications || [];
          signerIdentificationsUpdate.push({
            signer_id: identityForUser,
            identification: identityItem
          });
          setSignerData(signerData => ({
            ...signerData,
            signerIdentifications: signerIdentificationsUpdate
          }));
        }
      }
      setShowIdentityForm(false);
    }
  }, [identityItem]);

  //This useEffect will store Address in localState we will push to Redux on Signer Save
  useEffect(() => {
    //logger.log('Adding address on state', addressValue);
    if (addressValue) {
      let witnessNameLocationUpdate =
        signerData.witnessNameLocationResults || [];
      if (witnessNameLocationUpdate.length) {
        _.remove(
          witnessNameLocationUpdate,
          wid => wid.witness_id === witnessId
        );
      }
      witnessNameLocationUpdate.push({
        witness_id: witnessId,
        location: addressValue
      });

      setSignerData(signerData => ({
        ...signerData,
        witnessNameLocationResults: witnessNameLocationUpdate
      }));
      setShowAddressForm(false);
    }
    //logger.log('Updated signerdata after location', signerData);
  }, [addressValue]);

  const openSignerIdentityForm = (signer_id, saveToRedux) => {
    setSaveIdentityToRedux(saveToRedux);
    setIdentityForUser(signer_id);
    setWitnessId(null);
    setShowIdentityForm(true);
    segmentHelper.track(
      segmentHelper.events.SIGNING_ADD_ANOTHER_IDENTIFICATION
    );
  };

  const openWitnessIdentityForm = (signer_id, witness_id) => {
    setIdentityForUser(signer_id);
    setWitnessId(witness_id);
    setShowIdentityForm(true);
  };

  const openWitnessAddressForm = witness_id => {
    setWitnessId(witness_id);
    setShowAddressForm(true);
  };

  const openIdentityViewModel = (title, identification) => {
    dispatch(
      showAlert(
        title,
        <div>
          <IdentificationView title={title} identification={identification} />
        </div>,
        res => {
          if (res) {
            //setStartSavingFavourite(startSavingFavourite + 1);
          }
        }
      )
    );
  };

  const AddNewBlock = () => {
    return (
      <div className="text-center">
        <h4>
          <FontAwesomeIcon icon={faUser} />
        </h4>
        <p className="my-3">You haven't added any Signers yet.</p>
        <Button
          onClick={() => {
            openSignerForm(null);
          }}
          className="ml-2"
          color="primary"
        >
          <FontAwesomeIcon icon={faPlusCircle} /> Add New Signer
        </Button>
      </div>
    );
  };

  const WitnessRow = ({ signer, witness, index, isLast }) => {
    //logger.log('witness', witness);
    let witnessIdentification = _.find(
      identifications,
      w => w.witness_id === witness.witness_id
    );
    //logger.log('WitnessRow witnessIdentification', witnessIdentification);
    let witnessSignature = witness.signature;
    let witnessLocation = witness.location;
    let witnessDetail = witness.witness_name;
    return witness ? (
      <>
        <hr className="mb-1" />
        <Row
          className={classNames('align-items-center py-2', {
            'border-bottom border-200 ': !isLast
          })}
        >
          <Col className="py-1">
            <Flex className="align-items-center">
              <div className="avatar avatar-sm me-3">
                <div className={`avatar-name rounded-circle bg-soft-priamry`}>
                  <span className={`fs--2`}>
                    {index} (
                    {witnessDetail.first_name[0] + witnessDetail.last_name[0]})
                  </span>
                </div>
              </div>
              <Flex className="position-relative">
                <Flex tag="h6" align="left" className="mb-0">
                  <Avatar
                    size="m"
                    name={index + 1 + ''}
                    mediaClass={`text-primary bg-soft-primary fs--2 me-2`}
                  />
                  <div className="ms-2 fs-1">
                    <span className="text-primary">Witness </span>{' '}
                    <span className="text-800 stretched-link">
                      {witnessDetail.first_name + ' ' + witnessDetail.last_name}
                    </span>
                    <br />
                    <span className="fs--2 text-primary">
                      {' '}
                      {filters.getSignerTypeName(signer.identificationFlow)}
                    </span>
                  </div>
                </Flex>
              </Flex>
            </Flex>
          </Col>
          {/* <Col>
            <Row className="justify-content-end align-items-center">
              <Col xs="auto pe-0">
                <span
                  onClick={() =>
                    openIdentityViewModel(
                      `${witnessDetail.first_name}  ${witnessDetail.last_name}'s Identity`,
                      witnessIdentification
                    )
                  }
                >
                  {witnessIdentification?.length ? (
                    <Button variant="outline-primary" size="sm">
                      {witnessIdentification?.length}
                    </Button>
                  ) : (
                    'N/A'
                  )}
                </span>
              </Col>
            </Row>
          </Col> */}
        </Row>
        <Row>
          {witnessIdentification &&
            draftEntryUiHelper.getIdentificationViewCard(
              witnessIdentification,
              false
            )}

          {witnessSignature &&
            draftEntryUiHelper.getSignatureViewCards(
              'Witness Signature',
              witnessSignature.data
            )}

          {witnessLocation &&
            draftEntryUiHelper.getWitnessAddressViewCards(witnessLocation)}
        </Row>
      </>
    ) : (
      <></>
    );
  };

  const SignerRow = ({ data, isLast }) => {
    //logger.log('SignerRow data', data);
    let identification = _.filter(
      identifications,
      w => w.signer_id === data.signer_id
    );
    let isSigner = data.identificationFlow === enums.UI_FLOW_TYPE.SIGNER;
    let signature = _.filter(
      signatures,
      w => w.signer_id === data.signer_id && !w.witness_id
    );
    let witness = _.filter(witnesses, w => w.signer_id === data.signer_id);

    //logger.log('SignerRow Identification', identification);
    //logger.log('SignerRow signature', signature);
    //logger.log('SignerRow witness', witness);
    return data && data.first_name ? (
      <Card className="mt-3">
        <div className="p-3">
          <Row
            className={classNames('align-items-center py-2', {
              'border-bottom border-200 ': !isLast
            })}
          >
            <Col className="py-1">
              <Flex className="align-items-center">
                <div className="avatar avatar-xl me-3">
                  <div className={`avatar-name rounded-circle bg-soft-priamry`}>
                    <span className={`fs-0`}>
                      {data.first_name[0] + data.last_name[0]}
                    </span>
                  </div>
                </div>
                <Flex className="position-relative">
                  <Flex tag="h6" align="left" className="mb-0">
                    <div>
                      <span className="text-800 stretched-link">
                        {data.first_name + ' ' + data.last_name}
                      </span>
                      <br />
                      <span className="fs--2 text-primary">Signer</span>
                    </div>
                  </Flex>
                </Flex>
              </Flex>
            </Col>
            <Col>
              <Row className="justify-content-end align-items-center">
                <Col xs="auto pe-0">
                  <span
                    onClick={() =>
                      openIdentityViewModel(
                        `${data.first_name}  ${data.last_name}'s Identification`,
                        identification
                      )
                    }
                  >
                    {identification?.length ? (
                      <Button variant="outline-primary" size="sm">
                        {identification?.length} Identification
                      </Button>
                    ) : (
                      'N/A'
                    )}
                  </span>
                </Col>
                <Col xs="auto">
                  {isSigner && (
                    <Button
                      variant="outline-primary"
                      className="mx-1"
                      onClick={() =>
                        openSignerIdentityForm(data.signer_id, true)
                      }
                    >
                      <FontAwesomeIcon icon={faPlusCircle} size="sm" />{' '}
                      Identification
                    </Button>
                  )}
                  <Button
                    variant="outline-danger"
                    className="mx-1"
                    onClick={() => removeSigner(data.signer_id)}
                  >
                    <FontAwesomeIcon icon={faTrash} size="sm" />{' '}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            {isSigner ? (
              <>
                {identification &&
                  _.map(identification, i => {
                    return draftEntryUiHelper.getIdentificationViewCard(
                      i,
                      identification.length > 1
                        ? removeSignerIdentificationFromRedux
                        : false
                    );
                  })}

                {signature &&
                  _.map(signature, s => {
                    return draftEntryUiHelper.getSignatureViewCards(
                      'Signer Signature',
                      s.imgData.data
                    );
                  })}
              </>
            ) : (
              <>
                {/* {identification &&
                  _.map(identification, i => {
                    return draftEntryUiHelper.getIdentificationViewCard(
                      i,
                      false
                    );
                  })} */}

                {signature &&
                  _.map(signature, s => {
                    return draftEntryUiHelper.getSignatureViewCards(
                      'Signer Signature',
                      s.imgData.data
                    );
                  })}

                {witness &&
                  _.map(witness, (wit, index) => {
                    //logger.log('Prining', wit);
                    return (
                      <WitnessRow
                        key={_.uniqueId('witness-row')}
                        signer={data}
                        witness={wit}
                        index={index}
                        isLast={index === witness.length - 1}
                      />
                    );
                  })}
              </>
            )}
          </Row>
        </div>
      </Card>
    ) : (
      <></>
    );
  };

  return (
    <>
      <div>
        <h5 className="text-center">Add Signers & Witnesses</h5>
        <p className="text-center fs-0">
          Select which method is being used to identify the signer. The most
          common method of identifying the signer is by using the signer's
          approved form of paper identification (i.e. drivers license or
          passport)
        </p>
        <hr className="mb-0" />
        <div>
          <Collapse in={collapsed}>
            <div>
              <NotarySignerForm
                signers={signers}
                signerData={signerData}
                setSignerData={setSignerData}
                witnesses={witnesses}
                identifications={identifications}
                signatures={signatures}
                setCollapsed={setCollapsed}
                openSignerIdentityForm={openSignerIdentityForm}
                openWitnessAddressForm={openWitnessAddressForm}
                openWitnessIdentityForm={openWitnessIdentityForm}
                resetSignerState={resetSignerState}
              />
              {/* <div className="border-dashed border-bottom my-3" /> */}
            </div>
          </Collapse>
        </div>

        {signers && signers.length > 0 ? (
          <Card className="mt-2">
            <FalconCardHeader
              title={
                'Signer' + (signers.length > 1 ? 's' : '') + ' in this entry'
              }
              icon={faUserTie}
              endEl={
                !collapsed ? (
                  <Button size="sm" onClick={() => openSignerForm(null)}>
                    <FontAwesomeIcon icon={faPlusCircle} /> Add another signer
                  </Button>
                ) : (
                  <></>
                )
              }
            />
            <hr className="my-0" />
            <Card.Body>
              {signers.map((s, index) => (
                <SignerRow
                  data={s}
                  key={s.signer_id}
                  isLast={index === signers.length - 1}
                />
              ))}
            </Card.Body>
          </Card>
        ) : (
          <></>
        )}

        {!collapsed && !signers?.length ? <AddNewBlock /> : <></>}
      </div>

      <Offcanvas
        id="offcanvas-identity-form"
        show={showIdentityForm}
        backdrop="static"
        onHide={() => setShowIdentityForm(false)}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Identification Information</Offcanvas.Title>
        </Offcanvas.Header>
        <hr className="my-0" />
        <Offcanvas.Body>
          <IdentificationForm
            signerData={witnessId === null ? signerData : {}}
            witnessId={witnessId}
            setIdentityItem={setIdentityItem}
            setShowIdentityForm={setShowIdentityForm}
          />
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        id="offcanvas-address-form"
        show={showAddressForm}
        backdrop="static"
        onHide={() => setShowAddressForm(false)}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Address form</Offcanvas.Title>
        </Offcanvas.Header>
        <hr className="my-0" />
        <Offcanvas.Body>
          <GenericAddressForm
            setAddressValue={setAddressValue}
            setShowAddressForm={setShowAddressForm}
            register={register}
            errors={errors}
            col={12}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

Step2.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setValue: PropTypes.func.isRequired
};

export default Step2;
