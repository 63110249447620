import ActionButton from 'components/common/ActionButton';
import FalconComponentCard from 'components/common/FalconComponentCard';
import PageHeader from 'components/common/PageHeader';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Offcanvas,
  Row,
  Spinner,
  Table
} from 'react-bootstrap';

import {
  faFolderOpen,
  faPlusCircle,
  faUserCheck
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import corner3 from 'assets/img/illustrations/corner-3.png';
import BasicECharts from 'components/common/BasicEChart';
import Flex from 'components/common/Flex';
import { PieChart } from 'echarts/charts';
import {
  GridComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import segmentHelper from 'helpers/segment-helper';
import { getColor } from 'helpers/utils';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import SimpleBarReact from 'simplebar-react';
import { showAlert, showConfirm } from 'stores/modal';
import api from 'util/api';
import filters from 'util/filters';
import logger from 'util/logger';
import SigningPackagesForm from './SigningPackagesForm';
import { matchSorter } from 'match-sorter';
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer
]);
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from './StrictModeDroppable';

const SigningPackages = () => {
  const [signingPackages, setSigningPackages] = useState([]);
  const [signingPackagesDisplay, setSigningPackagesDisplay] = useState([]);
  const [loading, setLoading] = useState(false);
  const [signingPackagesLoadError, setSigningPackagesLoadError] =
    useState(false);

  const [sortBy, setSortBy] = useState('SortOrder');
  const [isAsc, setIsAsc] = useState(false);

  const [preSigningPackage, setPreSigningPackage] = useState(null);
  const [showSigningPackageForm, setShowSigningPackageForm] = useState(false);
  const [formTitle, setFormTitle] = useState(null);
  const [savingSigningPackage, setSavingSigningPackage] = useState(false);
  const dispatch = useDispatch();

  const loadSigningPackages = () => {
    setLoading(true);
    api.DocumentCollection.list()
      .then(res => {
        setLoading(false);
        setSigningPackages(
          _.orderBy(
            res,
            [sortBy],
            [sortBy === 'SortOrder' ? 'asc' : isAsc ? 'asc' : 'desc']
          )
        );
        setSigningPackagesLoadError(false);
      })
      .catch(err => {
        setLoading(false);
        setSigningPackagesLoadError(true);
      });
  };

  const editSigningPackage = sp => {
    setPreSigningPackage(sp);
    setFormTitle('Update Signing Package');
    setShowSigningPackageForm(true);
  };

  const duplicateSigningPackage = sp => {
    let duplicate = Object.assign({}, sp);
    duplicate.isDuplicate = true;
    duplicate.Name = sp.Name + ' (Copy)';
    setPreSigningPackage(duplicate);
    setFormTitle('Duplicate Signing Package');
    setShowSigningPackageForm(true);
  };

  const saveSigningPackage = item => {
    setSavingSigningPackage(true);
    let package_documents_ids = [];
    _.map(item.package_documents, p => {
      package_documents_ids.push(p.GUID);
    });
    if (preSigningPackage == null || !!preSigningPackage.isDuplicate) {
      api.DocumentCollection.add(
        item.package_name,
        '',
        item.notary_fee,
        item.notes,
        package_documents_ids,
        item.Signing_Fees
      )
        .then(res => {
          setSavingSigningPackage(false);
          loadSigningPackages();
          setShowSigningPackageForm(false);
          toast.success('Signing package added successfully', {
            theme: 'colored',
            position: 'bottom-center',
            icon: faUserCheck,
            toastId: 'signing-package'
          });
          //logger.log(res);
        })
        .catch(err => {
          logger.log(err);
          setSavingSigningPackage(false);
          dispatch(
            showAlert(
              'Error Saving Signing Package',
              'There was a problem saving this Signing Package. Please try again.'
            )
          );
          loadSigningPackages();
        });
    } else {
      //guid, name, description, Amount, notes, doc_type_guids, Signing_Fees
      api.DocumentCollection.update(
        preSigningPackage.GUID,
        item.package_name,
        '',
        item.notary_fee,
        item.notes,
        package_documents_ids,
        item.Signing_Fees,
        null
      )
        .then(res => {
          setSavingSigningPackage(false);
          loadSigningPackages();
          setShowSigningPackageForm(false);
          toast.success('Signing package updated successfully', {
            theme: 'colored',
            position: 'bottom-center',
            icon: faUserCheck,
            toastId: 'signing-package'
          });
        })
        .catch(err => {
          //logger.log(err);
          setSavingSigningPackage(false);
          dispatch(
            showAlert(
              'Error Updating Signing Package',
              'There was a problem updating this Signing Package.  Please try again.'
            )
          );
          loadSigningPackages();
        });
    }
  };

  const deleteSigningPackage = signingPackage => {
    dispatch(
      showConfirm(
        'Are you sure?',
        'Are you sure you want to remove this signing package?',
        res => {
          if (res) {
            api.DocumentCollection.remove(signingPackage.GUID)
              .then(res => {
                loadSigningPackages();
                segmentHelper.track(
                  segmentHelper.events.SIGNING_PACKAGE_DELETED
                );
              })
              .catch(err => {
                showAlert(
                  'Error Deleting Signing package',
                  'There was a problem removing this signing package.  Please try again.'
                );
                loadSigningPackages();
              });
          }
        }
      )
    );
  };

  const addSigningPackage = () => {
    setPreSigningPackage(null);
    setFormTitle('Add new Signing Packages');
    setShowSigningPackageForm(true);
  };

  const searchItems = e => {
    let keyword = e.target.value.toLowerCase();
    let filterSigningPackages = _.filter(
      signingPackages,
      d => d.Name.toLowerCase().includes(keyword)
      //moment.utc(d.Created).local().format('l, LT').includes(keyword)
    );
    let sortedItems = matchSorter(filterSigningPackages, keyword, {
      keys: ['Name']
    });
    setSigningPackagesDisplay(sortedItems);
  };

  useEffect(() => {
    let sortedRecords = _.orderBy(
      signingPackagesDisplay,
      [sortBy],
      [isAsc ? 'asc' : 'desc']
    );
    setSigningPackagesDisplay(sortedRecords);
  }, [sortBy, isAsc]);

  useEffect(() => {
    logger.log('signingPackages', signingPackages);
    setSigningPackagesDisplay(signingPackages);
  }, [signingPackages]);

  useEffect(() => {
    loadSigningPackages();
  }, []);

  const getData = e => {
    let data = [];
    let i = 1;
    if (e.NotaryFee) {
      data.push({
        id: i,
        value: e.NotaryFee,
        name: 'Notary Fee',
        color: 'primary'
      });
    }
    let colorRange = 1100;
    if (e?.SigningFees) {
      _.map(e.SigningFees, ef => {
        data.push({
          id: ++i,
          value: ef.Amount,
          name: ef.Name,
          color: 'gray-' + colorRange
        });
        colorRange -= 200;
      });
    }
    logger.log('data', data);
    return data;
  };

  const queryAttr = 'data-rbd-drag-handle-draggable-id';

  const onDragUpdate = update => {
    if (!update.destination) {
      return;
    }
    const draggableId = update.draggableId;

    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    if (!draggedDOM) {
      return;
    }
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    background: 'grey',
    backgroundColor: 'grey',
    ...draggableStyle
  });

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    position: 'relative'
  });

  const getMappedColor = data => {
    let colorArray = [];
    _.map(data, d => {
      colorArray.push(getColor(d.color));
    });
    return colorArray;
  };

  const getOptions = (data, radius) => ({
    color: getMappedColor(data),

    tooltip: {
      padding: [7, 10],
      transitionDuration: 0,
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      formatter: params =>
        `<strong>${params.data.name}:</strong> ${params.percent}%`
    },
    series: [
      {
        name: filters.formatCurrencyFromServer(
          data.reduce((acc, val) => parseFloat(val.value) + acc, 0)
        ),
        type: 'pie',
        radius,
        avoidLabelOverlap: false,
        emphasis: {
          scale: false
        },
        itemStyle: {
          borderWidth: 2,
          borderColor: getColor('gray-100')
        },
        label: {
          show: true,
          position: 'center',
          formatter: '{a}',
          fontSize: 20,
          color: getColor('dark')
        },
        data
      }
    ]
  });
  const MarketShare = ({ data, radius }) => {
    const total = data ? data.reduce((acc, val) => val.value + acc, 0) : 0;
    return data ? (
      <Card.Body className="p-0">
        <Row className="justify-content-between g-0 align-items-center align-content-center">
          <Col xs={5} sm={6} md={7} xl={8} xxl className="pe-2">
            {data.map((item, index) => (
              <MarketShareItem
                item={item}
                index={index}
                key={index}
                total={total}
              />
            ))}
          </Col>
          <Col xs="auto">
            <div className="ps-0 w-100 text-center">
              <BasicECharts
                echarts={echarts}
                options={getOptions(data, radius)}
                style={{ width: '6rem', height: '6rem' }}
              />
            </div>
          </Col>
        </Row>
      </Card.Body>
    ) : (
      <></>
    );
  };
  const MarketShareItem = ({ item, index, total }) => {
    const { name, value, color } = item;

    //const percentage = ((value * 100) / total).toFixed(0);
    return (
      <Flex
        alignItems="center"
        justifyContent="between"
        className={`fw-semi-bold fs--2 ${index == 0 && 'mt-3'}`}
      >
        <p className="mb-1">
          <FontAwesomeIcon
            icon="circle"
            className={`me-2`}
            style={{ color: getColor(color) }}
          />
          {name}
        </p>
        <div className="d-xxl-none">
          {filters.formatCurrencyFromServer(value)}
        </div>
      </Flex>
    );
  };
  const handleDragEnd = result => {
    if (result.source.index !== result.destination.index) {
      const data = signingPackagesDisplay.find(
        data => data.GUID == result.draggableId
      );
      const reorderedPackages = Array.from(signingPackagesDisplay);
      const [removed] = reorderedPackages.splice(result.source.index, 1);
      reorderedPackages.splice(result.destination.index, 0, removed);
      setSigningPackagesDisplay(reorderedPackages);
      api.DocumentCollection.update(
        data.GUID,
        data.Name,
        data.description,
        data.NotaryFee,
        data.AdditionalData,
        data.DocumentTypeGUIDs,
        data.SigningFees,
        result.destination.index
      )
        .then(() => {
          toast.success('Signing packages reordered successfully', {
            theme: 'colored',
            position: 'bottom-center',
            icon: faUserCheck,
            toastId: 'signing-package'
          });
        })
        .catch(() => {
          setSavingSigningPackage(false);
          dispatch(
            showAlert(
              'Error Ordering Signing Package',
              'There was a problem ordering this Signing Package. Please try again.'
            )
          );
          loadSigningPackages();
        });
    }
  };

  return (
    <>
      <PageHeader
        preTitle="Active Signing Packages"
        title="Signing Packages list"
        description=""
        className="mb-3"
        titleTag="h2"
        image={corner3}
      />

      <Row className="mb-3 g-3">
        <Col lg={12}>
          <FalconComponentCard noGuttersBottom>
            <FalconComponentCard.Header
              title="Signing Packages"
              endEl={
                <Flex className={'g-2'}>
                  <div>
                    <Form as={Row} className="gx-2 d-flex align-items-center">
                      <Col xs="auto">
                        <small>Sort by:</small>
                      </Col>
                      <Col xs="auto">
                        <InputGroup>
                          <Form.Select
                            className="pe-5"
                            defaultValue="SortOrder"
                            onChange={({ target }) => {
                              if (target.value === 'SortOrder') {
                                setIsAsc(true);
                              }
                              setSortBy(target.value);
                            }}
                          >
                            <option value="Name">Name</option>
                            <option value="NotaryFee">Notary Fee</option>
                            <option value="Created">Created</option>
                            <option value="SortOrder">Sort Order</option>
                          </Form.Select>
                          {sortBy !== 'SortOrder' && (
                            <InputGroup.Text
                              as={Button}
                              variant="link"
                              className="border border-300 text-700"
                              onClick={() => setIsAsc(!isAsc)}
                            >
                              <FontAwesomeIcon
                                icon={
                                  isAsc ? 'sort-amount-up' : 'sort-amount-down'
                                }
                              />
                            </InputGroup.Text>
                          )}
                        </InputGroup>
                      </Col>
                    </Form>
                  </div>
                  <div className="mx-1">
                    <Form.Control
                      onChange={e => searchItems(e)}
                      placeholder="search package..."
                      name="search"
                    ></Form.Control>
                  </div>
                  <div className="mx-1">
                    <Button
                      variant="primary"
                      onClick={() => addSigningPackage()}
                    >
                      <FontAwesomeIcon icon={faPlusCircle} size="sm" /> Add New
                    </Button>
                  </div>
                </Flex>
              }
            />
            <FalconComponentCard.Body noLight className="py-0">
              {loading && (
                <Flex justifyContent="center" className="py-3">
                  <Spinner size="sm" />
                </Flex>
              )}
              {signingPackagesDisplay.length !== 0 &&
                !loading &&
                !signingPackagesLoadError && (
                  <SimpleBarReact>
                    <DragDropContext
                      onDragEnd={handleDragEnd}
                      onDragUpdate={onDragUpdate}
                    >
                      <StrictModeDroppable droppableId="droppable1">
                        {(provided, snapshot) => (
                          <div ref={provided.innerRef}>
                            <Table {...provided.droppableProps} bordered>
                              <colgroup>
                                <col />
                                <col />
                                <col className="d-sm-none d-md-block" />
                                <col />
                              </colgroup>
                              <thead>
                                <tr>
                                  <th scope="col"></th>
                                  <th scope="col">Package name</th>
                                  <th scope="col">Fee</th>
                                  <th scope="col">Documents</th>
                                  <th className="text-end" scope="col">
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tbody
                                style={getListStyle(snapshot.isDraggingOver)}
                              >
                                {_.map(signingPackagesDisplay, (e, index) => {
                                  return (
                                    <Draggable
                                      key={e.GUID}
                                      draggableId={e.GUID}
                                      index={index}
                                    >
                                      {provided => (
                                        <tr
                                          key={e.GUID}
                                          className="bg-white"
                                          ref={provided.innerRef}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                          {...provided.draggableProps}
                                        >
                                          <td style={{ width: '2%' }}>
                                            <div {...provided.dragHandleProps}>
                                              <FontAwesomeIcon icon="grip-lines" />
                                            </div>
                                          </td>
                                          <td style={{ width: '15%' }}>
                                            <div> {e.Name} </div>
                                          </td>
                                          <td style={{ width: '20%' }}>
                                            <MarketShare
                                              data={getData(e)}
                                              radius={['100%', '87%']}
                                            />
                                          </td>
                                          <td style={{ width: '37%' }}>
                                            <ul>
                                              {_.map(e.DocumentTypeGUIDs, d => {
                                                let document =
                                                  e?.DocumentTypes?.find(
                                                    item => {
                                                      return item.GUID == d;
                                                    }
                                                  );
                                                if (document) {
                                                  return (
                                                    <li>
                                                      <span className="fw-medium text-primary">
                                                        {
                                                          document.NotarizationType
                                                        }
                                                      </span>{' '}
                                                      - {document.Value}
                                                    </li>
                                                  );
                                                }
                                              })}
                                            </ul>
                                          </td>
                                          <td
                                            className="text-end"
                                            style={{ width: '30%' }}
                                          >
                                            <ActionButton
                                              icon="edit"
                                              title="Edit"
                                              label="Edit"
                                              variant="action"
                                              className="p-1 mx-1 text-primary"
                                              onClick={() =>
                                                editSigningPackage(e)
                                              }
                                            />
                                            <ActionButton
                                              icon="copy"
                                              title="Duplicate"
                                              label="Duplicate"
                                              variant="action"
                                              className="p-1 mx-1 text-primary"
                                              onClick={() =>
                                                duplicateSigningPackage(e)
                                              }
                                            />
                                            <ActionButton
                                              icon="trash-alt"
                                              title="Remove"
                                              label="Remove"
                                              variant="action"
                                              className="p-1 mx-1 text-danger"
                                              onClick={() =>
                                                deleteSigningPackage(e)
                                              }
                                            />
                                          </td>
                                        </tr>
                                      )}
                                    </Draggable>
                                  );
                                })}
                              </tbody>
                            </Table>
                            <div style={{ background: 'lightBlue' }}>
                              {provided.placeholder}
                            </div>
                          </div>
                        )}
                      </StrictModeDroppable>
                    </DragDropContext>
                  </SimpleBarReact>
                )}
              {signingPackages.length === 0 &&
                !loading &&
                (signingPackagesLoadError ? (
                  <div className="text-center">
                    <FontAwesomeIcon icon={faFolderOpen} size="3x" />
                    <br />
                    <p className="my-3">
                      Unbale to load signing package list. Try again
                    </p>
                  </div>
                ) : (
                  <div className="text-center">
                    <FontAwesomeIcon icon={faFolderOpen} size="3x" />
                    <br />
                    <p className="my-3">
                      You haven't created any signing packages yet. <br />
                      Click "Add new" to create new signing packages
                    </p>
                  </div>
                ))}
            </FalconComponentCard.Body>
          </FalconComponentCard>
        </Col>
      </Row>
      <Offcanvas
        size="lg"
        id="offcanvas-address-form"
        show={showSigningPackageForm}
        backdrop="static"
        onHide={() => setShowSigningPackageForm(false)}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{formTitle}</Offcanvas.Title>
        </Offcanvas.Header>
        <hr className="my-0" />
        <Offcanvas.Body>
          <SigningPackagesForm
            preSigningPackage={preSigningPackage}
            saveSigningPackage={saveSigningPackage}
            setShowSigningPackagesForm={setShowSigningPackageForm}
            savingSigningPackage={savingSigningPackage}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default SigningPackages;
