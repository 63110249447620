import FalconCardHeader from 'components/common/FalconCardHeader';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { Alert, Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  setDeleteAccountInfo,
  updatePersistIdNumbersAction,
  updatePrivacyModeAction
} from 'stores/app';
import api from 'util/api';
import c from 'util/const';

const AccountSettings = () => {
  const { updatingPrivacyMode, updatingPersistIdNumbers, userMaster, deleteUserInfo } =
  useSelector(state => state.app);

  const accountDeletedDate = useMemo(() => {
    const timezones = c.timezones?.find(
      item => item?.offset === userMaster?.LocalTimezone
    );
    if (timezones?.zone && deleteUserInfo?.IsAccountDeleteRequested) {
      const parsedDate = moment.tz(deleteUserInfo?.AccountDeleteDate, 'UTC');
      return parsedDate.tz(timezones?.zone).format('MMM DD, YYYY');
    } else {
      return moment(timezones?.zone).format('MMM DD, YYYY');
    }
  }, [deleteUserInfo, userMaster]);

  const dispatch = useDispatch();

  const updatePrivacyMode = privacyMode => {
    let updatedUserMaster = Object.assign({}, userMaster);
    updatedUserMaster.PrivacyEnabled = privacyMode;
    dispatch(updatePrivacyModeAction(userMaster?.GUID, updatedUserMaster));
  };

  const updatePersistIdNumbersMode = persistIdNumbersMode => {
    let updatedUserMaster = Object.assign({}, userMaster);
    updatedUserMaster.PersistIdNumbers = persistIdNumbersMode;
    dispatch(updatePersistIdNumbersAction(userMaster?.GUID, updatedUserMaster));

    //dispatch(updatePersistIdNumbersAction(userMaster?.GUID, persistIdNumbersMode));
  };

  useEffect(() => {
    api.Token.detail('detail').then(res => {
      if (res?.User?.IsAccountDeleteRequested) {
        dispatch(
          setDeleteAccountInfo({
            AccountDeleteDate: res.User.AccountDeleteDate,
            AccountDeleteRequestedOn: res.User.AccountDeleteRequestedOn,
            AccountDeleteType: res.User.AccountDeleteType,
            IsAccountDeleteRequested: res.User.IsAccountDeleteRequested
          })
        );
      } else {
        dispatch(setDeleteAccountInfo({}));
      }
    });
  }, []);
  
  return (
    <Card className="mb-3">
      <FalconCardHeader title="Account Settings" />
      <Card.Body className="bg-light">
        <div className="ps-2">
          <Row>
            <Col className="d-flex" lg={12}>
              <Form.Check
                type="switch"
                id="custom-switch-privacy-mode"
                label="Enable Privacy mode"
                className="form-label-nogutter"
                name="numberVisibility"
                onChange={() => updatePrivacyMode(!userMaster?.PrivacyEnabled)}
                checked={userMaster?.PrivacyEnabled}
              />{' '}
              {updatingPrivacyMode ? (
                <Spinner className="ms-2" size="sm" />
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </div>

        <div className="ps-2">
          <Row>
            <Col className="d-flex" lg={12}>
              <Form.Check
                type="switch"
                id="custom-switch-privacy-mode"
                label="Persist Id Number (Mobile App)"
                className="form-label-nogutter"
                name="numberVisibility"
                onChange={() =>
                  updatePersistIdNumbersMode(!userMaster?.PersistIdNumbers)
                }
                checked={userMaster?.PersistIdNumbers}
              />{' '}
              {updatingPersistIdNumbers ? (
                <Spinner className="ms-2" size="sm" />
              ) : (
                <></>
              )}
            </Col>
            {!userMaster?.PersistIdNumbers && (
              <Col className="d-flex" lg={12}>
                <Alert variant="info fs--1 p-2 mt-2">
                  When disabled, any ID scan will omit the serial number. This
                  feature is for use in the mobile app. Your setting choice here
                  will be reflected in the mobile app.
                </Alert>
              </Col>
            )}
            <Col className="d-flex" lg={12}>
              {!deleteUserInfo?.IsAccountDeleteRequested ? (
                <Button
                  as={Link}
                  variant="danger"
                  color="secondary"
                  size="sm"
                  className="mt-3 p-2 px-3 "
                  to={`/account/delete-account`}
                >
                  Delete My Account
                </Button>
              ) : (
                <Alert variant="danger fs--1 p-2 ">
                  Your account deletion request has been received. Your account
                  is scheduled to be permanently deleted on {accountDeletedDate}{' '}
                  and all associated data will be removed.
                </Alert>
              )}
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};

export default AccountSettings;
