import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';


const ValidationErrors = (props) => {
    let { errors } = props;

    return (
        <Fragment>
            {errors && errors.length > 0 &&
                <Alert variant='danger'>
                    {errors.map((err, i) =>
                        <div key={i}>
                            {err}
                        </div>
                    )}
                </Alert>
            }
        </Fragment>
    );
};

ValidationErrors.propTypes = {
    errors: PropTypes.array.isRequired,
}

export default ValidationErrors;