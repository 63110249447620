import { CardElement, Elements, ElementsConsumer, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Flex from 'components/common/Flex';
import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import AddPaymentChildForm from './AddPaymnetChildForm';
import { useSelector } from 'react-redux';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
//const stripePromise = loadStripe('pk_test_51BTUDGJAJfZb9HEBwDg86TN1KNprHjkfipXmEDMb0gSCassK5T3ZfxsAbcgKVmAIXF7oZ6ItlZZbXO6idTHE67IM007EwQ4uN3');

export default function AddPaymentCard({ handlePaymentIdMethod, isSaving, setSaving }) {
    //const [elements, setElements] = useState(null);
    const { stripeKey } = useSelector(state => state.subscription);
    const stripePromise = loadStripe(stripeKey);
    const options = {
        // passing the client secret obtained from the server
        //clientSecret: '{{CLIENT_SECRET}}',
    };


    return (
        <Elements stripe={stripePromise} options={options}>
            <AddPaymentChildForm handlePaymentIdMethod={handlePaymentIdMethod} isSaving={isSaving} setSaving={setSaving} />
        </Elements>
    );
};
