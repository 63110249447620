import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import {
  faPlusCircle,
  faUser,
  faUserTie
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/common/Avatar';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { AuthWizardContext } from 'context/Context';
import draftEntryUiHelper from 'helpers/draft-entry-ui-helper';
import segmentHelper from 'helpers/segment-helper';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionLogSigningAction,
  actionRemoveFingerprint
} from 'stores/signing';
import filters from 'util/filters';
import FingerprintModal from './comp/FingerprintModal';

const Step5 = ({ setStep, saveSigningToDraft }) => {
  const { user } = useContext(AuthWizardContext);
  const [showFingerprintForm, setShowFingerprintForm] = useState(false);
  const [signer, setSigner] = useState({});

  const [author, setAuthor] = useState(null);
  const [fingerprintData, setFingerprintData] = useState(null);

  const dispatch = useDispatch();

  let fingerprintPad = {};
  const { signers, fingerprints } = useSelector(state => state.signing);

  const clearFingerprintPad = () => {
    fingerprintPad.clear();
  };

  const promptToAddSigners = () => {
    setStep(2);
  };

  useEffect(() => {
    if (fingerprintData) {
      dispatch(
        actionAddFingerprint({
          signer_id: signer.signer_id,
          fingerprint: fingerprintData
        })
      );
    }
  }, [fingerprintData]);

  const openSignerFingerprintForm = signer_id => {
    let signer = _.find(signers, s => {
      return s.signer_id === signer_id;
    });
    if (signer) {
      setSigner(signer);
      setAuthor(signer.first_name + ' ' + signer.last_name);
      setShowFingerprintForm(true);
    }
  };

  const removeFingerprint = fingerprint_id => {
    let foundSignatures = _.find(
      fingerprints,
      sig => sig.fingerprint_id === fingerprint_id
    );
    dispatch(actionLogSigningAction(`Remove fingerprint`));
    let foundSigner = _.find(
      signers,
      s => s.signer_id === foundSignatures.signer_id
    );
    dispatch(
      actionLogSigningAction(
        `Fingerprint for ${filters.formatNameObj(foundSigner)} removed.`
      )
    );
    segmentHelper.track(segmentHelper.events.SIGNING_REMOVE_FINGERPRINT);
    dispatch(actionRemoveFingerprint({ fingerprint_id }));
    saveSigningToDraft();
  };

  const AddNewBlock = () => {
    return (
      <div className="text-center">
        <h4>
          <FontAwesomeIcon icon={faUser} />
        </h4>
        <p className="my-3">You haven't added any Notary Signer yet.</p>
        <Button
          onClick={() => promptToAddSigners()}
          className="ml-2"
          color="primary"
        >
          <FontAwesomeIcon icon={faPlusCircle} /> Add Signer
        </Button>
      </div>
    );
  };

  const SignerRow = ({ data, color }) => {
    //logger.log('SignerRow', data);
    //logger.log('SignerRow fingerprints', fingerprints);
    let signerFingerprint =
      fingerprints.length > 0
        ? _.filter(fingerprints, s => s.signer_id === data.signer_id)
        : fingerprints;
    //logger.log('SignerRow fingerprint', fingerprint);
    return _.map(signerFingerprint, fingerprint => {
      return (
        <tr className="align-middle" key={fingerprint.fingerprint_id}>
          <td className="text-nowrap">
            <div className="d-flex align-items-center">
              <Avatar
                src={data.avatar}
                size="m"
                name={data.first_name + ' ' + data.last_name}
                mediaClass={`text-${color} bg-soft-${color} fs--2`}
              />{' '}
              &nbsp;
              {data.first_name + ' ' + data.last_name}
            </div>
          </td>
          <td className="text-nowrap">{fingerprint?.finger}</td>

          <td className="text-center">
            {fingerprint?.imgData?.data?.length
              ? draftEntryUiHelper.getFingerprintViewImage(
                  fingerprint?.imgData?.data,
                  80,
                  '100%',
                  'card-img-top'
                )
              : 'N/A'}
          </td>
          <td className="text-nowrap text-end">
            <Button
              variant="outline-danger"
              onClick={() => removeFingerprint(fingerprint.fingerprint_id)}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <div>
        <h5 className="text-center">Thumbprint Collection</h5>
        <p className="text-center fs-0">
          Thumbprint collection is currently unavailable on the web-app. Please
          use our mobile app for entries that require digital thumbprint
          capture.
        </p>
        <hr />
        {signers && signers.length > 0 ? (
          <div className="pt-1">
            <FalconCardHeader
              title={
                'Signer' + (signers.length > 1 ? 's' : '') + ' in this entry'
              }
              icon={faUserTie}
            />

            <Table responsive>
              <thead>
                <tr>
                  <th scope="col">Signer</th>
                  <th scope="col">Finger</th>
                  <th scope="col" className="text-center">
                    Fingerprint
                  </th>
                  <th className="text-end" scope="col">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {signers.map(s => (
                  <SignerRow data={s} color="primary" key={s.signer_id} />
                ))}
              </tbody>
            </Table>
          </div>
        ) : (
          <></>
        )}

        {!signers?.length ? <AddNewBlock /> : <></>}
      </div>

      {signer && (
        <FingerprintModal
          author={author}
          showFingerprintForm={showFingerprintForm}
          setShowFingerprintForm={setShowFingerprintForm}
          setFingerprintData={setFingerprintData}
        />
      )}
    </>
  );
};

Step5.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setValue: PropTypes.func.isRequired
};

export default Step5;
