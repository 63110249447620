import { useAuth } from 'hooks/useAuth';
import _ from 'lodash';
import enums from './enums';
import logger from './logger';
import utils from './utils';
import filters from './filters';
import AppContext from 'context/Context';
import { actionSetNtworkStatusOffline } from 'stores/app';
import moment from 'moment';
import { Buffer } from 'buffer';

let apiHelper = {};

apiHelper.registerApiHandlers = connectionErrorCb => {
  apiHelper.connectionErrorCB = connectionErrorCb;
};

apiHelper.onConnectionErrorCaught = err => {
  if (apiHelper.connectionErrorCB) {
    apiHelper.connectionErrorCB(err);
  }
};

apiHelper.textResponseHdl = (res, err) => {
  if (err) {
    return Promise.reject(err);
  }
  if (res) {
    if (res.text) {
      return Promise.resolve(res.text);
    }
  }

  return Promise.reject(new Error('Improper response body'));
};

apiHelper.rawResponseHdl = (res, err) => {
  if (err) {
    return Promise.reject(err);
  }

  if (res) {
    if (res.body) {
      return Promise.resolve(res.body);
    }
  }

  return Promise.reject(new Error('Improper response body'));
};

// apiHelper.isAuthorizationError = (err) => {
//     //logger.log('Checking authorization error', err.code, hasMethod, hasUrl);
//     const e = _.includes(err, 'Unauthorized');
//     //logger.log("isAuthorizationError", e);
//     return e;
// }

apiHelper.isAuthorizationError = err => {
  try {
    return _.includes(err, 'Unauthorized');
  } catch (err) {
    //logger.log(err);
  }
};

apiHelper.isTokenExpired = err => {
  return err && err.Code === 'LoginFailed';
};

// api.htmlDecodeResponse = (data) => {
//   if (Array.isArray(data)) {
//     for (var i = 0; i < data.length; i++) {
//       utils.htmlDecodeObj(data[i]);
//     }
//   }
//   else {
//     utils.htmlDecodeObj(data);
//   }
// }

apiHelper.dataUriResponseHdl = (res, err) => {
  if (err) {
    return Promise.reject(err);
  }

  if (res) {
    if (res.body) {
      let b64 = utils.arrayBufferToBase64(res.body);
      return Promise.resolve(`data:${res.type};base64,${b64}`);
    }
  }

  return Promise.reject(new Error('Improper response body'));
};

apiHelper.isConnectionError = err => {
  //logger.log('Checking connection error data', err);
  let hasMethod = err.hasOwnProperty('method');
  let hasUrl = err.hasOwnProperty('url');
  //logger.log('isConnectionError', err.code, hasMethod, hasUrl);
  if (err && err.code === 'ABORTED') {
    return true;
  } else if (hasMethod && hasUrl) {
    return true;
  } else {
    return false;
  }
};

apiHelper.isNetworkOffline = err => {
  return err.toString().includes('the network is offline');
};

apiHelper.errorHdl = err => {
  logger.log('Network error check', err);
  let customErr = _.get(err, 'response.body');
  if (apiHelper.isNetworkOffline(err)) {
    throw 'NETWORK_OFFLINE';
  } else if (apiHelper.isConnectionError(err)) {
    return err;
  } else if (apiHelper.isAuthorizationError(err)) {
    const auth = useAuth();
    auth.unsetAuth();
    throw err;
  } else if (customErr) {
    throw customErr;
  } else {
    throw err;
  }
};

apiHelper.responseHdl = (res, err, preventSessionErrors) => {
  //logger.log('api response?', res, res.body || null, err);

  if (err) {
    //logger.log('i got an error', res.body);
    return Promise.reject(err);
  }

  if (res) {
    //logger.log('i got an res', res.body);
    if (res.body) {
      if (res.body.error) {
        console.error('got an error', res.body);
      } else {
        //api.htmlDecodeResponse(res.body);
        return Promise.resolve(res.body);
      }
    } else if (res.text) {
      //If it's a text response, then it's probably html that needs to be
      //parsed and decoded.
      let retVal = res.text;
      try {
        retVal = JSON.parse(retVal);
      } catch { }

      return Promise.resolve(retVal);
    } else if (
      res.statusCode &&
      res.statusCode >= 200 &&
      res.statusCode < 300
    ) {
      return Promise.resolve({});
    } else {
      console.error('no body in request');
      return Promise.reject(new Error('no body in request'));
    }
  }

  console.error('no error, and no data!');
  return Promise.reject(new Error('no error or body in request'));
};

apiHelper.apiBlobTranslationHelper = (type, res) => {
  try {
    //Takes a response from responseHdl, looks at properties, and translates it to
    //match the enums we use in-app.
    //This accounts for a couple data issues and inconsistencies between this model and the api data
    _.each(res, blob => {
      if (type === enums.BLOB_TYPES.SIGNATURE) {
      } else if (type === enums.BLOB_TYPES.FINGERPRINT) {
        blob.Finger = enums.findResultCaseInsensitive(
          enums.FINGERS,
          blob.Finger
        );
      } else if (type === enums.BLOB_TYPES.IDENTIFICATION) {
        blob.IdType = enums.findResultCaseInsensitive(
          enums.ID_TYPES,
          blob.IdType
        );
      }
    });
  } catch (err) {
    //Just a safeguard.  If there's some exception, return what we had.
    //This layer shouldn't break any operation.
    console.error('Error translating notary entry', err);
  }

  return Promise.resolve(res);
};

apiHelper.apiNotaryEntryTranslationHelper = res => {
  try {
    //Takes a response from responseHdl, looks at properties, and translates it to
    //match the enums we use in-app.
    //This accounts for a couple data issues and inconsistencies between this model and the api data
    _.each(res, signingRes => {
      let NotaryEntries = _.get(signingRes, 'NotaryEntries', []);
      _.each(NotaryEntries, entry => {
        entry.NotarizationType = enums.normalizeNotarizationType(
          entry.NotarizationType
        );
        entry.DocumentType = enums.findResultCaseInsensitive(
          enums.DOCUMENT_TYPE,
          entry.DocumentType
        );

        let fingerPrints = _.get(entry, 'Fingerprints', []);
        _.each(fingerPrints, fp => {
          fp.Finger = enums.findResultCaseInsensitive(enums.FINGERS, fp.Finger);
        });
        let identifications = _.get(entry, 'Identifications', []);
        _.each(identifications, id => {
          id.IdType = enums.findResultCaseInsensitive(
            enums.ID_TYPES,
            id.IdType
          );
        });
      });
    });
  } catch (err) {
    //Just a safeguard.  If there's some exception, return what we had.
    //This layer shouldn't break any operation.
    console.error('Error translating notary entry', err);
  }

  return Promise.resolve(res);
};
apiHelper.apiNotaryEntryTranslationHelper = res => {
  try {
    //Takes a response from responseHdl, looks at properties, and translates it to
    //match the enums we use in-app.
    //This accounts for a couple data issues and inconsistencies between this model and the api data
    _.each(res, signingRes => {
      let NotaryEntries = _.get(signingRes, 'NotaryEntries', []);
      _.each(NotaryEntries, entry => {
        entry.NotarizationType = enums.normalizeNotarizationType(
          entry.NotarizationType
        );
        entry.DocumentType = enums.findResultCaseInsensitive(
          enums.DOCUMENT_TYPE,
          entry.DocumentType
        );

        let fingerPrints = _.get(entry, 'Fingerprints', []);
        _.each(fingerPrints, fp => {
          fp.Finger = enums.findResultCaseInsensitive(enums.FINGERS, fp.Finger);
        });
        let identifications = _.get(entry, 'Identifications', []);
        _.each(identifications, id => {
          id.IdType = enums.findResultCaseInsensitive(
            enums.ID_TYPES,
            id.IdType
          );
        });
      });
    });
  } catch (err) {
    //Just a safeguard.  If there's some exception, return what we had.
    //This layer shouldn't break any operation.
    logger.error('Error translating notary entry', err);
  }

  return Promise.resolve(res);
};

apiHelper.errorHdlNoConnectionHdl = err => {
  //logger.log('api: error handler', err);
  let customErr = _.get(err, 'response.body');
  if (customErr && !_.isEmpty(customErr)) {
    throw {
      ...err,
      ...customErr
    };
  } else {
    throw err;
  }
};

apiHelper.buildLocationResult = locationInfo => {
  let location = {
    Province: '',
    Country: 'USA'
  };

  if (locationInfo) {
    location.Address1 = _.get(locationInfo, 'address_1');
    location.Address2 = _.get(locationInfo, 'address_2');
    location.City = _.get(locationInfo, 'city');
    location.State = _.get(locationInfo, 'state');
    location.PostalCode = _.get(locationInfo, 'zip');

    if (locationInfo.latitude && locationInfo.longitude) {
      location = _.extend({}, location, {
        Latitude: locationInfo.latitude,
        Longitude: locationInfo.longitude
      });
    }
  }
  return location;
};

apiHelper.parseNameObjectFromIdentification = i => {
  return {
    FirstName: filters.getFirstNameFromObj(i),
    MiddleName: filters.getMiddleNameFromObj(i),
    LastName: filters.getLastNameFromObj(i),
    Prefix: filters.getPrefixFromObj(i),
    Suffix: filters.getSuffixFromObj(i)
  };
};

apiHelper.getRangeQueryParam = (from, to) => {
  let string = "";
  if (from) {
    string += ((string == "" ? "" : "&") + "from=" + from);
  }
  if (to) {
    string += ((string == "" ? "" : "&") + "to=" + to);
  }
  return string ? `?${string}` : "";
};

apiHelper.buildIdentificationResult = i => {
  //Guard against broken dates, which could have been breifly created by freeform date inputs.
  //also done for witnesses
  //save broken date data to notes.
  logger.log('buildIdentificationResult', i);
  let extraNotes = '';
  let issueDateValid = utils.isValidDate(i.id_issued_date);
  if (i.id_issued_date && !issueDateValid) {
    extraNotes += 'omitted IdIssued:' + i.id_issued_date;
  }
  let expirationDateValid = utils.isValidDate(i.id_expiration_date);
  if (i.id_expiration_date && !expirationDateValid) {
    extraNotes += 'omitted IdExpiration:' + i.id_expiration_date;
  }
  let dateOfBirthValid = utils.isValidDate(i.date_of_birth);
  if (i.date_of_birth && !dateOfBirthValid) {
    extraNotes += 'omitted DateOfBirth:' + i.date_of_birth;
  }

  let identification = {
    ...apiHelper.parseNameObjectFromIdentification(i),
    IdType: i.id_type,
    DisplayName: filters.formatNameObj(i),
    ...(i.id_type_other &&
      i.id_type_other.length > 0 && { IdTypeOther: i.id_type_other }),
    ...(i.id_number && i.id_number.length > 0 && { IdNumber: i.id_number }),
    ...(i.id_issued_date &&
      issueDateValid && {
      IdIssued: moment(
        utils.trimInvalidCharsFromDate(i.id_issued_date)
      ).format()
    }),
    ...(i.id_expiration_date &&
      expirationDateValid && {
      IdExpiration: moment(
        utils.trimInvalidCharsFromDate(i.id_expiration_date)
      ).format()
    }),
    ...(i.date_of_birth &&
      dateOfBirthValid && {
      DateOfBirth: moment(
        utils.trimInvalidCharsFromDate(i.date_of_birth)
      ).format()
    }),
    ...(i.id_address_1 && { Address1: i.id_address_1 }),
    ...(i.id_address_2 && { Address2: i.id_address_2 }),
    ...(i.id_city && { City: i.id_city }),
    ...(i.id_state && { State: i.id_state }),
    ...(i.id_postal_code && { PostalCode: i.id_postal_code }),
    ...(i.id_issuing_state && { IdIssuerState: i.id_issuing_state }),
    AdditionalData: i.notes + extraNotes,
    ScanVendor: i.scan_vendor,
    Blobs: []
  };

  if (i.scan_data) {
    let objJsonStr = JSON.stringify(i.scan_data);
    let objJsonB64 = Buffer.from(objJsonStr).toString('base64');
    identification.Blobs.push({
      ContentType: enums.CONTENT_TYPES.APPLICATION_JSON,
      Name: 'ScanData',
      Description: `ScanData for ${i.id_first_name} ${i.id_last_name}`,
      Data: objJsonB64
    });
  }
  return identification;
};

export default apiHelper;
