import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Flex from 'components/common/Flex';
import WizardInput from 'components/pages/notary/signing/steps/WizardInput';
import segmentHelper from 'helpers/segment-helper';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { showAlert } from 'stores/modal';
import api from 'util/api';
import c from 'util/const';
import enums from 'util/enums';
import logger from 'util/logger';

const DocumentTypesForm = ({
  setShowDocumentTypesForm,
  onSuccess,
  onFailure
}) => {
  const states = c.states;
  const initLocalDocumentType = {
    documentType: '',
    notarizationType: '',
    otherNotarizationType: '',
    IsFavorite: false
  };
  const [localDocumentType, setLocalDocumentType] = useState(
    initLocalDocumentType
  );
  const [savingDocumentType, setSavingDocumentType] = useState(false);
  const notarization_type_picker_opts = [
    {
      name: enums.NOTARIZATION_TYPE.ACKNOWLEDGE,
      text: enums.NOTARIZATION_TYPE.ACKNOWLEDGE
    },
    {
      name: enums.NOTARIZATION_TYPE.JURAT,
      text: enums.NOTARIZATION_TYPE.JURAT
    },
    {
      name: enums.NOTARIZATION_TYPE.COPY_CERTIFICATION,
      text: enums.NOTARIZATION_TYPE.COPY_CERTIFICATION
    },
    {
      name: enums.NOTARIZATION_TYPE.SIGNATURE_WITNESSING,
      text: enums.NOTARIZATION_TYPE.SIGNATURE_WITNESSING
    },
    {
      name: enums.NOTARIZATION_TYPE.OATH_AFFIRMATION,
      text: enums.NOTARIZATION_TYPE.OATH_AFFIRMATION
    },
    {
      name: enums.NOTARIZATION_TYPE.OTHER,
      text: enums.NOTARIZATION_TYPE.OTHER
    }
  ];

  const dispatch = useDispatch();
  const setDocumentTypeData = (field, value) => {
    //logger.log('field', field, 'value', value);
    setLocalDocumentType(localDocumentType => ({
      ...localDocumentType,
      [field]: value
    }));
  };

  const saveDocumentType = d => {
    setSavingDocumentType(true);
    api.DocumentType.add(d.documentType, d.notarizationType, d.IsFavorite)
      .then(res => {
        segmentHelper.track(segmentHelper.events.DOCUMENT_TYPE_CREATED);
        setSavingDocumentType(false);
        onSuccess();
      })
      .catch(err => {
        setSavingDocumentType(false);
        dispatch(
          showAlert(
            'Error Deleting  documentt type',
            'There was a problem removing this l Document Type.  Please try again.'
          )
        );
        onFailure();
      });
  };

  const doValidation = () => {
    logger.log('localDocumentType', localDocumentType);
    let err = [];
    if (_.trim(localDocumentType.documentType).length === 0) {
      err.push('Please enter document Type name');
    } else if (localDocumentType.notarizationType.length === 0) {
      err.push('Please enter Notarization Type');
    } else if (
      localDocumentType.notarizationType === enums.DOCUMENT_TYPE.OTHER &&
      localDocumentType.otherNotarizationType.length === 0
    ) {
      err.push('Please enter Other Notarization Type');
    }

    if (localDocumentType.notarizationType === enums.DOCUMENT_TYPE.OTHER) {
      _.map(notarization_type_picker_opts, d => {
        if (
          _.lowerCase(d.name) ===
          _.lowerCase(_.trim(localDocumentType.otherNotarizationType))
        ) {
          err.push(
            'Other Notarization type is already exist on Notarization Type'
          );
        }
      });
    }

    if (err.length) {
      toast.info(err[0], {
        theme: 'colored',
        position: 'bottom-center',
        icon: faExclamationTriangle,
        toastId: 'document-type'
      });
    }
    return err.length === 0;
  };

  const validateAndSetDocumentTypeItem = () => {
    if (doValidation()) {
      logger.log('Sending address to prop', localDocumentType);
      saveDocumentType(localDocumentType);
    } else {
      logger.log('Not a Document Type');
    }
  };

  return (
    <>
      <Row className="g-2 mb-3">
        <Col sm={12}>
          <Form.Group className="mb-3" controlId="documentType">
            <Form.Label>Document Type</Form.Label>
            <Form.Control
              placeholder="Title of the new document type"
              value={localDocumentType.documentType}
              name="documentType"
              onChange={e => {
                setDocumentTypeData('documentType', e.target.value);
              }}
              type="text"
            />
          </Form.Group>
        </Col>
        <Col sm={12}>
          <Form.Group className="mb-3" controlId="notarizationType">
            <Form.Label>Notarization Type</Form.Label>
            <Form.Select
              type="text"
              value={localDocumentType.notarizationType}
              onChange={e =>
                setDocumentTypeData('notarizationType', e.target.value)
              }
            >
              <option value="">Select notarization type</option>
              {notarization_type_picker_opts.map(option => (
                <option value={option.name} key={option.name}>
                  {option.text}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>

        {localDocumentType.notarizationType ===
          enums.NOTARIZATION_TYPE.OTHER && (
          <Col sm={12}>
            <Form.Group className="mb-3" controlId="otherNotarizationType">
              <Form.Label>Notarization Type (Please Specify)</Form.Label>
              <Form.Control
                placeholder="Specify notarization type"
                value={localDocumentType.oth}
                name="otherNotarizationType"
                onChange={e => {
                  setDocumentTypeData('otherNotarizationType', e.target.value);
                }}
                type="text"
              />
            </Form.Group>
          </Col>
        )}

        <Col
          sm={12}
          className="d-flex justify-content-between align-items-center"
        >
          <Form.Label>Set as favorite</Form.Label>
          <Form.Check
            type="switch"
            id="IsFavorite"
            name="IsFavorite"
            className="pull-right"
            label=""
            size={'md'}
            onChange={e => setDocumentTypeData('IsFavorite', e.target.checked)}
          />
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <hr />
          <Flex justifyContent="center">
            <Button
              disabled={savingDocumentType}
              className="mx-1"
              variant="secondary"
              onClick={() => setShowDocumentTypesForm(false)}
            >
              Close
            </Button>
            {savingDocumentType ? (
              <Spinner variant="primary" size="sm" />
            ) : (
              <Button
                variant="primary"
                onClick={() => validateAndSetDocumentTypeItem()}
              >
                Save
              </Button>
            )}
          </Flex>
        </Col>
      </Row>
    </>
  );
};
DocumentTypesForm.propTypes = {
  saveDocumentType: PropTypes.func,
  setShowDocumentTypesForm: PropTypes.func.isRequired,
  savingDocumentType: PropTypes.bool
};
export default DocumentTypesForm;
