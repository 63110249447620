import {
  faBook,
  faCalendarAlt,
  faCheckCircle,
  faEdit,
  faExclamation,
  faExclamationCircle,
  faInfoCircle,
  faLocationArrow,
  faMapMarkedAlt,
  faSave,
  faStop,
  faStopCircle,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import IconAlert from 'components/common/IconAlert';
import draftEntryUiHelper from 'helpers/draft-entry-ui-helper';
import segmentHelper from 'helpers/segment-helper';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  InputGroup,
  ListGroup,
  Offcanvas,
  OverlayTrigger,
  Popover,
  Row,
  Spinner,
  Tooltip
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { refreshSavedLocation } from 'stores/app';
import { showAlert, showConfirm } from 'stores/modal';
import {
  actionLogSigningAction,
  actionSetLocalTimezone,
  actionSetLocationInfo,
  actionSetSigningTimestamp,
  actionSetUserProfileGUID
} from 'stores/signing';
import Colors from 'util/Colors';
import api from 'util/api';
import c from 'util/const';
import geolocationService from 'util/geolocation-svc';
import logger from 'util/logger';
import AddressForm from './comp/AddressForm';
import 'react-datepicker/dist/react-datepicker.css';
import userHelper from 'helpers/userHelper';
import { object } from 'is_js';
import _ from 'lodash';

const Step1 = ({ register, errors, setPreviousAddress }) => {
  const dispatch = useDispatch();
  const { location, signing_timestamp, localTimezone, userProfileGUID } =
    useSelector(state => state.signing);
  const { savedLocations, userProfile, userMaster } = useSelector(
    state => state.app
  );
  const [favoriteLocationsDisplay, setFavoriteLocationsDisplay] = useState([]);
  const [sortBy, setSortBy] = useState('Created');
  const [isAsc, setIsAsc] = useState(false);
  const [stateDiffNotice, setStateDiffNotice] = useState(false);

  const [showLocationCanvas, setShowLocationCanvas] = useState(false);
  const [startSavingFavourite, setStartSavingFavourite] = useState(0);
  const [signingTimestamp, setSigningTimestamp] = useState(null);
  const [localTimezoneVal, setLocalTimezoneVal] = useState(null);
  const [userProfileGUIDVal, setUserProfileGUIDVal] = useState(null);
  const [isDatePickerOpen, setDatePickerOpen] = useState(null);
  const [prevSigningTimestamp, setPrevSigningTimestamp] = useState(null);
  const [signingLocation, setSigningLoction] = useState({
    validationErr: [],
    address_1: location?.address_1 || '',
    address_2: location?.address_2 || '',
    city: location?.city || '',
    state: location?.state || '',
    zip: location?.zip || '',
    latitude: location?.latitude,
    longitude: location?.longitude,
    isDetectingLocation: false,
    isSavingLocation: false,
    initialAddressString: '',
    showingLocationNamePrompt: false,
    locationName: location ? location.locationName || null : null
  });

  const states = c.states;

  const clearNotarizationDate = () => {
    setSigningTimestamp(null);
    dispatch(actionLogSigningAction(`Notarization date & time removed.`));
  };

  const setSigningData = (field, value) => {
    setSigningLoction(signingLocation => ({
      ...signingLocation,
      [field]: value
    }));
  };

  const setSearchedAddress = address => {
    logger.log('Updating serach address', address);

    // setSigningLoction(signingLocation => ({
    //   ...signingLocation,
    //   address
    // }));

    setSigningLoction(signingLocation => ({
      ...signingLocation,
      address_1: address.address_1 || '',
      address_2: address.address_2 || '',
      city: address.city || '',
      state: address.state || '',
      zip: address.zip || ''
    }));
  };

  const setPreviousSigningTimestamp = () => {
    logger.log('SigningTimestamp Previos Date called. modal open');
    setDatePickerOpen(true);
    setPrevSigningTimestamp(signingTimestamp);
  };

  const logSigningSigningTimestamp = () => {
    setDatePickerOpen(false);
  };

  const hasSavableAddress = () => {
    let addr = _.trim(draftEntryUiHelper.getDisplayAddress(signingLocation));
    return addr && addr.length > 0;
  };

  const saveLocationClick = () => {
    dispatch(
      showConfirm(
        'Save location to favorite',
        <div>
          <p>Location name</p>
          <Form.Group className="mb-3">
            <Form.Control
              placeholder="Enter location name"
              value={location.locationName}
              name="locationName"
              // onChange={(evt) => { dispatch(actionSetLocationName(evt.target.value)); }}
              onChange={evt => {
                setSigningData('locationName', evt.target.value);
              }}
              type="text"
            />
          </Form.Group>
        </div>,
        res => {
          if (res) {
            setStartSavingFavourite(startSavingFavourite + 1);
          }
        }
      )
    );
  };

  const proceedToSaveLocation = () => {
    let { locationName, address_1, address_2, city, state, zip } =
      signingLocation;
    if (!locationName || locationName.length == 0) {
      toast.warning('Location name is required', {
        theme: 'colored',
        position: 'bottom-center',
        icon: faExclamation
      });
      return;
    }

    setSigningLoction(signingLocation => ({
      ...signingLocation,
      isSavingLocation: true
    }));
    //this.props.state.app.segmentClient.track('Favorite Location Saved');
    api.SavedLocations.add(locationName, address_1, address_2, city, state, zip)
      .then(res => {
        setSigningLoction(signingLocation => ({
          ...signingLocation,
          isSavingLocation: false
        }));
        //logger.log('saved location created', res);
        return dispatch(refreshSavedLocation());
      })
      .then(() => {
        segmentHelper.track(
          segmentHelper.events.SIGNING_FAVORITE_LOCATION_SAVED
        );
        toast.success('Favorite Location Saved', {
          theme: 'colored',
          position: 'bottom-center',
          icon: faCheckCircle,
          toastId: 'signing'
        });
      })
      .catch(err => {
        setSigningLoction(signingLocation => ({
          ...signingLocation,
          isSavingLocation: false
        }));
        if (apiHelper.isConnectionError(err)) {
          dispatch(
            showAlert(
              'Connection error',
              'Oops.. we are unable to connect server. Check internet connection'
            )
          );
        } else {
          dispatch(
            showAlert(
              'Unable to Save Location',
              'There was a problem saving your Location.  Please try again.'
            )
          );
        }
      });
  };

  const detectLocation = () => {
    logger.log('detectLocation was clicked');
    segmentHelper.track(
      segmentHelper.events.SIGNING_DETECT_TO_CURRENT_LOCATION_CLICK
    );
    setSigningLoction(signingLocation => ({
      ...signingLocation,
      isDetectingLocation: true
    }));
    let coords = {};
    //this.props.state.app.segmentClient.track('Detect current location clicked');
    try {
      geolocationService
        .detectCoords()
        .then(res => {
          logger.log('geolocationService res', res);

          //logger.log('geolocationService detectCoords res', res);
          if (res.latitude) {
            coords.latitude = res.latitude;
          }
          if (res.longitude) {
            coords.longitude = res.longitude;
          }
          return api.Location.lookup(res.latitude, res.longitude);
        })
        .then(res => {
          logger.log('geolocationService location lookup res', res);

          setSigningLoction(signingLocation => ({
            ...signingLocation,
            isDetectingLocation: false
          }));
          logger.log('Address lookup', res);
          populateStateFromAddress(res);
        })
        .catch(err => {
          setSigningLoction(signingLocation => ({
            ...signingLocation,
            isDetectingLocation: false
          }));
          logger.log('error detecting coords', err);
        })
        .finally(err => {
          logger.log('finally detecting coords', err);
          setSigningLoction(signingLocation => ({
            ...signingLocation,
            isDetectingLocation: false
          }));
        });
    } catch (err) {
      setSigningLoction(signingLocation => ({
        ...signingLocation,
        isDetectingLocation: false
      }));
      toast.warning('Google Geo Location service is not available', {
        theme: 'colored',
        position: 'bottom-center',
        icon: faExclamationCircle,
        toastId: 'signing'
      });
      logger.log('Geolocation is not working with this domain', err);
    }
  };

  const populateStateFromAddress = res => {
    setSigningLoction(signingLocation => ({
      ...signingLocation,
      address_1:
        (res.StreetNumber ? res.StreetNumber : '') +
        (res.StreetName ? ' ' + res.StreetName : ''),
      city: res.City,
      state: res.StateAbbreviated,
      zip: res.Postal,
      longitude: res.Latitude,
      latitude: res.Latitude
    }));

    let locationDetected = draftEntryUiHelper.getDisplayAddress({
      address_1:
        (res.StreetNumber ? res.StreetNumber : '') +
        (res.StreetName ? ' ' + res.StreetName : ''),
      city: res.City,
      state: res.StateAbbreviated,
      zip: res.Postal,
      longitude: res.Latitude,
      latitude: res.Latitude
    });
    if (_.trim(locationDetected) != '')
      dispatch(
        actionLogSigningAction(`Location detected as ${locationDetected}.`)
      );
  };

  const useSavedLocation = loc => {
    setSigningLoction(signingLocation => ({
      ...signingLocation,
      address_1: loc.Address1 || '',
      address_2: loc.Address2 || '',
      city: loc.City || '',
      state: loc.State || '',
      zip: loc.PostalCode || ''
    }));

    dispatch(
      actionLogSigningAction(
        `Location changed from search to ${draftEntryUiHelper.getDisplayAddress(
          signingLocation
        )}.`
      )
    );
    segmentHelper.track(segmentHelper.events.SIGNING_SAVED_LOCATION_LOADED);
  };

  const searchItems = e => {
    let keyword = e.target.value.toLowerCase();
    let filterFavoriteLocations = _.filter(
      savedLocations,
      d =>
        d.DisplayName.toLowerCase().includes(keyword) ||
        d.Address1.toLowerCase().includes(keyword) ||
        d.PostalCode.toLowerCase().includes(keyword) ||
        moment.utc(d.Created).local().format('l, LT').includes(keyword)
    );
    setFavoriteLocationsDisplay(filterFavoriteLocations);
  };

  useEffect(() => {
    setFavoriteLocationsDisplay(savedLocations);
  }, [savedLocations]);

  useEffect(() => {
    let sortedFavoriteLocations = _.orderBy(
      savedLocations,
      [sortBy],
      [isAsc ? 'asc' : 'desc']
    );
    setFavoriteLocationsDisplay(sortedFavoriteLocations);
  }, [sortBy, isAsc]);

  useEffect(() => {
    // && moment(signing_timestamp, 'MM/DD/YYYY hh:mm a', true).isValid()
    logger.log('setting signing_timestamp', signing_timestamp);
    if (signing_timestamp != null) {
      //let nt = JSON.parse(signing_timestamp);
      //logger.log('setting 2 signing_timestamp nt', signing_timestamp);
      //logger.log('Stored time ', moment(nt).format('MM/DD/YYYY hh:mm a'), nt);
      setPreviousAddress(location);
      setSigningTimestamp(
        signing_timestamp ? new Date(signing_timestamp) : null
      );
      setLocalTimezoneVal(localTimezone);
      setUserProfileGUIDVal(userProfileGUID);
    } else {
      let selectedCommition = userProfile?.find(item => item.IsDefault);
      if (!selectedCommition && !selectedCommition?.GIUD) {
        selectedCommition = _.first(userProfile)?.GUID;
      }
      setUserProfileGUIDVal(selectedCommition?.GUID);
    }
  }, []);

  useEffect(() => {
    if (
      location?.state &&
      userProfileGUID &&
      location?.state !==
        _.find(userProfile, p => p.GUID === userProfileGUID)?.CommissionState
    ) {
      setStateDiffNotice(true);
    } else {
      setStateDiffNotice(false);
    }
  }, [location?.state, userProfileGUID]);

  useEffect(() => {
    setLocalTimezoneVal(
      _.find(userProfile, p => p.GUID === userProfileGUID)?.LocalTimezone
    );
  }, [userProfileGUID]);

  // useEffect(() => {
  //   if (signingLocation.longitude)
  //     dispatch(
  //       actionLogSigningAction(
  //         `Location detected as ${draftEntryUiHelper.getDisplayAddress(
  //           signingLocation
  //         )}`
  //       )
  //     );
  // }, [signingLocation.longitude]);

  useEffect(() => {
    //logger.log('Updating location stage', signingLocation);
    dispatch(actionSetLocationInfo(signingLocation));
  }, [signingLocation]);

  useEffect(() => {
    if (startSavingFavourite) {
      proceedToSaveLocation();
    }
  }, [startSavingFavourite]);

  useEffect(() => {
    if (
      !isDatePickerOpen &&
      signingTimestamp &&
      prevSigningTimestamp !== signingTimestamp
    ) {
      dispatch(
        actionSetSigningTimestamp(moment(signingTimestamp).format())
      ).then(() => {
        dispatch(
          actionLogSigningAction(
            `Notarization date & time added ${draftEntryUiHelper
              .getDateTimeWithTimeZone(moment(signingTimestamp).local())

              .format(c.dateFormat)}.`
          )
        );
      });
    }
  }, [isDatePickerOpen]);
  useEffect(() => {
    if (localTimezoneVal) {
      dispatch(actionSetLocalTimezone(localTimezoneVal)).then(() => {
        dispatch(
          actionLogSigningAction(
            `Local timezone set to ${
              userHelper.getTimezoneObj(localTimezoneVal)?.zone
            }.`
          )
        );
      });
    } else if (localTimezone && !localTimezoneVal) {
      dispatch(actionLogSigningAction(`Local timezone removed.`));
    }
  }, [localTimezoneVal]);

  useEffect(() => {
    if (userProfileGUIDVal) {
      let commProfile = _.find(userProfile, p => p.GUID == userProfileGUIDVal);
      dispatch(actionSetUserProfileGUID(userProfileGUIDVal)).then(() => {
        dispatch(
          actionLogSigningAction(
            `Commission profile set to ${commProfile?.CommissionedName} (${commProfile?.CommissionNumber}).`
          )
        );
      });
    } else if (userProfileGUIDVal && !userProfileGUID) {
      dispatch(actionLogSigningAction(`Commission profile removed.`));
    }
  }, [userProfileGUIDVal]);

  const signingTimestampPopver = (
    <Popover id="notarization-date-time">
      <Popover.Header as="h3">Notarization Date & Time</Popover.Header>
      <Popover.Body>
        Detect your current Location, or Load a Favorite. You can also search
        for one or enter it manually below. If you save what you've entered as a
        Favorite, you can access it again from any device.
      </Popover.Body>
    </Popover>
  );

  const loadvailableLocation = () => {
    setShowLocationCanvas(true);
  };

  const selectSavedLocation = loc => {
    let selectedLcation = _.filter(savedLocations, l => {
      return l.GUID === loc;
    });
    //logger.log(selectedLcation);
    useSavedLocation(selectedLcation[0]);
    setShowLocationCanvas(false);
  };

  const removeItemClick = guid => {
    dispatch(
      showConfirm(
        'Are you sure?',
        <div>
          <p>Are you sure you want to delete this location?</p>
        </div>,
        res => {
          if (res) {
            api.SavedLocations.remove(guid)
              .then(res => {
                toast.success('Location deleted successfully', {
                  theme: 'colored',
                  position: 'bottom-center',
                  icon: faCheckCircle
                });
                return dispatch(refreshSavedLocation());
              })
              .catch(err => {
                //logger.log('error removing saved location', err);
                if (apiHelper.isConnectionError(err)) {
                } else {
                  dispatch(
                    showAlert(
                      'Unable to Remove Saved Location',
                      'There was a problem removing your saved location.  Please try again.'
                    )
                  );
                }
              });
          }
        }
      )
    );
  };

  const LocationViewCanvas = ({ savedLocations, ...props }) => {
    //logger.log("savedLocations", savedLocations);
    return <></>;
  };

  return (
    <>
      {/* <h5 className="text-center">Notarization Date & Time & Address</h5> */}
      <Row>
        <Col sm={12}>
          <IconAlert variant="info">
            <p className="mb-0">
              Detect your current Location, or Load a Favorite. You can also
              search for one or enter it manually below. If you save what you've
              entered as a Favorite, you can access it again from any device.
            </p>
          </IconAlert>
        </Col>
      </Row>
      <hr className="my-0" />
      <Row className="g-2 my-3">
        <Col sm={4}>
          <Flex
            justifyContent="center"
            className="bg-soft-primary py-3 rounded border"
          >
            <Button
              variant="primary"
              disabled={signingLocation.isDetectingLocation}
              onClick={() => detectLocation()}
            >
              {signingLocation.isDetectingLocation ? (
                <Spinner size="sm" />
              ) : (
                <FontAwesomeIcon icon={faMapMarkedAlt} />
              )}{' '}
              {signingLocation.isDetectingLocation
                ? 'Detecting'
                : 'Detect current location'}
            </Button>
            {signingLocation.isDetectingLocation && (
              <Button
                className="ms-2"
                onClick={() => {
                  setSigningLoction(signingLocation => ({
                    ...signingLocation,
                    isDetectingLocation: false
                  }));
                }}
                variant="outline-danger"
              >
                <FontAwesomeIcon icon={faStop} />
              </Button>
            )}
          </Flex>
        </Col>
        <Col sm={4}>
          <Flex
            justifyContent="center"
            className="bg-soft-primary py-3 rounded border"
          >
            <Button
              variant="primary"
              type="button"
              onClick={() => loadvailableLocation()}
            >
              <FontAwesomeIcon icon={faBook} /> Load saved location
            </Button>
          </Flex>
        </Col>
        <Col sm={4}>
          <Flex
            justifyContent="center"
            className="bg-soft-primary py-3 rounded border"
          >
            <Button
              variant="primary"
              disabled={
                signingLocation.isDetectingLocation ||
                signingLocation.isSavingLocation ||
                !hasSavableAddress()
              }
              onClick={() => saveLocationClick()}
            >
              {' '}
              {signingLocation.isSavingLocation ? (
                <Spinner size="sm" />
              ) : (
                <FontAwesomeIcon icon={faSave} />
              )}{' '}
              {signingLocation.isSavingLocation
                ? 'Saving'
                : 'Save current location'}
            </Button>
          </Flex>
        </Col>
      </Row>

      <Row className="g-2 mb-3">
        <Col sm={4}>
          <Flex alignItems="end">
            <Form.Group>
              <Form.Label>Notarization Date & Time*</Form.Label>

              <DatePicker
                showTimeSelect
                selected={signingTimestamp ? signingTimestamp : null}
                onChange={date => setSigningTimestamp(date)}
                dateFormat="MM/dd/yyyy hh:mm a"
                className="form-control"
                placeholderText="Select Date & Time"
                timeIntervals={5}
                onCalendarClose={logSigningSigningTimestamp}
                onCalendarOpen={setPreviousSigningTimestamp}
              />
            </Form.Group>

            <Button variant="outline-primary" className="mx-1">
              <FontAwesomeIcon
                icon={faTrashAlt}
                onClick={() => clearNotarizationDate()}
              />
            </Button>
            {/* <OverlayTrigger
              trigger="click"
              placement="top"
              overlay={SigningTimestampPopver}
              delay={500}
            >
              <Button variant="outline-info">
                <FontAwesomeIcon icon={faInfoCircle} />
              </Button>
            </OverlayTrigger> */}
          </Flex>
        </Col>

        <Col sm={4}>
          <Form.Group as={Col} lg={12} controlId="LocalTimezone">
            <Form.Label>
              Commission profile{' '}
              {userProfileGUID && (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      {stateDiffNotice
                        ? "The state entered in the location field does not match the selected commission profile's state. You can still proceed with this entry, but please check to make sure you have the correct commission profile selected"
                        : "The selected commission profile's state matches the location selected below"}
                    </Tooltip>
                  }
                >
                  <span>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className={
                        stateDiffNotice ? 'text-warning' : 'text-success'
                      }
                    />
                  </span>
                </OverlayTrigger>
              )}
            </Form.Label>
            <Form.Select
              type="text"
              name="LocalTimezone"
              onChange={e => setUserProfileGUIDVal(e.target.value)}
              defaultValue={userProfileGUID}
              value={userProfileGUIDVal}
            >
              <option value="">Select commission profile</option>
              {userProfile &&
                userProfile.map(option => (
                  <option value={option.GUID} key={option.GUID}>
                    {option.CommissionedName} {option.CommissionNumber} /
                    {userHelper.getTimezoneObj(option.LocalTimezone)?.name}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
        </Col>

        <Col sm={4}>
          <Form.Group as={Col} lg={12} controlId="LocalTimezone">
            <Form.Label>Timezone</Form.Label>
            <Form.Select
              type="text"
              name="LocalTimezone"
              onChange={e => setLocalTimezoneVal(e.target.value)}
              defaultValue={localTimezone || userMaster?.localTimezone}
              value={localTimezoneVal}
            >
              <option value="">Select timezone</option>
              {c.timezones.map(option => (
                <option value={option.offset} key={option.abbreviation}>
                  {option.name} ({option.offset})
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      {stateDiffNotice && (
        <Row>
          <Col sm={12}>
            <IconAlert variant="warning">
              <p className="mb-0">
                The state entered in the location field does not match the
                selected commission profile's state. You can still proceed with
                this entry, but please check to make sure you have the correct
                commission profile selected
              </p>
            </IconAlert>
          </Col>
        </Row>
      )}

      <AddressForm
        address={signingLocation}
        setAddressValue={setSigningData}
        setSearchedAddress={setSearchedAddress}
        register={register}
        errors={errors}
      />

      <div className="py-0 my-0 fs--2 text-primary w-100">
        <b>Note:</b> All * input fields are required.
      </div>
      <Offcanvas
        show={showLocationCanvas}
        backdrop="static"
        enforceFocus={false}
        onHide={() => setShowLocationCanvas(false)}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Saved Favorite Location</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Flex className={'g-2'}>
            <div className="mb-2">
              <Form as={Row} className="gx-2 d-flex align-items-center">
                <Col xs="auto">
                  <InputGroup>
                    <Form.Select
                      className="pe-5"
                      defaultValue="Created"
                      onChange={({ target }) => setSortBy(target.value)}
                    >
                      <option value="DisplayName">Name</option>
                      <option value="IsFavorite">Favorite</option>
                      <option value="Created">Created</option>
                    </Form.Select>
                    <InputGroup.Text
                      as={Button}
                      variant="link"
                      className="border border-300 text-700"
                      onClick={() => setIsAsc(!isAsc)}
                    >
                      <FontAwesomeIcon
                        icon={isAsc ? 'sort-amount-up' : 'sort-amount-down'}
                      />
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
              </Form>
            </div>
            <div className="mx-1">
              <Form.Control
                onChange={e => searchItems(e)}
                placeholder="search location..."
                name="search"
              ></Form.Control>
            </div>
          </Flex>
          {favoriteLocationsDisplay?.length > 0 ? (
            <ListGroup>
              {favoriteLocationsDisplay.map((item, index) => (
                <ListGroup.Item
                  className="cursor-pointer"
                  key={item.GUID}
                  onClick={() => selectSavedLocation(item.GUID)}
                >
                  <Flex justifyContent="between" alignItems="center">
                    <h6>{item.DisplayName}</h6>
                    <div>
                      <FontAwesomeIcon
                        color={Colors.danger.color}
                        className="ms-1"
                        size="sm"
                        icon={faTrashAlt}
                        onClick={() => removeItemClick(item.GUID)}
                      />
                    </div>
                  </Flex>
                  <p className="mb-1">
                    {item.Address1 ? item.Address1 + ', ' : ''}{' '}
                    {item.City ? item.City + ', ' : ''} {item.State} -{' '}
                    {item.PostalCode}
                  </p>
                  <p className="my-0 fs--2 text-muted">
                    <FontAwesomeIcon icon={faCalendarAlt} />{' '}
                    {moment(item.Created).utc().format('MM/DD/YYYY HH:mm A')}
                  </p>
                </ListGroup.Item>
              ))}
            </ListGroup>
          ) : (
            <div className="text-center">
              <FontAwesomeIcon icon={faLocationArrow} size="3x" />
              <br />
              <p className="my-3">
                You don't have any favorite location yet. <br />
                Click "Add new" to add a location to your favourite. We
                recommend adding your office or any regular place of business
                where you normally meet with clients
              </p>
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

Step1.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default Step1;
