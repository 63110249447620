import CryptoJS from 'crypto-js';
import c from './const';
const SECRET_KEY = c.juratCryptoKey;
const juratCrypto = {
  encrypt(data) {
    return data
      ? CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString()
      : data;
  },
  decrypt(data) {
    return data
      ? CryptoJS.AES.decrypt(data, SECRET_KEY).toString(CryptoJS.enc.Utf8)
      : data;
  },
  base64Encode(data) {
    return data
      ? CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(data))
      : data;
  },
  base64Decode(data) {
    return data
      ? CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(data))
      : data;
  }
};
export default juratCrypto;
