import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { getColor, rgbaColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';

import {
  GridComponent,
  TooltipComponent,
  TitleComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import BasicECharts from 'components/common/BasicEChart';
import { progressPercentStatus } from 'data/common/common';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer
]);

const getOptions = (data, keys) => ({
  tooltip: {
    trigger: 'axis',
    formatter: '<strong>{b0}</strong> : {c0}'
  },
  xAxis: {
    data: keys
  },
  series: [
    {
      type: 'bar',
      showBackground: true,
      backgroundStyle: {
        borderRadius: 10
      },
      barWidth: '5px',
      itemStyle: {
        borderRadius: 10,
        color: getColor('primary')
      },
      data,
      z: 10
    }
  ],
  grid: {
    bottom: '2%',
    top: '2%',
    right: '10px',
    left: '10px'
  }
});

const DocumentTypes = ({ data }) => {
  let dataValues = _.values(data);
  let dataKeys = _.keys(data);
  return (
    <Card className="h-md-100">
      <Card.Header className="pb-0">
        <h6 className="mb-0 mt-2">Document Types</h6>
      </Card.Header>

      <Card.Body
        as={Flex}
        alignItems="end"
        justifyContent="between"
        className="pt-0"
      >
        <div>
          <h2 className="fw-normal text-700 mb-1 lh-1">{_.sum(dataValues)}</h2>
          {/* <Badge pill bg="200" className="text-primary fs--2">
            <FontAwesomeIcon icon="caret-up" className="me-1" />
            13.6%
          </Badge> */}
          {progressPercentStatus(dataValues)}
        </div>
        <div className="ps-0">
          <BasicECharts
            echarts={echarts}
            options={getOptions(dataValues, dataKeys)}
            style={{ width: '8.5rem', height: 90 }}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

DocumentTypes.propTypes = { data: PropTypes.any.isRequired };

export default DocumentTypes;
