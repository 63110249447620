import {
  faAddressBook,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronUp,
  faEdit,
  faExclamationCircle,
  faFileAlt,
  faFileContract,
  faFolderOpen,
  faInfoCircle,
  faPlusCircle,
  faReply,
  faTrashAlt,
  faUpload,
  faUserAlt,
  faUserCheck
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import ValidationErrors from 'components/utilities/ValidationErrors';
import draftEntryHelper from 'helpers/draft-entry-helper';
import draftEntryUiHelper from 'helpers/draft-entry-ui-helper';
import segmentHelper from 'helpers/segment-helper';
import { currencyCodeToSign } from 'helpers/utils';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Collapse,
  Form,
  ListGroup,
  OverlayTrigger,
  Popover,
  Row,
  Spinner
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  actionSetDraftSaveError,
  actionSetDraftSaveStatus,
  refreshAllAccountInfo,
  refreshUserMaster,
  removeDraftFromServer
} from 'stores/app';
import { showAlert, showConfirm, showConnectionError } from 'stores/modal';
import { actionAddNotification } from 'stores/notification';
import {
  actionAddRecipientEmail,
  actionLogSigningAction,
  actionRemoveRecipientEmail,
  actionReset,
  actionSetSigningFinished,
  actionSetSigningUnFinished
} from 'stores/signing';
import Colors from 'util/Colors';
import api from 'util/api';
import apiHelper from 'util/apiHelper';
import c from 'util/const';
import errorReportHelper from 'util/error-report-helper';
import filters from 'util/filters';
import logger from 'util/logger';
import utils from 'util/utils';
import { validateEmail } from 'util/validation';
import config from 'util/config';
const Step6 = ({
  reset,
  setStep,
  saveSigningToDraft,
  setSavingDraft,
  isCompleted,
  setIsCompleted
}) => {
  const [signingStatus, setSigningStatus] = useState();
  const dispatch = useDispatch();
  const { userMaster } = useSelector(state => state.app);
  const { signing } = useSelector(state => state);
  const [currentDraftId, setCurrentDraftId] = useState(null);
  const [openTimeline, setOpenTimeline] = useState(false);
  const [openAuditLog, setOpenAuditlog] = useState(false);

  const { deviceGUID } = useSelector(state => state.authentication);
  const {
    signers,
    witnesses,
    identifications,
    signatures,
    fingerprints,
    draft_id,
    recipientEmails,
    notarization_details,
    notarization_documents,
    hasSigningBeenFinished,
    completedAtDate,
    startedAtDate,
    auditLog
  } = useSelector(state => state.signing);

  const navigate = useNavigate();

  const [timelineData, setTimelineData] = useState([]);
  const [savingContactInfo, setSavingContactInfo] = useState(false);
  const [isSavingSigning, setSavingSigning] = useState(false);
  const [checkingCurrentStatus, setCheckingCurrentStatus] = useState(false);

  const { subscriptionStatus } = useSelector(state => state.subscription);
  let activeSubscriptions = _.get(subscriptionStatus, 'Active', []);

  const [contactData, setContactData] = useState({
    recipientEmail: recipientEmails,
    contactInfo: null
  });

  const layoutView = {
    REVIEW: 'REVIEW',
    DETAIL: 'DETAIL',
    CONTACT: 'CONTACT',
    COMPLETE: 'COMPLETE',
    UPLOAD: 'UPLOAD'
  };

  const handleContactData = e => {
    setContactData({
      ...contactData,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    if (!!completedAtDate || isCompleted) {
      setIsCompleted(true);
      setSigningStatus(layoutView.UPLOAD);
    }
  }, [completedAtDate]);

  useEffect(() => {
    logger.log('signing', signing);
  }, [signing]);

  useEffect(() => {
    if (!isCompleted) {
      setSigningStatus(layoutView.DETAIL);
    } else {
      setSigningStatus(layoutView.UPLOAD);
    }
    setTimelineData(draftEntryUiHelper.parseAuditLogToTimeline(auditLog));
    let defaultInfo =
      userMaster.FirstName +
      ' ' +
      userMaster.LastName +
      ' ' +
      (userMaster.Telephone?.length > 0 ? userMaster.Telephone : '') +
      ' ' +
      userMaster.Email;

    setContactData(contactData => ({
      ...contactData,
      contactInfo: userMaster.ContactInformation || defaultInfo
    }));
    setCurrentDraftId(draft_id);
    logger.log('signingStatus', signingStatus);
  }, []);

  const sveContactInfo = () => {
    setSavingContactInfo(true);
    api.UserMaster.changeContactInformation(
      userMaster.GUID,
      contactData.contactInfo
    )
      .then(res => {
        //logger.log('change signature success', res);
        return dispatch(refreshUserMaster());
      })
      .then(() => {
        setSavingContactInfo(false);
        toast.success('Contact information saved!', {
          theme: 'colored',
          position: 'bottom-center',
          icon: faUserCheck,
          toastId: 'receipient-email',
          hideProgressBar: true
        });

        setSigningStatus(layoutView.REVIEW);
      })
      .catch(err => {
        setSavingContactInfo(false);
        if (apiHelper.isConnectionError(err)) {
          dispatch(showConnectionError());
        } else {
          dispatch(
            showAlert(
              'Unable to change contact information',
              'There was a problem changing your contact information.  Please try again.'
            )
          );
        }
      });
  };

  const markSigningFinished = completedDate => {
    return new Promise((resolve, reject) => {
      dispatch(
        actionSetSigningFinished({
          completedAtDate: completedDate,
          type: c.actions.app.actionSetSigningFinished
        })
      );
      setTimeout(() => resolve(true), 200);
    });
  };

  // const doFinishCreateEntry = res => {
  //   //logger.log('doFinishCreateEntry', res);
  //   Promise.all([dispatch(refreshAllAccountInfo())]).then(res => {});
  //   removeSavedDraft()
  //     .then(() => {})
  //     .catch(err => {
  //       //logger.log('remove draft error', err);
  //     })
  //     .finally(res => {
  //       setSavingSigning(false);
  //       dispatch(actionReset());
  //       toast.success('Signing saved successfully', {
  //         theme: 'colored',
  //         position: 'bottom-center',
  //         icon: faUserCheck,
  //         toastId: 'signing-saved-success'
  //       });
  //       navigate('/notary/signing/success', {
  //         replace: true,
  //         state: res
  //       });
  //     });
  // };

  const doFinishCreateEntry = res => {
    Promise.all([dispatch(refreshAllAccountInfo())]).then(res => {});
    removeSavedDraft()
      .then(() => {})
      .catch(err => {
        //logger.log('remove draft error', err);
      })
      .finally(res => {
        setSavingSigning(false);
        dispatch(actionReset());
        toast.success('Signing saved successfully', {
          theme: 'colored',
          position: 'bottom-center',
          icon: faUserCheck,
          toastId: 'signing-saved-success'
        });
        navigate('/notary/signing/success', {
          replace: true,
          state: res
        });
      });
  };

  const reviewClick = () => {
    //this.props.newEntry.logSigningActionTaken(`Review button pressed.`);
    dispatch(
      showConfirm(
        'Confirm to complete',
        "Are you sure you want to mark this entry as complete and upload it? Once an entry marked as complete, it can't modified",
        res => {
          dispatch(actionLogSigningAction(`Review button pressed.`));
          //logger.log('review prompt result', res);
          if (res) {
            markDraftAsCompleteIfNot().then(() => {
              setTimeout(() => {
                finishClick();
              }, 100);
            });
          }
        }
      )
    );
  };

  const markDraftAsCompleteIfNot = () => {
    return new Promise((resolve, reject) => {
      fetchDraft()
        .then(draft => {
          let completedAtDate = null;
          if (draft) {
            let draftComplete = draft.getCompletedAtDate();
            if (draftComplete) {
              completedAtDate = moment(draftComplete);
            }
          }
          if (!completedAtDate) {
            return saveDraftEntryAsComplete(moment().format());
          }
        })
        .finally(() => {
          resolve(true);
        });
    });
  };

  const saveDraftEntryAsComplete = completedDate => {
    return new Promise((resolve, reject) => {
      //this.props.newEntry.logSigningActionTaken(`Signing Marked Complete.`);
      return markEntryAsCompleted(completedDate).then(() => {
        //wait a sec for things to save.  Should make this more obvious.
        dispatch(actionLogSigningAction(`Signing Marked Complete.`));
        resolve(utils.waitFor(100));
      });
    });
  };

  const markEntryAsCompleted = completedDate => {
    return new Promise((resolve, reject) => {
      setSavingDraft(true);
      markSigningFinished(completedDate).then(() => {
        //dispatch(appActions.setDraftSaveStatus(c.draftStatus.Saving, null));
        //logger.log('markEntryAsCompleted');
        //This timeout is kind of a hack
        //It's just trying to make sure that it catches the latest version of the model.
        setTimeout(() => {
          if (!userMaster.GUID || !signing.draft_id) {
            logger.warn(
              'error pulling needed draft data for state saving',
              signing,
              userMaster
            );
            // dispatch(
            //   appActions.setDraftSaveStatus(c.draftStatus.Error, 'No Draft ID'),
            // );
            return reject('no draft id supplied');
          }

          //logger.log('markEntryAsCompleted calling getDraft');
          //The only reason we need to pull this draft is so we can properly
          //set the created date, since it gets overwritten during save.  Maybe this should be reworked.
          return draftEntryHelper
            .getDraft(userMaster.GUID, signing.draft_id)
            .then(res => {
              logger.log('found existing draft to update', res);

              if (!res) {
                logger.warn(
                  'unable to look up draft',
                  userMaster.GUID,
                  signing.draft_id
                );
                return Promise.reject('unable to look up draft');
              }

              let draftMetadata = {
                created: res.getCreatedDate(),
                completed_at: completedDate,
                last_updated: completedDate,
                entry_step: c.entrySteps.STEP_6
              };
              logger.log('Updating Draft as ', draftMetadata);
              return draftEntryHelper
                .saveUpdateDraft(
                  userMaster.GUID,
                  draft_id,
                  draftMetadata,
                  signing
                )
                .then(() => {
                  resolve(true);
                });
            })
            .then(res => {
              //logger.log('save draft result', res);
              // dispatch(
              //   appActions.setDraftSaveStatus(c.draftStatus.Saved, null),
              // );
              setSavingDraft(false);
              resolve(res);
            })
            .catch(err => {
              logger.warn('error pulling draft info', err);
              setSavingDraft(false);
              //dispatch(appActions.setDraftSaveStatus(c.draftStatus.Error, err));
              reject(err);
            });
        }, 250);
      });
    });
  };

  const fetchDraft = () => {
    return new Promise((resolve, reject) => {
      draftEntryHelper
        .getDraft(userMaster.GUID, signing.draft_id)
        .then(draft => {
          //logger.log('got draft meta', draft);
          resolve(draft);
        })
        .catch(err => {
          //logger.log('error getting draft metadata', err);
          resolve(null);
        });
    });
  };

  const handleConnectionErrorForNewEntry = () => {
    let { draft_id } = signing;
    if (draft_id) {
      dispatch(
        showAlert(
          'Unable to Connect',
          'We were unable to connect to our servers in order to upload your new signing.  It has been marked as complete and saved as a draft, so you can try again later.  To avoid data loss, we recommend uploading this draft to our servers as soon as possible.'
        )
      );
      setSavingSigning(false);
    } else {
      dispatch(
        showConfirm(
          'Unable to Connect',
          'We were unable to connect to our servers in order to upload your new entry, and we are unable to access your storage to save as a draft.  Please try again to avoid data loss.'
        )
      );
      setSavingSigning(false);
    }
  };

  const hasUnlinkedSigner = () => {
    if (signers?.length > 0) {
      let allSignersIds = _.map(signers, 'signer_id');
      let usedSignersIds = _.flatten(
        _.map(notarization_documents, 'signer_ids')
      );
      logger.log('allSignersIds', allSignersIds);
      logger.log('usedSignersIds', usedSignersIds);
      let unUsedSignersIds = _.difference(allSignersIds, usedSignersIds);
      logger.log('unUsedSignersIds', unUsedSignersIds);
      if (unUsedSignersIds.length > 0) {
        let message =
          'You have added a signer to this entry who is not associated with any documents. Please link any unlinked signer(s) to at least 1 document or remove the unlinked signer(s) from this entry to continue. \n';
        {
          let i = 1;
          _.map(signers, s => {
            if (_.includes(unUsedSignersIds, s.signer_id)) {
              message +=
                '\n' +
                i++ +
                '. ' +
                s.first_name +
                ' ' +
                s.middle_name +
                ' ' +
                s.last_name;
            }
          });
        }
        logger.log('message', message);
        dispatch(showAlert('Unlinked Signer(s) Found', message));
        return true;
      } else {
        return false;
      }
    }
  };
  const confirmLastStep = () => {
    if (!hasUnlinkedSigner()) {
      logger.log('Set review');
      setSigningStatus(layoutView.REVIEW);
    }
  };

  // const finishClick = () => {
  //   setCheckingCurrentStatus(true);
  //   dispatch(refreshCurrentSubscriptionStatus())
  //     .then(res => {
  //       setCheckingCurrentStatus(false);
  //       if (activeSubscriptions.length == 0) {
  //         proceedToUpload();
  //       } else {
  //         dispatch(
  //           showAlert(
  //             'Limit exceeded or subscription expired',
  //             'Your trial period has ended because it has been ' + c.actions.app.maxTrialDays + ' days since you created your account or you have already created ' + c.actions.app.maxTrialSignings + ' entries. You can no longer save new entries in the mobile app or on the website. Upgrade to a monthly or yearly plan to continue using The Notary eJournal.der to upload your new entry, Either you have exceeded your maximum trial signings or your subscription is not active.'
  //           )
  //         );
  //       }
  //     })
  //     .catch(err => {
  //       setCheckingCurrentStatus(false);
  //     });
  // };

  const finishClick = () => {
    if (isSavingSigning) {
      return;
    }
    setSigningStatus(layoutView.UPLOAD);
    setSavingSigning(true);
    fetchDraft().then(draft => {
      let createdDate = null;
      let completedAtDate = null;
      logger.log('Got a draft to save', draft);

      if (draft) {
        let draftComplete = draft.draft_metadata.completed_at;
        let draftCreated = draft.draft_metadata.created;
        if (draftComplete) {
          completedAtDate = moment(draftComplete);
        }
        if (draftCreated) {
          createdDate = moment(draftCreated);
        }
      }
      //If you don't have a completed date set, set one, and submit.
      //If submit fails, then we need to mark the draft as finished,
      //and save the finished date in the draft metadata.
      if (!completedAtDate) {
        //logger.log('set completed date on failed upload is true', draft);
        completedAtDate = moment();
      }

      //If it's not set in the draft, try it from the entry itself.
      //I'm not sure when this would be necessary except in some problem saving drafts.
      if (!createdDate && startedAtDate) {
        createdDate = moment(startedAtDate);
      }

      api.SigningDraft.finalise(
        draftEntryHelper.getDarftKeyFromId(signing?.draft_id),
        signing.requestGUID,
        userMaster.GUID,
        deviceGUID,
        createdDate,
        completedAtDate,
        signing.signers,
        signing.location,
        signing.notarization_details,
        signing.notarization_documents,
        [],
        signing.fingerprints,
        signing.identifications,
        [],
        signing.signatures,
        signing.witnesses,
        signing.recipientEmails,
        signing.auditLog,
        signing.signing_timestamp,
        signing.localTimezone,
        signing.userProfileGUID
      )

        // api.Signing2.create(
        //     signing.requestGUID,
        //     userMaster.GUID,
        //     deviceGUID,
        //     createdDate,
        //     completedAtDate,
        //     signing.signers,
        //     signing.location,
        //     signing.notarization_details,
        //     signing.notarization_documents,
        //     [],
        //     signing.fingerprints,
        //     signing.identifications,
        //     [],
        //     signing.signatures,
        //     signing.witnesses,
        //     signing.recipientEmails,
        //     signing.auditLog,
        //     signing.signing_timestamp,
        //signing.localTimezone,
        //signing.userProfileGUID
        //   )

        .then(res => {
          //logger.log("SIGNING_NEW_JOURNAL_ENTRY_CREATED res", res);
          segmentHelper.track(
            segmentHelper.events.SIGNING_NEW_JOURNAL_ENTRY_CREATED,
            {
              GUID: res.GUID,
              UserMasterGUID: res.UserMasterGUID,
              RequestGUID: res.RequestGUID
            }
          );
          setSavingSigning(false);
          doFinishCreateEntry(res);
          dispatch(
            actionAddNotification({
              message: 'New entry created and saved to server',
              to: 'notary/journal'
            })
          );
        })
        .catch(err => {
          dispatch(
            actionAddNotification({
              message:
                'New entry completed and saved to draft. Open and Click to save on servre',
              to: 'notary/signing'
            })
          );
          setSavingSigning(false);
          //logger.log('error creating entry', err);
          let statusCode = _.get(err, 'response.statusCode', null);
          let errCode = _.get(err, 'Code', null);

          segmentHelper.track(
            segmentHelper.events.SIGNING_NEW_JOURNAL_ENTRY_FAILED,
            {
              statusCode: statusCode,
              error: errCode
            }
          );

          if (statusCode && statusCode === 409) {
            //logger.log('encountered duplicate entry in step 6', err);
            dispatch(
              actionSetDraftSaveError({
                title: 'Inforamtion',
                message: 'This signing was already uploaded and saved.'
              })
            );
            //Then we've essentially already uploaded it before, pretend it was successful!
            //doFinishCreateEntry({});
          } else if (apiHelper.isConnectionError(err)) {
            setTimeout(() => {
              dispatch(actionSetDraftSaveStatus(c.draftStatus.Completed));
              handleConnectionErrorForNewEntry();
            });
          } else {
            if (errCode === 'SubscriptionLimitReached') {
              dispatch(
                showAlert(
                  'Limit Reached',
                  'You have reached the maximum total number of signings for your current subscription level.  Please update your subscription settings at juratinc.com'
                )
              );
              dispatch(
                actionSetDraftSaveError({
                  title: 'Limit Reached',
                  message:
                    'You have reached the maximum total number of signings for your current subscription level.  Please update your subscription settings at juratinc.com'
                })
              );
              return;
            } else if (errCode === 'RequestTooLarge') {
              dispatch(
                showAlert(
                  'Limit Reached',
                  'The entry you have submitted is too large for us to process.   If you see this message frequently, please contact support@juratinc.com'
                )
              );
              dispatch(
                actionSetDraftSaveError({
                  title: 'Limit Reached',
                  message:
                    'The entry you have submitted is too large for us to process.   If you see this message frequently, please contact support@juratinc.com'
                })
              );
              return;
            } else {
              dispatch(actionSetDraftSaveStatus(c.draftStatus.Completed));
              //Generic fail, submit this, we don't know what happened.
              dispatch(
                showAlert(
                  'Unable to Save',
                  'There was a problem saving your entry.  Please try again.'
                )
              );
              //We no longer require for web
              //sentryHelper.captureMessage('error saving signing', err);
              errorReportHelper.trySubmitError('Error creating signing', {
                err,
                signing: {
                  requestGUID: signing.requestGUID,
                  signers: signing.signers,
                  location: signing.location,
                  notarization_details: signing.notarization_details,
                  notarization_documents: signing.notarization_documents,
                  identifications: signing.identifications,
                  witnesses: signing.witnesses
                }
              });
            }
            setSavingSigning(false);
          }
        });
    });
  };

  // const finishClick = () => {
  //   if (isSavingSigning) {
  //     return;
  //   }
  //   setSigningStatus(layoutView.UPLOAD);
  //   setSavingSigning(true);
  //   fetchDraft().then(draft => {
  //     let createdDate = null;
  //     let completedAtDate = null;
  //     logger.log('Got a draft to save', draft);

  //     if (draft) {
  //       let draftComplete = draft.draft_metadata.completed_at;
  //       let draftCreated = draft.draft_metadata.created;
  //       if (draftComplete) {
  //         completedAtDate = moment(draftComplete);
  //       }
  //       if (draftCreated) {
  //         createdDate = moment(draftCreated);
  //       }
  //     }
  //     //If you don't have a completed date set, set one, and submit.
  //     //If submit fails, then we need to mark the draft as finished,
  //     //and save the finished date in the draft metadata.
  //     if (!completedAtDate) {
  //       //logger.log('set completed date on failed upload is true', draft);
  //       completedAtDate = moment();
  //     }

  //     //If it's not set in the draft, try it from the entry itself.
  //     //I'm not sure when this would be necessary except in some problem saving drafts.
  //     if (!createdDate && startedAtDate) {
  //       createdDate = moment(startedAtDate);
  //     }

  //     api.SigningDraft.finalise(
  //       draftEntryHelper.getDarftKeyFromId(signing?.draft_id),
  //       signing.requestGUID,
  //       userMaster.GUID,
  //       deviceGUID,
  //       createdDate,
  //       completedAtDate,
  //       signing.signers,
  //       signing.location,
  //       signing.notarization_details,
  //       signing.notarization_documents,
  //       [],
  //       signing.fingerprints,
  //       signing.identifications,
  //       [],
  //       signing.signatures,
  //       signing.witnesses,
  //       signing.recipientEmails,
  //       signing.auditLog,
  //       signing.signing_timestamp
  //     )
  //       .then(res => {
  //         //logger.log("SIGNING_NEW_JOURNAL_ENTRY_CREATED res", res);
  //         segmentHelper.track(
  //           segmentHelper.events.SIGNING_NEW_JOURNAL_ENTRY_CREATED,
  //           {
  //             GUID: res.GUID,
  //             UserMasterGUID: res.UserMasterGUID,
  //             RequestGUID: res.RequestGUID
  //           }
  //         );
  //         setSavingSigning(false);
  //         doFinishCreateEntry(res);
  //         dispatch(
  //           actionAddNotification({
  //             message: 'New entry created and saved to server',
  //             to: 'notary/journal'
  //           })
  //         );
  //       })
  //       .catch(err => {
  //         dispatch(
  //           actionAddNotification({
  //             message:
  //               'New entry completed and saved to draft. Open and Click to save on servre',
  //             to: 'notary/signing'
  //           })
  //         );
  //         setSavingSigning(false);
  //         //logger.log('error creating entry', err);
  //         let statusCode = _.get(err, 'response.statusCode', null);
  //         let errCode = _.get(err, 'Code', null);

  //         segmentHelper.track(
  //           segmentHelper.events.SIGNING_NEW_JOURNAL_ENTRY_FAILED,
  //           {
  //             statusCode: statusCode,
  //             error: errCode
  //           }
  //         );

  //         if (statusCode && statusCode === 409) {
  //           //logger.log('encountered duplicate entry in step 6', err);
  //           dispatch(
  //             actionSetDraftSaveError({
  //               title: 'Inforamtion',
  //               message: 'This signing was already uploaded and saved.'
  //             })
  //           );
  //           //Then we've essentially already uploaded it before, pretend it was successful!
  //           //doFinishCreateEntry({});
  //         } else if (apiHelper.isConnectionError(err)) {
  //           setTimeout(() => {
  //             dispatch(actionSetDraftSaveStatus(c.draftStatus.Completed));
  //             handleConnectionErrorForNewEntry();
  //           });
  //         } else {
  //           if (errCode === 'SubscriptionLimitReached') {
  //             dispatch(
  //               showAlert(
  //                 'Limit Reached',
  //                 'You have reached the maximum total number of signings for your current subscription level.  Please update your subscription settings at juratinc.com'
  //               )
  //             );
  //             dispatch(
  //               actionSetDraftSaveError({
  //                 title: 'Limit Reached',
  //                 message:
  //                   'You have reached the maximum total number of signings for your current subscription level.  Please update your subscription settings at juratinc.com'
  //               })
  //             );
  //             return;
  //           } else if (errCode === 'RequestTooLarge') {
  //             dispatch(
  //               showAlert(
  //                 'Limit Reached',
  //                 'The entry you have submitted is too large for us to process.   If you see this message frequently, please contact support@juratinc.com'
  //               )
  //             );
  //             dispatch(
  //               actionSetDraftSaveError({
  //                 title: 'Limit Reached',
  //                 message:
  //                   'The entry you have submitted is too large for us to process.   If you see this message frequently, please contact support@juratinc.com'
  //               })
  //             );
  //             return;
  //           } else {
  //             dispatch(actionSetDraftSaveStatus(c.draftStatus.Completed));
  //             //Generic fail, submit this, we don't know what happened.
  //             dispatch(
  //               showAlert(
  //                 'Unable to Save',
  //                 'There was a problem saving your entry.  Please try again.'
  //               )
  //             );
  //             //We no longer require for web
  //             //sentryHelper.captureMessage('error saving signing', err);
  //             errorReportHelper.trySubmitError('Error creating signing', {
  //               err,
  //               signing: {
  //                 requestGUID: signing.requestGUID,
  //                 signers: signing.signers,
  //                 location: signing.location,
  //                 notarization_details: signing.notarization_details,
  //                 notarization_documents: signing.notarization_documents,
  //                 identifications: signing.identifications,
  //                 witnesses: signing.witnesses
  //               }
  //             });
  //           }
  //           setSavingSigning(false);
  //         }
  //       });
  //   });
  // };

  const removeServerDraft = () => {
    return new Promise((resolve, reject) => {
      if (currentDraftId && userMaster && userMaster.GUID) {
        dispatch(
          removeDraftFromServer(draftEntryHelper.getDarftKeyFromId(draft_id))
        ).then(res => {
          resolve(true);
        });
      }
    });
  };

  const removeSavedDraft = () => {
    logger.log('Removing draft', currentDraftId);
    return new Promise((resolve, reject) => {
      if (currentDraftId && userMaster && userMaster.GUID) {
        draftEntryHelper
          .removeDraft(userMaster.GUID, currentDraftId)
          .then(res => {
            resolve(true);
          });
      } else {
        logger.warn(
          'draft not removed, data does not exist',
          userMaster,
          currentDraftId
        );
        reject(false);
      }
    });
  };

  const appendReceipientEmail = () => {
    if (contactData.recipientEmail != '') {
      if (validateEmail(contactData.recipientEmail)) {
        let isExit = _.find(
          recipientEmails,
          e => e.toLowerCase() === contactData.recipientEmail.toLowerCase()
        );
        if (!isExit) {
          dispatch(
            actionAddRecipientEmail({
              email: contactData.recipientEmail.toLowerCase()
            })
          );
          setContactData(contactData => ({
            ...contactData,
            recipientEmail: ''
          }));
          segmentHelper.track(
            segmentHelper.events.SIGNING_REVIEW_AND_RECIPIENT_ADDED
          );
        } else {
          toast.warning('Email already added', {
            theme: 'colored',
            position: 'bottom-center',
            icon: faUserCheck,
            toastId: 'receipient-email'
          });
        }
      } else {
        toast.info('Please enter valid email', {
          theme: 'colored',
          position: 'bottom-center',
          icon: faUserCheck,
          toastId: 'receipient-email'
        });
      }
    } else {
      toast.info('Please enter email', {
        theme: 'colored',
        position: 'bottom-center',
        icon: faUserCheck,
        toastId: 'receipient-email'
      });
    }
  };

  const removeRecipientEmail = email => {
    dispatch(actionRemoveRecipientEmail({ email }));
  };

  const renderHeaderText = () => {
    if (hasSigningBeenFinished) {
      return (
        <>
          <Alert variant="success">
            <Alert.Heading>Signing Complete</Alert.Heading>
            <p>
              This Signing has been marked as complete at{' '}
              {filters.getFriendlyDate(completedAtDate)}, and changes can no
              longer be made.
            </p>
            <hr />
            <p className="mb-0">
              To avoid data loss, we recommend uploading this draft to our
              servers as soon as possible by tapping "Upload" below.
            </p>
          </Alert>
        </>
      );
    }

    return (
      <>
        <Alert variant="info">
          <Alert.Heading>
            <FontAwesomeIcon icon={faCheckCircle} /> Review Entry
          </Alert.Heading>
          <p>
            Review your entry. Hit Back to make changes, or Complete if
            everything is correct. Saved entries will be timestamped.
          </p>
          <hr />
          <p className="mb-0">
            Once saved, this entry can not be modified. If there is an error,
            you will have to re-do the entry. Jurat Inc can not modify entries
            on your behalf.
          </p>
        </Alert>
      </>
    );
  };

  const renderStepOne = () => {
    ////logger.log('Step6 Signers', signers);
    let { address_1, address_2, city, state, zip } =
      _.get(signing, 'location') || {};

    let hasAddressField = !!(
      (address_1 && address_1.length > 0) ||
      (address_2 && address_2.length > 0)
    );
    let hasAreaField = !!(
      (city && city.length > 0) ||
      (state && state.length > 0) ||
      (zip && zip.length > 0)
    );

    // //logger.log('render step six!!!', first_name, last_name, hasAddressField, hasAreaField, address_1, address_2, city, state, zip)
    return (
      <>
        <Card>
          <Card.Header>
            Signer{signers.length > 1 ? 's' : ''}{' '}
            <SoftBadge variant="primary"> {signers.length}</SoftBadge>
          </Card.Header>
          <hr className="my-0" />
          <Card.Body>
            {!!(signers && signers.length === 0) && (
              <Flex justifyContent="center">
                <ValidationErrors
                  errors={[
                    'You must add at least one Signer before you can upload this signing.'
                  ]}
                />
              </Flex>
            )}
            <Row>
              {signers.length > 0 &&
                _.map(signers, (s, sIndex) => {
                  return (
                    <Col
                      sm={12}
                      lg={4}
                      key={_.uniqueId('signers-list') + (s?.signer_id + sIndex)}
                    >
                      <Card
                        key={_.uniqueId('signers') + s.signer_id}
                        className="mb-2"
                      >
                        <Card.Body>
                          <h5>{filters.formatNameObj(s)}</h5>
                          {(hasAddressField || hasAreaField) && (
                            <div>
                              {hasAddressField && (
                                <p className="mb-1">
                                  {address_1 && address_1.length > 0
                                    ? address_1
                                    : ''}{' '}
                                  {address_2 && address_2.length > 0
                                    ? address_2
                                    : ''}
                                </p>
                              )}
                              {hasAreaField && (
                                <p className="mb-1">
                                  {city && city.length > 0 ? city + ', ' : ''}
                                  {state && state.length > 0
                                    ? state + ', '
                                    : ''}
                                  {zip && zip.length > 0 ? zip : ''}
                                </p>
                              )}
                            </div>
                          )}
                        </Card.Body>
                      </Card>
                    </Col>
                  );
                })}
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  };

  const renderStepTwo = () => {
    return (
      <>
        {signers.length > 0 &&
          _.map(signers, (s, sIndex) => {
            let signerWitnesses = _.filter(
              witnesses,
              w => w.signer_id === s.signer_id
            );
            signerWitnesses = _.sortBy(signerWitnesses, w => {
              return w.witness_id;
            });

            if (signerWitnesses.length === 0) {
              let signerIdentifications = _.filter(identifications, i => {
                return i.signer_id === s.signer_id;
              });

              return (
                <Card
                  key={
                    _.uniqueId('identification-for-signer') +
                    s.signer_id +
                    '-' +
                    sIndex
                  }
                  className="mt-3"
                >
                  <Card.Header>
                    Identification{signerIdentifications.length > 1 ? 's' : ''}{' '}
                    for {filters.formatNameObj(s)}
                    <hr className="mb-0" />
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      {signerIdentifications.length > 0 &&
                        _.map(signerIdentifications, i => {
                          return draftEntryUiHelper.getIdentificationViewCard(
                            i,
                            false
                          );
                        })}
                    </Row>
                  </Card.Body>
                </Card>
              );
            } else if (signerWitnesses.length === 1) {
              return (
                <Card key={s.signer_id} className="mt-3">
                  <Card.Header>
                    Credible Witness for {filters.formatNameObj(s)}
                  </Card.Header>
                  <Card.Body>
                    {draftEntryUiHelper.getWitnessViewCard(
                      signerWitnesses[0],
                      _.find(
                        identifications,
                        i => i.witness_id === signerWitnesses[0].witness_id
                      )
                    )}
                  </Card.Body>
                </Card>
              );
            } else if (signerWitnesses.length === 2) {
              return (
                <Card key={s.signer_id} className="mt-3">
                  <Card.Header>
                    Credible Witness for {filters.formatNameObj(s)}
                  </Card.Header>
                  <Card.Body>
                    {draftEntryUiHelper.getWitnessViewCard(
                      signerWitnesses[0],
                      _.find(
                        identifications,
                        i => i.witness_id === signerWitnesses[0].witness_id
                      )
                    )}
                    <hr />
                    {draftEntryUiHelper.getWitnessViewCard(
                      signerWitnesses[1],
                      _.find(
                        identifications,
                        i => i.witness_id === signerWitnesses[1].witness_id
                      )
                    )}
                  </Card.Body>
                </Card>
              );
            }
          })}
      </>
    );
  };

  const renderStepThree = () => {
    let notary_fee = notarization_details?.notary_fee || null;
    let notes = notarization_details?.notes || '';
    let SigningFees = notarization_details?.Signing_Fees || '';
    let totalFee = _.isNumber(notary_fee) ? parseFloat(notary_fee) : 0;

    if (SigningFees?.length > 0)
      SigningFees.map(efItem => {
        totalFee += parseFloat(efItem.Amount);
      });

    return (
      <>
        <Card className="mt-3">
          <Card.Header>
            Fees
            <hr className="mb-0" />
          </Card.Header>
          <Card.Body
            className="pt-0 d-flex"
            style={{ justifyContent: 'space-between' }}
          >
            <div>
              <p className="my-1 fs-0">
                <span>Notarization fee:</span>{' '}
                {notary_fee !== null
                  ? filters.formatCurrencyFromServer(notary_fee)
                  : 'Not added'}
              </p>

              {SigningFees &&
                SigningFees.map((efItem, efIndex) => (
                  <p key={efIndex} className="my-1 fs-0">
                    <span>{efItem.Name}:</span>{' '}
                    {filters.formatCurrencyFromServer(efItem.Amount)}
                  </p>
                ))}
            </div>
            <div
              className="d-flex align-items-center"
              style={{ flexDirection: 'column' }}
            >
              <span className="fs-4">
                {filters.formatCurrencyFromServer(totalFee)}
              </span>
              <span>Total fees</span>
            </div>
          </Card.Body>
        </Card>

        <Card className="mt-3">
          <Card.Header>
            Details
            <hr className="mb-0" />
          </Card.Header>
          <Card.Body className="pt-0">
            {notes.length > 0 && (
              <p className="my-1 fs-0">
                <b>Notes: </b> {notes}
              </p>
            )}

            <p className="my-1 fs-0">
              <b>Documents : </b> {notarization_documents.length}
            </p>

            {!!(
              notarization_documents && notarization_documents.length === 0
            ) && (
              <div>
                <ValidationErrors
                  errors={[
                    'You must add at least one Document before you can upload this signing.'
                  ]}
                />
              </div>
            )}
            <div className="pt-2 fs-0">
              <Row>
                {notarization_documents.length > 0 &&
                  _.map(notarization_documents, (d, dIndex) => {
                    let signerList = [];
                    _.each(d.signer_ids, sid => {
                      let found = _.find(signers, s => s.signer_id === sid);
                      if (found) {
                        signerList.push(found);
                      }
                    });

                    let signerString = _.map(signerList, (s, index) => {
                      return `${filters.formatNameObj(s)}${
                        index < signerList.length - 1 ? ', ' : ''
                      }`;
                    });

                    return (
                      <Col
                        sm={12}
                        md={4}
                        className="mb-3"
                        key={
                          _.uniqueId('documents') + d.document_id + '-' + dIndex
                        }
                      >
                        <Card className="h-md-100 ">
                          <Card.Body>
                            <p className="my-1 fs--1">
                              <FontAwesomeIcon
                                className="fa-fw"
                                icon={faFileAlt}
                              />{' '}
                              {d.document_type}
                            </p>
                            <p className="my-1 fs--1">
                              <FontAwesomeIcon
                                className="fa-fw"
                                icon={faFolderOpen}
                              />{' '}
                              {d.notarization_type}
                            </p>
                            {!!(d && d.notes && d.notes.length > 0) && (
                              <p className="my-1 fs--1">
                                <FontAwesomeIcon
                                  className="fa-fw"
                                  icon={faFileContract}
                                />{' '}
                                {d.notes}
                              </p>
                            )}
                            <p className="my-1 fs--1">
                              <FontAwesomeIcon
                                className="fa-fw"
                                icon={faUserAlt}
                              />{' '}
                              Signers:{' '}
                              {signerString && signerString.length > 0
                                ? signerString
                                : '- None -'}
                            </p>
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })}
              </Row>
            </div>
          </Card.Body>
        </Card>
      </>
    );
  };

  const renderStepFour = () => {
    return (
      <Card className="mt-3">
        <Card.Header>Signer's Signature</Card.Header>
        <hr className="my-0" />
        <Card.Body>
          <Row>
            {signers.length > 0 &&
              _.map(signers, (s, sIndex) => {
                //logger.log('renderStepFour', s);
                let signerSignatures = _.filter(signatures, f => {
                  return f.signer_id === s.signer_id;
                });

                return (
                  <>
                    {signerSignatures.length === 0 && (
                      <Card
                        className="h-md-100"
                        key={_.uniqueId('signers') + s.signer_id + '-' + sIndex}
                      >
                        <Card.Body>
                          <Flex
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <FontAwesomeIcon
                              icon={faExclamationCircle}
                              color={Colors.warning.lightColor}
                              size="2x"
                            />
                            <p className="text-center my-3">
                              {' '}
                              No Signatures Entered
                            </p>
                          </Flex>
                        </Card.Body>
                      </Card>
                    )}

                    {signerSignatures.length > 0 &&
                      _.map(signerSignatures, s => {
                        //logger.log('signerSignatures', s);
                        let markWitnesses = _.get(s, 'witnessData');
                        let isSignatureByMark = !!(
                          markWitnesses && markWitnesses.length > 0
                        );
                        let signer = _.find(
                          signers,
                          tempSigner => tempSigner.signer_id === s.signer_id
                        );

                        return (
                          <>
                            {isSignatureByMark && (
                              <Col
                                sm={12}
                                key={_.uniqueId(
                                  'witness-row-signatur-_by-mark'
                                )}
                              >
                                <p className="mt-3 mb-2 fs-0 fw-medium">
                                  Signature by Mark with{' '}
                                  {markWitnesses.length === 1
                                    ? 'One Witness'
                                    : 'Two Witnesses'}
                                </p>
                                <hr className="mt-0 mb-2" />
                              </Col>
                            )}
                            <Col
                              sm={12}
                              md={4}
                              key={s.signer_id + '-witness-' + sIndex}
                              className="my-2"
                            >
                              <Card className="h-md-100 text-center">
                                <Card.Body className="d-flex justify-content-center align-items-center">
                                  <div className="text-center p-2">
                                    {draftEntryUiHelper.getSignatureViewImage(
                                      s?.imgData?.data,
                                      180,
                                      200
                                    )}
                                  </div>
                                </Card.Body>
                                <Card.Footer>
                                  <span className="fs--1">
                                    Signature for{' '}
                                    {filters.formatNameObj(signer)}
                                  </span>
                                </Card.Footer>
                              </Card>
                            </Col>
                            {isSignatureByMark &&
                              _.map(markWitnesses, (mw, mwIndex) => {
                                return (
                                  <Col
                                    sm={12}
                                    md={4}
                                    key={
                                      _.uniqueId('markwitness') +
                                      s.signer_id +
                                      '-markwitness-' +
                                      mwIndex
                                    }
                                    className="my-2"
                                  >
                                    <Card className="h-md-100 text-center">
                                      <Card.Body className="d-flex justify-content-center align-items-center">
                                        <Flex
                                          justifyContent="center"
                                          alignContent="end"
                                          wrap="wrap"
                                        >
                                          <div
                                            key={mw.witness_id}
                                            className="text-center p-2"
                                            style={{ height: 150 }}
                                          >
                                            {draftEntryUiHelper.getSignatureViewImage(
                                              mw.signature.data,
                                              'auto',
                                              140
                                            )}
                                          </div>
                                          <Flex
                                            alignContent="end"
                                            className="text-center text-muted fs--1"
                                          >
                                            {mw.title} Signature
                                          </Flex>
                                        </Flex>
                                      </Card.Body>
                                      <Card.Footer>
                                        <span className="fs--1">
                                          Signature for{' '}
                                          {filters.formatNameObj(
                                            mw.identification
                                          )}
                                        </span>
                                      </Card.Footer>
                                    </Card>
                                  </Col>
                                );
                              })}
                          </>
                        );
                      })}
                  </>
                );
              })}
          </Row>
        </Card.Body>
      </Card>
    );
  };

  const renderStepFive = () => {
    return (
      <Card className="mt-3">
        <Card.Header>Signer's Fingerprint</Card.Header>
        <hr className="my-0" />
        <Card.Body>
          <Row>
            {signers.length > 0 &&
              _.map(signers, (s, sIndex) => {
                //logger.log('renderStepFour', s);
                let signerFingerprints = _.filter(fingerprints, f => {
                  return f.signer_id === s.signer_id;
                });

                return (
                  <>
                    {signerFingerprints.length === 0 && (
                      <Card
                        className="h-md-100"
                        key={_.uniqueId('signers') + s.signer_id + '-' + sIndex}
                      >
                        <Card.Body>
                          <Flex
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <FontAwesomeIcon
                              icon={faExclamationCircle}
                              color={Colors.warning.lightColor}
                              size="2x"
                            />
                            <p className="text-center my-3">
                              {' '}
                              No Fingerprint captured
                            </p>
                          </Flex>
                        </Card.Body>
                      </Card>
                    )}

                    {signerFingerprints.length > 0 &&
                      _.map(signerFingerprints, fingerprint => {
                        return (
                          <>
                            <Col
                              sm={12}
                              md={4}
                              key={
                                s.signer_id +
                                '-fingerprint-' +
                                fingerprint.fingerprint_id
                              }
                              className="my-2"
                            >
                              <Card className="h-md-100 text-center">
                                <Card.Header>
                                  <strong>{fingerprint.finger}</strong>
                                </Card.Header>
                                <Card.Body>
                                  <div className="text-center p-2">
                                    {draftEntryUiHelper.getFingerprintViewImage(
                                      fingerprint?.imgData?.data,
                                      150,
                                      '100%',
                                      'card-img-top'
                                    )}
                                  </div>
                                </Card.Body>
                                <Card.Footer>
                                  <span className="fs--1">
                                    Fingerprint for {filters.formatNameObj(s)}
                                  </span>
                                </Card.Footer>
                              </Card>
                            </Col>
                          </>
                        );
                      })}
                  </>
                );
              })}
          </Row>
        </Card.Body>
      </Card>
    );
  };

  const renderAuditLogTimeline = () => {
    return (
      <div>
        <h4 className="text-center mt-3">Audit log</h4>
        <hr />
        <div className="timeline-vertical">
          {timelineData.length > 0 &&
            timelineData.map((item, index) => {
              const { year, date, title, description, icon } = item;
              return (
                <div
                  key={_.uniqueId('timeline') + index}
                  className={classNames('timeline-item', {
                    'timeline-item-start': index % 2 === 0,
                    'timeline-item-end': index % 2 !== 0
                  })}
                >
                  <div className="timeline-icon icon-item icon-item-lg text-primary border-300">
                    <FontAwesomeIcon icon={icon} className="fs-1" />
                  </div>
                  <Row
                    className={` ${
                      index % 2 == 0
                        ? 'timeline-item-start'
                        : 'timeline-item-end'
                    }`}
                  >
                    <Col lg={6} className="timeline-item-time">
                      <div>
                        <p className="fs--1 mb-0 fw-semi-bold"> {year}</p>
                        <p className="fs--2 text-600">{date}</p>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="timeline-item-content">
                        <div className="timeline-item-card">
                          <h5 className="mb-2">{title}</h5>
                          <p className="fs--1 mb-0">{description}</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const SigningDetailView = () => {
    return (
      <Row>
        {renderHeaderText()}
        {renderStepOne()}

        {renderStepTwo()}

        {renderStepThree()}

        {renderStepFour()}

        {renderStepFive()}

        {renderAuditLogTimeline()}
        <Col className="mt-5">
          <Flex justifyContent="between">
            <Button
              variant="outline-primary"
              onClick={() => {
                setStep(5);
              }}
              disabled={hasSigningBeenFinished}
            >
              <FontAwesomeIcon icon={faReply} size="sm" /> Back
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                confirmLastStep();
              }}
              disabled={hasSigningBeenFinished}
            >
              Confirm
            </Button>
          </Flex>
        </Col>
      </Row>
    );
  };

  const step6NameOfBusiness = (
    <Popover id="notarization-date-time">
      <Popover.Header as="h3">Name of Business</Popover.Header>
      <Popover.Body>Details about name of business</Popover.Body>
    </Popover>
  );
  const step6CommissionedName = (
    <Popover id="notarization-date-time">
      <Popover.Header as="h3">commission name</Popover.Header>
      <Popover.Body>Details about name of commission name</Popover.Body>
    </Popover>
  );
  const step6DBA = (
    <Popover id="notarization-date-time">
      <Popover.Header as="h3">DBA</Popover.Header>
      <Popover.Body>Details about DBA</Popover.Body>
    </Popover>
  );

  const SigningReviewView = () => {
    return (
      <Row>
        <Col sm={12}>
          {/* <h6 className="text-center">
            <FontAwesomeIcon
              color={Colors.primary.color}
              icon={faExclamationCircle}
              size="3x"
            />
          </h6> */}

          <Alert variant="info">
            <Alert.Heading>
              <FontAwesomeIcon icon={faExclamationCircle} /> Complete Signing
            </Alert.Heading>
            <p>
              Click "Complete" to mark your Signing as complete and upload it to
              our servers.
            </p>
            <hr />
            <p className="mb-0">
              Once marked as complete, this signing can not be modified. If an
              error is discovered later, you will have to re-do the signing.
              Jurat Inc can not modify signings on your behalf.
            </p>
          </Alert>

          <Row className="g-2 my-3">
            <Col sm={12}>
              <p className="my-0 fw-bold">
                For a receipt of this transaction, enter email addresses of
                Signers below.
              </p>
            </Col>

            <Form.Group as={Col} lg={4} controlId="recipientEmail">
              <Form.Label>Recipient Email</Form.Label>
              <Form.Control
                type="text"
                className="w-100"
                placeholder="Enter Recipient Email..."
                value={contactData.recipientEmail}
                name="recipientEmail"
                onChange={handleContactData}
              />
            </Form.Group>
            <Col className="d-flex" sm={4}>
              <Flex alignItems="end">
                <Button
                  type="button"
                  disabled={isSavingSigning}
                  onClick={() => appendReceipientEmail()}
                >
                  <FontAwesomeIcon icon={faPlusCircle} size="sm" /> Add
                  recipient email
                </Button>
              </Flex>
            </Col>
            <Col sm={4}></Col>
          </Row>
          <Row className="g-2 my-3">
            <Col sm={12}>
              <ListGroup>
                {recipientEmails.length > 0 &&
                  _.map(recipientEmails, email => {
                    return (
                      <ListGroup.Item
                        key={_.uniqueId('receipients-email') + email}
                      >
                        <Flex justifyContent="between" alignItems="center">
                          {email}
                          <Button
                            type="button"
                            variant="outline-danger"
                            size="sm"
                            className="ms-1"
                            onClick={() => removeRecipientEmail(email)}
                          >
                            <FontAwesomeIcon icon={faTrashAlt} /> Remove
                          </Button>
                        </Flex>
                      </ListGroup.Item>
                    );
                  })}
              </ListGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={12} className="d-flex w-100">
              <Flex className="w-100" justifyContent="center">
                <Card className="w-100">
                  <Card.Header>
                    <FontAwesomeIcon icon={faAddressBook} size="sm" /> Contact
                    Information
                  </Card.Header>
                  <Card.Body className="py-0">
                    <Alert variant="info">
                      <p className="text-center">
                        <FontAwesomeIcon icon={faInfoCircle} size="2x" />
                      </p>
                      <p>
                        This contact information will appear in the receipt
                        email thay your signer receives. We recomended ensuring
                        your contact information includes your{' '}
                        <OverlayTrigger
                          trigger="hover"
                          placement="top"
                          overlay={step6CommissionedName}
                          delay={500}
                        >
                          <u className="fw-semi-bold">commissioned name</u>
                        </OverlayTrigger>{' '}
                        as well as the{' '}
                        <OverlayTrigger
                          trigger="hover"
                          placement="top"
                          overlay={step6NameOfBusiness}
                          delay={500}
                        >
                          <u className="fw-semi-bold">name of your business</u>
                        </OverlayTrigger>{' '}
                        or{' '}
                        <OverlayTrigger
                          trigger="hover"
                          placement="top"
                          overlay={step6DBA}
                          delay={500}
                        >
                          <u className="fw-semi-bold">DBA</u>
                        </OverlayTrigger>{' '}
                        if applicable, along with all{' '}
                        <span className="fw-semi-bold">email addresses</span>{' '}
                        and <span className="fw-semi-bold">phone numbers</span>{' '}
                        to ensure the signer can reach you if needed.
                      </p>
                    </Alert>

                    {userMaster.ContactInformation}
                  </Card.Body>
                  <Card.Footer>
                    <Button
                      type="button"
                      variant="outline-primary"
                      onClick={() => setSigningStatus(layoutView.CONTACT)}
                    >
                      Change Contact Information
                    </Button>
                  </Card.Footer>
                </Card>
              </Flex>
            </Col>
          </Row>
        </Col>
        <Col sm={12} className="mt-5">
          <Flex justifyContent="between">
            <Button
              type="button"
              disabled={isSavingSigning}
              variant="outline-primary"
              onClick={() => setSigningStatus(layoutView.DETAIL)}
            >
              <FontAwesomeIcon icon={faCheck} size="sm" /> Review
            </Button>

            <Button
              variant="primary"
              disabled={isSavingSigning}
              onClick={
                hasSigningBeenFinished
                  ? () => finishClick()
                  : () => reviewClick()
              }
            >
              {isSavingSigning ? (
                <>
                  <Spinner size="sm" /> Saving..
                </>
              ) : hasSigningBeenFinished ? (
                'Finish'
              ) : (
                'Complete'
              )}
            </Button>
          </Flex>
        </Col>
      </Row>
    );
  };

  const SigningContactView = () => {
    return (
      <Row>
        <Col sm={12}>
          <Alert variant="info">
            <Alert.Heading>
              <FontAwesomeIcon icon={faAddressBook} /> Contact Information
            </Alert.Heading>
            <p>
              Your Contact Information will be included with the emailed
              receipts of your Signings.
            </p>
          </Alert>

          <p className="text-center fs-0"></p>

          <Row className="g-2 my-3">
            <Form.Group as={Col} lg={12} controlId="contactInfo">
              <Form.Label>Contact Information</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter contact info"
                value={contactData.contactInfo}
                name="contactInfo"
                onChange={handleContactData}
              />
            </Form.Group>
          </Row>
          <Row>
            <Col className="d-flex mx-auto">
              <Flex justifyContent="between">
                <Button
                  type="button"
                  className="ms-1"
                  disabled={savingContactInfo}
                  onClick={() => sveContactInfo()}
                >
                  {savingContactInfo ? (
                    <>
                      <Spinner className="ms-1" variant="light" size="sm" />{' '}
                      Saving information ...
                    </>
                  ) : (
                    'Update Contact Information'
                  )}
                </Button>

                <Button
                  type="button"
                  variant="danger"
                  className="ms-1"
                  disabled={savingContactInfo}
                  onClick={() => setSigningStatus(layoutView.REVIEW)}
                >
                  Close
                </Button>
              </Flex>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const SigningUploadView = () => {
    return (
      <Row>
        <Col sm={12} className="text-center">
          <Flex className="my-2" justifyContent="center">
            {isSavingSigning ? (
              <Spinner size="lg" />
            ) : (
              <FontAwesomeIcon icon={faUpload} size="3x" />
            )}
          </Flex>
          {isSavingSigning ? (
            <p className="text-center my-3 py-3">Uploading... Please wait</p>
          ) : (
            <>
              <h4 className="mb-1">Journal entry is available to upload</h4>
              <p className="fs-0">Save this entry to server</p>
              <div className="fs-0 mb-2">
              <Button
                  variant="primary"
                  disabled={checkingCurrentStatus}
                  className='mx-2'
                  onClick={() => {
                    setIsCompleted(false);
                    setSigningStatus(layoutView.DETAIL);
                    setSavingDraft(false);
                    dispatch(actionSetSigningUnFinished());
                  }} // setSavingDraft(false), signingStatus(layoutView.DETAIL)
                >
                  <FontAwesomeIcon icon={faEdit} /> Edit
                </Button>
                {!checkingCurrentStatus ? (
                  <Button
                    variant="primary"
                    disabled={checkingCurrentStatus}
                    onClick={() => finishClick()}
                  >
                    <FontAwesomeIcon icon={faUpload} /> Upload
                  </Button>
                ) : (
                  <Flex
                    justifyContent={'center'}
                    alignItems={'center'}
                    direction={'column'}
                  >
                    <Spinner size="sm" /> <span>Checking subscription</span>
                  </Flex>
                )}
              </div>
            </>
          )}
        </Col>
        <Col sm={12}>
          <ListGroup>
            <ListGroup.Item>
              <Flex justifyContent="between" alignItems="center">
                <h5>Entry Details</h5>
                <Button
                  variant="outline-secondary"
                  onClick={() => setOpenTimeline(!openTimeline)}
                >
                  <FontAwesomeIcon
                    icon={openTimeline ? faChevronUp : faChevronDown}
                  />
                </Button>
              </Flex>
              <Collapse in={openTimeline} className="my-2">
                <div className="border rounded px-1">
                  {renderStepOne()}
                  {renderStepTwo()}
                  {renderStepThree()}
                  {renderStepFour()}
                  {renderStepFive()}
                </div>
              </Collapse>
            </ListGroup.Item>
            <ListGroup.Item>
              <Flex justifyContent="between" alignItems="center">
                <h5>Entry Audit log</h5>
                <Button
                  variant="outline-secondary"
                  onClick={() => setOpenAuditlog(!openAuditLog)}
                >
                  <FontAwesomeIcon
                    icon={openAuditLog ? faChevronUp : faChevronDown}
                  />
                </Button>
              </Flex>
              <Collapse in={openAuditLog} className="my-2">
                <div className="border rounded px-1">
                  {renderAuditLogTimeline()}
                </div>
              </Collapse>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <>{layoutView.DETAIL === signingStatus && SigningDetailView()}</>
      <>{layoutView.REVIEW === signingStatus && SigningReviewView()}</>
      <>{layoutView.CONTACT === signingStatus && SigningContactView()}</>
      <>{layoutView.UPLOAD === signingStatus && SigningUploadView()}</>
    </>
  );
};

Step6.propTypes = {
  reset: PropTypes.func.isRequired
};

export default Step6;
