import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCloseButton from 'components/common/FalconCloseButton';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Col,
  Form,
  Row,
  Button,
  DropdownButton,
  Dropdown,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { useSelector } from 'react-redux';

const NotaryJournalTableHeader = ({
  selectedRowIds,
  heading,
  searchString,
  setSearchString,
  filterMonth,
  setFilterMonth
}) => {
  const { subscriptionStatus } = useSelector(state => state.subscription);
  let activeSubscriptions = _.get(subscriptionStatus, 'Active', []);
  const { dataExportMonths } = useSelector(state => state.app);
  return (
    <Row className="flex-between-center">
      <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{heading}</h5>
      </Col>
      {activeSubscriptions.length > 0 && (
        <Col xs={8} sm="auto" className="ms-auto text-end ps-0 d-flex">
          <div className="d-flex align-items-center ">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  {filterMonth
                    ? `Showing ${filterMonth.title}'s signing(s)`
                    : 'Apply filter by month'}
                </Tooltip>
              }
            >
              <span>
                <FontAwesomeIcon className="text-primary" icon={faFilter} />
              </span>
            </OverlayTrigger>
            {/* <Form.Select size="sm" className='mx-1' aria-label="Bulk actions">
            <option>Bulk Actions</option>
            <option value="refund">Refund</option>
            <option value="delete">Delete</option>
            <option value="archive">Archive</option>
          </Form.Select> */}

            <DropdownButton
              variant="Light"
              align="end"
              title={filterMonth?.title || 'Filter month'}
              id="dropdown-menu-align-right"
            >
              {_.map(dataExportMonths, m => {
                return (
                  <Dropdown.Item
                    key={_.uniqueId(filterMonth?.displayKey)}
                    className={
                      filterMonth?.begin === m.begin ? 'text-primary' : ''
                    }
                    onClick={() => setFilterMonth(m)}
                    eventKey={m.begin}
                  >
                    {m.title}
                  </Dropdown.Item>
                );
              })}

              <Dropdown.Divider />
              <Dropdown.Item
                eventKey="clear"
                onClick={() => setFilterMonth(null)}
              >
                Show All
              </Dropdown.Item>
            </DropdownButton>
          </div>
          {Object.keys(selectedRowIds).length > 0 ? (
            <div className="d-flex">
              <Form.Select size="sm" aria-label="Bulk actions">
                <option>Bulk Actions</option>
                <option value="refund">Refund</option>
                <option value="delete">Delete</option>
                <option value="archive">Archive</option>
              </Form.Select>
              <Button
                type="button"
                variant="falcon-default"
                size="sm"
                className="ms-2"
              >
                Apply
              </Button>
            </div>
          ) : (
            <div id="orders-actions">
              <Flex>
                <Form.Control
                  type="search"
                  placeholder="Search..."
                  aria-label="Search"
                  className="rounded-pill search-input"
                  value={searchString || ''}
                  onChange={({ target }) => setSearchString(target.value)}
                />

                {searchString && (
                  <div
                    className="search-box-close-btn-container"
                    // style={{ right: '10px', top: '8px' }}
                  >
                    <FalconCloseButton
                      size="sm"
                      noOutline
                      onClick={() => setSearchString('')}
                    />
                  </div>
                )}
              </Flex>
              {/*<IconButton
              variant="falcon-default"
              size="sm"
              icon="external-link-alt"
              transform="shrink-3"
            >
              <span className="d-none d-sm-inline-block ms-1">Export</span>
              </IconButton>*/}
            </div>
          )}
        </Col>
      )}
    </Row>
  );
};

NotaryJournalTableHeader.propTypes = {
  selectedRowIds: PropTypes.object
};

export default NotaryJournalTableHeader;
