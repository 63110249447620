import { CardElement, Elements, ElementsConsumer, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Flex from 'components/common/Flex';
import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import logger from 'util/logger';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

export default function AddPaymentChildForm({ handlePaymentIdMethod, isSaving, setSaving }) {
    //const [elements, setElements] = useState(null);
    const elements = useElements();
    const stripe = useStripe();
    const [savingStripeCard, setSavingStripeCard] = useState(false);

    const options = {
        // passing the client secret obtained from the server
        //clientSecret: '{{CLIENT_SECRET}}',
    };
    const handleSubmitForm = (evt) => {
        evt.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        submitPaymentForm(evt).then(res => {
            return handlePaymentIdMethod(res);
        }).catch(err => {
            return handlePaymentIdMethod(false);
        });
        return false;
    }


    const submitPaymentForm = (evt) => {
        if (evt) evt.preventDefault();
        setSavingStripeCard(true);
        return new Promise((resolve, reject) => {
            stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),
            })
                .then((res) => {
                    setSavingStripeCard(false);
                    const { error, paymentMethod } = res;
                    logger.log('payment method res', error, paymentMethod);

                    if (error && !_.isEmpty(error)) {
                        // code: "invalid_number"
                        // message: "Your card number is invalid."
                        // type: "validation_error"
                        setSavingStripeCard(false);
                        setSaving(false);
                        reject(error.message);
                    }
                    else if (!paymentMethod || !paymentMethod.id) {
                        setSaving(false);
                        setSavingStripeCard(false);
                        reject("There was an unknown error while processing your request.  Please try again");
                        //I'm not sure this is a real case.  I guarded against it because the docs did.
                    }
                    else {
                        setSavingStripeCard(false);
                        resolve(paymentMethod);
                    }
                }).catch(err => {
                    setSaving(false);
                    setSavingStripeCard(false);
                    reject("There was an unknown error while processing your request.  Please try after sometime");
                })
        })
    }

    return <Form onSubmit={(e) => handleSubmitForm(e)} autoComplete='off'>
        <CardElement />
        <Flex className={'my-3'} justifyContent={'center'}>
            <Button type='submit' disabled={isSaving || savingStripeCard} className='mt-3' variant='primary'>{isSaving || savingStripeCard ? "Saving..." : "Add Card"}</Button>
        </Flex>
    </Form>;
};
