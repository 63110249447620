import { faIdBadge } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { showAlert } from 'stores/modal';
import { actionRemoveIdentification } from 'stores/signing';
import enums from 'util/enums';

const IdentificationView = ({ identification, allowRemove }) => {
  const dispatch = useDispatch();

  const [popupIdentification, setPopupIdentification] =
    useState(identification);

  //setPopupIdentification(identification);
  const getIdentityAddress = entry => {
    ////logger.log("entry", entry);
    return entry ? (
      <>
        {' '}
        {entry.id_address_1 && entry.id_address_1.length > 0
          ? entry.id_address_1 + ' '
          : ''}{' '}
        {entry.id_address_2 && entry.id_address_2.length > 0
          ? entry.id_address_2 + ' '
          : ''}
        {entry.id_city && entry.id_city.length > 0 ? entry.id_city + ', ' : ''}
        {entry.id_state && entry.id_state.length > 0
          ? entry.id_state + ', '
          : ''}
        {entry.id_postal_code && entry.id_postal_code.length > 0
          ? entry.id_postal_code
          : ''}
      </>
    ) : (
      <></>
    );
  };

  const removeIdentification = async identification_id => {
    await Promise.all([
      dispatch(actionRemoveIdentification({ identification_id }))
    ]);
    let updatedIdentification = _.filter(
      popupIdentification,
      p => p.identification_id !== identification_id
    );
    setPopupIdentification(updatedIdentification);
    dispatch(showAlert('Success !!!', 'Identification removed successfully'));
  };

  const identityCard = identity => {
    return (
      <Col lg={12} className="mb-3" key={identity.identification_id}>
        <Card className="h-md-100">
          <Card.Header className="pb-0">
            <Flex justifyContent="between" alignItems="center">
              <Flex alignItems="center">
                <FontAwesomeIcon icon={faIdBadge} size="2x" className="me-3" />
                <h5 className="mb-0 mt-2">
                  {identity.id_first_name} {identity.id_last_name}{' '}
                  <SoftBadge
                    pill
                    bg={
                      identity.id_type === enums.ID_TYPES.NONE
                        ? 'warning'
                        : 'success'
                    }
                    className="me-2 fs--2"
                  ></SoftBadge>
                  <p className="fs--1 text-primary mb-2"> {identity.id_type}</p>
                </h5>
              </Flex>
              {allowRemove && (
                <Button
                  variant="outline-danger"
                  size="sm"
                  onClick={() =>
                    removeIdentification(identity.identification_id)
                  }
                >
                  Remove
                </Button>
              )}
            </Flex>
            <hr className="my-0" />
          </Card.Header>

          <Card.Body
            as={Flex}
            alignItems="end"
            justifyContent="between"
            className="pt-1"
          >
            <div>
              {identity.id_number && (
                <p className="mb-1">
                  <span className="fw-semi-bold">Id Number:</span>{' '}
                  {identity.id_number}
                </p>
              )}
              {identity.id_issued_date && (
                <p className="mb-1">
                  <span className="fw-semi-bold">Issue Date:</span>{' '}
                  {moment
                    .utc(identity.id_issued_date)
                    .local()
                    .format('dddd, MMMM Do YYYY')}
                </p>
              )}
              {identity.id_expiration_date && (
                <p className="mb-1">
                  <span className="fw-semi-bold">Expiration Date:</span>{' '}
                  {moment
                    .utc(identity.id_expiration_date)
                    .local()
                    .format('dddd, MMMM Do YYYY')}
                </p>
              )}
              {identity.date_of_birth && (
                <p className="mb-1">
                  <span className="fw-semi-bold">Date of Birth:</span>{' '}
                  {moment
                    .utc(identity.date_of_birth)
                    .local()
                    .format('dddd, MMMM Do YYYY')}
                </p>
              )}
              <p className="mb-1">
                <span className="fw-semi-bold">Address:</span>{' '}
                {getIdentityAddress(identity)}
              </p>
              {identity.notes && (
                <p className="mb-1">
                  <span className="fw-semi-bold">Notes:</span>{' '}
                  <i className="fs--1">{identity.notes}</i>
                </p>
              )}
            </div>
            <div></div>
          </Card.Body>
        </Card>
      </Col>
    );
  };

  return (
    <Row>
      {popupIdentification.length ? (
        <>
          {_.map(popupIdentification, i => {
            return i ? identityCard(i) : <>Identification Detail not found</>;
          })}
        </>
      ) : (
        <Col>
          <p className="text-center">No identification available</p>
        </Col>
      )}
    </Row>
  );
};
IdentificationView.propTypes = {
  allowRemove: PropTypes.bool,
  identification: PropTypes.any
};

export default IdentificationView;
