import {
  faAndroid,
  faApple,
  faMicrosoft
} from '@fortawesome/free-brands-svg-icons';
import { faLaptop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import Colors from 'util/Colors';

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const monthsShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const DeviceIcon = (e, iconOnly) => {
  let icon = null;
  let color = null;
  if (
    e.Model.toLowerCase().indexOf('iphone') !== -1 ||
    e.Manufacturer.toLowerCase().indexOf('apple') !== -1
  ) {
    icon = faApple;
    color: Colors.apple.color;
  } else if (e.Manufacturer.toLowerCase().indexOf('windows') !== -1) {
    icon = faMicrosoft;
    color: Colors.apple.color;
  } else if (e.Manufacturer.toLowerCase().indexOf('mac os') !== -1) {
    icon = faLaptop;
    color: Colors.apple.color;
  } else {
    icon = faAndroid;
    color = Colors.android.color;
  }
  return icon ? (
    iconOnly ? (
      icon
    ) : (
      <FontAwesomeIcon color={color} className="fa-fw" icon={icon} />
    )
  ) : (
    <></>
  );
};

export const progressPercentStatus = data => {
  if (data && data.length) {
    let lastItem = data[data.length - 1];
    let average = _.sum(data) / data.length;
    let percent =
      average > lastItem
        ? Math.round((lastItem * 100) / average, 1)
        : Math.round((average * 100) / lastItem, 1);
    return (_.isNaN(percent) ? <></> :
      <SoftBadge
        pill
        bg={average > lastItem ? 'danger' : 'success'}
        className="me-2 fs--2"
      >
        {average > lastItem ? '-' : '+'}
        {percent}%
      </SoftBadge>
    );
  } else {
    return (
      <SoftBadge pill bg="primary" className="me-2 fs--2">
        0%
      </SoftBadge>
    );
  }
};
