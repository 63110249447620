import React, { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import invite from 'assets/img/icons/spot-illustrations/41.png';
import piiBadge from 'assets/img/jurat/piiBadge.png';
import piiBadgeBorder from 'assets/img/jurat/piiBadgeBorder.png';
import { Link } from 'react-router-dom';
import IconButton from 'components/common/IconButton';
import PageHeader from 'components/common/PageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import segmentHelper from 'helpers/segment-helper';

const AffiliateProgram = () => {
  const [showModal, setShowModal] = useState(false);

  const handleCopy = () => {
    setShowModal(true);
  };

  return (
    <>
      <PageHeader
        preTitle="Affiliate Program"
        title="The Notary eJournal Affiliate Program"
        description=""
        className="mb-3"
        titleTag="h2"
      >
        <div></div>

      </PageHeader>
      <Card>
        <Card.Body className="text-center py-5">

          <h3 className="mt-3 fw-normal fs-2 mt-md-4 fs-md-3">
            Spread the word. Get paid
          </h3>
          <p className="lead mb-5">
            Love The Notary eJournal by Jurat inc? We love you back! Earn commissions for new user conversions that sign up with your link.
          </p>
          <p>We are excited to offer our users the opportunity to benefit from sharing our app with their friends, colleagues and social media audiences. We truly believe in the power of genuine referrals, which is why we require all affiliates to be active subscribers of The Notary eJournal. Upon signing up to become an affiliate, we will verify your account status and notify you when you have been approved</p>
          <Row className="gx-2 my-4">
            <Col lg={12} className="d-flex justify-content-center flex-wrap">
              <Link target="_blank" to="https://juratinc.leaddyno.com" onClick={() => segmentHelper.track(segmentHelper.events.LOGIN_TO_AFFILIATE_DASHBOARD)}>
                <Button
                  as='Link'
                  variant="primary"
                  className="d-block mb-2 mb-2 mx-1"
                >

                  <span className="fw-medium ms-2">Log in to Affiliate Dashboard</span>
                </Button>
              </Link>
              <Link target="_blank" to="https://juratinc.leaddyno.com" onClick={() => segmentHelper.track(segmentHelper.events.APPLY_TO_BECOME_AN_AFFILIATE)}>
                <Button
                  variant="primary"
                  className="d-block mb-2 mb-2 mx-1"
                >
                  <span className="fw-medium ms-2">Apply to Become an Affiliate</span>
                </Button>
              </Link>
              <Link target="_blank" to="https://www.juratinc.com/affiliate-program" onClick={() => segmentHelper.track(segmentHelper.events.AFFILIATE_PROGRAM_LEARN_MORE)}>
                <Button
                  variant="falcon-primary"
                  className="d-block mb-2 mb-2 mx-1"
                >
                  <FontAwesomeIcon icon="link" />
                  <span className="fw-medium ms-2">Learn More</span>
                </Button>
              </Link>
            </Col>

          </Row>
        </Card.Body>
        <Card.Footer className="bg-light text-center pt-4">
          <Row className="justify-content-center">
            <Col xs={11} sm={10}>


              <p className="mb-2 fs--1">
                Once you’ve invited friends, you can{' '}
                <Link to="#!">view the status of your referrals</Link>{' '}
                <br className="d-none d-lg-block d-xxl-none" /> or visit our{' '}
                <Link to="#!">Help Center</Link> if you have any questions.{' '}
              </p>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </>
  );
};

export default AffiliateProgram;
