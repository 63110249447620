import config from "./config";

const logger = {
    log(...args) {
        if (config.debug) {
            console.log(...args);
        }
    },

    warn(...args) {
        if (config.debug) {
            console.warn(...args);
        }
    },

    error(...args) {
        console.error(...args);
    }
}

export default logger;
