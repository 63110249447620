import { createSlice, current } from '@reduxjs/toolkit';
import _ from 'lodash';
import moment from 'moment';
import idGenerator from 'util/idGenerator';
import { v4 as uuidv4 } from 'uuid';
import logger from '../util/logger';
import c from 'util/const';

export const signingSlice = createSlice({
  name: 'signing',
  initialState: {
    requestGUID: null,
    type: null,
    draft_id: null,
    signing_timestamp: null,
    localTimezone: null,
    userProfileGUID: null,
    startedAtDate: null,
    completedAtDate: null,
    promptForRatingOnComplete: false,
    hasSigningBeenFinished: false,

    location: null,
    notarization_details: null,

    signers: [],
    notarization_documents: [],
    identifications: [],
    signatures: [],
    fingerprints: [],

    witnesses: [],

    recipientEmails: [],
    auditLog: []
  },

  reducers: {
    start: (state, action) => {
      state.requestGUID = uuidv4();
      state.type = c.actions.newEntry.start;
      state.draft_id = action.payload.draft_id;
      state.hasSigningBeenFinished = false;
      state.promptForRatingOnComplete =
        action.payload.promptForRatingOnComplete;
      state.startedAtDate = action.payload.startedAtDate;
      state.signing_timestamp = null;
      state.localTimezone = null;
      state.userProfileGUID = null;
      state.completedAtDate = null;
      state.location = null;
      state.notarization_details = null;
      state.signers = [];
      state.notarization_documents = [];
      state.identifications = [];
      state.signatures = [];
      state.fingerprints = [];
      state.witnesses = [];
      state.recipientEmails = [];
      state.auditLog = [];
    },
    reInstateSigning: (state, action) => {
      state.requestGUID = action.payload.requestGUID;
      state.type = action.payload.type;
      state.draft_id = action.payload.draft_id;
      state.startedAtDate = action.payload.startedAtDate;
      state.signing_timestamp = action.payload.signing_timestamp;
      state.localTimezone = action.payload.localTimezone;
      state.userProfileGUID = action.payload.userProfileGUID;
      state.completedAtDate = action.payload.completedAtDate;
      state.promptForRatingOnComplete =
        action.payload.promptForRatingOnComplete;
      state.hasSigningBeenFinished = action.payload.hasSigningBeenFinished;
      state.location = action.payload.location;
      state.notarization_details = action.payload.notarization_details;
      state.signers = action.payload.signers;
      state.notarization_documents = action.payload.notarization_documents;
      state.identifications = action.payload.identifications;
      state.signatures = action.payload.signatures;
      state.fingerprints = action.payload.fingerprints;
      state.witnesses = action.payload.witnesses;
      state.recipientEmails = action.payload.recipientEmails;
      state.auditLog = action.payload.auditLog;
    },
    setSigningFinished: (state, action) => {
      state.hasSigningBeenFinished = true;
      state.completedAtDate = action.payload.completedAtDate;
      state.type = action.payload.type;
    },
    setSigningUnFinished: (state, action) => {
      state.hasSigningBeenFinished = false;
    },
    logSigningAction: (state, action) => {
      state.auditLog = _.concat([], state.auditLog, {
        ...action.payload.item
      });
    },
    logMultipleSigningActions: (state, action) => {
      state.auditLog = _.concat([], state.auditLog, action.items);
    },
    addRecipientEmail: (state, action) => {
      state.recipientEmails = _.concat(state.recipientEmails, [
        action.payload.email
      ]);
    },
    removeRecipientEmail: (state, action) => {
      state.recipientEmails = _.filter(
        _.concat(state.recipientEmails, []),
        e => e !== action.payload.email
      );
    },
    setLocationInfo: (state, action) => {
      logger.log('Action', action);
      state.location = {
        address_1: action.payload.address_1,
        address_2: action.payload.address_2,
        city: action.payload.city,
        state: action.payload.state,
        zip: action.payload.zip,
        latitude: action.payload.latitude,
        longitude: action.payload.longitude
      };
    },
    setLocationName: (state, action) => {
      let newLoaction = state.location;
      newLoaction.locationName = action.payload;
      state.location = newLoaction;
    },
    addSigner: (state, action) => {
      logger.log('Saving signer', action);
      if (action.payload.signer !== null) {
        if (state.signers)
          state.signers = _.concat([], state.signers, {
            ...action.payload.signer
          });
        else state.signers = _.concat([], { ...action.payload.signer });
      }
    },
    cleanSigners: (state, action) => {
      let signerClean = _.concat([], state.signers);
      _.remove(signerClean, i => !i.signer_id);

      let witnessClean = _.concat([], state.witnesses);
      _.remove(witnessClean, i => !i.signer_id);
      state.witnessClean = _.concat([], state.signers, { ...action.signer });

      state.witnesses = witnessClean;
      state.signers = signerClean;
    },
    removeSigner: (state, action) => {
      //logger.log(action.payload);
      let signerUpdate = _.concat([], state.signers || []);
      logger.log('signerupdate', signerUpdate);
      if (signerUpdate.length > 0) {
        _.remove(signerUpdate, a => a.signer_id === action.payload.signer_id);
      }
      //Kind of a hack, if you choose to add witness, a signer gets added without a signer_id because it gets added later
      //This just clears it out when you're removing a signer.
      _.remove(signerUpdate, k => !k.signer_id);

      let witnessUpdate = _.concat([], state.witnesses || []);
      _.remove(witnessUpdate, l => l.signer_id === action.payload.signer_id);

      let identificationUpdate = _.concat(state.identifications || []);
      if (identificationUpdate.length > 0) {
        _.remove(
          identificationUpdate,
          m => m.signer_id === action.payload.signer_id || !m.identification_id
        );
      }

      state.witnesses = witnessUpdate;
      state.identifications = identificationUpdate;
      state.signers = signerUpdate;
      logger.log('Updating Signers on state', signerUpdate);
    },
    setSignerName: (state, action) => {
      logger.log('setSignerUpdate action', action);
      let setSignerUpdate = state.signers || [];
      logger.log('setSignerUpdate', setSignerUpdate);
      let foundSigner = _.remove(
        setSignerUpdate,
        n => n.signer_id === action.payload.signer.signer_id || !n.signer_id
      )[0]; //This returns an array, but there will only be one.
      logger.log('foundSigner', foundSigner);
      foundSigner = _.extend({}, foundSigner, action.payload.signer);
      logger.log('foundSigner', foundSigner);
      setSignerUpdate.push(foundSigner);
      logger.log('setSignerUpdate', setSignerUpdate);
      state.signers = setSignerUpdate;
    },
    setSignerIdType: (state, action) => {
      logger.log('setSignerIdType action', action);
      let sUpdate = _.concat([], state.signers || []);
      let foundS = _.remove(
        sUpdate,
        i => i.signer_id === action.payload.signer_id
      )[0]; //This returns an array, but there will only be one.

      logger.log('setSignerIdType before', foundS);

      let ifoundSigner = _.extend({}, foundS, {
        signer_id: action.payload.signer_id,
        identificationFlow: action.payload.identificationFlow
      });
      sUpdate.push(ifoundSigner);

      state.signers = sUpdate;
    },
    addWitnessIdentification: (state, action) => {
      logger.log('addWitnessIdentification action', action);
      let wIdUpdate = _.concat([], state.witnesses || []);
      let fw = _.remove(
        wIdUpdate,
        w => w.witness_id === action.payload.witness_id
      )[0]; //This returns an array, but there will only be one.

      if (!fw) {
        fw = {
          signer_id: action.payload.signer_id,
          witness_id: action.payload.witness_id
        };
      }

      fw.witness_name = action.payload.witness_name;
      wIdUpdate.push(fw);

      state.witnesses = wIdUpdate;
      state.identifications = _.concat(state.identifications || [], {
        witness_id: action.payload.witness_id,
        signer_id: action.payload.signer_id,
        ...action.payload.identification
      });
    },
    setWitnessLocation: (state, action) => {
      logger.log('curret witness ib state', state.witnesses);
      logger.log('setWitnessLocation action', action);
      let wLocUpdate = _.concat([], state.witnesses || []);
      let foundW = _.remove(
        wLocUpdate,
        w => w.witness_id === action.payload.witness_id
      )[0]; //This returns an array, but there will only be one.

      foundW.location = action.payload.location;
      wLocUpdate.push(foundW);
      state.witnesses = wLocUpdate;
    },
    setWitnessSignature: (state, action) => {
      logger.log('setWitnessSignature action', action);
      let signaturePayload = {
        witness_id: action.payload.witness_id,
        imgData: action.payload.signature.data,
        signature: {
          witness_id: action.payload.witness_id,
          signature_id: idGenerator.getSignatureId(),
          imgData: action.payload.signature
        }
      };
      logger.log('setWitnessSignature signaturePayload', signaturePayload);
      let updatedWitness = [];
      _.each(state.witnesses, w => {
        if (w.witness_id === signaturePayload.witness_id) {
          w.signature = { ...signaturePayload.signature.imgData };
        }
        updatedWitness.push(w);
      });
      state.witnesses = updatedWitness;
      // let wSigUpdate = current(state.witnesses) || [];
      // let foundWitness = _.remove(
      //   wSigUpdate,
      //   w => w.witness_id === signaturePayload.witness_id
      // )[0]; //This returns an array, but there will only be one.
      // logger.log('setWitnessSignature foundWitness', foundWitness);
      // foundWitness.signature = { ...signaturePayload.signature.imgData };
      // wSigUpdate.push(foundWitness);
      // logger.log('setWitnessSignature wSigUpdate', wSigUpdate);
      // state.witnesses = wSigUpdate;
    },

    addIdentification: (state, action) => {
      logger.log('addIdentification action', action);
      if (action.payload) {
        let identificationUpdated = _.concat(state.identifications || [], {
          ...action.payload
        });
        //logger.log("state.identifications", state.identifications);
        //logger.log("identificationUpdated", identificationUpdated);
        state.identifications = identificationUpdated;
      }
    },

    removeIdentification: (state, action) => {
      logger.log('actionRemoveIdentification action', action);
      let update = _.concat(state.identifications);
      _.remove(
        update,
        i =>
          i.identification_id === action.payload.identification_id ||
          !i.identification_id
      );
      state.identifications = update;
    },

    addSignature: (state, action) => {
      // let updatedSignature = _.filter(
      //   state.signatures,
      //   s => s.signer_id !== action.payload.signer_id
      // );
      let updatedSignature = state.signatures || [];
      updatedSignature.push({
        signature_id: idGenerator.getSignatureId(),
        signer_id: action.payload.signer_id,
        imgData: { ...action.payload.signature }
      });
      state.signatures = updatedSignature;
    },
    addSignatureByMark: (state, action) => {
      logger.log('addSignatureByMark', action);
      state.signatures = _.concat([], state.signatures || [], {
        ...action.payload.signature
      });
    },
    removeSignature: (state, action) => {
      //logger.log('removeSignature', action);
      state.signatures = _.filter(
        state.signatures,
        i => i.signature_id !== action.payload.signature_id
      );
    },
    addFingerprint: (state, action) => {
      state.fingerprints = _.concat([], state.fingerprints || [], {
        signer_id: action.signer_id,
        ...action.fingerprint
      });
    },
    removeFingerprint: (state, action) => {
      state.fingerprints = _.filter(
        state.fingerprints,
        i => i.fingerprint_id !== action.payload.fingerprint_id
      );
    },
    setNotarizationDetails: (state, action) => {
      state.notarization_details = {
        notary_fee: action.payload.notary_fee,
        currency: action.payload.currency,
        notes: action.payload.notes,
        pictures: action.payload.pictures,
        Signing_Fees: action.payload.Signing_Fees
      };
    },
    addNotarizationDocuments: (state, action) => {
      state.notarization_documents = _.concat(
        [],
        state.notarization_documents,
        action.payload
      );
    },
    addNotarizationDocumentsMass: (state, action) => {
      logger.log('addNotarizationDocumentsMass', action.payload);
      state.notarization_documents = action.payload;
    },
    updateNotarizationDocument: (state, action) => {
      logger.log('updateNotarizationDocument action', action);
      let updateNotarizationDocuments = _.concat(
        [],
        state.notarization_documents
      );
      let existingIndex = _.findIndex(
        updateNotarizationDocuments,
        i => i.document_id === action.payload.document_id
      );
      updateNotarizationDocuments[existingIndex].notarization_type =
        action.payload.notarization_type;
      updateNotarizationDocuments[existingIndex].signer_ids =
        action.payload.signer_ids;
      updateNotarizationDocuments[existingIndex].notes = action.payload.notes;

      state.notarization_documents = updateNotarizationDocuments;
    },
    setSigningTimestamp: (state, action) => {
      state.signing_timestamp = action.payload;
    },
    setLocalTimezone: (state, action) => {
      state.localTimezone = action.payload;
    },
    setUserProfileGUID: (state, action) => {
      state.userProfileGUID = action.payload;
    },
    removeNotarizationDocument: (state, action) => {
      let docUpdate = _.concat([], state.notarization_documents || []);
      _.remove(docUpdate, i => i.document_id === action.document_id);

      state.notarization_documents = docUpdate;
    },

    reset: (state, action) => {
      state.requestGUID = null;
      state.type = null;
      state.draft_id = null;
      state.draft_id = null;
      state.startedAtDate = null;
      state.completedAtDate = null;
      state.promptForRatingOnComplete = false;
      state.hasSigningBeenFinished = false;

      state.location = null;
      state.notarization_details = null;

      state.signers = [];
      state.notarization_documents = [];
      state.identifications = [];
      state.signatures = [];
      state.fingerprints = [];

      state.witnesses = [];

      state.recipientEmails = [];
      state.auditLog = [];
    }
  }
});

let {
  start,
  reInstateSigning,
  setSigningFinished,
  setSigningUnFinished,
  logSigningAction,
  logMultipleSigningActions,
  addRecipientEmail,
  removeRecipientEmail,
  setLocationInfo,
  setLocationName,
  addSigner,
  cleanSigners,
  removeSigner,
  setSignerName,
  setSignerIdType,
  addWitnessIdentification,
  setWitnessLocation,
  setWitnessSignature,
  addIdentification,
  removeIdentification,
  addSignature,
  addSignatureByMark,
  removeSignature,
  addFingerprint,
  removeFingerprint,
  setNotarizationDetails,
  addNotarizationDocuments,
  addNotarizationDocumentsMass,
  updateNotarizationDocument,
  setSigningTimestamp,
  setLocalTimezone,
  setUserProfileGUID,
  removeNotarizationDocument,
  reset
} = signingSlice.actions;

let actions = {
  actionStart: payload => {
    return (dispatch, getState) => {
      return Promise.all([dispatch(start(payload))]);
    };
  },

  actionReInstateSigning: payload => {
    return (dispatch, getState) => {
      return Promise.all([dispatch(reInstateSigning(payload))]);
    };
  },

  actionSetSigningFinished: payload => {
    return (dispatch, getState) => {
      return Promise.all([dispatch(setSigningFinished(payload))]);
    };
  },
  actionSetSigningUnFinished: payload => {
    return (dispatch, getState) => {
      return Promise.all([dispatch(setSigningUnFinished(payload))]);
    };
  },

  actionLogSigningAction: description => {
    return (dispatch, getState) => {
      return Promise.all([
        dispatch(
          logSigningAction({
            item: {
              audit_log_item_id: idGenerator.getAuditLogItemId(),
              description: description,
              timestamp: moment().utc().format()
            }
          })
        )
      ]);
    };
  },

  actionLogMultipleSigningActions: () => {
    return (dispatch, getState) => {
      return Promise.all([dispatch(logMultipleSigningActions())]);
    };
  },

  actionAddRecipientEmail: payload => {
    return (dispatch, getState) => {
      return Promise.all([dispatch(addRecipientEmail(payload))]);
    };
  },

  actionRemoveRecipientEmail: payload => {
    return (dispatch, getState) => {
      return Promise.all([dispatch(removeRecipientEmail(payload))]);
    };
  },

  actionSetLocationInfo: payload => {
    return (dispatch, getState) => {
      return Promise.all([dispatch(setLocationInfo(payload))]);
    };
  },

  actionSetLocationName: payload => {
    return (dispatch, getState) => {
      return Promise.all([dispatch(setLocationName(payload))]);
    };
  },

  actionAddSigner: payload => {
    return (dispatch, getState) => {
      return Promise.all([dispatch(addSigner(payload))]);
    };
  },

  actionCleanSigners: () => {
    return (dispatch, getState) => {
      return Promise.all([dispatch(cleanSigners())]);
    };
  },

  actionRemoveSigner: signer_id => {
    return (dispatch, getState) => {
      return Promise.all([dispatch(removeSigner({ signer_id: signer_id }))]);
    };
  },

  actionSetSignerName: payload => {
    return (dispatch, getState) => {
      return Promise.all([dispatch(setSignerName(payload))]);
    };
  },

  actionSetSignerIdType: paylaod => {
    return (dispatch, getState) => {
      return Promise.all([dispatch(setSignerIdType(paylaod))]);
    };
  },

  actionAddWitnessIdentification: payload => {
    return (dispatch, getState) => {
      return Promise.all([dispatch(addWitnessIdentification(payload))]);
    };
  },

  actionSetWitnessLocation: payload => {
    return (dispatch, getState) => {
      return Promise.all([dispatch(setWitnessLocation(payload))]);
    };
  },

  actionSetWitnessSignature: payload => {
    return (dispatch, getState) => {
      logger.log('actionSetWitnessSignature payload', payload);
      return Promise.all([dispatch(setWitnessSignature(payload))]);
    };
  },

  actionAddIdentification: payload => {
    logger.log('actionAddIdentification', payload);
    return (dispatch, getState) => {
      return Promise.all([dispatch(addIdentification(payload))]);
    };
  },

  actionRemoveIdentification: payload => {
    return (dispatch, getState) => {
      return Promise.all([dispatch(removeIdentification(payload))]);
    };
  },

  actionAddSignature: payload => {
    return (dispatch, getState) => {
      return Promise.all([dispatch(addSignature(payload))]);
    };
  },

  actionAddSignatureByMark: (signer_id, imgData, witnessData) => {
    return (dispatch, getState) => {
      return Promise.all([
        dispatch(
          addSignatureByMark({
            signature: {
              signature_id: idGenerator.getSignatureId(),
              signer_id,
              imgData,
              witnessData
            }
          })
        )
      ]);
    };
  },

  actionRemoveSignature: payload => {
    return (dispatch, getState) => {
      return Promise.all([dispatch(removeSignature(payload))]);
    };
  },

  actionAddFingerprint: () => {
    return (dispatch, getState) => {
      return Promise.all([dispatch(addFingerprint())]);
    };
  },

  actionRemoveFingerprint: payload => {
    return (dispatch, getState) => {
      return Promise.all([dispatch(removeFingerprint(payload))]);
    };
  },

  actionSetNotarizationDetails: payload => {
    return (dispatch, getState) => {
      return Promise.all([dispatch(setNotarizationDetails(payload))]);
    };
  },

  actionAddNotarizationDocuments: payload => {
    return (dispatch, getState) => {
      return Promise.all([dispatch(addNotarizationDocuments(payload))]);
    };
  },

  actionAddNotarizationDocumentsMass: payload => {
    return (dispatch, getState) => {
      logger.log('Payload', payload);
      return Promise.all([dispatch(addNotarizationDocumentsMass(payload))]);
    };
  },

  actionUpdateNotarizationDocument: payload => {
    return (dispatch, getState) => {
      return Promise.all([dispatch(updateNotarizationDocument(payload))]);
    };
  },

  actionRemoveNotarizationDocument: () => {
    return (dispatch, getState) => {
      return Promise.all([dispatch(removeNotarizationDocument())]);
    };
  },

  actionSetSigningTimestamp: payload => {
    return (dispatch, getState) => {
      return Promise.all([dispatch(setSigningTimestamp(payload))]);
    };
  },

  actionSetLocalTimezone: payload => {
    return (dispatch, getState) => {
      return Promise.all([dispatch(setLocalTimezone(payload))]);
    };
  },

  actionSetUserProfileGUID: payload => {
    return (dispatch, getState) => {
      return Promise.all([dispatch(setUserProfileGUID(payload))]);
    };
  },

  actionMapSignerToAllDocs(payload) {
    return (dispatch, getState) => {
      let { notarization_documents } = getState().signing;
      _.each(notarization_documents, doc => {
        let currentSigners = doc.signer_ids;

        let found = _.find(currentSigners, sid => sid === payload.signer_id);
        if (!found) {
          dispatch(
            actionUpdateNotarizationDocument({
              document_id: doc.document_id,
              notarization_type: doc.notarization_type,
              signer_ids: _.concat([payload.signer_id], doc.signer_ids),
              notes: doc.notes
            })
          );
        }
      });
    };
  },

  actionReset: () => {
    return (dispatch, getState) => {
      dispatch(reset());
    };
  }
};

export const {
  actionStart,
  actionReInstateSigning,
  actionSetSigningFinished,
  actionSetSigningUnFinished,
  actionLogSigningAction,
  actionAddRecipientEmail,
  actionRemoveRecipientEmail,
  actionSetLocationInfo,
  actionSetLocationName,
  actionAddSigner,
  actionCleanSigners,
  actionRemoveSigner,
  actionSetSignerName,
  actionSetSignerIdType,
  actionAddWitnessIdentification,
  actionSetWitnessLocation,
  actionSetWitnessSignature,
  actionAddIdentification,
  actionRemoveIdentification,
  actionAddSignature,
  actionAddSignatureByMark,
  actionRemoveSignature,
  actionAddFingerprint,
  actionRemoveFingerprint,
  actionSetNotarizationDetails,
  actionAddNotarizationDocuments,
  actionAddNotarizationDocumentsMass,
  actionUpdateNotarizationDocument,
  actionRemoveNotarizationDocument,
  actionSetSigningTimestamp,
  actionSetLocalTimezone,
  actionSetUserProfileGUID,
  actionMapSignerToAllDocs,
  actionReset
} = actions;

export default signingSlice.reducer;
