import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Lottie from 'lottie-react';
import checkPrimaryLight from 'assets/img/animated-icons/check-primary-light.json';
import { Link } from 'react-router-dom';
import PageHeader from 'components/common/PageHeader';
import corner1 from 'assets/img/illustrations/corner-1.png';

const SigningSuccess = () => {
  return (
    <>
      <PageHeader
        preTitle=""
        title="Success"
        description="Journal entry successfully saved."
        className="mb-3"
        titleTag="h2"
        image={corner1}
      ></PageHeader>
      <Row className="g-3 mb-3">
        <Col className="text-center">
          <Flex className="mt-2" justifyContent="center">
            <Lottie
              animationData={checkPrimaryLight}
              loop={false}
              style={{ height: '150px', width: '120px' }}
            />
          </Flex>
          <h4 className="mb-1">New Journal entry created!</h4>
          <p className="fs-0">
            Congrats! Your notary journal entry has been successfully saved to
            our secure servers.
          </p>
          <Button
            type="button"
            variant="outline-primary"
            as={Link}
            to="/notary/drafts"
            className="px-5 my-3 mx-1"
          >
            View Drafts
          </Button>
          <Button
            type="button"
            variant="outline-primary"
            as={Link}
            to="/notary/journal"
            className="px-5 my-3 mx-1"
          >
            View Journal
          </Button>
        </Col>
      </Row>
    </>
  );
};

SigningSuccess.propTypes = {};

export default SigningSuccess;
