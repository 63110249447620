import { version } from 'config';
import packageInfo from '../../package.json';

const { homepage } = packageInfo;

export const faqs = [
  {
    id: 0,
    title: 'Creating a new notary journal entry',
    description:
      `<p class = "mb-1">
      <strong>Step 1</strong><br/>
      Sign in to the Notary eJournal to access your dashboard. The dashboard populates with your most recent notary journal entries, and gives you the option to start a new signing, access the menus, search through your notary journal entries or test your bluetooth thumbprint devices (thumbprints are only required in the State of California
      </p>
      <p class = "mb-1">
      <strong>Step 2</strong><br/>
      From the dashboard, locate and press the “start signing” button towards the top of the screen
      </p>
      <p class = "mb-1">
      <strong>Step 3</strong><br/>
      Follow the on-screen prompts to complete steps 1 through 6, starting with the location of which the signing is taking place. The on-screen prompts will guide you through collecting all of the relevant information needed to complete the notary journal entry
      </p>
      <p class = "mb-1">
      For a detailed article on this, <a target="_blank" href="https://www.juratinc.com/faq">click here</a>
      </p>`
  },
  {
    id: 1,
    title: 'Entering a signer\'s ID',
    description:
      `<p class = "mb-1">
      <strong>Step 1</strong><br/>
      After entering your location information in the first step, click “add new signer” at the top of the page on step 2.
      </p>
      <p class = "mb-1">
      <strong>Step 2</strong><br/>
      Select which method is being used to identify the signer. The most common method of identifying the signer is by using the signer’s approved form of paper identification (i.e. drivers license or passport)
      </p>
      <p class = "mb-1">
      <strong>Step 3</strong><br/>
      Assuming you’re using an ID to identify the signer, either manually enter their ID information or click “scan identification” to scan their ID with your phone. This will input all of the relevant fields from their ID automatically and speed up the process dramatically
      </p>
      <p class = "mb-1">
      For a detailed article on this, <a target="_blank" href="https://www.juratinc.com/entering-a-signers-id-information">click here</a>
      </p>`
  },
  {
    id: 2,
    title: 'Adding multiple signers to a notary journal entry ',
    description:
      `<p class = "mb-1">
      <strong>Step 1</strong><br/>
      Add the first signer by clicking “Add New Signer”. This step comes after entering the location information for the signing.</p>
      <p class = "mb-1">
      <strong>Step 2</strong><br/>
      Scan their ID or manually input their ID information, then hit “Save”. This will add the signer to the session and bring you back to step 2, giving you the option of adding another signer or moving on (if there is only one signer)</p>
      <p class = "mb-1">
      <strong>Step 3</strong><br/>
      Click “Add New Signer” again to add another signer. Follow the same procedure for inputting their ID information and hit “save” to add the second signer to the session</p>
      <p class = "mb-1">
      <strong>Step 4</strong><br/>
      If you are finished adding new signers, click “next” on the bottom of the screen to continue. Otherwise, you can add as many signers as necessary by repeating the previous steps.</p>
      <p class = "mb-1">
      For a detailed article on this, <a target="_blank" href="https://www.juratinc.com/adding-multiple-signers-to-a-notary-journal-entry">click here</a>
      </p>`
  },
  {
    id: 3,
    title: 'Using your phone’s GPS to input location fields',
    description:
      `<p class = "mb-1">
      <strong>Step 1</strong><br/>
      After starting a new notary journal entry, step 1 will ask for location information. To use your phone’s GPS to instantly fill in all relevant location information, click “Detect Current Location” at the top of step 1</p>
      <p class = "mb-1">
      <strong>Step 2</strong><br/>
      Make sure you allow the Notary eJournal to access your location by clicking “Allow while using app” when prompted. You can also adjust location settings in the privacy section of your phone’s setting menus</p>
      <p class = "mb-1">
      <strong>Step 3</strong><br/>
      Alternatively, you can simply start typing the address of the signing in the search field to find your exact location and the app will enter all relevant location fields instantly.
      </p>
      <p class = "mb-1">
      For a detailed article on this, <a target="_blank" href="https://www.juratinc.com/using-your-phones-gps-to-input-location-fields">click here</a>
      </p>`
  },
  {
    id: 4,
    title: 'How to capture thumbprints in the Notary eJournal?',
    description:
      `<p class = "mb-1">
      <strong>Step 1</strong><br/>
      Log in to your Notary eJournal and click on the thumbprint icon in the upper right portion of the screen. This will allow you to test your SecuGen Unity 20 to ensure proper function</p>
      <p class = "mb-1">
      <strong>Step 2</strong><br/>
      Power on your SecuGen Unity 20 by pressing down the power button until the unit flashes, indicating that it is on</p>
      <p class = "mb-1">
      <strong>Step 3</strong><br/>
      Click “Connect” once your SecuGen Unity 20 appears on screen.
      </p>
      <p class = "mb-1">
      <strong>Step 4</strong><br/>
      Click “Test Fingerprint Capture” to perform a test scan to ensure your device is working properly. Now you’re ready to go!
      </p>
      <p class = "mb-1">
      For a detailed article on this, <a target="_blank" href="https://www.juratinc.com/how-to-capture-thumbprints-in-your-notary-ejournal">click here</a>
      </p>`
  },
  {
    id: 5,
    title: 'How to create and use signing packages?',
    description:
      `<p class = "mb-1">
      <strong>Step 1</strong><br/>
      From the main menu, click “Signing Packages” to add a new signing package or edit existing ones
      </p>
      <p class = "mb-1">
      <strong>Step 2</strong><br/>
      Click “New Signing Package” to create a new one. Once you have signing packages in your library, they will appear here
      </p>
      <p class = "mb-1">
      <strong>Step 3</strong><br/>
      Name the signing package and add any additional notes along with the notary fee. These are optional and can be changed when creating a new entry if needed. Finish by adding the documents and hitting “save”
      </p>
      <p class = "mb-1">
      <strong>Step 4</strong><br/>
      To use a signing package when creating a new entry, click “load package” in step 3 of the app and select which signing package you’d like to use
      </p>
      <p class = "mb-1">
      For a detailed article on this, <a target="_blank" href="https://www.juratinc.com/signing-packages">click here</a>
      </p>`
  },
  {
    id: 6,
    title: 'Understanding draft entries ',
    description:
      `<p class = "mb-1">
      <strong>Step 1</strong><br/>
      All new entries begin as a draft. Drafts can be returned to at any time.
      </p>
      <p class = "mb-1">
      <strong>Step 2</strong><br/>
      Progress is saved in real-time. Click the “x” or "history" button in the top left of the screen to leave a draft entry and return to the home page
      </p>
      <p class = "mb-1">
      <strong>Step 3</strong><br/>
      Drafts appear as a different color than completed entries, and are marked as “draft”
      </p>
      <p class = "mb-1">
      <strong>Step 3</strong><br/>
      Perform a long press on the entry from the home page to access more options, including the ability to delete a draft.</p>
      <p class = "mb-1">
      For a detailed article on this, <a target="_blank" href="https://www.juratinc.com/draft-entries">click here</a>
      </p>`
  },
  {
    id: 7,
    title: 'Using the post-signing email option effectively',
    description:
      `<p class = "mb-1">
      <strong>Step 1</strong><br/>
      This prompt takes place after step 6, when you have reviewed the entry and are about to save it. At this point, ask the signer if they'd like an email record of this transaction. If they say yes, enter their email and click "add"<br/>
<strong>Note - This is completely option. We do not recommend pressuring the signer</strong>
      </p>
      <p class = "mb-1">
      <strong>Step 2</strong><br/>
      You can change your contact information to display your business name and any other contact information you'd like to provide<br/>
      That's it! The signer will receive an email overview of what just took place. We built this feature to make it easier for the signer to 
      <ul><li>Contact you directly if they ever need an official copy of this notary journal entry and Reach back out for future notarial needs.</li>
      <li>You give your clients the best possible service, and we want to make sure they can easily reach you if they need anything notarized in the future!</li>
      </ul>
      </p>
      <p class = "mb-1">
      For a detailed article on this, <a target="_blank" href="https://www.juratinc.com/understanding-the-postsigning-email-workflow">click here</a>
      </p>`
  },
  {
    id: 8,
    title: 'Offline functionality explained',
    description:
      `<p class = "mb-1">
      <strong>Does the Notary eJournal function offline?</strong><br/>
      The app will function for 12 hours from your most recent point of server authentication. Server authentication is defined as successfully logging in to the app while connected to wifi or cell service.
      </p>
      <p class = "mb-1">
      Note - any feature that requires connectivity will not function while in an offline state, ie “Detect current location” will not function. ID scanning will function while offline
      </p>
      <p class = "mb-1">
      For a detailed article on this, <a target="_blank" href="https://www.juratinc.com/offline-functionality-explained">click here</a>
      </p>`
  },
  {
    id: 9,
    title: 'How to create a journal entry for a signature by mark scenario?',
    description:
      `<p class = "mb-1">
      <strong>Step 1</strong><br/>
      Start your entry like normal, adding your location on step 1, your signer's information on step 2 and the documents on step 3. The signature by mark process takes place on step 4 of the app, where signature capture takes place
      </p>
      <p class = "mb-1">
      <strong>Step 2</strong><br/>
      Click "Add signature by mark" under the person's name. Have the signer make their mark on the signature pad and click "save
      </p>      
      <p class = "mb-1">
      <strong>Optional</strong><br/>
      Some states may require (or it may be considered a best practice in some states) a witness to write the name of the person who is signing by mark next to their mark on the signature pad. If you choose to do this, make sure you instruct the signer and witness to leave enough space on the signature pad to do this.
      </p>

      <p class = "mb-1">
      <strong>Step 3</strong><br/>
      After the signer makes their mark, you will have the option to input information for up to 2 witnesses. Only 1 is required to move forward in the app. After you enter their witness's information, the signature by mark process has been completed and you can move forward in the app like normal.
      </p>
      <p class = "mb-1">
      For a detailed article on this, <a target="_blank" href="https://www.juratinc.com/signature-by-mark-journal-entry">click here</a>
      </p>`
  }
];
