import FalconCloseButton from 'components/common/FalconCloseButton';
import Flex from 'components/common/Flex';
import SignatureScratchPad from 'components/common/SignatureScratchPad';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const SignatureModal = ({
  author,
  signatureForId,
  showSignatureForm,
  setShowSignatureForm,
  setSignatureData
}) => {
  const [enableSignatureSave, setEnableSignatureSave] = useState(false);
  const [strokeWidth, setStrokWidth] = useState(1.5);

  let signaturePad = {};

  const clearSignaturePad = () => {
    signaturePad.clear();
  };

  const saveSignerSignature = (signatureDataObject) => {
    setSignatureData(signatureDataObject);
    setShowSignatureForm(false);
  };

  return (
    <Modal
      show={showSignatureForm}
      onHide={() => setShowSignatureForm(false)}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>
          Add Signature
          <p className="fs--1 mb-0">
            for <span className="fw-bold fs-0"> {author} </span>
          </p>
        </Modal.Title>
        <FalconCloseButton onClick={() => setShowSignatureForm(false)} />
      </Modal.Header>
      <Modal.Body>
        {/* <Flex justifyContent="center">
          <ReactSignatureCanvas
            ref={ref => (signaturePad = ref)}
            penColor="black"
            minWidth={strokeWidth - 1}
            maxWidth={strokeWidth + 1}
            dotSize={1.5}
            canvasProps={{ width: 430, height: 300, className: 'sigCanvas' }}
            onEnd={() => setEnableSignatureSave(true)}
          />
        </Flex> */}
        <SignatureScratchPad setSignatureData={saveSignerSignature} signatureSignerId={signatureForId} closeEle={<Button
          className="mx-1"
          variant="danger"
          onClick={() => setShowSignatureForm(false)}
        >
          Close
        </Button>} />
      </Modal.Body>
    </Modal>
  );
};
SignatureModal.propTypes = {
  author: PropTypes.string,
  signatureForId: PropTypes.string,
  showSignatureForm: PropTypes.bool.isRequired,
  setShowSignatureForm: PropTypes.func.isRequired,
  setSignatureData: PropTypes.func.isRequired
};

export default SignatureModal;
