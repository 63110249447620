import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import c from 'util/const';
import logger from 'util/logger';
import WizardInput from '../WizardInput';
import { Autocomplete, usePlacesWidget } from 'react-google-autocomplete';
import GooglePlaceSearch from './GoolePlaceSearch';

const AddressForm = ({
  address,
  setAddressValue,
  setSearchedAddress,
  register,
  errors
}) => {
  const states = c.states;
  const drawGoogleAddress = place => {
    logger.log('Google address', place);
    let parsed = parseGoogleResults(place.address_components);
    let translated = parseIntoServerTerms(parsed);
    logger.log('Translated Google Address ', translated);
    setAddressValue('address_1', translated.address_1);
    setAddressValue('address_2', translated.address_2);
    setAddressValue('city', translated.city);
    setAddressValue('state', translated.state);
    setAddressValue('zip', translated.zip);
  };

  const parseGoogleResults = address_components => {
    let components = {};

    _.each(address_components, function (k, v1) {
      _.each(address_components[v1].types, function (k2, v2) {
        components[address_components[v1].types[v2]] =
          address_components[v1].long_name;
      });
    });
    return components;
  };

  const parseIntoServerTerms = res => {
    logger.log('Raw address response', res);
    let address_1 = '';
    let address_2 = '';
    let city = '';
    let state = '';
    let zip = '';
    let latitude = '';
    let longitude = '';
    let notarization_date = null;

    if (res.street_number || res.locality) {
      address_1 += res.street_number || res.locality;
    }

    if (res.street_number && res.locality) {
      address_2 = res.locality;
    }

    if (res.route) {
      if (address_1.length > 0) {
        address_1 += ' ';
      }
      address_1 += res.route;
    }

    if (res.administrative_area_level_2) {
      city = res.administrative_area_level_2;
    }
    if (res.administrative_area_level_1) {
      state = lookupStateAbbreviation(res.administrative_area_level_1);
    }
    if (res.postal_code) {
      zip = res.postal_code;
    }
    return {
      address_1,
      address_2,
      city,
      state,
      zip,
      latitude: res.latitude,
      longitude: res.longitude
    };
  };

  const lookupStateAbbreviation = val => {
    let found = null;
    // _.each(c.states, s => {
    //   if (s.text === val.toUpperCase()) {
    //     found = s.text;
    //   } else if (s.name === _.capitalize(val)) {
    //     found = s.text;
    //   }
    // });

    found = _.find(c.states, s => {
      return s.text === val.toUpperCase() || s.name === _.capitalize(val);
    });
    logger.log('State found', found, val);
    return found ? found.text : '';
  };

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    onPlaceSelected: place => drawGoogleAddress(place),
    options: {
      types: ['courthouse', 'lawyer', 'accounting', 'country', 'locality'],
      componentRestrictions: { country: 'us' },
      fields: ['ALL']
    }
  });

  //let googleMapOptions = { componentRestrictions: { country: 'us' } };
  return (
    <>
      <Row className="g-2 mb-3">
        {/* <Col sm={12}>
          <Form.Control
            type="text"
            placeholder="Type text to search your address here..."
            ref={ref}
          />
        </Col> */}
        <GooglePlaceSearch col={12} setSearchedAddress={setSearchedAddress} />
      </Row>

      <Row className="g-2 mb-3">
        <Col sm={4}>
          <WizardInput
            label="Address line 1"
            name="address_1"
            errors={errors}
            formGroupProps={{ className: 'mb-3' }}
            placeholder="Address line 1 here"
            formControlProps={{
              ...register('address_1', {
                required: 'Enter address line 1',
                pattern: {
                  //value: /^[a-z\d\-_\s]+$/i,
                  message: 'Address line 1 required'
                }
              })
            }}
            value={address.address_1}
            setValue={setAddressValue}
          />
        </Col>
        <Col sm={4}>
          <WizardInput
            label="Address line 2"
            name="address_2"
            errors={errors}
            formGroupProps={{ className: 'mb-3' }}
            placeholder="Address line 2 here - (Optional)"
            formControlProps={{
              ...register('address_2', {})
            }}
            value={address.address_2}
            setValue={setAddressValue}
          />
        </Col>

        <Col sm={4}>
          <WizardInput
            label="City"
            name="city"
            errors={errors}
            formGroupProps={{ className: 'mb-3' }}
            placeholder="City name here"
            formControlProps={{
              ...register('city', {
                pattern: {
                  value: /^[a-z\d\-_\s]+$/i,
                  message: 'City name required'
                }
              })
            }}
            value={address.city}
            setValue={setAddressValue}
          />
        </Col>

        <Col sm={4}>
          <Form.Group as={Col} lg={6} controlId="state" className="w-100">
            <Form.Label>State</Form.Label>
            <Form.Select
              type="text"
              name="state"
              value={address.state}
              onChange={e => setAddressValue(e.target.name, e.target.value)}
            >
              <option value="">Select state</option>
              {states.map(option => (
                <option value={option.text} key={option.text}>
                  {option.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>

        <Col sm={4}>
          <WizardInput
            label="Postal code"
            name="zip"
            errors={errors}
            formGroupProps={{ className: 'mb-3' }}
            placeholder="Postal code here
            "
            formControlProps={{
              ...register('zip', {
                pattern: {
                  value: /^[A-Za-z0-9? ,_-]+$/i,
                  message: 'Postal code required'
                }
              })
            }}
            value={address.zip}
            setValue={setAddressValue}
          />
        </Col>
      </Row>
    </>
  );
};
AddressForm.propTypes = {
  address: PropTypes.object.isRequired,
  setAddressValue: PropTypes.func.isRequired,
  setSearchedAddress: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object
};
export default AddressForm;
