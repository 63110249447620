import _ from 'lodash';
import c from 'util/const';

const locationHelper = {
  getStateNameByCode(code) {
    let states = c.states;
    let foundState = _.find(c.states, s => s.text === code);
    if (foundState)
      return foundState.name;
    else
      return code;
  },

}

export default locationHelper;
