import AppContext from 'context/Context';
import segmentHelper from 'helpers/segment-helper';
import { useAuth } from 'hooks/useAuth';
import { useContext } from 'react';
import { Navigate, useLocation, useOutlet } from 'react-router-dom';

export const ProtectedLayout = ({ children }) => {
  const { token } = useAuth();
  const location = useLocation();
  if (token == null) {
    segmentHelper.reset();
    Intercom('shutdown');
    return <Navigate to="/login" state={{ path: location.pathname }} />;
  }

  return children;
};
